import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useCategoriesList() {
    //User toast
    const toast = useToast()

    const refCategoryListTable = ref(null)

    const tableColumns = [
        {key: 'id', label:'編號', sortable: true},
        {key:'image_path', label:'相片'},
        {key: 'name', label: '類別名稱'},
        {key: 'short_description', label: '簡短描述'},
        {key: 'is_active', label:'狀態',sortable: true},
        {key: 'actions', label: '操作'},
    ]

    const perPage = ref(10)
    const totalCategories = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('sort_order')
    const isSortDirDesc = ref(true)

    //Filter 
    const statusFilter = ref(-1)


    const dataMeta = computed(() => {
        const localItemsCount = refCategoryListTable.value ? refCategoryListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCategories.value,
        }
    })

    const refetchData = () => {
        refCategoryListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })

    const fetchCategories = (ctx, callback) => {
        store.dispatch('category/fetchCategories',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            is_active: statusFilter.value, // As defined -1 means get all category not matter it is active or not
 
        })
        .then(response =>{
            const {warehouse_categories, total} = response.data
            callback(warehouse_categories)
            totalCategories.value = total        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching category list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchCategories,
        tableColumns,
        perPage,
        currentPage,
        totalCategories,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCategoryListTable,
        ability,
        refetchData,
        statusFilter, 
    }

}