import { render, staticRenderFns } from "./ModalEditTask.vue?vue&type=template&id=06b78a25&scoped=true"
import script from "./ModalEditTask.vue?vue&type=script&lang=js"
export * from "./ModalEditTask.vue?vue&type=script&lang=js"
import style0 from "./ModalEditTask.vue?vue&type=style&index=0&id=06b78a25&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b78a25",
  null
  
)

export default component.exports