<template>
  <div>
    <modal-edit-price
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      :priceData="priceData"
      @refetch-data="refetchData"
    />
    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex align-items-cente">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block common-rounded"
                placeholder="搜尋合約編號/客戶編號/客戶名稱"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">類型</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              placeholder="選擇類型"
              class="d-inline-block common-rounded"
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <a
            class="mr-2"
            v-b-popover.hover.top="'新增價目表'"
            href="javascript:void(0)"
            @click="$bvModal.show('modal-edit-price')"
          >
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <div class="card-outer bgColor0">
        <div class="d-flex align-items-center justify-content-center">
          <a
            :class="`${warehouse_category_id == 1 ? 'active' : ''}`"
            @click="warehouse_category_id = 1"
            href="javascript:void(0)"
            >迷你倉</a
          >
          <a
            :class="`${warehouse_category_id == 2 ? 'active' : ''}`"
            @click="warehouse_category_id = 2"
            href="javascript:void(0)"
            >迷你辦公室</a
          >
          <a
            :class="`${warehouse_category_id == 3 ? 'active' : ''}`"
            @click="warehouse_category_id = 3"
            href="javascript:void(0)"
            >正常單位</a
          >
        </div>
      </div>

      <b-table
        ref="refPriceListTable"
        class="position-relative"
        :items="priceList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(size)="data">
          <span>
            {{ data.item.depth }} * {{ data.item.width }} *
            {{ data.item.height }}</span
          >
        </template>
        <template #cell(area)="data">
          <span>
            {{
              (data.item.depth * data.item.width)
                .toFixed(2)
                .replace(/\.00$/, "")
            }}
          </span>
        </template>
        <template #cell(monthly_price)="data">
          <span> HK${{ data.item.monthly_price }}</span>
        </template>
        <template #cell(bi_annual_price)="data">
          <span>
            HK${{ data.item.bi_annual_price }} /HK${{
              Math.ceil(data.item.bi_annual_price / 6)
            }}</span
          >
        </template>
        <template #cell(annual_price)="data">
          <span>
            HK${{ data.item.annual_price }} /HK${{
              Math.ceil(data.item.annual_price / 12)
            }}</span
          >
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="handleEdit(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPrices"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
  <script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBPopover
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import priceStoreModule from "../priceStoreModule";
import usePricesList from "./usePriceList";
import ModalEditPrice from "../modal/modal-edit-price.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    vSelect,
    ModalEditPrice,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      priceData: {},
      isEdit: false,
    };
  },
  methods: {
    handleEdit(id) {
      // this.fetchPrice(id);
      this.priceData = this.priceList.filter((ele) => ele.id == id)[0];
      this.isEdit = true;
      this.$bvModal.show("modal-edit-price");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
    },
    confirmDelete(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "Your action is final and you will not be able to retrieve the warehouse.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("price/deletePrice", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    fetchPrice(id) {
      store
        .dispatch("price/fetchPrice", {
          id,
        })
        .then((response) => {
          this.priceData = { ...response.data.price };
        })
        .catch((error) => {
          console.log("error when fetching price", error);
          if (error.response.status === 404) {
            this.priceData = undefined;
          }
        });
    },
  },
  setup() {
    const APP_PRICE_STORE_MODULE_NAME = "price";

    if (!store.hasModule(APP_PRICE_STORE_MODULE_NAME))
      store.registerModule(APP_PRICE_STORE_MODULE_NAME, priceStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_PRICE_STORE_MODULE_NAME))
        store.unregisterModule(APP_PRICE_STORE_MODULE_NAME);
    });

    const {
      fetchPrices,
      tableColumns,
      perPage,
      currentPage,
      totalPrices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPriceListTable,
      ability,
      refetchData,
      priceList,
      warehouse_category_id,
    } = usePricesList();

    return {
      fetchPrices,
      tableColumns,
      perPage,
      currentPage,
      totalPrices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPriceListTable,
      ability,
      refetchData,
      priceList,
      warehouse_category_id,
    };
  },
};
</script>
<style lang="scss">
.table.b-table[aria-busy="true"] {
  opacity: 1;
}
.card {
  .table.b-table {
    [aria-busy="true"] & {
      opacity: 0.55;
    }
  }
}
</style>