<template>
  <div class="payment-detail">
    <div class="payment-info">
      <b-table
        v-if="paymentStatusOptions && paymentStatusOptions.length > 0"
        ref="refPaymentScheduleListTable"
        class="position-relative"
        :items="newPaymentScheduleData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(mini_storage_number)="data">
          {{ miniStorageNumber }}
        </template>
        <template #cell(amount)="data">
          {{ (+data.item.amount + +data.item.deposite + +data.item.card_deposite - +data.item.total_discount).toFixed(2) }}
        </template>

        <template #cell(payment_status)="data">
          <b-button
            class="stage-button"
            v-if="data.item.invoice"
            :style="{
              backgroundColor:
                paymentStatusOptions.find(
                  (ele) =>
                    ele.id ===
                    (data.item.deleted_at
                      ? 4
                      : data.item.total_amount - data.item.total_paid_amount > 0
                      ? 1
                      : data.item.total_paid_amount -
                          data.item.total_audit_paid_amount <=
                        0
                      ? 3
                      : data.item.total_amount - data.item.total_paid_amount <=
                        0
                      ? 2
                      : 1)
                ).background_color + '!important',
              color:
                paymentStatusOptions.find(
                  (ele) =>
                    ele.id ===
                    (data.item.deleted_at
                      ? 4
                      : data.item.total_amount - data.item.total_paid_amount > 0
                      ? 1
                      : data.item.total_paid_amount -
                          data.item.total_audit_paid_amount <=
                        0
                      ? 3
                      : data.item.total_amount - data.item.total_paid_amount <=
                        0
                      ? 2
                      : 1)
                ).font_color + '!important',
            }"
            >{{
              paymentStatusOptions.find(
                (ele) =>
                  ele.id ===
                  (data.item.deleted_at
                    ? 4
                    : data.item.total_amount - data.item.total_paid_amount > 0
                    ? 1
                    : data.item.total_paid_amount -
                        data.item.total_audit_paid_amount <=
                      0
                    ? 3
                    : data.item.total_amount - data.item.total_paid_amount <= 0
                    ? 2
                    : 1)
              ).name
            }}
          </b-button>
          <span v-else class="colorRed">需要先生成發票</span>
        </template>

        <template #cell(prepay_type_id)="data">
          <div>
            <span v-if="prepayTypeId == 1"> 月付 </span>
            <span v-if="prepayTypeId == 2"> 預付6個月 </span>
            <span v-if="prepayTypeId == 3"> 預付12個月 </span>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="text-center">
            <a
              v-if="
                !data.item.invoice && ability.can('create', 'payment-schedules')
              "
              v-b-popover.hover.top="'生成發票'"
              href="javascript:void(0)"
              @click="generateInvoice(data.item.id)"
            >
              <font-awesome-icon
                class="color1"
                icon="fa-solid fa-file-circle-plus"
              />
            </a>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
      
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios.js";
import {
  ref,
  watch,
  onUnmounted,
  onMounted,
  computed,
} from "@vue/composition-api";
import ability from '@/libs/acl/ability'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    "b-popover": VBPopover,
  },
  methods: {
    generateInvoice(id) {
      this.$swal({
        title: "你確定要生成發票嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          axiosIns
            .get("/payment_schedule/invoice", { params: {id} })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit('refetch-mini-data');
              this.$emit('refetch-data');
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      ability,
      paymentStatusOptions: [
        {
          id: 1,
          name: "待支付",
          background_color: "rgb(57 95 113)",
          font_color: "#ffffff",
        },
        {
          id: 2,
          name: "已支付",
          background_color: "rgb(28 154 170)",
          font_color: "#ffffff",
        },
        {
          id: 3,
          name: "已核數",
          background_color: "rgb(0 163 255)",
          font_color: "#ffffff",
        },
        {
          id: 4,
          name: "已刪除",
          background_color: "rgb(255 192 74",
          font_color: "#ffffff",
        },
      ],
    };
  },
  props: {
    paymentScheduleData: {},
    prepayTypeId: {},
    miniStorageNumber: {},
  },
  setup(props) {
    const refPaymentScheduleListTable = ref(null);

    const tableColumns = [
      { key: "payment_status", label: "狀態", sortable: true },
      { key: "pay_date", label: "付款日期", sortable: true },
      { key: "mini_storage_number", label: "迷你倉", sortable: true },
      { key: "prepay_type_id", label: "預付款", sortable: true },
      { key: "payment_cycle", label: "付款週期", sortable: true },
      { key: "standard_price", label: "標準租金", sortable: true },
      { key: "price", label: "租金", sortable: true },
      { key: "deposite", label: "按金", sortable: true },
      { key: "card_deposite", label: "智能卡按金", sortable: true },
      { key: "total_discount", label: "總折扣"},
      { key: "amount", label: "總值", sortable: true },
      { key: "action", label: "操作" },
    ];

    const perPage = ref(10);
    const totalRow = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
      const localItemsCount = refPaymentScheduleListTable.value
        ? refPaymentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRow.value,
      };
    });

    const refetchTbData = (total, meta, perPage, current, target, from) => {
      total.value = from && from.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      target.value =
        from && from.filter((ele, index) => index >= min && index <= max);
    };

    const newPaymentScheduleData = ref(null);
    watch(
      () => props.paymentScheduleData,
      (newVal) => {
        newPaymentScheduleData.value = newVal;

        refetchTbData(
          totalRow,
          dataMeta,
          perPage,
          currentPage,
          newPaymentScheduleData,
          newVal
        );
      },
      { immediate: true, deep: true }
    );

    watch(
      () => currentPage.value,
      (newVal) => {
        refetchTbData(
          totalRow,
          dataMeta,
          perPage,
          currentPage,
          newPaymentScheduleData,
          props.paymentScheduleData
        );
      }
    );

    return {
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentScheduleListTable,
      newPaymentScheduleData,
    };
  },
};
</script>
      
<style lang="scss" scoped>
</style>
      
      <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
      
      