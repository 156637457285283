import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useInvoicesList() {
    //User toast
    const toast = useToast()
    

    const refInvoiceListTable = ref(null)

    const tableColumns = [
        { key: 'invoice_status_id', label: '狀態' },
        { key: 'mini_storage', label: '迷李倉庫'},
        { key: 'invoice_number', label: '發票編號', sortable: true },
        { key: 'invoice_date', label: '發票日期', sortable: true  },
        { key: 'show_details', label: '詳情' },
        { key: 'sale_id', label: '項目經理' },
        { key: 'contract_number', label: '合約編號' },
        { key: 'customer.name', label: '客戶' },
       /*  { key: 'customer_id', label: '客戶編號', sortable: true }, */
        // { key: 'total_amount', label:'發票總價',  sortable:true},
        { key: 'total_amount', label:'應付金額'},
        { key: 'total_paid_amount', label:'已付金額'},
        { key: 'total_audit_paid_amount', label:'已核數金額'},
        { key: 'balance', label:'餘額'},
        { key: 'remark', label:'備註',  sortable:true},
        { key: 'actions', label:'操作'}
    ]

    //Filter 

    const perPage = ref(25)
    const totalRows = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRows.value,
        }
    })

    const invoice_status_id = ref(null)
    const warehouse_id = ref(null)
    const miniStorageFilter = ref(null)
    const contractFilter = ref(null)

    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, invoice_status_id, warehouse_id, miniStorageFilter, contractFilter], () => {
        refetchData()
    })


    const tableItemColumns = [
        { key: 'id', label: '編號', sortable: true },
        { key: 'quantity', label: '數量', sortable: true },
        { key: 'price', label: '單價', sortable: true },
        { key: 'amount', label: '數額', sortable: true },
        { key: 'description', label: '描述', sortable: true },
    ];


    const tablePaymentColumns = [
        { key: 'id', label: '編號', sortable: true },
        { key: 'image', label: '文件' },
        { key: 'payment_type_id', label: '付款類型', sortable: true },
        { key: 'amount', label: '金額', sortable: true },
        { key: 'points', label: '積分', sortable: true },
        // { key: 'complete', label: '完成', sortable: true },
        { key: 'description', label: '描述', sortable: true },
        { key: 'audit', label: '核實付款', sortable: true },
        { key: 'receipt', label: '列印收據', sortable: true },
    ];
    const invoiceList = ref([])
    const fetchInvoices = (ctx, callback) => {
   
        store.dispatch('invoice/fetchInvoices',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            contract_id: contractFilter.value,
            invoice_status_id: invoice_status_id.value,
            warehouse_id: warehouse_id.value,
            mini_storage_id: miniStorageFilter.value

 
        })
        .then(response =>{
            const {invoices, total} = response.data
            callback(invoices)
            totalRows.value = total        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching invoice list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    const invoiceStatusOptions = ref([])
    // const invoiceTypeOptions = ref([])
    const contractOptions = ref([]);
    const customerOptions = ref([]);
    const saleOptions = ref([])
    const invoiceStatusOptions2 = ref([])
    const miniStorageOptions = ref([]);
    const paymentTypeOptions = ref([]);
    const warehouseOptions = ref([])

    const refetchOption = () => {
        invoiceStatusOptions.value = [
            { id: 1, name: '待支付', background_color: 'rgb(57 95 113)', font_color: '#ffffff' },
            { id: 2, name: '已支付', background_color: 'rgb(28 154 170)', font_color: '#ffffff' },
            { id: 3, name: '已核數', background_color: 'rgb(0 163 255)', font_color: '#ffffff' },
            { id: 4, name: '已刪除', background_color: 'rgb(204 36 36', font_color: '#ffffff' },
        ]

        // invoiceTypeOptions.value = [
        //     { id: 1, name: '租金' },
        //     { id: 2, name: '罰金' },
        //     { id: 3, name: '清潔費' },
        // ]
        store
          .dispatch("app/fetchOptions", { 
            // invoice: true,
            contract: true,
            customer: true,
            mini_storage: true,
            user: true,
            invoice_status: true,
            payment_type: true,
            warehouse: true
         })
          .then((response) => {
            // invoiceStatusOptions.value = response.data.invoices;
            contractOptions.value = response.data.contracts;
            customerOptions.value = response.data.customers;
            miniStorageOptions.value = response.data.mini_storages;
            saleOptions.value = response.data.users;
            invoiceStatusOptions2.value = response.data.invoice_statuses
            paymentTypeOptions.value = response.data.payment_types.filter(ele => ele.id > 1);
            warehouseOptions.value = response.data.warehouses;
          })
          .catch((error) => {
            console.log({ error });
          });
    };


    onMounted(() => {
        refetchOption();
    })

    return {
        fetchInvoices,
        tableColumns,
        perPage,
        currentPage,
        totalRows,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refInvoiceListTable,
        ability,
        refetchData,
        invoiceList,
        invoice_status_id,
        tableItemColumns,
        tablePaymentColumns,

        invoiceStatusOptions,
        // invoiceTypeOptions,
        contractOptions,
        customerOptions,
        saleOptions,
        invoiceStatusOptions2,
        miniStorageOptions,
        paymentTypeOptions,
        warehouseOptions,
        warehouse_id,
        miniStorageFilter,
        contractFilter
    }

}