<template>
  <div>
    <modal-pdf :pdf-url="pdfUrl" />
    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div class="d-flex flex-wrap align-items-center colorWhite">
        <div class="d-flex align-items-center mr-2">
          <span>顯示</span>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block common-rounded mx-50"
          />
          <span>條目</span>
        </div>
        <div class="d-flex align-items-center mr-2">
          <span class="mr-1">搜尋</span>
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              class="d-inline-block common-rounded"
              placeholder="搜尋合約編號/客戶編號/客戶名稱"
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
            <v-select
              v-model="customerFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="過瀘客戶"
              :clearable="false"
              class="d-inline-block common-rounded mx-50"
            />
          </div>
        <div class="d-flex align-items-center">
          <span class="mr-1">狀態</span>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="d-inline-block common-rounded"
          />
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-table
        ref="refContractListTable"
        class="position-relative"
        :items="contractList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(smart_cards)="data">
          {{ data.item.smart_cards.toString() }}
        </template>
        <template #cell(export)="data">
          <a href="javascript:void(0)" @click="downloadContract(data.item.id)">
            <feather-icon icon="DownloadIcon" />
            <span class="align-middle ml-50">下載</span>
          </a>
        </template>
        <template #cell(whatsapp_link)="data">
          <div class="d-flex justify-content-center">
            <a :href="data.item.whatsapp_link" target="_blank">
              <img
                src="@/assets/images/icons/whatsapp-logo.png"
                style="width: 24px"
                class="whatsapp-icon"
              />
            </a>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import contractStoreModule from "../contractStoreModule";
import useContractsList from "./useContractsList";
import ModalPdf from "../modal/ModalPdf.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalPdf,
  },
  methods: {
    resetContractData() {
      this.contractData = {
        id: 0,
      };
    },
    downloadContract(id) {
      this.loading = true;
      this.$store
        .dispatch("contract/downloadContract", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
  },
  data() {
    return {
      id: 0,
      pdfUrl: "",
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "contract";
    const isAddNewContractSidebarActive = ref(false);

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refContractListTable,
      showMessage,
      contractList,
      customerFilter,
      userFilter,
      contractStatusFilter,
      customerOptions,
      userOptions,
      contractStatusOptions,
    } = useContractsList();

    return {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refContractListTable,
      showMessage,
      contractList,
      customerFilter,
      userFilter,
      contractStatusFilter,
      customerOptions,
      userOptions,
      contractStatusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

