import axiosIns from '@/libs/axios'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addPayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/payment', data,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/payment/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/payment', data,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePayment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/payment', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReceipt(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/payment/export', { params: queryParams , responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    fetchPaymentSchedules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/payment_schedule/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // auditInvoice(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns.post('/payment/audit', data)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    generateInvoice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/payment_schedule/invoice', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
