<template>
  <b-modal
    id="modal-edit-audit"
    cancel-variant="outline-secondary"
    centered
    size="sm"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">核實付款</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="payment-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            <b-col cols="12">
              <div class="info-item">
                <b class="info-item-label"
                  >核實 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="核實"
                  rules="required"
                >
                  <b-form-checkbox
                    :checked="newAuditData.is_audit"
                    name="complete"
                    switch
                    inline
                    v-model="newAuditData.is_audit"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{ newAuditData.is_audit ? "是" : "否" }}
                  </b-form-checkbox>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>  <b-col cols="12">
              <div class="info-item">
                <b class="info-item-label"
                  > 收據日期 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="收據日期"
                  rules="required"
                >
                
                <flat-pickr
                    v-model="newAuditData.receipt_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                    placeholder="輸入YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="info-item">
                <b class="info-item-label">備註</b>
                <b-form-input v-model="newAuditData.audit_remark"></b-form-input>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    auditData: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-audit");
    },
    resetModal() {
      this.newAuditData = {is_audit:1};
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.newAuditData.is_audit = this.newAuditData.is_audit ? 1 : 0
          axiosIns.post('/payment/audit', this.newAuditData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newAuditData = ref({ id: 0 });

    const resetauditData = () => {
      newAuditData.value = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetauditData);

    watch(
      () => props.auditData,
      (newVal) => {
        newAuditData.value = newVal;
      }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newAuditData,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.info-wrap .info-item b {
  min-width: 140px;
}
.info-item-label {
  & + span {
    width: 100%;
  }
}
.v-select {
  width: 100%;
}
</style>
  