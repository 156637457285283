<template>
  <div class="terminate-detail">
    <div class="terminate-info" v-if="terminateData">
      <h4 class="text-center color1">退租</h4>
      <b-row class="info-wrap">
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">合約編號</b>
            <span>{{ terminateData.contract_id }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">客戶名稱</b>
            <span>{{ terminateData.name }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">退租日期</b>
            <span>{{ terminateData.termination_date }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">退租總金額</b>
            <span>{{ terminateData.total_amount }}</span>
          </div>
        </b-col>
      </b-row>
      <hr />
      <div class="">
        <div class="mb-2 d-flex justify-content-center">
          <h4 class="colorRed">項目資料</h4>
        </div>
        <b-row
          class="info-wrap"
          v-for="(item, index) of terminateData.termination_items"
          :key="item.id + item.item_name"
        >
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">名稱</b>
              <span>{{ item.item_name }}</span>
            </div>
          </b-col>
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">數量</b>
              <span>{{ item.quantity }}</span>
            </div>
          </b-col>
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">單價</b>
              <span>{{ item.price }}</span>
            </div>
          </b-col>
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">金額</b>
              <span>{{ item.amount }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
      
      <script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios.js";
import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {},
  data() {
    return {};
  },
  props: {
    terminateData: {},
  },
  setup() {
    return {};
  },
};
</script>
      
<style lang="scss" scoped>
</style>
      
      <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
      
      