<template>
  <div class="payment-detail">
    <div class="payment-info" v-if="newRentScheduleData">
      <b-table
        ref="refRentScheduleListTable"
        class="position-relative"
        :items="newRentScheduleData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
       
      <template #cell(mini_storage_number)="data">
            {{miniStorageNumber}}
      </template>
      <template #cell(start_date)="data">
            {{data.item.start_date.indexOf('T') > -1 ? data.item.start_date.split('T')[0] : data.item.start_date}}
      </template>
      <template #cell(end_date)="data">
            {{data.item.end_date.indexOf('T') > -1 ? data.item.end_date.split('T')[0] : data.item.end_date}}
      </template>
      
      <template #cell(prepay_type_id)="data">
        <div>
          <span v-if="prepayTypeId == 1"> 月付 </span>
          <span v-if="prepayTypeId == 2"> 預付6個月 </span>
          <span v-if="prepayTypeId == 3"> 預付12個月 </span>
        </div>
      </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
      
      <script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios.js";
import { ref, watch, onUnmounted, onMounted, computed } from "@vue/composition-api";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {},
  data() {
    return {};
  },
  props: {
    rentScheduleData: {},
    prepayTypeId: {},
    miniStorageNumber: {},
  },
  setup(props) {
    const refRentScheduleListTable = ref(null);

    const tableColumns = [
      { key: "start_date", label: "開始日期", sortable: true },
      { key: "end_date", label: "結束日期" },
      { key: "mini_storage_number", label: "迷你倉", sortable: true },
      /* { key: "prepay_type_id", label: "預付款", sortable: true }, */
      { key: "amount", label: "價錢", sortable: true },
    ];

    const perPage = ref(10);
    const totalRow = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
      const localItemsCount = refRentScheduleListTable.value
        ? refRentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRow.value,
      };
    });


    const refetchTbData = (total, meta, perPage, current, target, from) => {
      total.value = from && from.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      target.value = from && from.filter((ele, index) => index >= min && index <= max);
      console.log(target.value)
    };

    const newRentScheduleData = ref(null)
    watch(
      () => props.rentScheduleData,
      (newVal) => {
        newRentScheduleData.value = newVal
        refetchTbData(
            totalRow,
            dataMeta,
            perPage,
            currentPage,
            newRentScheduleData,
            newVal
          );
      },{immediate:true}
    )

    watch(
      () => currentPage.value,
      (newVal) => {
        refetchTbData(
            totalRow,
            dataMeta,
            perPage,
            currentPage,
            newRentScheduleData,
            props.rentScheduleData
          );
      }
    )

    return {
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refRentScheduleListTable,
        newRentScheduleData
    }


  },
};
</script>
      
<style lang="scss" scoped>
</style>
      
      <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
      
      