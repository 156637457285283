<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="customerData === undefined">
      <h4 class="alert-heading">Error fetching customer data</h4>
      <div class="alert-body">
        No customer found with this customer id. Check
        <b-link class="alert-link" :to="{ name: 'customers-list' }">
          Customer List
        </b-link>
        for other customers.
      </div>
    </b-alert>

    <b-tabs v-if="customerData" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">User Information</span>
        </template>
        <customer-edit-tab-information
          :customer-data="customerData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
         <template #title>
          <feather-icon icon="GiftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Loyalty Points</span>
        </template>
        <CustomerPoint :customer-data="customerData" @refetch-data="refetchData"/>
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BTable } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import customerStoreModule from "../customerStoreModule";
import CustomerEditTabInformation from "./CustomerEditTabInformation.vue";
import CustomerPoint from "./CustomerPoint.vue"
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BTable,
    CustomerEditTabInformation,
    CustomerPoint,
  },
  setup() {
    const customerData = ref(null);

    const CUSTOMER_APP_STORE_MODULE_NAME = "app-customer";

    const customer = {
      id : 0,
      is_active:true
    }


    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if(router.currentRoute.name == 'customers-create') {
          customerData.value = customer
      }else{
        store
        .dispatch("app-customer/fetchCustomer", { id: router.currentRoute.params.id })
        .then((response) => {
          customerData.value = response.data.customer;
          customerData.value.is_active = (customerData.value.is_active == 1)?true:false
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            customerData.value = undefined;
          }else{
            console.log('Fail to refetchData', error)
          }
        });
      }

    };

    refetchData()

    return {
      customerData,
      refetchData,
    };
  },
};
</script>

<style></style>
