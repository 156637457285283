import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import axiosIns from '@/libs/axios.js'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useContractsList() {

    const toast = useToast()

    const refContractListTable = ref(null)

    const tableColumns = [
        { key: 'mini_storage', label: '合約單位' },
        { key: 'name', label: '客戶', sortable: true },
        { key: 'customer_id', label: '客戶編號', sortable: true },
        { key: 'contract_number', label: '合約編號', sortable: true },
        { key: 'sale_id', label: '銷售顧問', sortable: true },
        { key: 'sign_date', label: '簽約時間', sortable: true },
        { key: 'contract_date', label: '生成日期', sortable: true },
        { key: 'sign_status', label: '簽約狀態' },
        // { key: 'invoice_id', label: '發票編號', sortable: true },
        { key: 'smart_cards', label: '智能卡' },
        { key: 'start_date', label: '開始日期', sortable: true },
        { key: 'end_date', label: '完結日期', sortable: true },
        { key: 'amount', label: '總值', sortable: true },
        { key: 'rent_period', label: '期數', sortable: true },
        { key: 'price', label: '租金', sortable: true },
        { key: 'standard_price', label: '標準租金', sortable: true },
        { key: 'deposite', label: '按金', sortable: true },
        { key: 'card_deposite', label: '智能卡按金', sortable: true },
        { key: 'remark', label: '備註', sortable: true },
        { key: 'contract_status', label: '合約狀態' },
        { key: 'media', label: '媒體' },
        { key: 'action', label: '操作' },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refContractListTable.value ? refContractListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refContractListTable.value.refresh()
    }

    const customerFilter = ref(null)
    const userFilter = ref(null)
    const contractStatusFilter = ref(null)
    const warehouseFilter = ref(null)

    watch([currentPage, perPage, searchQuery, warehouseFilter, customerFilter, userFilter, contractStatusFilter], () => {
        refetchData()
    })


    const contractList = ref(null)

    const fetchContracts = (ctx, callback) => {

        store.dispatch('contract/fetchContracts', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            customer_id: customerFilter.value,
            sale_id: userFilter.value,
            contract_status_id: contractStatusFilter.value,
            warehouse_id: warehouseFilter.value,
        })
            .then(response => {
                const { contract, total } = response.data
                contractList.value = contract
                callback(contract);
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching contract list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }
    const customerOptions = ref([])
    const contractStatusOptions = ref([])
    const saleOptions = ref([])
    const signStatusOptions = ref([]);
    const warehouseOptions = ref([]);

    const emailTemplateOptions = ref([])
    const fetchOptions = () => {
        store
            .dispatch("app/fetchOptions", {
                user: true,
                sign_status: true,
                contract_status: true,
                customer: true,
                warehouse: true,
            })
            .then((response) => {
                saleOptions.value = response.data.users
                signStatusOptions.value = response.data.sign_statuses;
                customerOptions.value = response.data.customers
                contractStatusOptions.value = response.data.contract_statuses
                warehouseOptions.value = response.data.warehouses
            })
            .catch((error) => {
                console.log({ error });
            });

    
    };

    onMounted(() => {
        fetchOptions();
    });

    return {
        fetchContracts,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refContractListTable,
        showMessage,
        contractList,
        saleOptions,
        signStatusOptions,
        customerFilter,
        userFilter,
        warehouseFilter,
        contractStatusFilter,
        customerOptions,
        contractStatusOptions,
        warehouseOptions,
        ability
    }

}