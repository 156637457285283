<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">員工資訊</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form: Personal Info Form -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
      <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <!-- Field: Staff ID -->
          <b-col cols="12" md="12">
            <b-form-group label="員工號碼" label-for="id">
              <b-form-input id="id" v-model="userData.id" readonly />
            </b-form-group>
          </b-col>

          <!-- Field: Name -->
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label="名稱" label-for="name">
                <b-form-input
                  id="name"
                  v-model="userData.name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="電子郵件"
              rules="required"
            >
              <b-form-group label="電子郵件" label-for="email">
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  placeholder="Email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="角色"
              rules="required"
            >
              <b-form-group label-for="user-role" label="角色">
                <v-select style="background: #fff;"
                  v-model="userData.role_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(option) => option.name"
                
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <b-col cols="12" md="12" v-if="userData.id == 0">
            <h4 class="mb-0">密碼</h4>
            <hr />
          </b-col>
          <!-- Field: Password -->
          <b-col cols="12" md="6" v-if="userData.id == 0">
            <validation-provider
              #default="validationContext"
              name="密碼"
              rules="required|min:8"
            >
              <b-form-group
                class="required"
                label="密碼"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  type="password"
                  :state="getValidationState(validationContext)"
                  v-model="userData.password"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Password Confirm -->
          <b-col cols="12" md="6" v-if="userData.id == 0">
            <validation-provider
              #default="validationContext"
              name="確認密碼"
              rules="required|min:8|confirmed:密碼"
            >
              <b-form-group
                class="required"
                label="確認密碼"
                label-for="password_confirmation"
              >
                <b-form-input
                  id="password_confirmation"
                  type="password"
                  :state="getValidationState(validationContext)"
                  v-model="userData.password_confirmation"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label-for="is_active">
              <b-form-checkbox
                checked="true"
                v-model="userData.is_active"
                class="custome-control-success"
                name="check-button"
                switch
                inline
              />
              {{ userData.is_active ? "啟用" : "禁用" }}
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Button: Submit & Reset Button.-->
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              提交
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="resetData"
            >
              重設
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="hide"
            >
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay
  },
  methods: {
    hide() {
      this.$router.replace("/users/list");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-user/updateUser", this.userData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const positionOptions = ref([]);
    const teamOptions = ref([]);
    const loading = ref(false)
    const genderOptions = [
      { text: "Male", value: "m" },
      { text: "Female", value: "f" },
    ];

    const contactOptionsOptions = ["Email", "Message", "Phone"];
    const roleOptions = ref([])


    watch(()=>{
      props.userData.role_name = props.userData.role[0].name
    },{deep:true})

    const resetData = () => {
      emit("refetch-data");
    };
    const resetuserData = () => {
      props.userData = JSON.parse(JSON.stringify({}));
    };

    const getRoleOptions = () => {
      store
        .dispatch("app-user/fetchRoles")
        .then((response) => {
          roleOptions.value = response.data.roles
        });
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);


    onMounted(() => {
      getRoleOptions()
      console.log(props.userData);
    });

    return {
      genderOptions,
      contactOptionsOptions,
      positionOptions,
      teamOptions,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetuserData,
      roleOptions,
      loading
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}
</style>
