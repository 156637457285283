<template>
  <b-modal
    id="modal-delete"
    cancel-variant="outline-secondary"
    centered
    size="sm"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">刪除發票</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="payment-form" @submit.prevent="onSubmit">
        <b-row class="info-wrap">
          <b-col cols="12">
            <div class="info-item">
              <b class="info-item-label"
                >發票編號 </b
              >
              <span class="info-item-value">{{ invoice_id }}</span>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="info-item">
              <b class="info-item-label">
                內部備註
                <span class="colorRed">*</span>
              </b>
              <div>
                <b-form-input v-model="remark"> </b-form-input>

                <div class="colorRed" v-if="!remark">內部備註是必須的</div>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="d-flex flex-wrap justify-content-center w-100 p-2"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
              type="submit"
            >
              提交
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              class="px-3"
              @click="hide"
            >
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      remark: null,
    };
  },
  props: {
    invoice_id: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-delete");
    },
    resetModal() {
      remark: null;
    },
    onSubmit() {
      if (!this.remark) {
        return;
      }
      this.$swal({
        title: "你確定嗎?",
        text: "您的操作是最終的，您將無法檢索該事件。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          const data = { id: this.invoice_id, remark: this.remark };
          store
            .dispatch("invoice/deleteInvoice", data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  watch: {},
  setup() {},
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  