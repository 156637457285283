<template>
    <b-tab>
      <template #title>
        <feather-icon icon="BarChartIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">區域倉庫報表</span>
      </template>
      <b-card no-body class="mb-0 no-custome-top-menu" v-if="false">
        <div id="chart" class="mt-2">
          <apexchart
            ref="apexChart"
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <div style="margin-top: -10px" class="text-center mb-2 text-16">
            區域
          </div>
        </div>
      </b-card>
      <b-card no-body class="mb-0 mt-2 no-custome-top-menu" v-if="false">
        <div id="chart" class="mt-2">
          <apexchart
            ref="apexChart2"
            type="bar"
            height="350"
            :options="chartOptions2"
            :series="series2"
          ></apexchart>
          <div style="margin-top: -10px" class="text-center mb-2 text-16">
            倉庫
          </div>
        </div>
      </b-card>
      <b-card no-body class="mb-0 mt-2 no-custome-top-menu">
        <div class="d-flex align-items-center mb-2 px-2 mt-2">
          <span class="mr-1">選擇倉庫類別:</span>
          <v-select
            v-model="selectedWarehouseCategory"
            :options="warehouseCategoryOptions"
            label="name"
            :reduce="item => item.id"
            placeholder="選擇倉庫類別"
            class="w-25"
            style="background-color: #fff;"
          />
        </div>
        <div v-for="(warehouse, index) in warehousesList" :key="warehouse.warehouse_id" class="mt-4">
          <h4 class="mb-2 px-2">{{ warehouse.warehouse_name }}</h4>
          <div class="customer-dist-chart" style="padding:20px;">
            <apexchart
              :ref="'customerDistChart_' + index"
              type="bar"
              height="350"
              :options="getChartOptions(warehouse)"
              :series="getChartSeries(warehouse)"
            ></apexchart>
          </div>
        </div>
      </b-card>
    </b-tab>
  </template>
  
  <script>
  import { BTab, BCard } from 'bootstrap-vue'
  import axiosIns from "@/libs/axios.js"
  import { ref, onMounted, watch } from "@vue/composition-api"
  import vSelect from "vue-select";

  export default {
    name: 'AreaWarehouseReport',
    components: {
      BTab,
      BCard,
      vSelect
    },
    props:{
        warehouseCategoryOptions:Array,
    },
    setup(props) {
      const area_list = ref([])
      const area_list2 = ref([])
      const series = ref([])
      const series2 = ref([])
      
      const chartOptions = ref({
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 2,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: area_list,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "right",
          offsetX: 0,
          offsetY: 50,
        },
      })
  
      const chartOptions2 = ref({
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            borderRadius: 2,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: area_list2,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "right",
          offsetX: 0,
          offsetY: 50,
        },
      })
  
      const apexChart = ref(null)
      const apexChart2 = ref(null)
  
      const getAreaData = () => {
        axiosIns
          .get("/statistic/districts")
          .then((res) => {
            area_list.value = res.data.statistics.area_list
            series.value = res.data.statistics.series
            apexChart.value.refresh()
            apexChart.value.updateOptions({ series: res.data.statistics.series })
          })
          .catch((err) => {
            console.log(err)
          })
      }
  
      const getAreaData2 = () => {
        axiosIns
          .get("/statistic/warehouses")
          .then((res) => {
            area_list2.value = res.data.statistics.area_list
            series2.value = res.data.statistics.series
            apexChart2.value.refresh()
            apexChart2.value.updateOptions({
              series: res.data.statistics.series,
            })
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const selectedWarehouseCategory = ref(null);
  
      const warehousesList = ref([]);
  
      const getChartOptions = (warehouse) => {
        return {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: { show: false }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              borderRadius: 2
            },
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: warehouse.district_distribution.map(d => d.district_name),
            labels: {
              rotate: -45,
              style: {
                fontSize: '12px'
              }
            }
          },
          yaxis: {
            title: {
              text: '客戶數量',
              style: {
                fontSize: '14px'
              },
              offsetX: 0,
              offsetY: 20
            },
            labels: {
              style: {
                fontSize: '12px'
              }
            }
          },
          fill: {
            opacity: 1
          }
        };
      };

      const getChartSeries = (warehouse) => {
        return [{
          name: '客戶數量',
          data: warehouse.district_distribution.map(d => d.customer_count)
        }];
      };

      const getCustomerDistribution = () => {
        const params = {};
        if (selectedWarehouseCategory.value) {
          params.warehouse_category_id = selectedWarehouseCategory.value;
        }

        axiosIns
          .get("/statistic/warehouses-customer-distribution", { params })
          .then((res) => {
            // 只顯示有客戶分佈數據的倉庫
            warehousesList.value = res.data.statistics.filter(
              w => w.district_distribution.length > 0
            );
          })
          .catch((err) => {
            console.error('Error fetching customer distribution:', err);
          });
      };
  
      // 監聽倉庫類別選擇變化
      watch(selectedWarehouseCategory, () => {
        getCustomerDistribution();
      });
  
      onMounted(() => {
       //getAreaData()
       // getAreaData2()
        getCustomerDistribution()
      })
  
      return {
        series,
        series2,
        chartOptions,
        chartOptions2,
        apexChart,
        apexChart2,
        selectedWarehouseCategory,
        warehousesList,
        getChartOptions,
        getChartSeries
      }
    }
  }
  </script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>