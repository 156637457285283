<template>
  <div>
    <div
      class="mini-storage-type navbar-container custome-top-menu bgColor2 sami-bottom-rounded"
    >
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                class="d-inline-block"
                placeholder="搜尋迷你倉種類"
              />
            </div>
          </div>
          <!-- <div class="d-flex align-items-center mr-2">
            <span class="mr-1">類別</span>
            <v-select
              v-model="filterCustomerType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerTypeOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="全部"
              class="d-inline-block common-rounded"
            />
          </div> -->
        </div>
        <div class="d-flex align-items-center">
          <a class="" href="javascript:void(0)" @click="handleCreateType">
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <h4 class="mb-1 ml-50">迷你倉種類</h4>
    </div>
    <div>
      <b-table
        ref="refWarehouseTypeListTable"
        class="position-relative"
        :items="warehouseTypeList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(size)="data">
          <span v-if="data.item.depth && data.item.width && data.item.height">
            {{ data.item.depth }} * {{ data.item.width }} *
            {{ data.item.height }}</span
          >
        </template>

        <template #cell(monthly_price)="data">
          <span v-if="data.item.monthly_price">
            HK${{ data.item.monthly_price }}</span
          >
        </template>

        <template #cell(bi_annual_price)="data">
          <span v-if="data.item.bi_annual_price">
            HK${{ data.item.bi_annual_price }} /HK${{
              Math.ceil(data.item.bi_annual_price / 6)
            }}</span
          >
        </template>

        <template #cell(annual_price)="data">
          <span v-if="data.item.annual_price">
            HK${{ data.item.annual_price }} /HK${{
              Math.ceil(data.item.annual_price / 12)
            }}</span
          >
        </template>

        <template #cell(action)="data">
          <a href="javascript:void(0)" @click="handleEditType(data.item)">
            <feather-icon class="color1" icon="Edit2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a href="javascript:void(0)" @click="confirmDelete(data.item.id)">
            <feather-icon class="color1" icon="Trash2Icon" />
          </a>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <template v-if="warehouseTypeData">
      <hr class="borderColor1 my-2" />

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="種類"
                  rules="required"
                >
                  <b-form-group
                    label="種類"
                    label-for="name"
                    class="required"
                    label-cols-md="3"
                  >
                    <b-form-input
                      id="name"
                      v-model="warehouseTypeData.name"
                      placeholder="種類"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="每月定價"
                  rules="required"
                >
                  <b-form-group
                    label="每月定價"
                    label-for="monthly_price"
                    class="required"
                    label-cols-md="3"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="每月定價"
                      v-model="warehouseTypeData.monthly_price"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="深x闊x高(呎)"
                  label-for="size"
                  class="required"
                  label-cols-md="3"
                >
                  <div class="d-flex align-items-center">
                    
                    <validation-provider
                      #default="validationContext"
                      name="深"
                      rules="required"
                    >
                      <b-form-input
                        type="number"
                        step="0.01"
                        v-model="warehouseTypeData.depth"
                        placeholder="深"
                        :state="getValidationState(validationContext)"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>

                    <span class="mx-25">X</span>

                    <validation-provider
                      #default="validationContext"
                      name="闊"
                      rules="required"
                    >
                      <b-form-input
                        type="number"
                        step="0.01"
                        v-model="warehouseTypeData.width"
                        placeholder="闊"
                        :state="getValidationState(validationContext)"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                   
                    <span class="mx-25">X</span>

                    <validation-provider
                      #default="validationContext"
                      name="高"
                      rules="required"
                    >
                      <b-form-input
                        type="number"
                        step="0.01"
                        v-model="warehouseTypeData.height"
                        placeholder="高"
                        :state="getValidationState(validationContext)"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="預付6個月"
                  rules="required"
                >
                  <b-form-group
                    label="預付6個月"
                    label-for="bi_annual_price"
                    class="required"
                    label-cols-md="3"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="預付6個月"
                      v-model="warehouseTypeData.bi_annual_price"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="簡介"
                  label-for="description"
                  label-cols-md="3"
                >
                  <b-form-textarea
                    rows="3"
                    id="description"
                    v-model="warehouseTypeData.description"
                    placeholder="簡介"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="預付12個月"
                  rules="required"
                >
                  <b-form-group
                    label="預付12個月"
                    label-for="annual_price"
                    class="required"
                    label-cols-md="3"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="預付12個月"
                      v-model="warehouseTypeData.annual_price"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <div class="d-flex justify-content-end mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    提交
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    取消
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template>
  </div>
</template>


<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BPagination,
  BCardBody,
  BTable,
  BCard,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  data() {
    return {
      required,
    };
  },
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BPagination,
    BCardBody,
    BTable,
    BCard,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    warehouse_id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    hide() {
      this.warehouseTypeData = null;
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("warehouse/updateMiniStorageType", this.warehouseTypeData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.fetchMiniStorageTypes();
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmDelete(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("warehouse/deleteMiniStorageType", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.fetchMiniStorageTypes();
              this.hide();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const warehouseTypeData = ref(null);

    const refWarehouseTypeListTable = ref(null);

    const tableColumns = [
      { key: "id", label: "編號" },
      { key: "name", label: "種類", sortable: true },
      { key: "description", label: "簡介", sortable: true },
      { key: "size", label: "深x闊x高(呎)", sortable: true },
      { key: "monthly_price", label: "每月定價", sortable: true },
      { key: "bi_annual_price", label: "預付6個月", sortable: true },
      { key: "annual_price", label: "預付12個月", sortable: true },
      { key: "action", label: "操作" },
    ];

    const perPage = ref(10);
    const totalRow = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
      const localItemsCount = refWarehouseTypeListTable.value
        ? refWarehouseTypeListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRow.value,
      };
    });
    const warehouseTypeList = ref(null);

    const fetchMiniStorageTypes = () => {
      store
        .dispatch("warehouse/fetchMiniStorageTypes", {
          // id: router.currentRoute.params.id,
          q: searchQuery.value,
         // perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          warehouse_id: props.warehouse_id,
        })
        .then((response) => {
          warehouseTypeList.value = response.data.mini_storage_types;
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            warehouseTypeList.value = undefined;
          } else {
            console.log("Fail to refetchData", error);
          }
        });
    };

    const handleCreateType = () => {
      warehouseTypeData.value = { id: 0, warehouse_id: props.warehouse_id };
    };

    const handleEditType = (item) => {
      warehouseTypeData.value = item;
    };

    const resetWarehouseTypeData = () => {
      props.warehouseTypeData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetWarehouseTypeData
    );

    onMounted(() => {
      fetchMiniStorageTypes();
    });

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      resetWarehouseTypeData,
      fetchMiniStorageTypes,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWarehouseTypeListTable,
      warehouseTypeList,
      warehouseTypeData,
      handleEditType,
      handleCreateType,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.mini-storage-type.custome-top-menu {
  margin-left: -1.5rem;
}

</style>
