import axiosIns from '@/libs/axios'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addVideo(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/video', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVideos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/video/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVideo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/video', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }




  },
}
