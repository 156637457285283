<template>
  <div>
      <!-- :key="'rent-form'+trigger" -->
    <modal-rent-form
      v-if="mini_storage"
      :trigger="trigger"
      :storageData="mini_storage"
      @refetch-data="fetchWarehouse"
      @refetch-mini-data="fetchMiniStorage"
    ></modal-rent-form>

    <div
      style="overflow: visible"
      class="navbar-container custome-top-menu bgColor2 common-rounded"
    >
      <div class="blank-block"></div>
      <div class="d-flex flex-wrap align-items-center colorWhite">
        <div class="d-flex align-items-center mr-2">
          <span>平面圖</span>
          <v-select
            v-model="filter_warehouse_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="warehouseOptions"
            label="name"
            :reduce="(option) => option.id"
            class="d-inline-block common-rounded mx-50"
            placeholder="請先選擇一個倉庫！"
          />
        </div>
      </div>
    </div>
    <!-- class="in_modal ? 'in_modal' : ''" -->
    <div id="warehouse" class="" v-if="filter_warehouse_id">
      <b-row
        v-if="
          warehouseData &&
          warehouseData.images &&
          warehouseData.images.length > 0
        "
      >
        <b-col cols="12">
          <div class="d-flex align-items-center">
            <div class="mr-2 d-flex align-items-center">
              <b class="block-color bgSelected mr-25"></b><span>已選擇</span>
            </div>
            <!-- <div class="mr-2 d-flex align-items-center"><b class="block-color bgColorGrey mr-25"></b><span>上一個選擇</span></div> -->
            <div class="mr-2 d-flex align-items-center">
              <b class="block-color bgAvailable mr-25"></b><span>可租用</span>
            </div>
            <div class="mr-2 d-flex align-items-center">
              <b class="block-color bgAlreadyRented mr-25"></b
              ><span>已租出</span>
            </div>
            <div class="mr-2 d-flex align-items-center">
              <b class="block-color bgLatePayment mr-25"></b><span>欠租</span>
            </div>
          </div>
        </b-col>

        <!-- <div v-if="in_modal" @click="$emit('update:in_modal', false)" class="black-background"></div> -->
        <b-col cols="12">
          <h3 class="text-center my-2">{{ warehouseData.name }}</h3>
        </b-col>
        <!--    <b-col cols="12" class="d-flex justify-content-center">
      <div class="d-flex justify-content-center">
        <canvas
          @click="selectChecked"
          class="mx-auto"
          :style="{
            aspectRatio: backgroundWidth / backgroundHeight,
            maxWidth: backgroundWidth + 'px',
          }"
          id="canvas"
        ></canvas>
      </div>
      </b-col> -->
        <b-col
          cols="12"
          class="d-flex justify-content-center"
          v-for="(image, index) in warehouseData.images.filter(
            (ele) => ele.image_type_id == 2
          )"
          :key="`canvas_${image.id}`"
        >
          <floor-plan
            :warehouseData="warehouseData"
            :warehouseImageId="image.id"
            :storages="
              warehouseData.mini_storages.filter(
                (ele) => ele.warehouse_image_id == image.id
              )
            "
            :miniStorage.sync="mini_storage"
            :trigger="trigger"
            :index="index"
            @selectChecked="selectChecked"
          ></floor-plan>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BButton, BRow, BCol } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import axiosIns from "@/libs/axios";
import ModalRentForm from "./modal/modal-rent-form.vue";
import FloorPlan from "./FloorPlan.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    flatPickr,
    vSelect,
    ModalRentForm,
    BRow,
    BCol,
    FloorPlan,
  },
  data() {
    return {
      trigger: 1,
      warehouseOptions: [],
      bookingStageOptions: [],
      lease_date: null,
      filter_warehouse_id: null,
      mini_storage: null,
      warehouseData: {},
      storages: [],
      selected_storage_index: null,
      selected_storage_number: null,
      backgroundWidth: 0,
      backgroundHeight: 0,
    };
  },
  props: {},
  computed: {
    isSelected() {
      return this.selected_storage_index === 0 || this.selected_storage_index;
    },
  },
  watch: {
    filter_warehouse_id: {
      handler: function (newVal, oldVal) {
        this.fetchWarehouse();
      },
    },
    warehouseData: {
      handler: function (newVal, oldVal) {
        this.trigger += 1;
          setTimeout(() => {
            this.trigger += 1;
          }, 100);
      },
      deep: true,
    },
    /*     warehouseData: {
      handler: function (newVal, oldVal) {
        this.canvas = document.getElementById("canvas");
        this.context = this.canvas.getContext("2d");
        this.storages = newVal.mini_storages;

        // if (this.selected_storage_number){
        //   this.selected_storage_index = this.storages.findIndex(
        //     (ele) => ele.storage_number == this.selected_storage_number
        //   );
        // }

        // this.draw();
      },
      deep: true,
    }, */
    storages: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selected_storage_index: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selected_storage_number: {
      handler(val) {
        this.selected_storage_index = this.storages.findIndex(
          (ele) => ele.storage_number == val
        );
      },
    },
  },
  mounted() {
    this.fetchOptions();
  },
  methods: {
    goToTable(offset) {
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    },
    draw() {
      let image = new Image();

      image.src = this.warehouseData.images.find(
        (ele) => ele.image_type_id === 2
      ).image_path;

      let that = this;

      image.onload = function (e) {
        that.backgroundWidth = e.target.naturalWidth;
        that.backgroundHeight = e.target.naturalHeight;

        that.canvas.width = that.backgroundWidth;
        that.canvas.height = that.backgroundHeight;
        that.canvas.style.maxWidth = that.backgroundWidth;

        that.canvas.style.background = `url(${
          that.warehouseData.images.find((ele) => ele.image_type_id === 2)
            .image_path
        }) no-repeat`;
        that.canvas.style.backgroundSize = `100% 100%`;
        that.canvas.style.backgroundPostiion = `0px 0px`;

        // 清空畫布
        that.context.clearRect(0, 0, that.canvas.width, that.canvas.height);

        // 繪製迷你倉

        for (let i = that.storages.length - 1; i >= 0; i--) {
          let storage = that.storages[i];
          // that.context.strokeStyle = "grey";
          that.context.lineWidth = 2;

          that.context.strokeRect(
            storage.x,
            storage.y,
            storage.rectWidth,
            storage.rectHeight
          );
          //console.log(storage);
          if (that.selected_storage_index === i) {
            that.context.fillStyle = "#6d6d6d";
          } else if (storage.late_payment) {
            that.context.fillStyle = "#f82500";
          } else if (storage.on_sell) {
            that.context.fillStyle = "#ffff95";
          } else {
            that.context.fillStyle = "#42c8a8";
          }

          that.context.fillRect(
            storage.x,
            storage.y,
            storage.rectWidth,
            storage.rectHeight
          );
          if (storage.storage_number) {
            // 繪製文字
            if (that.selected_storage_index === i) {
              that.context.fillStyle = "white";
            } else {
              that.context.fillStyle = "black";
            }
            that.context.font = "14px Arial";
            const text = storage.storage_number;
            const textWidth = that.context.measureText(text).width;
            const textX =
              Number(storage.x) + (Number(storage.rectWidth) - textWidth) / 2;
            const textY =
              Number(storage.y) + Number(storage.rectHeight) / 2 + 7; // 調整文字的垂直位置
            that.context.fillText(text, textX, textY);
          }
        }
      };
    },
    selectChecked(event) {
        const rect = this.canvas.getBoundingClientRect();

        const wRadio = rect.width / this.canvas.width;
        const hRadio = rect.height / this.canvas.height;

        const x = (event.clientX - rect.left) / wRadio;
        const y = (event.clientY - rect.top) / hRadio;

        // 檢查被點擊的位置是否在迷你倉的範圍內
        for (let i = this.storages.length - 1; i >= 0; i--) {
          const storage = this.storages[i];

          if (
            x >= storage.x &&
            x <= storage.x + storage.rectWidth &&
            y >= storage.y &&
            y <= storage.y + storage.rectHeight
          ) {
            this.fetchMiniStorage(storage.id);
            this.selected_storage_index = i;
            // this.mini_storage = {
            //   name:this.warehouseData.name,
            //   location:this.warehouseData.location,
            //   warehouse_category_id:this.warehouseData.warehouse_category_id,
            //   ...storage
            // }

            break;
          }
        }
      
    },
    fetchWarehouse() {
      axiosIns
        .get("warehouse/", { params: { id: this.filter_warehouse_id } })
        .then((response) => {
          this.warehouseData = response.data.warehouse;
        })
        .catch((error) => {
          console.log({ error });
        });
    },

    fetchMiniStorage(id) {
      axiosIns
        .get("warehouse/mini_storage", { params: { id } })
        .then((response) => {
          this.mini_storage = null;
          this.mini_storage = response.data.mini_storage;
          setTimeout(() => {
            this.$bvModal.show("modal-rent-form");
          }, 500);
        })
        .catch((error) => {
          console.log({ error });
        });
    },

    fetchOptions() {
      this.$store
        .dispatch("app/fetchOptions", {
          warehouse: true,
        })
        .then((response) => {
          this.warehouseOptions = response.data.warehouses;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
#warehouse {
}

#canvas {
  width: 100%;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.bgSelected {
  background: #6d6d6d;
}

.bgAvailable {
  background: #ffff95;
}

.bgAlreadyRented {
  background: #42c8a8;
}

.bgLatePayment {
  background: #f82500;
}
</style>