<template>
    <div class="p-2 bgCommon" style="margin-top: -100px;">
      <div class="d-flex mb-2 justify-content-between w-100 align-items-md-center">
        <div class="d-flex flex-wrap flex-sm-nowrap align-items-center w-100">
          <span class="text-nowrap font-weight-bolder pr-1"
            >批量新增客戶：</span
          >
          <div class="form-col-select mt-1 mt-sm-0 ml-sm-2 mr-1" v-if="false">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary inMulti mt-1 mt-sm-0" v-if="false">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="搜尋客戶"
            />
          </div>
        </div>
        <div>
          <font-awesome-icon
            class="refresh-icon mr-1"
            @click="refetchData"
            icon="fas fa-redo"
          />
        </div>
      </div>
      <b-card no-body class="mb-0">
        <b-card-body class="p-2">
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <div class="position-relative">
              <b-table
                ref="refCustomerSubmissionListTable"
                class="position-relative multi-table"
                :items="fetchCustomersSubmission"
                :fields="tableColumns"
                responsive
                primary-key="id"
                show-empty
                empty-text="未找到匹配的記錄"
              >
                <!-- Column: total_records -->
                <template #cell(total_records)="data">
                  <div class="d-flex justify-content-center">
                    {{
                      `${Math.floor(
                        (data.item.total_handled / data.item.total_records) * 100
                      )}% (${data.item.total_handled}/${data.item.total_records})`
                    }}
                  </div>
                </template>
                <!-- Column: create time -->
                <template #cell(created_at)="data">
                    {{ getCorrectDateTime(data.item.created_at) }}
                </template>
  
                <!-- Column: Download -->
                <template #cell(actions)="data">
                  <a
                    v-if="data.item.failed"
                    href="javascript:void(0)"
                    download
                    @click="
                      exportSubmissionExcel(
                        data.item.id,
                        formatTime(data.item.created_at, true)
                      )
                    "
                  >
                    <font-awesome-icon
                      class="color0"
                      style="font-size: 20px"
                      icon="fas fa-file"
                    />
                  </a>
                  <div v-else>- -</div>
                </template>
              </b-table>
  
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >在{{ dataMeta.of }}個記錄中，正在顯示
                      {{ dataMeta.from }} 至 {{ dataMeta.to }}個記錄</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalCustomers"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
  
            <div class="d-flex mt-2 flex-wrap justify-content-between">
              <div class="d-flex mb-2 align-items-center">
                <span class="mx-auto">或</span>
                <b-button
                  class="btn-primary-green btn-trigger-export ml-1 mobile-w100"
                  @click="$refs.importFile.$el.childNodes[0].click()"
                >
                  <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                  上傳Excel檔案
                </b-button>
                <b-form-file
                  style="display: none"
                  type="file"
                  @change="importExcel"
                  id="upload"
                  name=""
                  ref="importFile"
                >
                </b-form-file>
              </div>
  
              <div class="mobile-w100">
                <!-- :fields="download excel -->
                <b-button
                  @click="exporTemplateExcel"
                  class="btn btn-primary-green text-white btn-trigger-export mr-sm-2 mobile-w100"
                >
                  <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                  導出表單模板
                </b-button>
  
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="hide"
                  variant="danger"
                  class="custom-cancel mt-1 mt-sm-0 mobile-w100"
                >
                  取消
                </b-button>
              </div>
            </div>
          </b-overlay>
        </b-card-body>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BButton,
    BOverlay,
    BFormFile,
    BTable,
    BPagination,
  } from "bootstrap-vue";
  import { ref, onUnmounted } from "@vue/composition-api";
  import store from "@/store";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import "animate.css";
  import { required } from "@validations";
  import useCustomersSubmissionList from "./useCustomersSubmissionList";
  import customerStoreModule from "@/views/customer/customerStoreModule.js"
  import * as xlsx from "xlsx";
  
  export default {
    data() {
      return {
        required,
        loading: false, // Whether the table is loading
        customerData_fields: {
          中文姓名: "name_cn",
          英文姓名: "name_en",
          性別: "gender",
          身份證號碼: "id_number",
          出生日期: "date_of_birth",
          年齡: "age",
          手提電話: "mobile",
          住宅電話: "tel",
          住宅地址: "address",
          "公司名稱（中文）": "company_name_cn",
          "公司名稱（英文）": "company_name_en",
          公司電話: "company_phone",
          公司地址: "company_address",
          職位: "position",
          每月薪金: "monthly_salary",
          工作時間: "working_time",
          工齡: "work_age",
          出糧日期: "salary_date",
          出糧方式: "payment_method",
          強積金公司: "mpf_company",
          上司名稱: "boss_name",
          上司電話: "boss_phone",
          "業主/戶主本人": "householder",
          與顧客關係: "relationship",
          "月供/租金": "rent",
          兼職資料: "parttime_remark",
          資料補充: "remark",
        },
        file_name:null
      };
    },
    directives: {
      Ripple,
    },
    components: {
      BCard,
      BCardBody,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BFormCheckbox,
      BRow,
      BCol,
      BButton,
      BOverlay,
      BFormFile,
      BTable,
      BPagination,
      vSelect,
    },
    methods: {
      hide() {
        this.$router.replace("/customers");
      },
      getHeader(sheet) {
        const XLSX = xlsx;
        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]); // worksheet['!ref'] Is the valid range of the worksheet
  
        let C;
        /* Get cell value start in the first row */
        const R = range.s.r; //Line / / column C
        let i = 0;
        for (C = range.s.c; C <= range.e.c; ++C) {
          var cell =
            sheet[
              XLSX.utils.encode_cell({ c: C, r: R })
            ]; /* Get the cell value based on the address  find the cell in the first row */
          var hdr = "UNKNOWN" + C; // replace with your desired default
          // XLSX.utils.format_cell Generate cell text value
          if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
          if (hdr.indexOf("UNKNOWN") > -1) {
            if (!i) {
              hdr = "__EMPTY";
            } else {
              hdr = "__EMPTY_" + i;
            }
            i++;
          }
          headers.push(hdr);
        }
  
        return headers;
      },
      importExcel(e) {
        const files = e.target.files;
        this.file_name = files[0].name;
  
        function areEqual(array1, array2) {
          if (array1.length === array2.length) {
            return array1.every((element, index) => {
              if (element === array2[index]) {
                return true;
              }
  
              return false;
            });
          }
          return false;
        }
  
        if (!files.length) {
          return;
        } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
          return alert("上傳格式不正確。請上傳xlsx格式");
        }
        const fileReader = new FileReader();
        fileReader.onload = (ev) => {
          try {
            const data = ev.target.result;
            const XLSX = xlsx;
            const workbook = XLSX.read(data, {
              type: "binary",
            });
  
            const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
  
            const a = workbook.Sheets[workbook.SheetNames[0]];
            // console.log("sh", a);
            const headers = this.getHeader(a);
  
            // if (!areEqual(headers, Object.keys(this.customerData_fields))) {
            //   this.$bvToast.toast(
            //     `請使用我們的模板，您可以點擊“導出表單模板”按鈕下載模板`,
            //     {
            //       title: "Excel 文件格式未運行!",
            //       variant: "danger",
            //       autoHideDelay: 5000,
            //     }
            //   );
            //   return;
            // }
  
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
              header: headers,
            }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
            const excellist = []; // Clear received data
            // Edit data
            for (var i = 0; i < ws.length; i++) {
              excellist.push(ws[i]);
            }
            // console.log("Read results", excellist); // At this point, you get an array containing objects that need to be processed
  
            this.setTable(headers, excellist);
          } catch (e) {
            return alert("Read failure!");
          }
        };
        fileReader.readAsBinaryString(files[0]);
        var input = document.getElementById("upload");
        input.value = "";
      },
  
      setTable(headers, excellist) {
        const checkList = []; // set check table content data
  
        const renderCheckList = (list, newList) => {
          list.forEach((item, index) => {
            const newObj = {};
            if (index > 0) {
              Object.keys(item).forEach((key) => {
                newObj[this.customerData_fields[key]] = item[key];
              });
              newList.push(newObj);
            }
          });
        };
        renderCheckList(excellist, checkList);
  
        if (checkList.length <= 0) {
          this.$bvToast.toast(`您沒有在excel輸入任何內容`, {
            title: "請輸入內容!",
            variant: "danger",
            autoHideDelay: 5000,
          });
          return;
        }
  
        this.loading = true;
        store
          .dispatch("app-customer/checkImportCustomerData", {'data':checkList, file_name : this.file_name})
          .then((response) => {
            this.loading = false;
  
            this.$swal({
              text: response.data.message,
              confirmButtonText: "確定",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
            this.refetchData();
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      },
      exporTemplateExcel() {
        store.dispatch("app-customer/exportCustomerData").then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "Customer Template"); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      },
      exportSubmissionExcel(id, date) {
        store
          .dispatch("app-customer/exportSubmissionData", { id })
          .then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
            // create "a" HTLM element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", `Customer Submission List - ${date}`); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
      },
      formatTime(time, isExcelName) {
        let target_time = this.getCorrectDateTime(time);
        const t_array = target_time.split(" ")[1].split(":");
        let hour = t_array[0];
        let min = t_array[1];
        let sec = t_array[2];
  
        if (isExcelName) {
          if (hour >= 12) {
            if (hour > 12) {
              hour -= 12;
            }
            hour += "pm";
          } else {
            hour += "am";
          }
          min += "m";
          sec += "s";
  
          return `${target_time.split(" ")[0]} ${hour} ${min} ${sec}`;
        } else {
          return target_time;
        }
      },
    },
    mounted() {},
    setup(props) {
      const CLIENT_APP_STORE_MODULE_NAME = "app-customer";
  
      if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.registerModule(CLIENT_APP_STORE_MODULE_NAME, customerStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
          store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
      });
  
      const {
        fetchCustomersSubmission,
        tableColumns,
        perPage,
        currentPage,
        totalCustomers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCustomerSubmissionListTable,
        refetchData,
        customersSubmissionList,
        ability,
      } = useCustomersSubmissionList();
  
      return {
        fetchCustomersSubmission,
        tableColumns,
        perPage,
        currentPage,
        totalCustomers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCustomerSubmissionListTable,
        refetchData,
        customersSubmissionList,
        ability,
      };
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>