<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">倉庫資訊</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form: Personal Info Form -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Field: Staff ID -->
            <b-col cols="12" md="12">
              <b-form-group label="倉號" label-for="id">
                <b-form-input id="id" v-model="warehouseData.id" readonly />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="倉庫類別"
                rules="required"
              >
                <b-form-group
                  label="倉庫類別"
                  label-for="warehouse_category_id"
                >
                  <v-select
                    id="warehouse_category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="warehouseData.warehouse_category_id"
                    :options="warehouseOptions"
                    label="name"
                    class="w-100"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- Field: Name -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="倉庫名稱"
                rules="required"
              >
                <b-form-group label="倉庫名稱" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="warehouseData.name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group label="位置" label-for="location">
                <b-form-input
                  id="location"
                  type="text"
                  placeholder="位置"
                  v-model="warehouseData.location"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group label="電話" label-for="tel">
                <b-form-input
                  id="tel"
                  type="text"
                  placeholder="電話"
                  v-model="warehouseData.tel"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group label="電郵" label-for="email">
                <b-form-input
                  id="email"
                  type="email"
                  placeholder="電郵"
                  v-model="warehouseData.email"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="12">
              <b-form-group label="規格" label-for="specifications">
                <quill-editor
                  v-model="warehouseData.specifications"
                  :content="warehouseData.specifications"
                  :options="editorOption"
                  ref="QuillEditor"
                  id="specifications"
                >
                </quill-editor>
              </b-form-group>

            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label-for="is_active">
                <b-form-checkbox
                  checked="true"
                  v-model="warehouseData.is_active"
                  class="custome-control-success"
                  name="check-button"
                  switch
                  inline
                />
                {{ warehouseData.is_active ? "啟用" : "禁止" }}
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Button: Submit & Reset Button.-->
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                提交
              </b-button>
              
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>
  
  <script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
    quillEditor
  },
  methods: {
    hide() {
      this.$router.replace("/warehouses/list");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("warehouse/addWarehouse", this.warehouseData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  props: {
    warehouseData: {
      type: Object,
      required: true,
    },
    warehouseOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetwarehouseData = () => {
      props.warehouseData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetwarehouseData);

    onMounted(() => {
      console.log(props.warehouseData);
    });

    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [
              {
                font: [
                  "SimSun",
                  "SimHei",
                  "Microsoft-YaHei",
                  "KaiTi",
                  "FangSong",
                  "Arial",
                ],
              },
            ],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
          handlers: {
            image: (value) => {
              console.log("image", value);
              if (value) {
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };

    return {
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetwarehouseData,
      loading,
      editorOption
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}

.quill-editor{
  background:white;
  .ql-container{
    min-height: 180px;
  }
}

</style>
  