import { render, staticRenderFns } from "./terminate-detail.vue?vue&type=template&id=472ee126&scoped=true"
import script from "./terminate-detail.vue?vue&type=script&lang=js"
export * from "./terminate-detail.vue?vue&type=script&lang=js"
import style1 from "./terminate-detail.vue?vue&type=style&index=1&id=472ee126&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472ee126",
  null
  
)

export default component.exports