<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Customer Information</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form: Personal Info Form -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Field: Customer ID -->
            <b-col cols="12" md="12">
              <b-form-group label="Customer ID" label-for="id">
                <b-form-input id="id" v-model="customerData.id" readonly />
              </b-form-group>
            </b-col>

            <!-- Field: Name -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="customerData.name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
              >
                <b-form-group label="Email Address" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="customerData.email"
                    placeholder="Email"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group label="HKID / BR" label-for="id_number">
                <b-form-input
                  id="id_number"
                  v-model="customerData.id_number"
                  autofocus
                  trim
                  placeholder="HKID / BR"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12"
              ><b-form-group label="Address" label-for="address">
                <b-form-input
                  id="address"
                  v-model="customerData.address"
                  autofocus
                  trim
                  placeholder="Address"
                /> </b-form-group
            ></b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Remark" label-for="remark">
                <b-form-textarea
                  id="remark"
                  v-model="customerData.remark"
                  rows="3"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label-for="is_active">
                <b-form-checkbox
                  checked="true"
                  v-model="customerData.is_active"
                  class="custome-control-success"
                  name="check-button"
                  switch
                  inline
                />
                {{ customerData.is_active ? "Enable" : "Disable" }}
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Button: Submit & Reset Button.-->
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                variant="outline-secondary"
                class="mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="resetData"
              >
                Reset
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="hide"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
  BFormTextarea
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
  BFormTextarea

  },
  methods: {
    hide() {
      this.$router.replace("/customers/list");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-customer/updateCustomer", this.customerData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const positionOptions = ref([]);
    const teamOptions = ref([]);
    const loading = ref(false);
    const genderOptions = [
      { text: "Male", value: "m" },
      { text: "Female", value: "f" },
    ];

    const contactOptionsOptions = ["Email", "Message", "Phone"];

    const resetData = () => {
      emit("refetch-data");
    };
    const resetcustomerData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetcustomerData);

    onMounted(() => {
      console.log(props.customerData);
    });

    return {
      genderOptions,
      contactOptionsOptions,
      positionOptions,
      teamOptions,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetcustomerData,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}
</style>
