<template>
  <div class="d-flex justify-content-center mb-3">
    <canvas
      @click="selectChecked"
      class="mx-auto"
      :style="{
        aspectRatio: backgroundWidth / backgroundHeight,
        maxWidth: backgroundWidth + 'px',
      }"
      :id="`canvas-${index}`"
    ></canvas>
  </div>
</template>
  
  <script>
import { BTab, BTabs, BCard, BButton, BRow, BCol } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import axiosIns from "@/libs/axios";
import ModalRentForm from "./modal/modal-rent-form.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    flatPickr,
    vSelect,
    ModalRentForm,
    BRow,
    BCol,
  },
  data() {
    return {
      selected_storage_index: null,
      selected_storage_number: null,
      backgroundWidth: 0,
      backgroundHeight: 0,
    };
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    warehouseData: {
      type: Object,
      required: true,
    },
    storages: {
      type: Array,
      required: true,
    },
    miniStorage: {},
    warehouseImageId: {
      type: Number,
      required: true,
    },
    trigger: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isSelected() {
      return this.selected_storage_index === 0 || this.selected_storage_index;
    },
  },
  watch: {
    trigger: {
      handler() {
        if (this.warehouseData) {
        
          this.canvas = document.getElementById("canvas-" + this.index);
          this.context = this.canvas.getContext("2d");
          this.draw();
        }
      },
    },
    warehouseData: {
      handler: function (newVal, oldVal) {

        this.canvas = document.getElementById("canvas-" + this.index);
        this.context = this.canvas.getContext("2d");
        // if (this.selected_storage_number){
        //   this.selected_storage_index = this.storages.findIndex(
        //     (ele) => ele.storage_number == this.selected_storage_number
        //   );
        // }
      },
      deep: true,
    },
    storages: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selected_storage_index: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selected_storage_number: {
      handler(val) {
        this.selected_storage_index = this.storages.findIndex(
          (ele) => ele.storage_number == val
        );
      },
    },
  },
  methods: {
    goToTable(offset) {
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    },
    draw() {
      let image = new Image();

      image.src = this.warehouseData.images.find(
        (ele) => ele.id === this.warehouseImageId
      ).image_path;

      let that = this;

      image.onload = function (e) {
        that.backgroundWidth = e.target.naturalWidth;
        that.backgroundHeight = e.target.naturalHeight;

        that.canvas.width = that.backgroundWidth;
        that.canvas.height = that.backgroundHeight;
        that.canvas.style.maxWidth = that.backgroundWidth;

        that.canvas.style.background = `url(${
          that.warehouseData.images.find((ele) => ele.id === that.warehouseImageId)
            .image_path
        }) no-repeat`;
        that.canvas.style.backgroundSize = `100% 100%`;
        that.canvas.style.backgroundPostiion = `0px 0px`;

        // 清空畫布
        that.context.clearRect(0, 0, that.canvas.width, that.canvas.height);

        // 繪製迷你倉

        for (let i = that.storages.length - 1; i >= 0; i--) {
          let storage = that.storages[i];
          // that.context.strokeStyle = "grey";
          that.context.lineWidth = 2;

          that.context.strokeRect(
            storage.x,
            storage.y,
            storage.rectWidth,
            storage.rectHeight
          );
          //console.log(storage);
          if (that.selected_storage_index === i) {
            that.context.fillStyle = "#6d6d6d";
          } else if (storage.late_payment) {
            that.context.fillStyle = "#f82500";
          } else if (storage.on_sell) {
            that.context.fillStyle = "#ffff95";
          } else {
            that.context.fillStyle = "#42c8a8";
          }

          that.context.fillRect(
            storage.x,
            storage.y,
            storage.rectWidth,
            storage.rectHeight
          );
          if (storage.storage_number) {
            // 繪製文字
            if (that.selected_storage_index === i) {
              that.context.fillStyle = "white";
            } else {
              that.context.fillStyle = "black";
            }
            that.context.font = "14px Arial";
            const text = storage.storage_number;
            const textWidth = that.context.measureText(text).width;
            const textX =
              Number(storage.x) + (Number(storage.rectWidth) - textWidth) / 2;
            const textY =
              Number(storage.y) + Number(storage.rectHeight) / 2 + 7; // 調整文字的垂直位置
            that.context.fillText(text, textX, textY);
          }
        }
      };
    },
    selectChecked(event) {
      const rect = this.canvas.getBoundingClientRect();

      const wRadio = rect.width / this.canvas.width;
      const hRadio = rect.height / this.canvas.height;

      const x = (event.clientX - rect.left) / wRadio;
      const y = (event.clientY - rect.top) / hRadio;

      // 檢查被點擊的位置是否在迷你倉的範圍內
      for (let i = this.storages.length - 1; i >= 0; i--) {
        const storage = this.storages[i];

        if (
          x >= storage.x &&
          x <= storage.x + storage.rectWidth &&
          y >= storage.y &&
          y <= storage.y + storage.rectHeight
        ) {
          this.fetchMiniStorage(storage.id);
          this.selected_storage_index = i;
          // this.mini_storage = {
          //   name:this.warehouseData.name,
          //   location:this.warehouseData.location,
          //   warehouse_category_id:this.warehouseData.warehouse_category_id,
          //   ...storage
          // }

          break;
        }
      }
    },
    fetchMiniStorage(id) {
      axiosIns
        .get("warehouse/mini_storage", { params: { id } })
        .then((response) => {
          this.$emit("update:miniStorage", null);
          this.$emit("update:miniStorage", response.data.mini_storage);
          setTimeout(() => {
            this.$bvModal.show("modal-rent-form");
          }, 500);
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  // mounted(){
  //   this.fetchMiniStorage(269)
  // }
};
</script>
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
#warehouse {
}

#canvas {
  width: 100%;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.bgSelected {
  background: #6d6d6d;
}

.bgAvailable {
  background: #ffff95;
}

.bgAlreadyRented {
  background: #42c8a8;
}

.bgLatePayment {
  background: #f82500;
}
</style>