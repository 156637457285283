<template>
  <b-modal
    id="modal-wechat"
    cancel-variant="outline-secondary"
    centered
    size="sm"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
  >
    <!-- @hide="resetModal" -->
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">Wechat</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    
    
      <b-row>
        <b-col cols="12">
          <b-form-group label="訊息" label-for="message" class="required">
          
              <b-form-textarea
                rows="3"
                id="message"
                readonly
                v-model="newWechatData.message"
              />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="d-flex flex-wrap justify-content-center w-100 p-2"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
            type="submit"
            @click="copyText"
          >
            復製
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="px-3"
            @click="hide"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    wechatData: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-wechat");
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newWechatData = ref({ id: 0 });

    const resetwechatData = () => {
      newWechatData.value = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetwechatData);

    watch(
      () => props.wechatData,
      (newVal) => {
        newWechatData.value = newVal;
      }
    );

    const toast = useToast()

    const copyText = () => {
      const textField = document.createElement('textarea');
      textField.value = props.wechatData.message;
      document.body.appendChild(textField);
      textField.select();
      textField.setSelectionRange(0, 99999)
      // document.execCommand('copy');
      navigator.clipboard.writeText(textField.value);
      document.body.removeChild(textField);

      toast({
        component: ToastificationContent,
        props: {
          title: '已復製訊息！',
          icon: 'AlertTriangleIcon',
          variant: 'info'
        }
      })
      this.hide();
    }

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newWechatData,
      copyText
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sms-form {
  .form-group {
    width: 100%;
  }
}
</style>
  