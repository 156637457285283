<template>
  <div class="app-calendar overflow-hidden border">
    <modal-edit-task-calendar
      v-model="isEventHandlerSidebarActive"
      @remove-event="removeEvent"
      @update-event="updateEvent"
      @refetch-events="refetchEvents"
      :taskData="event"
    ></modal-edit-task-calendar>
    <div class="row no-gutters">
      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import ModalEditTaskCalendar from "@/views/task/modal/ModalEditTaskCalendar.vue";
import useCalendar from "./useCalendar.js";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    ModalEditTaskCalendar,
  },
  setup() {
    const {
      refCalendar,
      event,
      updateEvent,
      fetchEvents,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar();

    return {
      refCalendar,
      event,
      updateEvent,
      fetchEvents,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc .fc-daygrid-event-harness .fc-event {
  padding: 0rem 0rem;
}
</style>