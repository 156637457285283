
<template>
<div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col class="mb-1" cols="12" xl="4">
              <!-- 客戶名稱-->
              <validation-provider
                #default="validationContext"
                name="客戶編號"
                rules="required"
              >
                <b-form-group
                  label="客戶編號："
                  label-for="customer_id"
                  label-cols-md="3"
                  class="required"
                >
                  <v-select
                    id="customer_id"
                    v-model="newTaskData.customer_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    :clearable="false"
                    label="name"
                    :reduce="(option) => option.id"
                    placeholder="選擇客戶"
                  >
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- contract -->
            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="合約"
                rules="required"
              >
                <b-form-group
                  label="合約："
                  label-for="contract_id"
                  label-cols-md="3"
                  class="required"
                >
                  <v-select
                    id="contract_id"
                    v-model="newTaskData.contract_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="contractOptions"
                    :clearable="false"
                    label="contract_number"
                    :reduce="(option) => option.id"
                    placeholder="選擇合約"
                  >
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- 倉庫 -->
            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="倉庫"
                rules="required"
              >
                <b-form-group
                  label="倉庫："
                  label-for="warehouse"
                  label-cols-md="3"
                  class="required"
                >
                  <v-select
                    id="warehouse_id"
                    v-model="newTaskData.warehouse_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="warehouseOptions"
                    :clearable="false"
                    label="name"
                    :reduce="(option) => option.id"
                    placeholder="選擇倉庫"
                  >
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- address-->
            <b-col class="mb-1" cols="12" xl="4">
              <b-form-group
                label="地址："
                label-for="task_address"
                label-cols-md="3"
              >
                <b-form-input
                  id="task_address"
                  v-model="newTaskData.address"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="開始日期"
                rules="required"
              >
                <b-form-group
                  label="開始日期："
                  label-for="start"
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="newTaskData.start"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d H:i',
                    }"
                    placeholder="輸入YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="結束日期"
                rules="required"
              >
                <b-form-group
                  label="結束日期："
                  label-for="end"
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="newTaskData.end"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d H:i',
                    }"
                    placeholder="輸入YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="狀態"
                rules="required"
                  class="required"
              >
                <b-form-group
                  label="狀態："
                  label-for="meeting_stage_id"
                  label-cols-md="3"
                >
                  <v-select
                    id="meeting_stage_id"
                    v-model="newTaskData.meeting_stage_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="meetingStageOptions"
                    :clearable="false"
                    label="stage"
                    :reduce="(option) => option.id"
                    placeholder="選擇狀態"
                  >
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="參加者"
                rules="required"
              >
                <b-form-group
                  label="參加者"
                  label-for="users"
                  label-cols-md="3"
                >
                  <v-select
                    multiple
                    class="inner_svg_white"
                    v-model="newTaskData.users"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    label="name"
                    :reduce="(option) => option.id"
                  >
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- title-->
            <b-col class="mb-1" cols="12" xl="4">
              <validation-provider
                #default="validationContext"
                name="標題"
                rules="required"
              >
                <b-form-group
                  label="標題："
                  label-for="meeting_title"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="meeting_title"
                    v-model="newTaskData.meeting_title"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- 會議紀錄 -->
            <b-col class="mb-1" cols="12" md="12">
              <b>會議紀錄:</b>
              <div class="pt-50 custom-quill-editor quill-editor">
                <!--富文本编辑器组件-->
                <quill-editor
                  v-model="newTaskData.meeting_content"
                  :content="newTaskData.meeting_content"
                  :options="editorOption"
                  ref="QuillEditor"
                >
                </quill-editor>
              </div>
            </b-col>
          </b-row>

          <div
            class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-sm-2 mobile-w100 bgGreen"
              type="submit"
            >
              <feather-icon size="16" class="mr-50" icon="CheckCircleIcon" />
              提交
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('hide')"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormRadio,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onMounted, watch } from "@vue/composition-api";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BModal,
    VBModal,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormRadio,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    // taskEditTabInformation,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    taskData: {},
    is_normal:{}
  },
  data() {
    return {};
  },

  methods: {
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.newTaskData.start = this.newTaskData.start instanceof Date ?  this.getEventDateTime(this.newTaskData.start) : this.newTaskData.start
          
          this.newTaskData.end = this.newTaskData.end instanceof Date ?  this.getEventDateTime(this.newTaskData.end) : this.newTaskData.end
          this.newTaskData.users = this.newTaskData.users.map(ele => ele.id)


          store
            .dispatch("task/addTask", this.newTaskData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                confirmButtonText: "Confirm",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              console.log('this.is_normal',this.is_normal)
              if(this.is_normal){
                this.$emit('refetch-data')
              }else{
                this.$emit('refetch-events')
              }
              // location.reload();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  setup(props, { emit }) {
    const resetTaskData = () => {
      props.taskData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetTaskData);

    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [
              {
                font: [
                  "SimSun",
                  "SimHei",
                  "Microsoft-YaHei",
                  "KaiTi",
                  "FangSong",
                  "Arial",
                ],
              },
            ],
            [{ align: [] }],
            ["clean"],
            ["link", "image"],
          ],
          handlers: {
            image: (value) => {
              console.log("image", value);
              if (value) {
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };
    const loading = ref(false);

      const getEventDateTime = (time) => {
        const eventTime = new Date(time);
        const y = eventTime.getFullYear();
        const M = Number(eventTime.getMonth() + 1) >= 10 ? Number(eventTime.getMonth() + 1) : '0' + Number(eventTime.getMonth() + 1);
        const d = eventTime.getDate();
        const h = eventTime.getHours() >= 10 ? eventTime.getHours() : '0' + eventTime.getHours()
        const m = eventTime.getMinutes() >= 10 ? eventTime.getMinutes() : '0' + eventTime.getMinutes()
        return `${y}-${M}-${d} ${h}:${m}`
      }

    const newTaskData = ref({});
    watch(
      () => props.taskData,
      (newVal) => {
        newTaskData.value = {...newVal, start:getEventDateTime(newVal.start)};
      },{immediate:true}
    );

    watch(
      () => newTaskData.value.warehouse_id,
      (newVal) => {
        if (warehouseOptions.value && warehouseOptions.value.length > 0) {
          const target = warehouseOptions.value.find((ele) => ele.id == newVal);
          if (target) {
            newTaskData.value.address = target.location;
          }
        }
      }
    );



    const meetingStageOptions = ref([]);
    const customerOptions = ref([]);
    const contractOptions = ref([]);
    const warehouseOptions = ref([]);
    const userOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptions", {
          meeting_stage: true,
          customer: true,
          contract: true,
          warehouse: true,
          user: true,
        })
        .then((response) => {
          meetingStageOptions.value = response.data.meeting_stages;
          customerOptions.value = response.data.customers;
          contractOptions.value = response.data.contracts;
          warehouseOptions.value = response.data.warehouses;
          userOptions.value = response.data.users;
        });
    };

    onMounted(() => {
      refetchOption();
    });

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      editorOption,
      newTaskData,
      loading,
      meetingStageOptions,
      customerOptions,
      contractOptions,
      warehouseOptions,
      userOptions,
      getEventDateTime
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.custom-quill-editor.quill-editor{
  .ql-editor{
    height: 180px;
  }
}

</style>
<style lang="scss" scoped>
</style>

