import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from '@/auth/utils'

export default function useCustomersList(){

    const toast = useToast()

    const refCustomerListTable = ref(null)

    const tableColumns = [
        {key:'id', label:'編號'},
        {key:'name', label:'客戶名稱',  sortable:true},
        {key:'email', label:'電郵',  sortable:true},
        {key:'address', label:'地址',  sortable:true},
        {key:'mobile', label:'手機',  sortable:true},
        {key: 'referral_code', label: '推薦碼', sortable: true},
        {key: 'points', label: '積分', sortable: true},
        {key:'is_active', label:'狀態',  sortable:true},
        {key:'actions', label:'操作'}
    ]

    const perPage = ref(10)
    const totalRows = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    

    const dataMeta = computed(() => {
        const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRows.value,
        }
    })

    const showMessage = (title,text,icon, variant) =>{
        toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon,
              variant
            },
        })
    }

    const refetchData = () =>{
        refCustomerListTable.value.refresh()
    }

    const getCustomerImage = (image) =>  process.env.VUE_APP_IMAGE_URL+image

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const customerList = ref(null)

    const fetchCustomers = (ctx, callback) => {

        store.dispatch('customer/fetchCustomers', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            //console.log(response.data)
            const { customers, total} = response.data
            callback(customers)
            customerList.value = customers
            totalRows.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching customers list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
            })
        })
    }


    return {
        fetchCustomers,
        tableColumns,
        perPage,
        currentPage,
        totalRows,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refCustomerListTable,
        getCustomerImage,
        showMessage,
    }

}