import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmails(ctx ,queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/email/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEmailTemplates(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/email/template/list")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEmailTemplate(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/email/template")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    storeEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/email/template", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/email/template/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendEmail(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/email/send", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
}
