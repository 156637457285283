import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/invoice/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchInvoice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/invoice', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/invoice", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteInvoice(ctx, queryParams)
    {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/invoice', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    restoreInvoice(ctx, data)
    {
        return new Promise((resolve, reject) => {
            axiosIns.patch('/invoice/restore', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },

    payment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/payment/offline", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadInvoice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/invoice/export', { params: queryParams , responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // auditInvoice(ctx, data) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns.post('/payment/audit', data)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    deletePaymentDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/payment/image', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReceipt(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/payment/export', { params: queryParams , responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
