<template>
  <div class="w-100">
      <div
        class="navbar-container d-flex align-items-center bgColor1 colorWhite sami-bottom-rounded"
      >
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link class="nav-link" @click="toggleVerticalMenuActive">
              <feather-icon icon="MenuIcon" size="21" />
            </b-link>
          </li>
        </ul>

        <!-- Left Col -->
        <div
          class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
        >
          {{$route.meta.text}}
        </div>

        <b-navbar-nav :key="$store.state.nav_key" class="nav align-items-center ml-auto">
          <b-nav-item-dropdown
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
          >
            <template #button-content>
              <div class="d-sm-flex d-none user-nav">
                <p
                  class="user-name font-weight-bolder colorWhite mb-0"
                  v-html="userName"
                ></p>
                <span class="user-status" v-html="storeName"></span>
              </div>
              <b-avatar
                size="40"
                variant="light-primary"
                badge
                :src="require('@/assets/images/avatars/19.png')"
                class="badge-minimal"
                badge-variant="success"
              />
            </template>

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="handleEditPassword"
            >
              <feather-icon size="16" icon="EditIcon" class="mr-50" />
              <span>Edit Password</span>
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="handleLogout"
            >
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              <span>Logout</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <edit-password />
      </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BFormInput,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EditPassword from "./EditPassword.vue";
export default {
  data() {
    return {
      userName: getUserData().name,
      storeName: "",
      storeId: "",
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BFormInput,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    vSelect,

    // Navbar Components
    DarkToggler,

    EditPassword,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleEditPassword() {
      this.$root.$emit("bv::show::modal", "modal-edit-password", "#btnShow");
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/api/auth/logout");
      localStorage.clear();
      //Reqired to login page
      //Show the success message
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    },
  },
  mounted() {
    if (!getUserData()) {
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    }

      //console.log("mounted");
      axiosIns.get("/auth").then((response) => {
        //console.log(response.data.user.ability)
        let ability =
          response &&
          response.data &&
          response.data.user &&
          response.data.user.ability;
        if (ability) {
          localStorage.setItem("userData", JSON.stringify(response.data.user));
          this.$ability.update(ability);
        }
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>