<template>
  <div>
    <modal-edit-referral
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      :referralData="referralData"
      :contractOptions="contractOptions"
      :referralCouponTypeOptions="referralCouponTypeOptions"
      :customerOptions="customerOptions"
      @refetch-data="refetchData"
    />
    <!-- top menu -->
    <div
      class="navbar-container custome-top-menu bgColor2 sami-bottom-rounded"
    >
      <div class="blank-block"></div>
      <div
        class="d-flex align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <!-- <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input 
                v-model="searchQuery" class="d-inline-block" placeholder="搜尋..." />
            </div>
          </div> -->

          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">優惠券類型</span>
            <v-select
              v-model="filterReferralCouponType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="referralCouponTypeOptions"
              label="name"
              :reduce="(option) => option.id"
              :clearable="true"
              placeholder="優惠券類型"
              class=" d-inline-block common-rounded mx-50"
            />
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">受益人</span>
            <v-select
              v-model="filterReferralCustomer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              :reduce="(option) => option.id"
              :clearable="true"
              placeholder="受益人"
              class=" d-inline-block common-rounded mx-50"
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <a v-if="(ability.can('create', 'referrals') || true)"
            class="mr-2"
            href="javascript:void(0)"
            @click="$bvModal.show('modal-edit-referral')"
          >
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <b-card no-body class=" mb-0">

      <b-table
        ref="refReferralListTable"
        class="position-relative"
        :items="fetchReferrals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(customer_id)="data">
          {{ getCustomerName(data.item.customer_id) }}
        </template>
        <template #cell(referral_coupon_type_id)="data">
          {{ getReferralCouponTypeName(data.item.referral_coupon_type_id) }}
        </template>
        <template #cell(free_month)="data">
          {{ data.item.free_month ? data.item.free_month + "個月" : "" }}
        </template>
        <template #cell(discount_percentage)="data">
          {{ data.item.discount_percentage ?  Math.round(data.item.discount_percentage * 100) / 100  + "%" : "" }}
        </template>
        <template #cell(contract_id)="data">
          {{ getContractNumber(data.item.contract_id) }}
        </template>
        <template #cell(referrer_id)="data">
          {{ getCustomerName(data.item.referrer_id) }}
        </template>
        <template #cell(invitee_id)="data">
          {{ getCustomerName(data.item.invitee_id) }}
        </template>
        <template #cell(user_id)="data">
          {{ getUserName(data.item.user_id) }}
        </template>

        <template #cell(action)="data">
          <a v-if="(ability.can('update', 'referrals')) || true" href="javascript:void(0)" @click="handleEdit(data.item)">
            <feather-icon class="color1" icon="Edit2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a v-if="(ability.can('delete', 'referrals')) || true" href="javascript:void(0)" @click="confirmDelete(data.item.id)">
            <feather-icon class="color1" icon="Trash2Icon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api";

import referralStoreModule from "../referralStoreModule";
import useReferralsList from "./useReferralsList";
import ModalEditReferral from "../modal/ModalEditReferral.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalEditReferral,
  },
  methods: {
    handleEdit(item) {
      this.referralData = item;
      this.isEdit = true;
      this.$bvModal.show("modal-edit-referral");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.referralData = { id: 0, };
    },
    confirmDelete(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("referral/deleteReferral", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      referralData: {},
      isEdit: false,
      trigger: false,
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "referral";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, referralStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const {
        fetchReferrals,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refReferralListTable,
        showMessage,
        referralList,
        contractOptions,
        userOptions,
        referralCouponTypeOptions,
        customerOptions,
        filterReferralCouponType, 
        filterReferralCustomer,
        filterReferralContract,
        filterReferralReferrer,
        filterReferralInvitee,
        ability
    } = useReferralsList();

    const getCustomerName = computed(() => {
      return (customerId) => {
        const customer = customerOptions.value.find(ele => customerId == ele.id);
        return customer ? customer.name : '';
      };
    });

    const getReferralCouponTypeName = computed(() => {
      return (typeId) => {
        const couponType = referralCouponTypeOptions.value.find(ele => typeId == ele.id);
        return couponType ? couponType.name : '';
      };
    });

    const getContractNumber = computed(() => {
      return (contractId) => {
        const contract = contractOptions.value.find(ele => contractId == ele.id);
        return contract ? contract.contract_number : '';
      };
    });

    const getUserName = computed(() => {
      return (userId) => {
        const user = userOptions.value.find(ele => userId == ele.id);
        return user ? user.name : '';
      };
    });

    return {
        fetchReferrals,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refReferralListTable,
        showMessage,
        referralList,
        contractOptions,
        userOptions,
        referralCouponTypeOptions,
        customerOptions,
        filterReferralCouponType, 
        filterReferralCustomer,
        filterReferralContract,
        filterReferralReferrer,
        filterReferralInvitee,
        ability,
        getCustomerName,
        getReferralCouponTypeName,
        getContractNumber,
        getUserName,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

../referralStoreModule
