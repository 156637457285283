import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBookingsList(){

    const toast = useToast()

    const refBookingListTable = ref(null)

    const tableColumns = [
        {key:'id', label:'記錄編號',  sortable:true},
        {key:'mini_storage.warehouse', label:'倉庫',},
        {key:'mini_storage.storage_number', label:'倉庫號碼',  sortable:true},
        {key:'booking_stage_id', label:'階段',  sortable:true},
        {key:'show_details', label:'合約詳情',  sortable:true},
        {key:'contract', label:'合約',  sortable:true},
        {key:'cancel_rent', label:'退租',  sortable:true},
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    

    const dataMeta = computed(() => {
        const localItemsCount = refBookingListTable.value ? refBookingListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })



    const tableContractsColumns = [
        {key:'id', label:'編號',  sortable:true},
        {key:'name', label:'名字',},
        {key:'smart_cards', label:'智能卡',  sortable:true},
        {key:'id_number', label:'身份證號碼',  sortable:true},
        {key:'phone', label:'電話',  sortable:true},
        {key:'address', label:'地址',  sortable:true},
        {key:'start_date', label:'開始日期',  sortable:true},
        {key:'end_date', label:'完結日期',  sortable:true},
        {key:'amount', label:'總值',  sortable:true},
        {key:'rent_period', label:'期數',  sortable:true},
        {key:'price', label:'優惠價',  sortable:true},
        {key:'standard_price', label:'標準價格',  sortable:true},
        {key:'deposite', label:'訂金',  sortable:true},
        {key:'export', label:'下載',  sortable:true},
        {key:'whatsapp_link', label:'Whatsapp',  sortable:true},
    ]

    const showMessage = (title,text,icon, variant) =>{
        toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon,
              variant
            },
        })
    }

    const refetchData = () =>{
        refBookingListTable.value.refresh()
    }


    const warehouse = ref(null)
    const fetchWarehouse = () => {
        store
          .dispatch("booking/fetchWarehouse", { id:filter_warehouse_id.value })
          .then((response) => {
            warehouse.value = response.data.warehouse;
          })
          .catch((error) => {
            console.log({ error });
          });
    }

    const bookingList = ref(null)
  
    const filter_warehouse_id = ref(1)
    const filter_mini_storage_id = ref(null)
    const fetchBookings = (ctx, callback) => {

        store.dispatch('booking/fetchBookings', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            warehouse_id: filter_warehouse_id.value,
            mini_storage_id:filter_mini_storage_id.value
        })
        .then(response => {
            //console.log(response.data)
            const { bookings, total} = response.data
            bookingList.value = bookings
            totalRow.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching booking list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
            })
        })
    }

    const bookingStageOptions = ref([])
    const warehouseOptions = ref([])

    const fetchOptions = () => {
        store
          .dispatch("app/fetchOptions", { 
            booking_stage: true,
            warehouse: true,
        })
          .then((response) => {
            bookingStageOptions.value = response.data.booking_stages;
            warehouseOptions.value = response.data.warehouses;
          })
          .catch((error) => {
            console.log({ error });
          });
      };

    watch([filter_warehouse_id, filter_mini_storage_id,currentPage, perPage, searchQuery], () => {
        fetchBookings();
        fetchWarehouse();
        
    });

      onMounted(() => {
        fetchOptions();
        fetchBookings();
        fetchWarehouse();
      });


    return {
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refBookingListTable,
        showMessage,
        filter_warehouse_id,
        warehouse,
        bookingList,
        filter_mini_storage_id,

        tableContractsColumns,

        bookingStageOptions,
        warehouseOptions
    }

}