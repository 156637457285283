<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="FileTextIcon" size="19" />
      <h4 class="mb-0 ml-50">Contract</h4>
    </div>
    <validation-observer v-if="newContractData" #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form: Personal Info Form -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Field: Contract ID -->
            <b-col cols="12" md="6">
              <b-form-group label="Contract ID" label-for="contract_id">
                <b-form-input
                  id="contract_id"
                  v-model="newContractData.id"
                  readonly
                />
              </b-form-group>
            </b-col>
            <!-- Field: Booking ID -->
            <b-col cols="12" md="6">
              <b-form-group label="Booking ID" label-for="booking_id">
                <b-form-input
                  id="booking_id"
                  v-model="newContractData.booking_id"
                  readonly
                />
              </b-form-group>
            </b-col>
            <!-- Field: Mini Storage ID -->
            <b-col cols="12" md="6">
              <b-form-group label="Mini Storage ID" label-for="mini_storage_id">
                <b-form-input
                  id="mini_storage_id"
                  v-model="newContractData.mini_storage_id"
                  readonly
                />
              </b-form-group>
            </b-col>
            <!-- Field: Customer ID -->
            <b-col cols="12" md="6">
              <b-form-group label="Customer ID" label-for="customer_id">
                <b-form-input
                  id="customer_id"
                  v-model="newContractData.mini_storage_id"
                  readonly
                />
              </b-form-group>
            </b-col>

            <!-- Field: Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="newContractData.name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Smart Cards"
                rules="required"
              >
                <b-form-group label="Smart Cards" label-for="smart_cards">
                  <el-select
                    v-model="newContractData.smart_cards"
                    class="w-100"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    no-data-text="無數據"
                    placeholder='輸入內容 並 按下"Enter"以創建 Smart Cards'
                  >
                  </el-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="HKID / BR" label-for="id_number">
                <b-form-input
                  id="id_number"
                  v-model="newContractData.id_number"
                  autofocus
                  trim
                  placeholder="HKID / BR"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6"
              ><b-form-group label="Phone" label-for="phone">
                <b-form-input
                  id="phone"
                  v-model="newContractData.phone"
                  trim
                  placeholder="Phone"
                /> </b-form-group
            ></b-col>

            <b-col cols="12" md="6"
              ><b-form-group label="Address" label-for="address">
                <b-form-input
                  id="address"
                  v-model="newContractData.address"
                  trim
                  placeholder="Address"
                /> </b-form-group
            ></b-col>

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Rent Period"
                rules="required"
              >
                <b-form-group label="Rent Period" label-for="rent_period">
                  <b-form-input
                    id="rent_period"
                    v-model="newContractData.rent_period"
                    trim
                    :state="getValidationState(validationContext)"
                    placeholder="Rent Period"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Prepay Type"
                rules="required"
              >
                <b-form-group
                  label="Prepay Type"
                  label-for="prepay_type_id"
                  class="required"
                >
                  <v-select
                    v-model="newContractData.prepay_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="prePayTypeOptions"
                    label="type_name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="contract_date"
                rules="required"
              >
                <b-form-group label="Contract Date" label-for="contract_date">
                  <flat-pickr
                    v-model="newContractData.contract_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Start Date"
                rules="required"
              >
                <b-form-group label="Start Date" label-for="start_date">
                  <flat-pickr
                    v-model="newContractData.start_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" v-if="contractPediod">
              <b-form-group label="End Date" label-for="period_end_date">
                <b-form-input
                  id="period_end_date"
                  v-model="contractPediod.end_date"
                  readonly
                />
              </b-form-group>
            </b-col>


            <b-col cols="12" md="6" v-if="contractPediod">
              <b-form-group label="Amount" label-for="period_amount">
                <b-form-input
                  id="period_amount"
                  v-model="contractPediod.amount"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="contractPediod">
              <b-form-group label="Deposite" label-for="period_deposite">
                <b-form-input
                  id="period_deposite"
                  v-model="contractPediod.deposite"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="contractPediod">
              <b-form-group label="Price" label-for="period_price">
                <b-form-input
                  id="period_price"
                  v-model="contractPediod.price"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="contractPediod">
              <b-form-group label="Standard Pice" label-for="period_standard_price">
                <b-form-input
                  id="period_standard_price"
                  v-model="contractPediod.standard_price"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Button: Submit & Reset Button.-->
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Save
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="hide"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
          <hr/>
          <b-table
            ref="refRentScheduleListTable"
            class="position-relative"
            :items="rent_schedule_list"
            responsive
            :fields="tableColumnsRentSchedule"
            primary-key="id"
            :sort-by.sync="sortByRentSchedule"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescRentSchedule"
          >
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaRentSchedule.from }} to {{ dataMetaRentSchedule.to }} of
                  {{ dataMetaRentSchedule.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageRentSchedule"
                  :total-rows="totalRowRentSchedule"
                  :per-page="perPageRentSchedule"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>

          <hr/>

          <b-table
            ref="refPaymentScheduleListTable"
            class="position-relative"
            :items="payment_schedules_list"
            responsive
            :fields="tableColumnsPaymentSchedule"
            primary-key="id"
            :sort-by.sync="sortByPaymentSchedule"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescPaymentSchedule"
          >
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaPaymentSchedule.from }} to {{ dataMetaPaymentSchedule.to }} of
                  {{ dataMetaPaymentSchedule.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePaymentSchedule"
                  :total-rows="totalRowPaymentSchedule"
                  :per-page="perPagePaymentSchedule"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTable,
  BPagination,
  BOverlay,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BTable,
    BPagination,
    BOverlay,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    hide() {
      this.$router.replace("/booking/list");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("booking/editContract", this.newContractData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  props: {
    contractData: {
      type: Object,
      required: true,
    },
    prePayTypeOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const newContractData = ref(null);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetContractData = () => {
      props.contractData = JSON.parse(JSON.stringify({}));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetContractData);

    const contractPediod = ref(null);

    const getContractPeriods = (
      start_date,
      rent_period,
      mini_storage_id,
      prepay_type_id
    ) => {
      store
        .dispatch("booking/getContractPeriods", {
          start_date,
          rent_period,
          mini_storage_id,
          prepay_type_id,
        })
        .then((response) => {
          contractPediod.value = response.data.result;

          refetchTbData(
            totalRowRentSchedule,
            dataMetaRentSchedule,
            perPageRentSchedule,
            currentPageRentSchedule,
            rent_schedule_list,
            contractPediod.value.rent_schedules
          );

          refetchTbData(
            totalRowPaymentSchedule,
            dataMetaPaymentSchedule,
            perPagePaymentSchedule,
            currentPagePaymentSchedule,
            payment_schedules_list,
            contractPediod.value.payment_schedules
          );
        })
        .catch((error) => {
          console.log("error when fetching contract", error);
          if (error.response.status === 404) {
            contractData.value = undefined;
          }
        });
    };
    
    const rent_schedule_list = ref([]);
    const refRentScheduleListTable = ref(null);
    const tableColumnsRentSchedule = [
      { key: "start_date", label: "Start Date", sortable: true },
      { key: "end_date", label: "End Date" },
      { key: "mini_storage_id", label: "Mini Storage", sortable: true },
      { key: "prepay_type_id", label: "Prepay Type", sortable: true },
      { key: "amount", label: "Amount", sortable: true },
    ];

    const perPageRentSchedule = ref(10);
    const totalRowRentSchedule = ref(0);
    const currentPageRentSchedule = ref(1);
    const perPageOptionsRentSchedule = [10, 25, 50, 100];
    const sortByRentSchedule = ref("id");
    const isSortDirDescRentSchedule = ref(false);

    const dataMetaRentSchedule = computed(() => {
      const localItemsCount = refRentScheduleListTable.value
        ? refRentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPageRentSchedule.value * (currentPageRentSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPageRentSchedule.value * (currentPageRentSchedule.value - 1) +
          localItemsCount,
        of: totalRowRentSchedule.value,
      };
    });

    const payment_schedules_list = ref([]);
    const refPaymentScheduleListTable = ref(null);
    const tableColumnsPaymentSchedule = [
      { key: "pay_date", label: "Pay Date", sortable: true },
      { key: "mini_storage_id", label: "Mini Storage", sortable: true },
      { key: "amount", label: "Amount", sortable: true },
      { key: "prepay_type_id", label: "Prepay Type", sortable: true },
      { key: "payment_cycle", label: "Payment Cycle", sortable: true },
      { key: "price", label: "Price", sortable: true },
      { key: "rent_period", label: "Rent Period", sortable: true },
      { key: "standard_price", label: "Standard Price", sortable: true },
    ];

    const perPagePaymentSchedule = ref(10);
    const totalRowPaymentSchedule = ref(0);
    const currentPagePaymentSchedule = ref(1);
    const perPageOptionsPaymentSchedule = [10, 25, 50, 100];
    const sortByPaymentSchedule = ref("id");
    const isSortDirDescPaymentSchedule = ref(false);

    const dataMetaPaymentSchedule = computed(() => {
      const localItemsCount = refPaymentScheduleListTable.value
        ? refPaymentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPagePaymentSchedule.value * (currentPagePaymentSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPagePaymentSchedule.value * (currentPagePaymentSchedule.value - 1) +
          localItemsCount,
        of: totalRowPaymentSchedule.value,
      };
    });

    const refetchTbData = (total, meta, perPage, current, target, from) => {
      total.value = from.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      target.value = from.filter((ele, index) => index >= min && index <= max);
    };

    watch(
      () => props.contractData,
      (newVal) => {
        newContractData.value = { ...newVal };
      }
    );

    watch(
      () => newContractData.value,
      (newVal) => {
        if (
          newVal.start_date &&
          newVal.rent_period &&
          newVal.mini_storage_id &&
          newVal.prepay_type_id
        ) {
          getContractPeriods(
            newVal.start_date,
            newVal.rent_period,
            newVal.mini_storage_id,
            newVal.prepay_type_id
          );
        }
      },
      { deep: true }
    );
    watch(
      [currentPageRentSchedule, currentPagePaymentSchedule],()=>{
        refetchTbData(
            totalRowRentSchedule,
            dataMetaRentSchedule,
            perPageRentSchedule,
            currentPageRentSchedule,
            rent_schedule_list,
            contractPediod.value.rent_schedules
          );

          refetchTbData(
            totalRowPaymentSchedule,
            dataMetaPaymentSchedule,
            perPagePaymentSchedule,
            currentPagePaymentSchedule,
            payment_schedules_list,
            contractPediod.value.payment_schedules
          );

      }
    )

    onMounted(() => {});

    return {
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newContractData,
      contractPediod,

      rent_schedule_list,
      refRentScheduleListTable,
      tableColumnsRentSchedule,
      perPageRentSchedule,
      totalRowRentSchedule,
      currentPageRentSchedule,
      perPageOptionsRentSchedule,
      sortByRentSchedule,
      isSortDirDescRentSchedule,
      dataMetaRentSchedule,

      payment_schedules_list,
      refPaymentScheduleListTable,
      tableColumnsPaymentSchedule,
      perPagePaymentSchedule,
      totalRowPaymentSchedule,
      currentPagePaymentSchedule,
      perPageOptionsPaymentSchedule,
      sortByPaymentSchedule,
      isSortDirDescPaymentSchedule,
      dataMetaPaymentSchedule,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}
</style>
