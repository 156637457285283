<template>
  <b-sidebar
    id="add-new-booking-sidebar"
    :visible="isAddNewBookingSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-booking-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ button_text }} booking-data</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Stage"
              rules="required"
            >
              <b-form-group label="Stage" label-for="stage" class="required">
                <b-form-input
                  id="stage"
                  v-model="bookingData.stage"
                  :state="getValidationState(validationContext)"
                  autofocus
                  trim
                  placeholder="Stage"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="bookingData.description"
                autofocus
                trim
                placeholder="Description"
              />
            </b-form-group>

            <!-- Field : Background Color-->
            <b-form-group label="Background Color" label-for="background_color">
              <b-input-group>
                <b-form-input
                  id="background_color"
                  v-model="bookingData.background_color"
                  autofocus
                  trim
                />
                <b-input-group-append is-text>
                  <el-color-picker
                    v-model="bookingData.background_color"
                    size="mini"
                  ></el-color-picker>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- Field : Font Color-->
            <b-form-group label="Font Color" label-for="font_color">
              <b-input-group>
                <b-form-input
                  id="font_color"
                  v-model="bookingData.font_color"
                  autofocus
                  trim
                />
                <b-input-group-append is-text>
                  <el-color-picker
                    v-model="bookingData.font_color"
                    size="mini"
                  ></el-color-picker>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    hide() {
      this.$emit("update:is-add-new-booking-sidebar-active", false);
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("booking/updateBooking", this.bookingData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  model: {
    prop: "isAddNewBookingSidebarActive",
    event: "update:is-add-new-booking-sidebar-active",
  },
  props: {
    isAddNewBookingSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      confirm_p: "",
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const button_text = ref("Add New");

    const blankBookingData = ref({
      id: 0,
      name: "",
      email: "",
      mobile: "",
      address: "",
      is_active: 1,
    });

    const bookingData = ref(
      JSON.parse(JSON.stringify(blankBookingData.value))
    );

    const resetbookingData = () => {
      bookingData.value = JSON.parse(JSON.stringify(blankBookingData.value));
      button_text.value = "Add New";
    };

    watch(
      () => [props.id, props.isAddNewBookingSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewBookingSidebarActive) {
          bookingData.value = { ...props.data };
          button_text.value = "Edit";
        }
      }
    );

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetbookingData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
      bookingData,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-group-append .input-group-text{
  padding: 0.3rem 1rem;
}
</style>