import axiosIns from '@/libs/axios'
import {getUserData} from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addDiscount(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/discount', data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchDiscounts(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/discount/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    updateDiscount(ctx, data) {
      return new Promise((resolve, reject) => {
          axiosIns.post('/discount', data,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDiscount(ctx, queryParams)
    {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/discount', {params:queryParams})
        .then(response=>resolve(response))
        .catch(error=>reject(error))
    })
    }




  },
}
