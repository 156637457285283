import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useCustomerTypesList() {

    const toast = useToast()

    const refCustomerTypeListTable = ref(null)

    const tableColumns = [
        { key: 'id', label: '客戶編號' },
        { key: 'name', label: '客戶類別', sortable: true },
        { key: 'sort_order', label: '排序', sortable: true },
        { key: 'is_active', label: '狀態', sortable: true },
        { key: 'action', label: '操作', },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refCustomerTypeListTable.value ? refCustomerTypeListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refCustomerTypeListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })


    const customerTypesList = ref(null)

    const fetchCustomerTypes = (ctx, callback) => {

        store.dispatch('customer-type/fetchCustomerTypes', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
            .then(response => {
                const { customer_types, total } = response.data
                callback(customer_types)
                customerTypesList.value = customer_types
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching customer types list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
        fetchCustomerTypes,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refCustomerTypeListTable,
        showMessage,
        customerTypesList,
        ability
    }

}