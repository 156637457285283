<template>
  <div>
    <div class="d-flex">
      <h4 class="mb-0">排序</h4>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-1">
        <b-row>
          <b-col cols="12" md="12">
            <div class="header-row">
              <div>Storage Number</div>
            </div>
            <draggable
              v-model="warehouseData.mini_storages"
              tag="ul"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(item, index) in warehouseData.mini_storages"
                :key="index"
                tag="li"
                class="list-wrapper"
              >
              <div> {{ item.storage_number }}</div>     
              </b-list-group-item>
            </draggable>
            <prism
            v-if="false"
              language="javascript"
              class="rounded mt-2"
            >list: {{ warehouseData.mini_storages }}
            </prism>
          </b-col>

          <div class="d-flex mt-2 align-items-center justify-content-end"></div>
          <b-col cols="12" md="12">
            <hr />
            <div class="d-flex align-items-center justify-content-end">
              <!-- 8009 3728 / 290-->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="success"
                @click="handleUpdate"
                class="mr-1"
              >
                更改排序
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
  BListGroupItem,
} from "bootstrap-vue";
import { ref, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  data() {
    return {
      id: 0,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    draggable,
    Prism,
    BListGroupItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect
  },
  computed: {
  },
  methods: {
    handleUpdate(){
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value){
          let ministorages = this.warehouseData.mini_storages.map((item, index) => {
            return {
              id: item.id,
              sort_order: index + 1
            }
          });

          let submitData = {
            ministorages
          }
          this.loading = true
          store.dispatch("warehouse/updateMinistorageSorting", submitData)
          .then((response) => {
              this.loading = false;
              this.fitler_id = null;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      })
    },
  },
  props: {
    warehouseData: {
      type: Object,
      required: true,
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const tableColumns = [{ key: "item_name" }, { key: "actions" }];
    return {
      loading,
      tableColumns
    };
  },
};
</script>
<style scoped>
.list-wrapper, .header-row{
  display:flex;
  justify-content:space-between;
  align-items: center;
  text-align:left;
}
.list-wrapper > div, .header-row > div{
  width: 40%;
}
.header-row{
  font-weight: 700;
  padding: 10px;
  background: #f3f2f7ed;
  height: 50px;
}
</style>