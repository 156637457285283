import axiosIns from '@/libs/axios'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/info', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRecentRentReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/warehouse/recent-rent-report', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
