import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAppointments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/appointment/list',{params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchAppointment(ctx, qureyParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/appointment`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addAppointment(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/appointment', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteAppointment(ctx, qureyParams){
      return new Promise((resolve, reject) => {
        axiosIns.delete(`/appointment`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }
  },
}
