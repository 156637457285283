<template>
  <div>
    <page-list-edit
      :is-edit-page-sidebar-active.sync="isEditPageSidebarActive"
      :id="id"
      :data="pageData"
      @refetch-data="refetchData"
    />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div class="d-flex flex-wrap align-items-center colorWhite">
        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="搜尋.."
            v-if="false"
          />
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-table
        ref="refPageListTable"
        class="position-relative"
        :items="fetchPages"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(show_details)="row">
          <b-button
            v-if="row.item.content"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? "隱藏" : "顯示" }}
          </b-button>
        </template>

        <template #row-details="row">
          <span v-html="row.item.content"></span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="ability.can('update', 'pages') || true" @click="editPage(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">編輯</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPages"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import pageStoreModule from "../pageStoreModule";
import usePagesList from "./usePagesList";
import PageListEdit from "./PageListEdit.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    PageListEdit,
  },
  methods: {
    resetPageData() {
      this.pageData = {
        id: 0,
        name: null,
        content: null,
      };
    },
    handleAdd() {
      this.id = 0;
      this.resetPageData();
      this.isEditPageSidebarActive = true;
    },
    confirmDelete(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的操作是最終的，您無法恢復資料。",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("bar-page/deletePage", { id: id })
            .then((response) => {
              this.refetchData();
              this.showMessage(
                "Success",
                response.data.message,
                "CheckIcon",
                "success"
              );
            })
            .catch((error) => {
              console.log(error);
              //this.showMessage('Fail', error.response.data.message, 'HeartIcon', 'danger')
            });
        }
      });
    },
    editPage(item) {
      this.id = item.id;
      this.pageData = { ...item };
      this.isEditPageSidebarActive = true;
    },
  },
  data() {
    return {
      id: 0,
      pageData: {},
    };
  },
  setup() {
    const BAR_PAGE_STORE_MODULE_NAME = "bar-page";
    const isEditPageSidebarActive = ref(false);

    if (!store.hasModule(BAR_PAGE_STORE_MODULE_NAME))
      store.registerModule(BAR_PAGE_STORE_MODULE_NAME, pageStoreModule);

    onUnmounted(() => {
      if (store.hasModule(BAR_PAGE_STORE_MODULE_NAME))
        store.unregisterModule(BAR_PAGE_STORE_MODULE_NAME);
    });

    const {
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalPages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPageListTable,
      refetchData,
      showMessage,
      ability
    } = usePagesList();

    return {
      //SideBar
      isEditPageSidebarActive,
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalPages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPageListTable,
      refetchData,
      showMessage,
      ability
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

