import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePricesList() {
    //User toast
    const toast = useToast()

    const refPriceListTable = ref(null)

    const tableColumns = [
        {key: 'id', label:'編號', sortable: true},
        {key: 'position', label: '位置', sortable:true},
        {key: 'location', label: '地址', sortable:true},
        {key: 'size', label: '闊x深x高(呎)'},
        {key: 'area', label: '面積（平方呎）'},
        {key: 'bi_annual_price', label: '6個月合約價格' },
        {key: 'annual_price', label: '12個月合約價格' },
        {key: 'monthly_price', label: '每月定價', sortable:true},
        {key: 'remark', label: '備註', sortable:true},
        {key: 'actions', label: '操作'},
    ]
    
    //Filter 

    const perPage = ref(10)
    const totalRows = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refPriceListTable.value ? refPriceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRows.value,
        }
    })

    const warehouse_category_id = ref(1)

    const refetchData = () => {
        refPriceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, warehouse_category_id], () => {
        refetchData()
    })

    const priceList = ref([])
    const fetchPrices = () => {
        priceList.value = [
            {
                id:1, 
                position:'威力工業 A區', 
                location:'沙田火炭坳背灣街2-12號 ', 
                width:'2.1', 
                depth:'5.7', 
                height:'4', 
                monthly_price:'300', 
                bi_annual_price:'1750', 
                annual_price:'3400',
                remark: 'rrrvvv'
            },
            {
                id:2, 
                position:'威力工業 A區', 
                location:'沙田火炭坳背灣街2-12號 ', 
                width:'2.1', 
                depth:'5.7', 
                height:'4', 
                monthly_price:'300', 
                bi_annual_price:'1800', 
                annual_price:'3600',
                remark: 'nnannaan'
            },
            {
                id:3, 
                position:'威力工業 A區', 
                location:'沙田火炭坳背灣街2-12號 ', 
                width:'2.1', 
                depth:'5.7', 
                height:'4', 
                monthly_price:'300', 
                bi_annual_price:'1800', 
                annual_price:'3600',
                remark: 'keyer'
            },
        ]

    }
    //     store.dispatch('price/fetchPrices',{
    //         q: searchQuery.value,
    //         perPage: perPage.value,
    //         page: currentPage.value,
    //         sortBy: sortBy.value,
    //         sortDesc: isSortDirDesc.value,
    //     })
    //     .then(response =>{
    //         const {prices, total} = response.data
    //         callback(prices)
    //         totalRows.value = total        
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //         toast({
    //             component: ToastificationContent,
    //             props: {
    //                 title: 'Error fetching price list',
    //                 icon: 'AlertTriangleIcon',
    //                 variant: 'danger'
    //             }
    //         })
    //     })

    onMounted(() => {
        fetchPrices();
    })

    return {
        fetchPrices,
        tableColumns,
        perPage,
        currentPage,
        totalRows,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPriceListTable,
        ability,
        refetchData,
        priceList,
        warehouse_category_id
    }

}