var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mini_storage)?_c('modal-rent-form',{attrs:{"trigger":_vm.trigger,"storageData":_vm.mini_storage},on:{"refetch-data":_vm.fetchWarehouse,"refetch-mini-data":_vm.fetchMiniStorage}}):_vm._e(),_c('div',{staticClass:"navbar-container custome-top-menu bgColor2 common-rounded",staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"blank-block"}),_c('div',{staticClass:"d-flex flex-wrap align-items-center colorWhite"},[_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('span',[_vm._v("平面圖")]),_c('v-select',{staticClass:"d-inline-block common-rounded mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.warehouseOptions,"label":"name","reduce":function (option) { return option.id; },"placeholder":"請先選擇一個倉庫！"},model:{value:(_vm.filter_warehouse_id),callback:function ($$v) {_vm.filter_warehouse_id=$$v},expression:"filter_warehouse_id"}})],1)])]),(_vm.filter_warehouse_id)?_c('div',{attrs:{"id":"warehouse"}},[(
        _vm.warehouseData &&
        _vm.warehouseData.images &&
        _vm.warehouseData.images.length > 0
      )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 d-flex align-items-center"},[_c('b',{staticClass:"block-color bgSelected mr-25"}),_c('span',[_vm._v("已選擇")])]),_c('div',{staticClass:"mr-2 d-flex align-items-center"},[_c('b',{staticClass:"block-color bgAvailable mr-25"}),_c('span',[_vm._v("可租用")])]),_c('div',{staticClass:"mr-2 d-flex align-items-center"},[_c('b',{staticClass:"block-color bgAlreadyRented mr-25"}),_c('span',[_vm._v("已租出")])]),_c('div',{staticClass:"mr-2 d-flex align-items-center"},[_c('b',{staticClass:"block-color bgLatePayment mr-25"}),_c('span',[_vm._v("欠租")])])])]),_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-center my-2"},[_vm._v(_vm._s(_vm.warehouseData.name))])]),_vm._l((_vm.warehouseData.images.filter(
          function (ele) { return ele.image_type_id == 2; }
        )),function(image,index){return _c('b-col',{key:("canvas_" + (image.id)),staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('floor-plan',{attrs:{"warehouseData":_vm.warehouseData,"warehouseImageId":image.id,"storages":_vm.warehouseData.mini_storages.filter(
              function (ele) { return ele.warehouse_image_id == image.id; }
            ),"miniStorage":_vm.mini_storage,"trigger":_vm.trigger,"index":index},on:{"update:miniStorage":function($event){_vm.mini_storage=$event},"update:mini-storage":function($event){_vm.mini_storage=$event},"selectChecked":_vm.selectChecked}})],1)})],2):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }