<template>
  <div>
    <modal-edit-task
      :taskData="taskData"
      @refetch-data="refetchData"
    ></modal-edit-task>
    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>

      <div class="d-flex flex-wrap align-items-center">
        <div class="group-tabs d-flex align-items-center mr-2">
          <a
            :class="`tab-item mr-1 ${isCalandar ? 'active' : ''}`"
            href="javascript:void(0)"
            @click="isCalandar = true"
          >
            日歷形式
          </a>
          <a
            :class="`tab-item ${isCalandar ? '' : 'active'}`"
            href="javascript:void(0)"
            @click="isCalandar = false"
          >
            列表形式
          </a>
        </div>
        <div
          class="d-flex flex-wrap align-items-center justify-content-between colorWhite flex-grow-1"
        >
          <div class="d-flex flex-wrap">
            <div class="d-flex align-items-center mr-2">
              <span>顯示</span>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block common-rounded mx-50"
              />
              <span>條目</span>
            </div>
            <div class="d-flex align-items-center mr-2">
              <span class="mr-1">搜尋</span>
              <div class="search-primary">
                <feather-icon size="16" icon="SearchIcon" />
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block common-rounded"
                  placeholder="搜尋事件"
                />
              </div>
            </div>
          </div>
          <a v-if="ability.can('create', 'tasks')"
            class=""
            href="javascript:void(0)"
            @click="$bvModal.show('modal-edit-task')"
          >
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>

    <b-card no-body class="mb-0 calendar-tab">
      <Calendar v-if="isCalandar"></Calendar>
      <div v-if="!isCalandar">
        <b-table
          v-if="taskList"
          ref="refTasksListTable"
          class="position-relative"
          :items="fetchTasks"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(dateTime)="data">
            {{ data.item.start.slice(0, 10) }} -
            {{ data.item.end.slice(0, 10) }}
          </template>

          <template #cell(customer_name)="data">
            {{ data.item.customer && data.item.customer.name }}
          </template>
          <template #cell(contract_number)="data">
            {{ data.item.contract && data.item.contract.contract_number }}
          </template>
          <template #cell(warehouse)="data">
            {{ data.item.warehouse && data.item.warehouse.name }}
          </template>

          <template #cell(meeting_content)="data">
            <pre
              class="mb-0"
              style="background-color: transparent"
              v-html="data.item.meeting_content"
            ></pre>
          </template>

          <template #cell(meeting_stage_id)="data">
            <b-button
              v-if="meetingStageOptions && meetingStageOptions.length > 0"
              class="stage-button cursor-default"
              :style="getMeetingStageStyle(data.item.meeting_stage_id)"
            >
              {{ getMeetingStageText(data.item.meeting_stage_id) }}
            </b-button>
          </template>

          <template #cell(users)="data">
            <div v-if="data.item.users">
              <b-badge
                class="mr-25"
                v-for="user of data.item.users"
                :key="user.id"
              >
                {{ user.name }}
              </b-badge>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="d-flex">
              <a v-if="ability.can('update', 'tasks')" href="javascript:void(0)" @click="handleEdit(data.item)">
                <feather-icon icon="EditIcon" />
              </a>
              <span class="mx-50">|</span>
              <a v-if="ability.can('delete', 'tasks')" href="javascript:void(0)" @click="removeItem(data.item.id)">
                <feather-icon icon="Trash2Icon" />
              </a>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import useTasksList from "./useTasksList";
import taskStoreModule from "../taskStoreModule";
import Calendar from "../calendar/Calendar";
import ModalEditTask from "../modal/ModalEditTask";

import router from "@/router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    Calendar,
    ModalEditTask,
  },
  data() {
    return {
      isCalandar: true,
      taskData: { id: 0 },
    };
  },
  methods: {
    handleEdit(item) {
      this.taskData = item;
      this.$bvModal.show("modal-edit-task");
    },
    fouceIsEditFalse() {
      this.taskData = { id: 0 };
    },
    removeItem(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的操作是最終的，您將無法檢索該事件。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("task/deleteTask", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },

    getMeetingStageStyle(meetingStageId) {
      const stage = this.meetingStageOptions.find(ele => ele.id === meetingStageId);
      return stage ? {
        backgroundColor: `${stage.background_color}!important`,
        color: `${stage.font_color}!important`
      } : {};
    },

    getMeetingStageText(meetingStageId) {
      const stage = this.meetingStageOptions.find(ele => ele.id === meetingStageId);
      return stage ? stage.stage : '';
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "task";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, taskStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const meetingStageOptions = ref([]);
    const refetchOption = () => {
      store
        .dispatch("app/fetchOptions", {
          meeting_stage: true,
        })
        .then((response) => {
          meetingStageOptions.value = response.data.meeting_stages;
        });
    };

    onMounted(() => {
      refetchOption();
    });

    const {
      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTasksListTable,
      ability,
      refetchData,
      taskList,
    } = useTasksList();

    return {
      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTasksListTable,
      ability,
      refetchData,
      taskList,

      meetingStageOptions,
    };
  },
};
</script>
    
<style lang="scss" scoped>
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.tabs .nav-tabs:first-child {
  margin-left: 2rem;
}
.over {
  background: #f9dfe2 !important;
}
</style>
