import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePaymentTypeList() {

    const toast = useToast()

    const refPaymentTypeListTable = ref(null)

    const tableColumns = [
        /* { key: 'id', label: '客戶編號' }, */
        { key: 'payment_name', label: '付款類型', sortable: true },
       /*  { key: 'sort_order', label: '排序', sortable: true },
        { key: 'is_active', label: '狀態', sortable: true }, */
        { key: 'action', label: '操作', },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refPaymentTypeListTable.value ? refPaymentTypeListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refPaymentTypeListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })


    const paymentTypeList = ref(null)

    const fetchPaymentTypes = (ctx, callback) => {

        store.dispatch('payment-type/fetchPaymentTypes', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            paypal_bit: 0,
            upload_bit: 1
        })
            .then(response => {
                const { payment_types, total } = response.data
                callback(payment_types)
                paymentTypeList.value = payment_types
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching sign status list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
        fetchPaymentTypes,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refPaymentTypeListTable,
        showMessage,
        paymentTypeList,
        ability
    }

}