<template>
  <b-modal
    id="modal-booking-stage"
    cancel-variant="outline-secondary"
    centered
    size="sm"
    title="更改狀態"
    :ok-disabled="false"
    hide-footer
    headerClass="pr-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form v-if="stageData"
        class="mt-2"
        @submit.prevent="handleEdit"
        ref="resetPasswordForm"
      >
        <div class="px-2">
          <b-form-group class="required" >
            <v-select
              v-model="stageData.booking_stage_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="bookingStageOptions"
              label="stage"
              :reduce="(option) => option.id"
              :clearable="false"
              class="w-100"
            />
          </b-form-group>
        </div>
        <div class="d-flex flex-wrap m-2 justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-sm-2 mobile-w100"
            type="submit"
            style="line-height: 1.1"
          >
            <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
            提交
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hideModal"
            class="mt-1 mt-sm-0 mobile-w100"
          >
            取消
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      stageData: null,
    };
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    bookingStageOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-booking-stage", "#btnShow");
    },
    handleEdit() {
      this.loading = true;
      axiosIns
        .post("/booking/", this.stageData)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
          this.hideModal();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);

          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
  watch: {
    bookingData: {
      handler(newVal) {
        this.stageData = { ...newVal };
      },
    },
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .modal-title{
    font-weight: bold;
  }
</style>
