import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'


const config = {
    loginEndpoint: process.env.VUE_APP_SERVICE_URL+'/login',
    logoutEndpoint: process.env.VUE_APP_SERVICE_URL+'/logout'  
}


/*
VUE_APP_SERVICE_URL= 'https://zanolife-api.uniconsults.com/api/user'
VUE_APP_IMAGE_URL = 'https://zanolife-api.uniconsults.com/storage/'
*/

// const config = {
//     loginEndpoint: localStorage.getItem('server')+'/api/user/login',
//     logoutEndpoint: localStorage.getItem('server')+'/api/user/logout'  
// }


const { jwt } = useJwt(axios, config)
export default jwt
