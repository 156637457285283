import { render, staticRenderFns } from "./invoice-detail.vue?vue&type=template&id=6379b0f9&scoped=true"
import script from "./invoice-detail.vue?vue&type=script&lang=js"
export * from "./invoice-detail.vue?vue&type=script&lang=js"
import style1 from "./invoice-detail.vue?vue&type=style&index=1&id=6379b0f9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6379b0f9",
  null
  
)

export default component.exports