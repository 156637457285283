import axios from '@axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/list', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(``, {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
         axiosIns.post('/', userData)
         .then(response=>resolve(response))
         .catch(error=>reject(error))
      })
    },
    deleteUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
         axiosIns.delete('/', {params:queryParams})
         .then(response=>resolve(response))
         .catch(error=>reject(error))
      })
    },

    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
          axiosIns.get('/role/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
          axiosIns.get('/role', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setUserRole(ctx, data) {
      return new Promise((resolve, reject) => {
          axiosIns.post('/role/setUserRole', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
