<template>
  <div id="warehouse">
    <div class="controls" v-if="warehouses">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form: Personal Info Form -->
        <b-overlay
          style="height: calc(100% - 20px)"
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
        <div v-if="floorplanOptions.length > 1">
          <label>選擇平面圖:</label>
          <v-select
            v-model="warehouse_image_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="floorplanOptions"
            label="name"
            :reduce="(option) => option.id"
            placeholder="全部"
            class="mb-50"
            :clearable="false"
            @input="(val) => changeWarehouseImage(val)"
          >
          </v-select>
        </div>
          <b-form
            style="height: 100%"
            class="mt-50 d-flex flex-column justify-content-between"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <div>
              <div>
                <div class="mb-2" v-if="isSelected">
                  <!-- <h5 class="w-100">
                    ID: {{ warehouses[selectedWarehouse].id }}
                  </h5> -->
                  <b-row>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="圖型闊度"
                        rules="required"
                      >
                        <label>圖型闊度:</label>
                        <b-form-input
                          type="number"
                          placeholder="圖型闊度"
                          :state="getValidationState(validationContext)"
                          v-model="warehouses[selectedWarehouse].rectWidth"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="圖型高度"
                        rules="required"
                      >
                        <label>圖型高度:</label>
                        <b-form-input
                          type="number"
                          placeholder="圖型高度"
                          :state="getValidationState(validationContext)"
                          v-model="warehouses[selectedWarehouse].rectHeight"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="X-axis"
                        rules="required"
                      >
                        <label>X-axis:</label>
                        <b-form-input
                          type="number"
                          placeholder="x"
                          :state="getValidationState(validationContext)"
                          v-model="warehouses[selectedWarehouse].x"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="Y-axis"
                        rules="required"
                      >
                        <label>Y-axis:</label>
                        <b-form-input
                          type="number"
                          :state="getValidationState(validationContext)"
                          placeholder="y"
                          v-model="warehouses[selectedWarehouse].y"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="d-flex button-list justify-content-start flex-wrap">
                  <button type="button" @click="handleAdd">增加</button>
                  <button type="button" v-if="isSelected" @click="handleCopy">
                    復制
                  </button>
                  <button type="button" v-if="isSelected" @click="handleDelete">
                    刪除
                  </button>
                  <button
                    type="button"
                    class=""
                    @click="handleTop"
                    v-if="isLastSelected"
                  >
                    上
                  </button>
                  <button
                    type="button"
                    @click="handleBottom"
                    v-if="isLastSelected"
                  >
                    下
                  </button>
                  <button
                    type="button"
                    @click="handleLeft"
                    v-if="isLastSelected"
                  >
                    左
                  </button>
                  <button
                    type="button"
                    @click="handleRight"
                    v-if="isLastSelected"
                  >
                    右
                  </button>

                  <button type="button" v-if="isSelected" @click="handleRotate">
                    旋轉
                  </button>
                  <button
                    type="button"
                    v-if="isSelected"
                    @click="handleResize(true)"
                  >
                    放大
                  </button>
                  <button
                    type="button"
                    v-if="isSelected"
                    @click="handleResize(false)"
                  >
                    縮小
                  </button>
                </div>
              </div>
              <hr v-if="isSelected" style="border-color: black" />
              <div v-if="isSelected">
                <h5 style="font-weight: bold; margin-bottom: 0.5rem">
                  迷你倉資訊：
                </h5>
                <label>迷你倉種類:</label>
                <v-select
                  v-model="warehouses[selectedWarehouse].mini_storage_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="warehouseData.mini_storage_types"
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="全部"
                  class="mb-50"
                  @input="
                    (mini_storage_type_id) =>
                      changeWarehouse(
                        warehouses[selectedWarehouse],
                        mini_storage_type_id
                      )
                  "
                >
                  <template
                    #option="{ name, monthly_price, width, depth, height }"
                  >
                    [{{ name }}] {{ monthly_price }} {{ width }} * {{ depth }} *
                    {{ height }}
                  </template>
                  <template
                    #selected-option="{
                      name,
                      monthly_price,
                      width,
                      depth,
                      height,
                    }"
                  >
                    [{{ name }}] {{ monthly_price }} {{ width }} * {{ depth }} *
                    {{ height }}
                  </template>
                </v-select>

                <validation-provider
                  #default="validationContext"
                  name="闊度"
                  rules="required"
                >
                  <label>闊度:</label>
                  <b-form-input
                    type="number"
                    step="0.01"
                    placeholder="闊度"
                    :state="getValidationState(validationContext)"
                    v-model="warehouses[selectedWarehouse].width"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="深度"
                  rules="required"
                >
                  <label>深度:</label>
                  <b-form-input
                    type="number"
                    step="0.01"
                    placeholder="深度"
                    :state="getValidationState(validationContext)"
                    v-model="warehouses[selectedWarehouse].depth"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="高度"
                  rules="required"
                >
                  <label>高度:</label>
                  <b-form-input
                    type="number"
                    step="0.01"
                    placeholder="高度"
                    :state="getValidationState(validationContext)"
                    v-model="warehouses[selectedWarehouse].height"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="寬度"
                  rules="required"
                >
                  <label>貨倉編號:</label>
                  <b-form-input
                    type="text"
                    placeholder="貨倉編號"
                    :state="getValidationState(validationContext)"
                    v-model="warehouses[selectedWarehouse].storage_number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <label>每月定價:</label>
                <b-form-input
                  type="number"
                  step="0.01"
                  placeholder="每月定價"
                  v-model="warehouses[selectedWarehouse].monthly_price"
                />
                <label>預付6個月:</label>
                <b-form-input
                  type="number"
                  step="0.01"
                  placeholder="預付6個月"
                  v-model="warehouses[selectedWarehouse].bi_annual_price"
                />
                <label>預付12個月:</label>
                <b-form-input
                  type="number"
                  step="0.01"
                  placeholder="預付12個月"
                  v-model="warehouses[selectedWarehouse].annual_price"
                />
                <div class="d-flex align-items-center">
                  <label for="on_sell">賣出中？</label>
                  <!-- checked="true" -->
                  <b-form-checkbox
                    id="on_sell"
                    v-model="warehouses[selectedWarehouse].on_sell"
                    class="custome-control-success ml-1"
                    name="check-button"
                    switch
                    inline
                  />
                </div>
              </div>
            </div>
            <div>
              <b-button variant="primary" type="submit" class="w-100 mt-2">
                提交
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </div>
    <div class="canvas-wrapper">
      <canvas
        :style="{ aspectRatio: backgroundWidth / backgroundHeight }"
        id="canvas"
        @mousedown="startDragging"
        @mousemove="drag"
        @mouseup="stopDragging"
        @mouseleave="stopDragging"
      ></canvas>
    </div>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BButton,
  BForm,
  BOverlay,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, onMounted, watch } from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      selectedWarehouse: null,
      lastSelectedWarehouse: null,
      backgroundWidth: 0,
      backgroundHeight: 0,
    };
  },
  props: {
    warehouseData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLastSelected() {
      return this.lastSelectedWarehouse === 0 || this.lastSelectedWarehouse;
    },
    isSelected() {
      return this.selectedWarehouse === 0 || this.selectedWarehouse;
    },
  },
  watch: {
    warehouses: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selectedWarehouse: {
      handler() {
        this.draw();
      },
      deep: true,
    },
  },
  mounted() {
    this.canvas = document.getElementById("canvas");
    this.context = this.canvas.getContext("2d");

    let image = new Image();

    image.src = this.warehouseData.images.find(
      (ele) => ele.id === this.warehouse_image_id
    ).image_path;
    let that = this;

    image.onload = function (e) {
      that.backgroundWidth = e.target.naturalWidth;
      that.backgroundHeight = e.target.naturalHeight;

      that.canvas.width = that.backgroundWidth;
      that.canvas.height = that.backgroundHeight;

      that.canvas.style.background = `url(${
        that.warehouseData.images.find((ele) => ele.image_type_id === 2)
          .image_path
      }) no-repeat`;
      that.canvas.style.backgroundSize = `100% 100%`;
      that.canvas.style.backgroundPostiion = `0px 0px`;

      that.isDragging = false; // 是否正在拖放
      that.draggedWarehouse = null; // 被拖放的迷你倉

      that.draw(); // 繪製迷你倉
    };
  },
  methods: {
    changeWarehouseImage() {
    // Set loading to true to show the overlay and loading spinner
    this.loading = true;
    this.selectedWarehouse = null;

    // Find the selected image by the warehouse_image_id
    const selectedImage = this.warehouseData.images.find(
      (image) => image.id === this.warehouse_image_id
    );

    // Update the image source if the selected image exists
    if (selectedImage) {
      let image = new Image();
      image.src = selectedImage.image_path;

      image.onload = () => {
        this.backgroundWidth = image.naturalWidth;
        this.backgroundHeight = image.naturalHeight;

        this.canvas.width = this.backgroundWidth;
        this.canvas.height = this.backgroundHeight;

        this.canvas.style.backgroundImage = `url(${image.src})`;
        this.canvas.style.backgroundSize = '100% 100%';
        this.canvas.style.backgroundPosition = '0px 0px';

        // After updating the background, fetch the new warehouses
        this.fetchWarehousesByImageId(this.warehouse_image_id);
      };
    }
  },

  fetchWarehousesByImageId(imageId) {
    // This method should make an API call to get warehouses by imageId
    // For this example, let's assume we filter the existing warehouses
    this.warehouses = this.warehouseData.mini_storages.filter(
      (warehouse) => warehouse.warehouse_image_id === imageId
    );

    // Convert on_sell and is_rotate to boolean values for all warehouses
    this.warehouses.forEach((warehouse) => {
      warehouse.on_sell = warehouse.on_sell == true? 1: 0;
      warehouse.is_rotate = warehouse.is_rotate === 1;
      // If rotated, swap width and height
      if (warehouse.is_rotate) {
        [warehouse.rectWidth, warehouse.rectHeight] = [warehouse.rectHeight, warehouse.rectWidth];
      }
    });

    // Redraw the canvas with the new warehouses
    this.draw();

    // Set loading to false to hide the overlay and loading spinner
    this.loading = false;
  },
    changeWarehouse(warehouse, mini_storage_type_id) {
      if (!this.warehouseData && !this.warehouseData.mini_storage_types) return;
      let mini_storage_type = this.warehouseData.mini_storage_types.find(
        (ele) => ele.id === mini_storage_type_id
      );
      warehouse.width = mini_storage_type.width;
      warehouse.depth = mini_storage_type.depth;
      warehouse.height = mini_storage_type.height;
      warehouse.monthly_price = mini_storage_type.monthly_price;
      warehouse.bi_annual_price = mini_storage_type.bi_annual_price;
      warehouse.annual_price = mini_storage_type.annual_price;
    },
    handleTop() {
      let selectedWarehouse = this.warehouses[this.selectedWarehouse];
      let lastSelectedWarehouse = this.warehouses[this.lastSelectedWarehouse];

      this.warehouses[this.selectedWarehouse].x = Number(
        lastSelectedWarehouse.x
      );
      this.warehouses[this.selectedWarehouse].y =
        Number(lastSelectedWarehouse.y) -
        Number(selectedWarehouse.rectHeight) +
        2;
    },
    handleBottom() {
      let selectedWarehouse = this.warehouses[this.selectedWarehouse];
      let lastSelectedWarehouse = this.warehouses[this.lastSelectedWarehouse];

      this.warehouses[this.selectedWarehouse].x = Number(
        lastSelectedWarehouse.x
      );
      this.warehouses[this.selectedWarehouse].y =
        Number(lastSelectedWarehouse.y) +
        Number(lastSelectedWarehouse.rectHeight) +
        2;
    },
    handleLeft() {
      let selectedWarehouse = this.warehouses[this.selectedWarehouse];
      let lastSelectedWarehouse = this.warehouses[this.lastSelectedWarehouse];

      this.warehouses[this.selectedWarehouse].x =
        Number(lastSelectedWarehouse.x) -
        Number(selectedWarehouse.rectWidth) -
        2;
      this.warehouses[this.selectedWarehouse].y = Number(
        lastSelectedWarehouse.y
      );
    },
    handleRight() {
      let selectedWarehouse = this.warehouses[this.selectedWarehouse];
      let lastSelectedWarehouse = this.warehouses[this.lastSelectedWarehouse];

      this.warehouses[this.selectedWarehouse].x =
        Number(lastSelectedWarehouse.x) +
        Number(lastSelectedWarehouse.rectWidth) +
        2;
      this.warehouses[this.selectedWarehouse].y = Number(
        lastSelectedWarehouse.y
      );
    },
    handleRotate() {
      let temWidth = this.warehouses[this.selectedWarehouse].rectWidth;
      let temHeight = this.warehouses[this.selectedWarehouse].rectHeight;

      this.warehouses[this.selectedWarehouse].rectWidth = temHeight;
      this.warehouses[this.selectedWarehouse].rectHeight = temWidth;
    },
    // handleWarehouseClick(warehouse) {
    //   console.log(warehouse);
    // },

    handleResize(isBigger) {
      let temWidth = this.warehouses[this.selectedWarehouse].rectWidth;
      let temHeight = this.warehouses[this.selectedWarehouse].rectHeight;
      let temX = this.warehouses[this.selectedWarehouse].x;
      let temY = this.warehouses[this.selectedWarehouse].y;
      let centerX = temWidth / 2 + temX;
      let centerY = temHeight / 2 + temY;

      if (isBigger) {
        this.warehouses[this.selectedWarehouse].rectWidth =
          temWidth + (temWidth * 10) / 100;
        this.warehouses[this.selectedWarehouse].rectHeight =
          temHeight + (temHeight * 10) / 100;
        this.warehouses[this.selectedWarehouse].x =
          temX - 0.5 * ((temWidth * 10) / 100);
        this.warehouses[this.selectedWarehouse].y =
          temY - 0.5 * ((temHeight * 10) / 100);
      } else {
        this.warehouses[this.selectedWarehouse].rectWidth =
          temWidth - (temWidth * 10) / 100;
        this.warehouses[this.selectedWarehouse].rectHeight =
          temHeight - (temHeight * 10) / 100;
        this.warehouses[this.selectedWarehouse].x =
          temX + 0.5 * ((temWidth * 10) / 100);
        this.warehouses[this.selectedWarehouse].y =
          temY + 0.5 * ((temHeight * 10) / 100);
      }
    },
    handleAdd() {
      var newRect = {
        id: 0,
        x: 0,
        y: 0,
        rectWidth: 50,
        rectHeight: 60,
        is_rotate: false,
        storage_number: 0,
        width: 0,
        depth: 0,
        height: 0,
        monthly_price: 0,
        bi_annual_price: 0,
        annual_price: 0,
        on_sell: true,
        warehouse_image_id: this.wareshoue_image_id,
      };
      this.warehouses = [...this.warehouses, newRect];

      if (this.selectedWarehouse) {
        this.lastSelectedWarehouse = this.selectedWarehouse;
      }
      this.selectedWarehouse = this.warehouses.length - 1;
    },
    handleCopy() {
      var newRect = { ...this.warehouses[this.selectedWarehouse] };
      newRect.id = 0;
      newRect.x = newRect.x + Math.floor(Math.random() * 30);
      newRect.y = newRect.y + Math.floor(Math.random() * 30);
      this.warehouses = [...this.warehouses, newRect];
      this.lastSelectedWarehouse = this.selectedWarehouse;

      this.selectedWarehouse = this.warehouses.length - 1;
    },
    handleDelete() {
      if (this.warehouses[this.selectedWarehouse].id > 0) {
        this.$swal({
          title: "你確定嗎?",
          text: "Your action is final and you will not be able to retrieve the storage.",
          showCancelButton: true,
          confirmButtonText: "Delete",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            store
              .dispatch("warehouse/deleteMiniStorage", {
                id: this.warehouses[this.selectedWarehouse].id,
              })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                if (this.lastSelectedWarehouse) {
                  this.selectedWarehouse = this.lastSelectedWarehouse;
                  this.lastSelectedWarehouse = null;
                } else {
                  this.selectedWarehouse = null;
                }
                this.$emit("refetch-data");
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      } else {
        this.warehouses.splice(this.selectedWarehouse, 1);
        if (this.lastSelectedWarehouse) {
        this.selectedWarehouse = this.lastSelectedWarehouse;
        this.lastSelectedWarehouse = null;
      } else {
        this.selectedWarehouse = null;
      }
      }

    
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?請輸入密碼確定提交",
        input: "password",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "修改",
        showLoaderOnConfirm: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.warehouses.forEach((item) => {
            item.on_sell = item.on_sell == true ? 1 : 0;
            item.is_rotate = item.is_rotate == true ? 1 : 0;
          });

          store
            .dispatch("warehouse/updateMiniStorage", {
              warehouse_id: this.warehouseData.id,
              warehouse_image_id: this.warehouse_image_id,
              storages: this.warehouses,
              password: result.value,
            })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });

      /*    this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.warehouses.forEach((item) => {
            item.on_sell = item.on_sell == true ? 1 : 0;
            item.is_rotate = item.is_rotate == true ? 1 : 0;
          });
            store
            .dispatch("warehouse/updateMiniStorage", {
              warehouse_id: this.warehouseData.id,
              storages: this.warehouses,
            })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      }); */
    },
    draw() {
      // 清空畫布
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      // 繪製迷你倉

      for (let i = this.warehouses.length - 1; i >= 0; i--) {
        let warehouse = this.warehouses[i];
        this.context.strokeStyle = "black";
        this.context.lineWidth = 2;

        this.context.strokeRect(
          warehouse.x,
          warehouse.y,
          warehouse.rectWidth,
          warehouse.rectHeight
        );

        if (warehouse.on_sell) {
          this.context.fillStyle = "green";
        } else {
          this.context.fillStyle = "red";
        }

        if (this.selectedWarehouse === i) {
          this.context.fillStyle = "yellow";
        } else if (this.lastSelectedWarehouse === i) {
          this.context.fillStyle = "lightGrey";
        }

        this.context.fillRect(
          warehouse.x,
          warehouse.y,
          warehouse.rectWidth,
          warehouse.rectHeight
        );

        if (warehouse.storage_number) {
          // 繪製文字

          if (this.selectedWarehouse === i) {
            this.context.fillStyle = "black";
          } else {
            this.context.fillStyle = "white";
          }

          this.context.font = "12px Arial";
          const text = warehouse.storage_number;
          const textWidth = this.context.measureText(text).width;
          const textX =
            Number(warehouse.x) + (Number(warehouse.rectWidth) - textWidth) / 2;
          const textY =
            Number(warehouse.y) + Number(warehouse.rectHeight) / 2 + 6; // 調整文字的垂直位置
          //console.log(text, textX, textY)
          this.context.fillText(text, textX, textY);
        }
      }
    },
    startDragging(event) {
      const rect = this.canvas.getBoundingClientRect();

      const wRadio = rect.width / this.canvas.width;
      const hRadio = rect.height / this.canvas.height;

      const x = (event.clientX - rect.left) / wRadio;
      const y = (event.clientY - rect.top) / hRadio;

      // 檢查被點擊的位置是否在迷你倉的範圍內
      for (let i = this.warehouses.length - 1; i >= 0; i--) {
        const warehouse = this.warehouses[i];

        if (
          x >= warehouse.x &&
          x <= warehouse.x + warehouse.rectWidth &&
          y >= warehouse.y &&
          y <= warehouse.y + warehouse.rectHeight
        ) {
          this.isDragging = true;
          this.draggedWarehouse = warehouse;
          this.lastSelectedWarehouse = this.selectedWarehouse;
          this.selectedWarehouse = i;
          break;
        }
      }
    },
    drag(event) {
      if (this.isDragging && this.draggedWarehouse) {
        const rect = this.canvas.getBoundingClientRect();

        const wRadio = rect.width / this.canvas.width;
        const hRadio = rect.height / this.canvas.height;

        const x = (event.clientX - rect.left) / wRadio;
        const y = (event.clientY - rect.top) / hRadio;

        // 更新被拖放迷你倉的位置
        this.draggedWarehouse.x = Math.round(
          x - this.draggedWarehouse.rectWidth / 2
        );
        this.draggedWarehouse.y = Math.round(
          y - this.draggedWarehouse.rectHeight / 2
        );

        // 重新繪製畫布
        this.draw();
      }
    },
    stopDragging() {
      this.isDragging = false;
      this.draggedWarehouse = null;
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const warehouses = ref([]);
    const warehouse_image_id = ref(null);
    const floorplanOptions = ref([]);

    const resetStorageData = () => {
      warehouses = JSON.parse(JSON.stringify({ id: 0 }));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetStorageData);

    watch(
      () => props.warehouseData,
      (newVal, oldVal) => {
        
        warehouse_image_id.value = newVal.images.find(
          (ele) => ele.image_type_id === 2
        ).id;
        floorplanOptions.value = newVal.images.filter(
          (ele) => ele.image_type_id === 2
        );
        warehouses.value = newVal.mini_storages.filter(ele => ele.warehouse_image_id == warehouse_image_id.value);
        warehouses.value.forEach((ele) => {
          ele.on_sell = ele.on_sell == 1 ? true : false;
          if (ele.is_rotate) {
            var w = ele.rectWidth;
            var h = ele.rectHeight;
            ele.rectWidth = h;
            ele.rectHeight = w;
          }
        });
      },
      { immediate: true }
    );

    const miniStorageTypeOptions = ref([]);
    const refetchOption = () => {
      store
        .dispatch("warehouse/fetchMiniStorageTypes", {
          warehouse_id: props.warehouseData.id,
        })
        .then((response) => {
          miniStorageTypeOptions.value = response.data.mini_storage_types;
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            miniStorageTypeOptions.value = undefined;
          } else {
            console.log("Fail to refetchData", error);
          }
        });
    };
    onMounted(() => {
      refetchOption();
    });

    return {
      warehouses,
      refFormObserver,
      getValidationState,
      resetForm,
      resetStorageData,
      loading,
      miniStorageTypeOptions,
      warehouse_image_id,
      floorplanOptions,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#warehouse {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  .canvas-wrapper {
    width: 80%;
    order: 1;
  }
  #canvas {
    width: calc(100% - 20px);
    margin-right: 10px;
    border: 1px solid #ccc;
  }
  .controls {
    order: 2;
    width: 20%;
    background: #fff;
    // position: absolute;
    // right:22px;
    // top:30px;
    border: 1px solid #ccc;
    padding: 10px;
    label {
      margin-bottom: 0px;
    }

    input {
      // width: 100%;
      height: 25px;
      margin: 0px 0 10px;
      padding-inline: 10px;
    }
    .button-list {
      button {
        width: 22%;
        padding-inline: 0.2rem;
        margin: 0rem 8px 0.6rem 0px;
        &:nth-child(4n + 4) {
          margin: 0rem 0px 0.6rem 0px;
        }
      }
    }
  }

  .v-select {
    .vs__selected {
      font-size: 0.9rem;
    }
    .vs__selected-options input {
      margin: 0px 0 0px;
    }
  }
  @media screen and (max-width: 991px) {
    #warehouse {
      width: 100%;
      display: block;
    }
    .canvas-wrapper {
      width: 100%;
    }
    .controls {
      position: absolute;
      top: 30px;
      right: 22px;
    }
  }
}
</style>