import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useCustomersSubmissionList() {
  // Use toast
  const toast = useToast();

  const refCustomerSubmissionListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "ID", sortable: true },
    { key: "file_name", label: "檔案名稱", sortable: true },
    { key: "users.name", label: "用戶名稱" , sortable: true},
    { key: "successful", label: "成功數" , sortable: true},
    { key: "failed", label: "失敗數" , sortable: true},
    { key: "total_records", label: "總數" , sortable: true},
    { key: "created_at", label: "生成日期" },
    { key: "actions" , label: "下載"},
  ];
  const perPage = ref(10);
  const totalCustomers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const customersSubmissionList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerSubmissionListTable.value
      ? refCustomerSubmissionListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    };
  });

  const refetchData = () => {
    refCustomerSubmissionListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const fetchCustomersSubmission = (ctx, callback) => {
    let customer_submissions = [{id: 1, file_name: "customer-upload.xlsx", users: {name: 'Nick'}, 'successful': 10, 'failed': 0, 'total_records': 10, 'total_handled': 10, created_at: '2024-02-01'}]
    let total = 1;
    callback(customer_submissions);
    customersSubmissionList.value = customer_submissions
    totalCustomers.value = total;
    /* store
      .dispatch("app-customer/submissionRecord", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(customer_submissions);
        customersSubmissionList.value = customer_submissions
        totalCustomers.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }); */
  };

  return {
    fetchCustomersSubmission,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerSubmissionListTable,
    ability,
    customersSubmissionList,
    refetchData,
  };
}
