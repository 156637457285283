var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-edit-audit","cancel-variant":"outline-secondary","centered":"","size":"sm","title":"","ok-disabled":false,"hide-header":"","hide-footer":""},on:{"hide":_vm.resetModal}},[_c('div',{staticClass:"blue-header sami-top-rounded bgColor1 text-center w-100"},[_c('h5',{staticClass:"colorWhite"},[_vm._v("核實付款")]),_c('a',{staticClass:"close-button",on:{"click":_vm.hide}},[_c('feather-icon',{staticClass:"colorWhite",attrs:{"icon":"XIcon","size":"18"}})],1)]),_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"payment-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{staticClass:"info-wrap"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"info-item"},[_c('b',{staticClass:"info-item-label"},[_vm._v("核實 "),_c('span',{staticClass:"colorRed"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"核實","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-checkbox',{staticStyle:{"margin-top":"5px"},attrs:{"checked":_vm.newAuditData.is_audit,"name":"complete","switch":"","inline":"","value":"1"},model:{value:(_vm.newAuditData.is_audit),callback:function ($$v) {_vm.$set(_vm.newAuditData, "is_audit", $$v)},expression:"newAuditData.is_audit"}},[_vm._v(" "+_vm._s(_vm.newAuditData.is_audit ? "是" : "否")+" ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)]),_vm._v(" "),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"info-item"},[_c('b',{staticClass:"info-item-label"},[_vm._v(" 收據日期 "),_c('span',{staticClass:"colorRed"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"收據日期","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  },"placeholder":"輸入YYYY-MM-DD"},model:{value:(_vm.newAuditData.receipt_date),callback:function ($$v) {_vm.$set(_vm.newAuditData, "receipt_date", $$v)},expression:"newAuditData.receipt_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"info-item"},[_c('b',{staticClass:"info-item-label"},[_vm._v("備註")]),_c('b-form-input',{model:{value:(_vm.newAuditData.audit_remark),callback:function ($$v) {_vm.$set(_vm.newAuditData, "audit_remark", $$v)},expression:"newAuditData.audit_remark"}})],1)]),_c('b-col',{staticClass:"d-flex flex-wrap justify-content-center w-100 p-2",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1 px-3",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" 提交 ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"px-3",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.hide}},[_vm._v(" 取消 ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }