<template>
  <b-modal
    id="modal-edit-payment"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">付款</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="payment-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">發票編號</b>
                <span>{{ newPaymentData.invoice_number }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >付款類型 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="付款類型"
                  rules="required"
                >
                  <v-select
                    v-model="newPaymentData.payment_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentTypeOptions"
                    label="payment_name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >金額 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="金額"
                  rules="required"
                >
                  <b-form-input
                    id="amount"
                    type="number"
                    v-model="newPaymentData.amount"
                    placeholder="金額"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >付款日期 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="付款日期"
                  rules="required"
                >
                <flat-pickr
                    v-model="newPaymentData.payment_date"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d H:i',
                    }"
                    placeholder="輸入YYYY-MM-DD HH:mm"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <!-- <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">完成</b>
                <b-form-checkbox
                  :checked="newPaymentData.complete"
                  name="complete"
                  switch
                  inline
                  v-model="newPaymentData.complete"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ newPaymentData.complete ? "是" : "否" }}
                </b-form-checkbox>
              </div>
            </b-col> -->

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">積分</b>
                <b-input-group>
                  <template #append>
                    <b-input-group-text><strong class="">現有積分：{{ newPaymentData.points }}</strong></b-input-group-text>
                  </template>
                  <b-form-input
                    type="number"
                    v-model="points"
                  ></b-form-input>
                </b-input-group>
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >備註</b
                >
          
                  <b-form-input
                    type="text"
                    v-model="newPaymentData.description"
                  ></b-form-input>
        
              </div>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <upload-file
                key="fileupload1"
                ref="childRef1"
                title="付款"
                custome_id="1"
                v-on:file-uploaded="onPayFileUploaded"
              ></upload-file>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
// import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";
import uploadFile from "@/layouts/components/file/uploadFile.vue";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupText,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupText,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    uploadFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    paymentData: {},
    is_rent:{},
    paymentTypeOptions:{},
    points:0
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-payment");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("id", this.newPaymentData.id);
          formData.append("invoice_id", this.newPaymentData.invoice_id);

          formData.append("payment_type_id", this.newPaymentData.payment_type_id);
          // formData.append("complete", this.newPaymentData.complete ? 1 : 0);
          formData.append("amount", this.newPaymentData.amount);
          formData.append("points", this.points ? this.points : 0);
          formData.append("payment_date", this.newPaymentData.payment_date);
          formData.append("description", this.newPaymentData.description);


          if (this.newPaymentData.payment_images && this.newPaymentData.payment_images.length > 0) {
            this.newPaymentData.payment_images.forEach((item) => {
              formData.append("payment_images[]", item);
            });
          }
          
          this.loading = true;

          store
            .dispatch("invoice/payment", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit('refetch-data');
              this.hide()
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    // addItem() {
    //   this.newPaymentData.images.push(
    //     JSON.parse(
    //       JSON.stringify({
    //         id: 0,
    //       })
    //     )
    //   );
    // },
    // removeItem(index) {
    //   // check number of record
    //   let record =
    //     this.newPaymentData &&
    //     this.newPaymentData.images &&
    //     this.newPaymentData.images.length;
    //   if (record <= 1) {
    //     this.makeToast("danger", "Warning", "請至少留有一個表單。");
    //   } else {
    //     this.newPaymentData.images.splice(index, 1);
    //   }
    // },
    onPayFileUploaded(file) {
      this.newPaymentData.payment_images = file;
    },
    deletePayDocument(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("invoice/deletePaymentDocument", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newPaymentData = ref({ id: 0 });

    const resetPaymentData = () => {
      newPaymentData.value = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPaymentData);

    watch(
      () => props.paymentData,
      (newVal) => {
        newPaymentData.value = {...newVal};
        newPaymentData.value.amount = Number(newPaymentData.value.total_amount) - Number(newPaymentData.value.total_paid_amount);
        console.log('newPaymentData',newPaymentData.value)
      }
    );
   


    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newPaymentData,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// .info-wrap .info-item b {
//   min-width: 140px;
// }
// .info-item-label {
//   & + span {
//     width: 100%;
//   }
// }
.v-select {
  width: 100%;
}
</style>
  