import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from '@/auth/utils'
import ability from '@/libs/acl/ability'

export default function useBannersList(){

    const toast = useToast()

    const refBannerListTable = ref(null)
    const refBannerCategoryListTable = ref(null)

    const tableColumns = [
        {key:'image_path', label:'相片'},
        //{key:'title'},
        //{key:'subtitle'},
        {key:'is_active', label:'狀態',  sortable:true},
        {key:'sort_order', label:'排序',  sortable:true},
        {key:'actions', label:'操作'}
    ]

    //Filter
    const bannerCategoryFilter = ref(1)
    
    const categoryTableColumns = [
        {key:'label', label:'name'},
        {key:'actions'}
    ]
    const perPage = ref(10)
    const totalBanners = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('sort_order')
    const isSortDirDesc = ref(false)
    const categoryOptions = ref([])
    

    const dataMeta = computed(() => {
        const localItemsCount = refBannerListTable.value ? refBannerListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBanners.value,
        }
    })

    const showMessage = (title,text,icon, variant) =>{
        toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon,
              variant
            },
        })
    }

    const refetchData = () =>{
        refBannerListTable.value.refresh()
    }

    const getBannerImage = (image) =>  process.env.VUE_APP_IMAGE_URL+image

    watch([currentPage, perPage, searchQuery, bannerCategoryFilter], () => {
        refetchData()
    })

    const fetchBanners = (ctx, callback) => {

        store.dispatch('bar-banner/fetchBanners', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            store_id: getUserData().store_id,
            banner_category_id: bannerCategoryFilter.value,
        })
        .then(response => {
            //console.log(response.data)
            const { banners, total} = response.data
            callback(banners)
            totalBanners.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching banners list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
            })
        })
    }



    return {
        fetchBanners,
        tableColumns,
        categoryTableColumns,
        perPage,
        currentPage,
        totalBanners,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refBannerListTable,
        refBannerCategoryListTable,
        getBannerImage,
        categoryOptions,
        showMessage,
        ability,
        
        //Extra Filters
        bannerCategoryFilter,
    }

}