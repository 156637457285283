import { render, staticRenderFns } from "./mini-storage-detail.vue?vue&type=template&id=a1359f50"
import script from "./mini-storage-detail.vue?vue&type=script&lang=js"
export * from "./mini-storage-detail.vue?vue&type=script&lang=js"
import style0 from "./mini-storage-detail.vue?vue&type=style&index=0&id=a1359f50&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports