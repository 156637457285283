<template>
  <b-sidebar
    id="add-new-discount-sidebar"
    :visible="isAddNewDiscountSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-discount-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">優惠券</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="優惠券號碼"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="優惠券號碼"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      :state="getValidationState(validationContext)"
                      v-model="discountData.code"
                      @update="checkCode"
                      placeholder="客戶輸入以獲得折扣的代碼"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="優惠券名稱"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="優惠券名稱"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      :state="getValidationState(validationContext)"
                      v-model="discountData.title"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group class="" label="描述" label-for="description">
                  <b-form-input
                    id="description"
                    v-model="discountData.description"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group
                  :class="`${!discountData.percentage ? 'required' : ''}`"
                  label="金額"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    type="number"
                    v-model="discountData.amount"
                    @input="onAmountInput"
                    placeholder="折扣金額"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group
                  :class="`${!discountData.amount ? 'required' : ''}`"
                  label="百分比"
                  label-for="percentage"
                >
                  <b-input-group append="%">
                    <b-form-input
                      id="percentage"
                      type="number"
                      v-model="discountData.percentage"
                      @input="onPercentageInput"
                      placeholder="折扣百分比"
                      v-limit-number:max="100"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group
                  label="總金額"
                  label-for="total_amount"
                  v-b-popover.hover.top="
                    '優惠券生效前必須達到的總金額。 留空表示無限制'
                  "
                >
                  <b-form-input
                    id="total_amount"
                    type="number"
                    v-model="discountData.total_amount"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group class="" label="客戶" label-for="uses_per_coupon">
                  <v-select
                    v-model="discountData.customers"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    :clearable="true"
                    label="name"
                    multiple
                    :reduce="(val) => val.id"
                    class="w-100"
                    v-b-popover.hover.top="
                      '限制可以使用優惠券的使用者。 留空表示無限制'
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="從"
                  rules="required"
                >
                  <b-form-group class="required" label="從" label-for="from">
                    <flat-pickr
                      v-model="discountData.date_from"
                      class="form-control"
                      :config="{
                        allowInput: true,
                        altInput: true,
                        altFormat: 'Y-m-d',
                        dateFormat: 'Y-m-d',
                      }"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="到"
                  rules="required"
                >
                  <b-form-group class="required" label="到" label-for="to">
                    <flat-pickr
                      v-model="discountData.date_to"
                      class="form-control"
                      :config="{
                        allowInput: true,
                        altInput: true,
                        altFormat: 'Y-m-d',
                        dateFormat: 'Y-m-d',
                      }"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group
                  class="required"
                  label="每張優惠券使用"
                  label-for="uses_per_coupon"
                >
                  <b-form-input
                    id="uses_per_coupon"
                    type="number"
                    v-model="discountData.uses_per_coupon"
                    v-b-popover.hover.top="
                      '任何客戶可以使用優惠券的最大次數。 留空表示無限制'
                    "
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group
                  class="required"
                  label="每個客戶的使用次數"
                  label-for="uses_per_customer"
                >
                  <b-form-input
                    id="uses_per_customer"
                    type="number"
                    v-model="discountData.uses_per_customer"
                    v-b-popover.hover.top="
                      '單一客戶可以使用優惠券的最大次數。 留空表示無限制'
                    "
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              label="狀態"
              label-for="is_active"
              label-cols-md="5"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="discountData.is_active" /> -->
              <b-form-checkbox
                :checked="discountData.is_active"
                name="is_active"
                switch
                inline
                v-model="discountData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ discountData.is_active ? "啟用" : "禁止" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  VBPopover,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import discountStoreModule from "../discountStoreModule";
import flatPickr from "vue-flatpickr-component";

export default {
  directives: {
    "b-popover": VBPopover,
    Ripple,
    "limit-number": {
      bind: function (el, binding) {
        const max = Number(binding.value) || Infinity;
        el.addEventListener("input", function (event) {
          const input = event.target;
          let value = Number(input.value);
          if (isNaN(value) || value < 0) {
            value = 0;
          } else if (value > max) {
            value = max;
          }
          if (input.value !== value.toString()) {
            input.value = value.toString();
            // Dispatch a change event instead of input
            input.dispatchEvent(new Event("change"));
          }
        });
      },
    },
  },
  components: {
    BSidebar,
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    VBPopover,
  },
  methods: {
    onAmountInput(value) {
      if (value && value > 0) {
        this.discountData.percentage = null;
      }
    },
    onPercentageInput(value) {
      if (value && value > 0) {
        this.discountData.amount = null;
      }
    },
    checkCode() {
      this.discountData.code = this.discountData.code
        .toUpperCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    onSubmit() {
      if (typeof this.discountData.customers === "object") {
        this.discountData.customers = this.discountData.customers.map(
          (item) => {
            return item.id || item;
          }
        );
      }

      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("discount/addDiscount", this.discountData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-add-new-discount-sidebar-active", false);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  model: {
    prop: "isAddNewDiscountSidebarActive",
    event: "update:is-add-new-discount-sidebar-active",
  },
  props: {
    customerOptions: {
      type: Array,
      required: true,
    },
    isAddNewDiscountSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },

  setup(props, { emit }) {
    const loading = ref(false);
    const APP_CLIENT_STORE_MODULE_NAME = "discount";

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, discountStoreModule);

    const blankData = ref({
      id: 0,
      is_active: 1,
    });

    const discountData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetdiscountData = () => {
      discountData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetdiscountData);

    watch(
      () => [props.id, props.isAddNewDiscountSidebarActive],
      () => {
        if (props.isAddNewDiscountSidebarActive) {
          discountData.value = JSON.parse(JSON.stringify(props.data));
        }
      }
    );

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      discountData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
 