<template>
  <component :is="contractData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="contractData === undefined">
      <h4 class="alert-heading">Error fetching contract data</h4>
      <div class="alert-body">
        No record found with this id. Check
        <b-link class="alert-link" :to="{ name: 'payments' }">
          contract List
        </b-link>
        for other record.
      </div>
    </b-alert>
    <contract-edit-tab-information
      v-if="contractData"
      :contract-data="contractData"
      :prePayTypeOptions="prePayTypeOptions"
      @refetch-data="refetchData"
      class=""
    />
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import contractStoreModule from "../contractStoreModule";
import contractEditTabInformation from "./contractEditTabInformation.vue";

export default {
  components: {
    contractEditTabInformation,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {
    const contractData = ref({ contracts: [{ id: "0" }] });


    const booking_APP_STORE_MODULE_NAME = "booking";

    if (!store.hasModule(booking_APP_STORE_MODULE_NAME))
      store.registerModule(
        booking_APP_STORE_MODULE_NAME,
        contractStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(booking_APP_STORE_MODULE_NAME))
        store.unregisterModule(booking_APP_STORE_MODULE_NAME);
    });

    const getToday = () =>{
      const time = new Date();
      return time.getFullYear() + '-' + (time.getMonth() + 1)  + '-' + time.getDate()
    }

    const refetchData = () => {
      

      if(router.currentRoute.name == 'contract-create') {

        store
          .dispatch("booking/fetchBooking", {
            id: router.currentRoute.params.booking_id,
          })
          .then((response) => {
            const booking = response.data.booking
            contractData.value = {
              id:0,
              booking_id:booking.id,
              customer_id:booking.customer_id,
              mini_storage_id:booking.mini_storage_id,
              address: booking.customer.address,
              phone: booking.customer.phone,
              id_number: booking.customer.id_number,
              rent_period: booking.rent_period,
              start_date: booking.start_date ? booking.start_date : getToday(),
              prepay_type_id: booking.prepay_type_id,

            }
          })
          .catch((error) => {
            console.log("error when fetching contract", error);
            if (error.response.status === 404) {
              contractData.value = undefined;
            }
          });
        
      }else{
        // store
        //   .dispatch("booking/fetchContract", {
        //     id: router.currentRoute.params.contract_id,
        //   })
        //   .then((response) => {
        //     contractData.value = { ...response.data.contract };
        //   })
        //   .catch((error) => {
        //     console.log("error when fetching contract", error);
        //     if (error.response.status === 404) {
        //       contractData.value = undefined;
        //     }
        //   });
      }
    };

    const prePayTypeOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptions", {
          pre_pay_type: true,
        })
        .then((response) => {
          prePayTypeOptions.value = response.data.pre_pay_types;
        })
        .catch((error) => {
          console.log(error)
        });
    };

    onMounted(() => {
        refetchData();
        refetchOption();
    });

    return {
      contractData,
      refetchData,
      prePayTypeOptions
    };
  },
};
</script>
