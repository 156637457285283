import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePaymentSchedulesList() {

    const toast = useToast()

    const refPaymentSchedulesListTable = ref(null)

    const tableColumns = [
        { key: 'payment_status', label:'狀態'},
        { key: 'mini_storage', label: '迷李倉庫'},
        /* { key: 'id', label:'收款編號',  sortable:true}, */
        // { key: 'payment_date', label: '生成日期' },
        { key: 'pay_date', label:'支付日期',  sortable:true},
        // { key: 'mini_storage', label:'單位',  sortable:true},
        { key: 'customer_name', label: '客戶' },
/*         { key: 'customer_id', label: '客戶編號', sortable: true }, */
        { key: 'show_details', label:'發票詳情'},
        { key: 'contract.contract_number', label:'合約編號'},
        { key: 'period', label: '期數', sortable: true },
        { key: 'payment_cycle', label:'付款周期',  sortable:true},
        { key: 'sale_id', label: '銷售顧問' },
        { key: 'standard_price', label:'標準租金',  sortable:true},
        { key: 'price', label:'租金',  sortable:true},
        { key: 'deposite', label:'按金',  sortable:true},
        { key: 'card_deposite', label:'智能卡按金',  sortable:true},
        { key: "total_discount", label: "總折扣",  sortable:true},
        { key: 'total_amount', label:'總值',  sortable:true},
        // { key: 'remark', label:'備註',  sortable:true},
        { key: 'action', label:'操作'}
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refPaymentSchedulesListTable.value ? refPaymentSchedulesListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refPaymentSchedulesListTable.value.refresh()
    }

    const payment_status_id = ref(1)

    const start_date = ref(null)
    const end_date = ref(new Date().toISOString().slice(0,10))
    const customer_id = ref(null)
    const mini_storage_id = ref(null)
    watch([currentPage, perPage, searchQuery, payment_status_id, start_date, end_date, customer_id, 
        mini_storage_id], () => {

        if(start_date.value && end_date.value && start_date.value > end_date.value){
            toast({
                component: ToastificationContent,
                props: {
                    title: '開始日期不可大於結束日期',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            return
        }
        refetchData()
    })


    const paymentSchedulesList = ref(null)
    
    const fetchPaymentSchedules = (ctx, callback) => {
     
        store.dispatch('payment/fetchPaymentSchedules', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            dateFrom: start_date.value,
            dateTo: end_date.value,
            customer_id: customer_id.value,
            mini_storage_id: mini_storage_id.value,
        })
            .then(response => {
                const { payment_schedules, total } = response.data
                callback(payment_schedules)
                paymentSchedulesList.value = payment_schedules
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching payments list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    const paymentStatusOptions = ref([])
    const contractOptions = ref([]);
    const customerOptions = ref([]);
    const saleOptions = ref([])
    const miniStorageOptions = ref([])

    const fetchOptions = () => {
        paymentStatusOptions.value = [
            { id: 1, name: '待支付', background_color: 'rgb(57 95 113)', font_color: '#ffffff' },
            { id: 2, name: '已支付', background_color: 'rgb(28 154 170)', font_color: '#ffffff' },
            { id: 3, name: '已核數', background_color: 'rgb(0 163 255)', font_color: '#ffffff' },
            { id: 4, name: '已刪除', background_color: 'rgb(255 192 74', font_color: '#ffffff' },
        ]
        store
            .dispatch("app/fetchOptions", {
                contract: true,
                customer: true,
                user: true,
                mini_storage: true
            })
            .then((response) => {
                contractOptions.value = response.data.contracts;
                customerOptions.value = response.data.customers;
                saleOptions.value = response.data.users;
                miniStorageOptions.value = response.data.mini_storages;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    onMounted(() => {
        fetchOptions();
    });


    return {
        fetchPaymentSchedules,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refPaymentSchedulesListTable,
        showMessage,
        paymentSchedulesList,
        payment_status_id,

        paymentStatusOptions,
        contractOptions,
        customerOptions,
        saleOptions,
        miniStorageOptions,

        start_date,
        end_date,
        customer_id,
        mini_storage_id,
        ability

    }

}