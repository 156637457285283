<template>
  <div>
    <modal-pdf :pdf-url="pdfUrl" />
    <booking-list-add-new
      :is-add-new-booking-sidebar-active.sync="isAddNewBookingSidebarActive"
      :id="id"
      :data="bookingData"
      @refetch-data="refetchData"
      @show-message="showMessage"
    />

    <modal-booking-stage
      :booking-data="stageData"
      :booking-stage-options="bookingStageOptions"
      @refetch-data="refetchData"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <b-row>
          <b-col cols="12" class="mb-1">
            <div class="d-flex align-items-center">
              <b>倉庫</b>
              <v-select
                class="ml-1"
                style="width: 250px"
                v-model="filter_warehouse_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="warehouseOptions"
                label="name"
                :reduce="(option) => option.id"
                :clearable="false"
                placeholder="選擇倉庫"
              />
            </div>
          </b-col>
          <b-col cols="12" class="mb-1">
            <warehouses-floorplan
              v-if="warehouse"
              :in_modal.sync="in_modal"
              :warehouse-data="warehouse"
              :filter_mini_storage_id.sync="filter_mini_storage_id"
            ></warehouses-floorplan>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-if="filter_warehouse_id"
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <!-- Search -->
            <div class="d-flex align-items-center ml-md-2 justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refBookingListTable"
        v-if="filter_warehouse_id"
        class="position-relative"
        :items="bookingList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: mini_storage info  -->
        <template #cell(mini_storage.warehouse)="data">
          <div class="text-nowrap">
            {{
              `${data.item.mini_storage.warehouse.name} - 倉${data.item.mini_storage.storage_number}號`
            }}
          </div>
        </template>

        <!-- Column: booking stage -->
        <template #cell(booking_stage_id)="data">
          <b-button
            @click="handleEditStage(data.item.id, data.item.booking_stage_id)"
            :style="getBookingStageStyle(data.item.booking_stage_id)"
          >
            {{ getBookingStageName(data.item.booking_stage_id) }}
          </b-button>
        </template>

        <template #cell(show_details)="row">
          <b-button
            v-if="row.item.contracts && row.item.contracts.length > 0"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? "隱藏" : "顯示" }}
          </b-button>
          <span v-else> - - </span>
        </template>

        <template #row-details="row">
          <b-table
            class="position-relative"
            :items="row.item.contracts"
            responsive
            :fields="tableContractsColumns"
            primary-key="id"
          >
            <template #cell(smart_cards)="data">
              {{ data.item.smart_cards.toString() }}
            </template>
            <template #cell(export)="data">
              <a
                href="javascript:void(0)"
                @click="downloadContract(data.item.id)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">下載</span>
              </a>
            </template>
            <template #cell(whatsapp_link)="data">
              <div class="d-flex justify-content-center">
                <a :href="data.item.whatsapp_link" target="_blank">
                  <img
                    src="@/assets/images/icons/whatsapp-logo.png"
                    style="width: 24px"
                    class="whatsapp-icon"
                  />
                </a>
              </div>
            </template>
          </b-table>
        </template>

        <template #cell(contract)="data">
          <b-link
            class="color1"
            :to="{
              name: 'contract-create',
              params: { booking_id: data.item.id },
            }"
          >
            <b><feather-icon class="mr-25" icon="BookIcon" /> 新增合約</b>
          </b-link>
        </template>

        <template #cell(cancel_rent)="data">
          <b-link>
            <b><feather-icon class="mr-25" icon="LogOutIcon" /> 退租</b>
          </b-link>
        </template>
      </b-table>
      <div v-if="filter_warehouse_id" class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import bookingStoreModule from "../bookingStoreModule";
import useBookingsList from "./useBookingsList";
import BookingListAddNew from "./BookingListAddNew.vue";
import ModalBookingStage from "../modal/ModalBookingStage.vue";
import ModalPdf from "../modal/ModalPdf.vue";
import WarehousesFloorplan from "./WarehousesFloorplan.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    ModalBookingStage,
    vSelect,
    BookingListAddNew,
    ModalPdf,
    WarehousesFloorplan,
  },
  methods: {
    resetBookingData() {
      this.bookingData = {
        id: 0,
      };
    },
    handleAdd() {
      this.id = 0;
      this.resetBookingData();
      this.isAddNewBookingSidebarActive = true;
    },
    downloadContract(id) {
      this.loading = true;
      this.$store
        .dispatch("booking/downloadContract", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    // confirmDelete(id) {
    //   this.$swal({
    //     title: "你確定嗎?",
    //     text: "您的操作是最終的，您無法恢復資料。",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes, delete it!",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //     if (result.value) {
    //       store
    //         .dispatch("booking/deleteBooking", { id: id })
    //         .then((response) => {
    //           this.refetchData();
    //           this.showMessage(
    //             "Success",
    //             response.data.message,
    //             "CheckIcon",
    //             "success"
    //           );
    //         })
    //         .catch((error) => {
    //           console.log(error);

    //           this.$swal({
    //             text: JSON.stringify(error.response.data.message),
    //             icon: "error",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //             showClass: {
    //               popup: "animate__animated animate__bounceIn",
    //             },
    //             buttonsStyling: false,
    //           });
    //         });
    //     }
    //   });
    // },
    handleEditStage(id, booking_stage_id) {
      this.stageData = { id, booking_stage_id };

      this.$bvModal.show("modal-booking-stage");
    },
    // editBooking(item) {
    //   this.id = item.id;
    //   this.bookingData = { ...item };
    //   this.isAddNewBookingSidebarActive = true;
    // },
  },
  data() {
    return {
      id: 0,
      pdfUrl: "",
      bookingData: {},
      stageData: {},
      in_modal: false,
    };
  },
  setup() {
    const BOOKING_STORE_MODULE_NAME = "booking";
    const isAddNewBookingSidebarActive = ref(false);

    if (!store.hasModule(BOOKING_STORE_MODULE_NAME))
      store.registerModule(BOOKING_STORE_MODULE_NAME, bookingStoreModule);

    onUnmounted(() => {
      if (store.hasModule(BOOKING_STORE_MODULE_NAME))
        store.unregisterModule(BOOKING_STORE_MODULE_NAME);
    });

    const {
      fetchBookings,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refBookingListTable,
      showMessage,
      bookingList,
      filter_warehouse_id,
      filter_mini_storage_id,
      warehouse,
      bookingStageOptions,
      warehouseOptions,
      tableContractsColumns,
    } = useBookingsList();

    return {
      //SideBar
      isAddNewBookingSidebarActive,
      fetchBookings,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refBookingListTable,
      showMessage,
      bookingList,
      filter_warehouse_id,
      filter_mini_storage_id,
      warehouse,
      bookingStageOptions,
      warehouseOptions,
      tableContractsColumns,
    };
  },
  computed: {
    getBookingStageStyle() {
      return (booking_stage_id) => {
        const stage = this.bookingStageOptions.find(ele => ele.id === booking_stage_id);
        if (stage) {
          return {
            backgroundColor: stage.background_color + '!important',
            color: stage.font_color + '!important'
          };
        }
        return {};
      };
    },
    
    getBookingStageName() {
      return (booking_stage_id) => {
        const stage = this.bookingStageOptions.find(ele => ele.id === booking_stage_id);
        return stage ? stage.stage : '';
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

