<template>
  <b-modal
    id="modal-edit-contract"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">{{ isEdit ? "修改" : "新增" }}合約</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserve">
        <b-form class="contract-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >合約日期 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="合約日期"
                  rules="required"
                >
                  <flat-pickr
                    v-model="newContractData.contract_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">合約編號</b>
                {{ newContractData.id }}
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >客戶編號 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="客戶編號"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.customer_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    label="id"
                    :filter="customFilter"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                    :disabled="newContractData.id > 0"
                  >
                    <template #option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                    <template #selected-option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >客戶名稱 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="客戶名稱"
                  rules="required"
                >
                  <b-form-input
                    id="rent_period"
                    v-model="newContractData.name"
                    placeholder="客戶名稱"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">簽約時間 </b>
                <flat-pickr
                  v-model="newContractData.sign_date"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d H:i',
                    dateFormat: 'Y-m-d H:i',
                  }"
                />
              </div>
            </b-col>
            <!-- <b-col cols="12" xl="4" v-if="newContractData.customer_id && referralCouponsOptions.length">
              <div class="info-item">
                <b class="info-item-label"
                  >優惠</b
                >
                  <v-select
                    v-model="newContractData.referral_coupon_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="referralCouponsOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="true"
                    class="w-100"
                    :disabled="newContractData.id > 0"
                  >
                  </v-select>
              </div>
            </b-col> -->
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >簽約狀態 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="簽約狀態"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.sign_status_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="signStatusOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >合約模版 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="合約模版"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.contract_template_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="contractTemplateOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >合約單位 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="合約單位"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.mini_storage_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      newContractData.id > 0
                        ? miniStorageOptions
                        : is_continuous
                        ? miniStorageOptions
                        : miniStorageOptions.filter((ele) => ele.on_sell == 1)
                    "
                    label="name"
                    :reduce="(option) => option.id"
                    class="w-100"
                    :disabled="newContractData.id > 0"
                  >
                    <template #option="data">
                      {{ data.name }} - {{ data.storage_number }}
                    </template>
                    <template #selected-option="data">
                      {{ data.name }} - {{ data.storage_number }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="12" xl="4" :key="'Location' + triggerLocation">
              <div class="info-item">
                <b class="info-item-label">合約位置</b>
                <b-form-input
                  id="customer_location"
                  v-model="newContractData.location"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >預付款 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="預付款"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.prepay_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="prePayTypeOptions"
                    label="type_name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                    :disabled="newContractData.id > 0"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >租用期間 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="租用期間"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.rent_period"
                    :clearable="false"
                    label="name"
                    :options="rentPeriodOptions"
                    :reduce="(option) => option.id"
                    :disabled="newContractData.id > 0"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >開始日期 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="開始日期"
                  rules="required"
                >
                  <flat-pickr
                    v-model="newContractData.start_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                    :disabled="newContractData.id > 0"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >結束日期 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="結束日期"
                  rules="required"
                >
                  <flat-pickr
                    v-model="contractPediod.end_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                    disabled
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >總值 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="總值"
                  rules="required"
                >
                  <b-form-input
                    id="period_amount"
                    v-model="contractPediod.amount"
                    :disabled="newContractData.id > 0 || true"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >按金 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="按金"
                  rules="required"
                >
                  <b-form-input
                    v-if="is_continuous"
                    id="new_deposite"
                    v-model="new_deposite"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-input
                    v-else
                    id="period_deposite"
                    v-model="contractPediod.deposite"
                    :state="getValidationState(validationContext)"
                    :disabled="newContractData.id > 0"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >租金 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="租金"
                  rules="required"
                >
                  <b-form-input
                    id="period_price"
                    v-model="contractPediod.price"
                    :state="getValidationState(validationContext)"
                    :disabled="newContractData.id > 0"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >標準租金 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="租金"
                  rules="required"
                >
                  <b-form-input
                    id="period_standard_price"
                    v-model="contractPediod.standard_price"
                    :disabled="newContractData.id > 0 || true"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">智能卡</b>

                <el-select
                  v-model="newContractData.smart_cards"
                  class="w-100"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  no-data-text="無數據"
                  placeholder='輸入內容 並 按下"Enter"以創建 智能卡'
                >
                </el-select>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">智能卡按金</b>
                <b-form-input
                  id="period_card_deposite"
                  v-model="newContractData.card_deposite"
                  :disabled="newContractData.id > 0"
                />
              </div>
            </b-col>

            <b-col cols="12" xl="4" v-if="is_continuous">
              <div class="info-item">
                <b class="info-item-label">舊按金</b>

                <b-form-input
                  id="period_deposite"
                  v-model="newContractData.original_deposite"
                  disabled
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4" v-if="is_continuous">
              <div class="info-item">
                <b class="info-item-label">舊智能卡按金</b>

                <b-form-input
                  id="period_card_deposite"
                  v-model="newContractData.original_card_deposite"
                  disabled
                />
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >銷售顧問 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="銷售顧問"
                  rules="required"
                >
                  <v-select
                    v-model="newContractData.sale_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="saleOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">身份證號碼 / 商業登記號碼</b>
                <b-form-input
                  id="customer_id_number"
                  v-model="newContractData.id_number"
                  placeholder="輸入身份證號碼 / 商業登記號碼"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">客戶電話</b>
                <b-form-input
                  id="customer_phone"
                  v-model="newContractData.phone"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">客戶地址</b>
                <b-form-input
                  id="customer_address"
                  v-model="newContractData.address"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">Fax</b>
                <b-form-input id="customer_fax" v-model="newContractData.fax" />
              </div>
            </b-col>

            <!--            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">備註</b>
                <b-form-input
                  class="info-item-value"
                  v-model="newContractData.remark"
                ></b-form-input>
              </div>
            </b-col> -->
            <!-- For trigger the div cannot remove-->
            <div style="display: none">{{ counts }}</div>
            <!-- End of trigger-->
            <template v-if="true">
              <b-col
                cols="12"
                xl="4"
                v-for="(remark, index) of newContractData.remarks"
                :key="`contract_${index}`"
              >
                <div class="info-item" :key="`count_${counts}`">
                  <b class="info-item-label">備註{{ index + 1 }}:</b>
                  <div class="d-flex info-item-value align-items-center">
                    <b-form-input
                      v-model="newContractData.remarks[index]"
                      @input="updateRemarkValue($event, index)"
                    ></b-form-input>
                    <a class="color4 ml-1" @click="removeRemark(index)">
                      <font-awesome-icon
                        class="text-16"
                        :icon="['fas', 'circle-minus']"
                      />
                    </a>
                  </div>
                </div>
              </b-col>
              <b-col class="mt-50">
                <b-button @click="addRemark" variant="primary"
                  ><font-awesome-icon :icon="['fas', 'circle-plus']" />
                  備註</b-button
                >
              </b-col>
            </template>

            <b-col
              cols="12"
             
            >
              <hr />
              <div class="text-right w-100 my-50">
                <b-button @click="addNewDiscount" variant="primary"
                  ><font-awesome-icon :icon="['fas', 'circle-plus']" />
                  新增額外優惠</b-button
                >
              </div>
            </b-col>
            <b-col
              cols="12"
              v-if="newContractData.customer_id && discountArray.length > 0"
            >
              <div class="small-rounded border p-2">
                <b-row
                  class="d-flex align-items-center mb-2 mb-xl-0"
                  v-for="(item, index) of discountArray"
                  :key="'discount' + index"
                >
                  <b-form-checkbox
                    class="mx-1 mb-50 mb-xl-0"
                    :value="item"
                    :true-value="item"
                    :false-value="null"
                    v-model="discountSelected"
                    @change="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                  ></b-form-checkbox>
                  <b-col xl="3">
                    <validation-provider
                      #default="validationContext"
                      :vid="'old title' + index"
                      name="優惠標題"
                      :rules="
                        selectedIndex(discountSelected, item.idx) > -1
                          ? 'required'
                          : ''
                      "
                    >
                      <b-form-input
                        v-model="item.remark"
                        placeholder="優惠標題"
                        :disabled="Boolean(item.inviter_record_id)"
                        @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col xl="3">
                    <div class="info-item">
                      <b class="info-item-label"
                        >優惠券類型
                        <span
                          v-if="selectedIndex(discountSelected, item.idx) > -1"
                          class="colorRed"
                          >*</span
                        ></b
                      >
                      <validation-provider
                        #default="validationContext"
                        :vid="'old referral_coupon_type_id' + index"
                        name="優惠券類型"
                        :rules="
                          selectedIndex(discountSelected, item.idx) > -1
                            ? 'required'
                            : ''
                        "
                      >
                        <v-select
                          v-model="item.referral_coupon_type_id"
                          :clearable="false"
                          label="name"
                          :options="referralCouponTypeOptions"
                          :reduce="(option) => option.id"
                          :disabled="newContractData.id > 0"
                          @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                          @input="
                            (val) =>
                              val == 1
                                ? (item.discount_percentage = 0)
                                : (item.free_month = 0)
                          "
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col xl="3" v-if="item.referral_coupon_type_id == 1">
                    <div class="info-item">
                      <b class="info-item-label"
                        >免租月數
                        <span
                          v-if="selectedIndex(discountSelected, item.idx) > -1"
                          class="colorRed"
                          >*</span
                        ></b
                      >
                      <validation-provider
                        #default="validationContext"
                        :vid="'old free_month' + index"
                        name="免租月數"
                        :rules="
                          selectedIndex(discountSelected, item.idx) > -1
                            ? 'required'
                            : ''
                        "
                      >
                        <b-input-group size="md" append="個月">
                          <b-form-input
                            v-model="item.free_month"
                            class="d-inline-block"
                            type="number"
                            placeholder="免租月數"
                            @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                            :state="getValidationState(validationContext)"
                          />
                        </b-input-group>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>

                  <b-col xl="3" v-if="item.referral_coupon_type_id == 2">
                    <div class="info-item">
                      <b class="info-item-label"
                        >折扣百分比
                        <span
                          v-if="selectedIndex(discountSelected, item.idx) > -1"
                          class="colorRed"
                          >*</span
                        ></b
                      >
                      <validation-provider
                        #default="validationContext"
                        :vid="'old discount_percentage' + index"
                        name="折扣百分比"
                        :rules="
                          selectedIndex(discountSelected, item.idx) > -1
                            ? 'required'
                            : ''
                        "
                        class="w-100"
                      >
                        <b-input-group class="input2" size="md" append="%">
                          <b-form-input
                            v-model="item.discount_percentage"
                            class="d-inline-block"
                            type="number"
                            placeholder="10 = 九折"
                            @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                            :state="getValidationState(validationContext)"
                          />
                        </b-input-group>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              cols="12"
              v-if="newDiscountArray.length > 0"
            >
              <hr />
            </b-col>
            <b-col
              cols="12"
              v-if="newDiscountArray.length > 0"
            >
              <div class="small-rounded border p-2">
                <b-row
                  class="d-flex align-items-center mb-2 mb-xl-0"
                  v-for="(item, index) of newDiscountArray"
                  :key="'discount' + index"
                >
                  <b-form-checkbox
                    class="mx-1 mb-50 mb-xl-0"
                    :value="item"
                    :true-value="item"
                    :false-value="null"
                    v-model="discountSelected2"
                    @change="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                  ></b-form-checkbox>
                  <b-col xl="3">
                    <validation-provider
                      #default="validationContext"
                      :vid="'title' + index"
                      name="優惠標題"
                      :rules="
                        selectedIndex(discountSelected2, item.idx) > -1
                          ? 'required'
                          : ''
                      "
                    >
                      <b-form-input
                        v-model="item.remark"
                        placeholder="優惠標題"
                        :disabled="Boolean(item.inviter_record_id)"
                        @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col xl="3">
                    <div class="info-item">
                      <b class="info-item-label"
                        >優惠券類型
                        <span
                          v-if="selectedIndex(discountSelected2, item.idx) > -1"
                          class="colorRed"
                          >*</span
                        ></b
                      >
                      <validation-provider
                        #default="validationContext"
                        :vid="'referral_coupon_type_id' + index"
                        name="優惠券類型"
                        :rules="
                          selectedIndex(discountSelected2, item.idx) > -1
                            ? 'required'
                            : ''
                        "
                      >
                        <v-select
                          v-model="item.referral_coupon_type_id"
                          :clearable="false"
                          label="name"
                          :options="referralCouponTypeOptions"
                          :reduce="(option) => option.id"
                          @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                          @input="
                            (val) =>
                              val == 1
                                ? (item.discount_percentage = 0)
                                : (item.free_month = 0)
                          "
                          :disabled="newContractData.id > 0"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col xl="3" v-if="item.referral_coupon_type_id == 1">
                    <div class="info-item">
                      <b class="info-item-label"
                        >免租月數
                        <span
                          v-if="selectedIndex(discountSelected2, item.idx) > -1"
                          class="colorRed"
                          >*</span
                        ></b
                      >
                      <validation-provider
                        #default="validationContext"
                        name="免租月數"
                        :vid="'free_month' + index"
                        :rules="
                          selectedIndex(discountSelected2, item.idx) > -1
                            ? 'required'
                            : ''
                        "
                      >
                        <b-input-group size="md" append="個月">
                          <b-form-input
                            v-model="item.free_month"
                            class="d-inline-block"
                            type="number"
                            placeholder="免租月數"
                            @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                            :state="getValidationState(validationContext)"
                          />
                        </b-input-group>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>

                  <b-col xl="3" v-if="item.referral_coupon_type_id == 2">
                    <div class="info-item">
                      <b class="info-item-label"
                        >折扣百分比
                        <span
                          v-if="selectedIndex(discountSelected2, item.idx) > -1"
                          class="colorRed"
                          >*</span
                        ></b
                      >
                      <validation-provider
                        #default="validationContext"
                        
                        name="折扣百分比"
                        :rules="
                          selectedIndex(discountSelected2, item.idx) > -1
                            ? 'required'
                            : ''
                        "
                        :vid="'discount_percentage' + index"
                        class="w-100"
                      >
                        <b-input-group class="input2" size="md" append="%">
                          <b-form-input
                            v-model="item.discount_percentage"
                            class="d-inline-block"
                            type="number"
                            placeholder="10 = 九折"
                            @blur="isDiscountSelectedDataChange = !isDiscountSelectedDataChange"
                            :state="getValidationState(validationContext)"
                          />
                        </b-input-group>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <a class="color4 ml-1" @click="removeNewDiscount(index)">
                    <font-awesome-icon
                      class="text-16"
                      :icon="['fas', 'circle-minus']"
                    />
                  </a>
                </b-row>
               
              </div>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
          <hr />
          <h5 class="mt-2">租用週期</h5>
          <b-table
            ref="refRentScheduleListTable"
            class="position-relative"
            :items="rent_schedule_list"
            responsive
            :fields="tableColumnsRentSchedule"
            primary-key="id"
            :sort-by.sync="sortByRentSchedule"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescRentSchedule"
          >
            <template #cell(prepay_type_id)="data">
              <div>
                <span v-if="data.item.prepay_type_id == 1"> 月付 </span>
                <span v-if="data.item.prepay_type_id == 2"> 預付6個月 </span>
                <span v-if="data.item.prepay_type_id == 3"> 預付12個月 </span>
              </div>
            </template>

           
            <template #cell(discount_price)="data">
              {{ data.item.discount_price ? Math.round(data.item.discount_price * 100) / 100 : '' }}
            </template>
            <template #cell(is_free_month)="data">
              <feather-icon
                v-if="data.item.is_free_month"
                icon="CheckIcon"
                size="18"
                class="mr-50 text-success"
              />
            </template>
            <template #cell(amount)="data">
              <div>
                {{ data.item.amount ? Math.round(data.item.amount * 100) / 100 : ''}}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaRentSchedule.from }} to
                  {{ dataMetaRentSchedule.to }} of
                  {{ dataMetaRentSchedule.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageRentSchedule"
                  :total-rows="totalRowRentSchedule"
                  :per-page="perPageRentSchedule"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>

          <hr />
          <h5 class="mt-2">付款日期</h5>
          <b-table
            ref="refPaymentScheduleListTable"
            class="position-relative"
            :items="payment_schedules_list"
            responsive
            :fields="tableColumnsPaymentSchedule"
            primary-key="id"
            :sort-by.sync="sortByPaymentSchedule"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescPaymentSchedule"
          >
            <template #cell(prepay_type_id)="data">
              <div>
                <span v-if="data.item.prepay_type_id == 1"> 月付 </span>
                <span v-if="data.item.prepay_type_id == 2"> 預付6個月 </span>
                <span v-if="data.item.prepay_type_id == 3"> 預付12個月 </span>
              </div>
            </template>

           
            <template #cell(standard_price)="data">
              {{ data.item.standard_price ? Math.round(data.item.standard_price * 100) / 100 : '' }}
            </template>
            <template #cell(price)="data">
              {{ data.item.price ? Math.round(data.item.price * 100) / 100 : '' }}
            </template>
            <template #cell(amount)="data">
              <div>
                {{ data.item.amount ? Math.round(data.item.amount * 100) / 100 : ''}}
              </div>
            </template>

            <template #cell(discount_percentage)="data">
              <div>
                {{
                  data.item.discount_percentage
                    ? Math.round(data.item.discount_percentage * 100) / 100  + "%"
                    : ""
                }}
              </div>
            </template>
            <template #cell(discount_price)="data">
              <div>
                {{ data.item.discount_price ? Math.round(data.item.discount_price * 100) / 100 : ''}}
              </div>
            </template>
            <template #cell(total_discount)="data">
              <div>
                {{ data.item.total_discount ? Math.round(data.item.total_discount * 100) / 100 : ''}}
              </div>
            </template>
            <template #cell(total_amount)="data">
              <div>
                {{ data.item.total_amount ? Math.round(data.item.total_amount * 100) / 100 : ''}}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaPaymentSchedule.from }} to
                  {{ dataMetaPaymentSchedule.to }} of
                  {{ dataMetaPaymentSchedule.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePaymentSchedule"
                  :total-rows="totalRowPaymentSchedule"
                  :per-page="perPagePaymentSchedule"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import {
  ref,
  watch,
  onUnmounted,
  onMounted,
  computed,
} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";
import contractStoreModule from "../../contractStoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTable,
  BPagination,
  BOverlay,
  BFormTextarea,
  BInputGroup,
    VBTooltip,
} from "bootstrap-vue";
import { resolveNavDataRouteName } from "@/@core/layouts/utils";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BTable,
    BPagination,
    BOverlay,
    BFormTextarea,
    BInputGroup,
    VBTooltip,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      counts: 0,
    };
  },
  props: {
    contractData: {},
    isEdit: {},
    is_continuous: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-contract");
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");

      this.contractPediod = {};
    },
    getRemarkValue(index) {
      return this.newContractData.remarks[index] || "";
    },
    updateRemarkValue(value, index) {
      this.newContractData.remarks[index] = value;
    },
    addRemark() {
      if (
        !this.newContractData.remarks ||
        !this.newContractData.remarks.length
      ) {
        this.newContractData.remarks = [];
      }
      this.newContractData.remarks.push("");
      this.counts++;
    },
    removeRemark(index) {
      this.newContractData.remarks.splice(index, 1);
      this.counts++;
    },
    customFilter(options, search) {
      if (!search) return options;
      
      search = search.toString().toLowerCase();
      
      return options.filter(option => {
        const id = option.id.toString().toLowerCase();
        const idNumber = (option.id_number || '').toString().toLowerCase();
        const name = (option.name || '').toString().toLowerCase();
        const nameEn = (option.name_en || '').toString().toLowerCase();
        const mobile = (option.mobile || '').toString().toLowerCase();

        return id.includes(search) ||
               idNumber.includes(search) ||
               name.includes(search) ||
               nameEn.includes(search) ||
               mobile.includes(search);
      });
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          if (this.newContractData.whatsapp_link) {
            delete this.newContractData.whatsapp_link;
          }

          let url = "addContract";
          if (this.is_continuous) {
            this.newContractData.deposite = this.new_deposite;
            this.newContractData.original_deposite = this.old_deposite;
            this.newContractData.card_deposite = Number(this.newContractData.card_deposite) + Number(this.newContractData.original_card_deposite)
          }
          this.newContractData.card_deposite
            ? this.newContractData.card_deposite
            : 0;

          if (this.newContractData.id > 0) {
            url = "updateContract";
          } else if (this.newContractData.id == 0) {
            this.newContractData = {
              ...this.newContractData,
              ...this.contractPediod,
            };
          }

          this.newContractData.contract_discounts = [];
          this.newContractData.contract_discounts = [
            ...this.discountSelected,
            ...this.discountSelected2,
          ];

          store
            .dispatch(`contract/${url}`, this.newContractData)
            .then((response) => {
              this.$emit("refetch-data");
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
    addNewDiscount() {
      if (!this.newDiscountArray || !this.newDiscountArray.length) {
        this.newDiscountArray = [];
      }
      this.counts2 += 1;
      const item = {
        referral_coupon_type_id: null,
        free_month: null,
        discount_percentage: null,
        remark: null,
        idx: this.counts2,
      }
      this.newDiscountArray.push(item);
      this.discountSelected2.push(item) 
    },
    removeNewDiscount(index) {
      if (this.discountSelected2.length > 0) {
        var targetItemIdx = this.newDiscountArray[index].idx;
        var selectedIndex = this.selectedIndex(
          this.discountSelected2,
          targetItemIdx
        );
        if (selectedIndex > -1) {
          this.discountSelected2.splice(selectedIndex, 1);
        }
      }
      this.newDiscountArray.splice(index, 1);
    },
    selectedIndex(arr, targetItemIdx) {
      return arr.findIndex((ele) => ele.idx === targetItemIdx);
    },
  },
  computed: {},
  watch: {},
  setup(props) {
    const loading = ref(false);
    const triggerLocation = ref(false);

    const CONTRACT_STORE_MODULE_NAME = "contract";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const old_deposite = ref(null);
    const new_deposite = ref(null);
    const old_card_deposite = ref(null);
    const new_card_deposite = ref(null);

    const newContractData = ref({
      id: 0,
      smart_cards: [],
      card_deposite: 0,
      remarks: [],
    });

    const resetData = () => {
      emit("refetch-data");
    };

    const resetContractData = () => {
      newContractData.value = {
        id: 0,
        smart_cards: [],
        card_deposite: 0,
        remarks: [],
      };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetContractData);

    const contractPediod = ref({});

    const getContractPeriods = (
      start_date,
      rent_period,
      mini_storage_id,
      prepay_type_id,
      contract_discounts
    ) => {
      store
        .dispatch("contract/getContractPeriods", {
          start_date,
          rent_period,
          mini_storage_id,
          prepay_type_id,
          contract_discounts,
        })
        .then((response) => {
          contractPediod.value = response.data.result;
          if (props.is_continuous) {
            old_deposite.value =
              Number(newContractData.value.original_deposite) +
              Number(newContractData.value.deposite);

            new_deposite.value =
              Number(contractPediod.value.deposite) -
              Number(newContractData.value.original_deposite);

            if (!new_deposite.value) {
              new_deposite.value = 0;
            }

            if (!new_card_deposite.value) {
              new_card_deposite.value = 0;
            }
          }

          refetchTbData(
            totalRowRentSchedule,
            dataMetaRentSchedule,
            perPageRentSchedule,
            currentPageRentSchedule,
            rent_schedule_list,
            contractPediod.value.rent_schedules
          );

          refetchTbData(
            totalRowPaymentSchedule,
            dataMetaPaymentSchedule,
            perPagePaymentSchedule,
            currentPagePaymentSchedule,
            payment_schedules_list,
            contractPediod.value.payment_schedules
          );
        })
        .catch((error) => {
          console.log("error when fetching contract", error);
          if (error.response.status === 404) {
            contractData.value = undefined;
          }
        });
    };

    const rent_schedule_list = ref([]);
    const refRentScheduleListTable = ref(null);
    const tableColumnsRentSchedule = [
      { key: "start_date", label: "開始日期", sortable: true },
      { key: "end_date", label: "結束日期" },
      { key: "mini_storage_number", label: "迷你倉", sortable: true },
      { key: "prepay_type_id", label: "預付款", sortable: true },
      { key: "is_free_month", label: "是否免費月份", sortable: true },
      { key: "amount", label: "租金", sortable: true },
      { key: "discount_price", label: "折扣價", sortable: true },
    ];

    const perPageRentSchedule = ref(10);
    const totalRowRentSchedule = ref(0);
    const currentPageRentSchedule = ref(1);
    const perPageOptionsRentSchedule = [10, 25, 50, 100];
    const sortByRentSchedule = ref("id");
    const isSortDirDescRentSchedule = ref(false);

    const dataMetaRentSchedule = computed(() => {
      const localItemsCount = refRentScheduleListTable.value
        ? refRentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPageRentSchedule.value * (currentPageRentSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPageRentSchedule.value * (currentPageRentSchedule.value - 1) +
          localItemsCount,
        of: totalRowRentSchedule.value,
      };
    });

    const payment_schedules_list = ref([]);
    const refPaymentScheduleListTable = ref(null);
    const tableColumnsPaymentSchedule = [
      { key: "pay_date", label: "付款日期", sortable: true },
      { key: "mini_storage_number", label: "迷你倉", sortable: true },
      { key: "prepay_type_id", label: "預付款", sortable: true },
      { key: "payment_cycle", label: "付款週期", sortable: true },
      { key: "discount_percentage", label: "折扣百分比", sortable: true },
      { key: "standard_price", label: "標準租金", sortable: true },
      { key: "price", label: "租金", sortable: true },
      { key: "discount_price", label: "折扣價", sortable: true },
      // { key: "rent_period", label: "租用期", sortable: true },
      { key: "amount", label: "總值", sortable: true },
      { key: "total_discount", label: "折扣總額", sortable: true },
      { key: "total_amount", label: "應付金額", sortable: true },
    ];

    const perPagePaymentSchedule = ref(10);
    const totalRowPaymentSchedule = ref(0);
    const currentPagePaymentSchedule = ref(1);
    const perPageOptionsPaymentSchedule = [10, 25, 50, 100];
    const sortByPaymentSchedule = ref("id");
    const isSortDirDescPaymentSchedule = ref(false);

    const dataMetaPaymentSchedule = computed(() => {
      const localItemsCount = refPaymentScheduleListTable.value
        ? refPaymentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPagePaymentSchedule.value *
            (currentPagePaymentSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPagePaymentSchedule.value *
            (currentPagePaymentSchedule.value - 1) +
          localItemsCount,
        of: totalRowPaymentSchedule.value,
      };
    });

    const refetchTbData = (total, meta, perPage, current, target, from) => {
      total.value = from.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      target.value = from.filter((ele, index) => index >= min && index <= max);
    };

    const referralCouponTypeOptions = ref([]);
    const discountArray = ref([]);
    const newDiscountArray = ref([]);
    const discountSelected = ref([]);
    const discountSelected2 = ref([]);
    const counts2 = ref(0);
    const getCustomerCoupons = (customer_id) => {
      if (!customer_id) return;
      axiosIns
        .get("/customer/coupons", { params: { customer_id } })
        .then((response) => {
          const { discounts } = response.data;
          discountSelected.value = []
          discountSelected2.value = []
          newDiscountArray.value = []
          discountArray.value = discounts.map((ele) => {
            counts2.value += 1;
            return { ...ele, idx: counts2.value };
          });
        });
    };

    watch(
      () => props.contractData,
      (newVal) => {
        newContractData.value = { ...newVal };
      }
    );

    const toast = useToast();
    const isDiscountSelectedDataChange = ref(false);
    watch(
      () => [
        newContractData.value.start_date,
        newContractData.value.rent_period,
        newContractData.value.mini_storage_id,
        newContractData.value.prepay_type_id,
        isDiscountSelectedDataChange.value
        // discountSelected.value,
        // discountSelected2.value,
      ],
      (newVal) => {
        var contract_discounts = [
          ...discountSelected.value,
          ...discountSelected2.value,
        ];
        if (contract_discounts.length > 0) {
          const allValuesValid = contract_discounts.every(
            (obj) =>
              obj.referral_coupon_type_id !== null &&
              obj.referral_coupon_type_id !== undefined &&
              obj.free_month !== null &&
              obj.free_month !== undefined &&
              obj.discount_percentage !== null &&
              obj.discount_percentage !== undefined &&
              obj.remark !== null &&
              obj.remark !== undefined
          );

          if (!allValuesValid) {
            // toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "請你填寫已選擇的優惠卷內的空格！",
            //     icon: "AlertTriangleIcon",
            //     variant: "info",
            //   },
            // });
            return;
          }
        }

        if (
          newContractData.value.start_date &&
          newContractData.value.rent_period &&
          newContractData.value.mini_storage_id &&
          newContractData.value.prepay_type_id
        ) {
          getContractPeriods(
            newContractData.value.start_date,
            newContractData.value.rent_period,
            newContractData.value.mini_storage_id,
            newContractData.value.prepay_type_id,
            contract_discounts
          );
        }
      },
      { deep: true }
    );


    watch(
      () => newContractData.value.customer_id,
      (newVal) => {
        if (
          customerOptions.value &&
          customerOptions.value.length > 0 &&
          newContractData.value.id == 0
        ) {
          const target = customerOptions.value.find((ele) => ele.id == newVal);
          if (target) {
            newContractData.value.name = target.name;
            newContractData.value.id_number = target.id_number;
            newContractData.value.phone = target.mobile;
            newContractData.value.address = target.address;
          }
        }
        getCustomerCoupons(newVal);
      }
    );

    const rentPeriodOptions = ref([]);
    watch(
      () => newContractData.value.prepay_type_id,
      (newVal) => {
        if (
          prePayTypeOptions.value &&
          prePayTypeOptions.value.length > 0 &&
          newContractData.value.id == 0
        ) {
          const target = prePayTypeOptions.value.find(
            (ele) => ele.id == newVal
          );
          if (target) {
            let month = target.months;
            rentPeriodOptions.value = [];

            for (let i = month; i <= 24; i += month) {
              rentPeriodOptions.value.push({ id: i, name: i + "個月" });
            }
          }
        }
      }
    );

    const handleLocation = () => {
      if (
        miniStorageOptions.value &&
        miniStorageOptions.value.length > 0 &&
        newContractData.value.mini_storage_id
      ) {
        const target = miniStorageOptions.value.find(
          (ele) => ele.id == newContractData.value.mini_storage_id
        );

        if (target) {
          newContractData.value.location = target.location;
        }
        triggerLocation.value = !triggerLocation.value;
      }
    };

    watch(
      () => newContractData.value.mini_storage_id,
      (newVal) => {
        handleLocation();
      }
    );

    watch([currentPageRentSchedule, currentPagePaymentSchedule], () => {
      refetchTbData(
        totalRowRentSchedule,
        dataMetaRentSchedule,
        perPageRentSchedule,
        currentPageRentSchedule,
        rent_schedule_list,
        contractPediod.value.rent_schedules
      );

      refetchTbData(
        totalRowPaymentSchedule,
        dataMetaPaymentSchedule,
        perPagePaymentSchedule,
        currentPagePaymentSchedule,
        payment_schedules_list,
        contractPediod.value.payment_schedules
      );
    });

    const customerOptions = ref([]);
    const miniStorageOptions = ref([]);
    const contractTemplateOptions = ref([]);
    const saleOptions = ref([]);
    const prePayTypeOptions = ref([]);
    const signStatusOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", {
          customer: true,
          mini_storage: true,
          contract_template: true,
          user: true,
          pre_pay_type: true,
          sign_status: true,
          referral_coupon_type: true,
        })
        .then((response) => {
          customerOptions.value = response.data.customers;
          miniStorageOptions.value = response.data.mini_storages;
          contractTemplateOptions.value = response.data.contract_templates;
          saleOptions.value = response.data.users;
          prePayTypeOptions.value = response.data.pre_pay_types;
          signStatusOptions.value = response.data.sign_statuses;
          referralCouponTypeOptions.value = response.data.referral_coupon_types;

          // just for rent form
          if (newContractData.value.id == 0) {
            handleLocation();
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    onMounted(() => {
      fetchOptions();
    });

    return {
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newContractData,
      contractPediod,

      rent_schedule_list,
      refRentScheduleListTable,
      tableColumnsRentSchedule,
      perPageRentSchedule,
      totalRowRentSchedule,
      currentPageRentSchedule,
      perPageOptionsRentSchedule,
      sortByRentSchedule,
      isSortDirDescRentSchedule,
      dataMetaRentSchedule,

      payment_schedules_list,
      refPaymentScheduleListTable,
      tableColumnsPaymentSchedule,
      perPagePaymentSchedule,
      totalRowPaymentSchedule,
      currentPagePaymentSchedule,
      perPageOptionsPaymentSchedule,
      sortByPaymentSchedule,
      isSortDirDescPaymentSchedule,
      dataMetaPaymentSchedule,

      customerOptions,
      miniStorageOptions,
      contractTemplateOptions,
      saleOptions,
      prePayTypeOptions,
      signStatusOptions,

      rentPeriodOptions,

      old_deposite,
      new_deposite,
      triggerLocation,

      referralCouponTypeOptions,
      discountArray,
      newDiscountArray,
      discountSelected,
      discountSelected2,
      counts2,
      isDiscountSelectedDataChange
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
  