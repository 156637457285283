<template>
  <b-card no-body class="card-client bgColorWhite">
    <b-card-header>
      <b-card-title class="font-weight-bolder text-center color2 w-100 mb-2">{{
        title
      }}</b-card-title>
    </b-card-header>
    <b-card-body class="client-body">
      <vue-apex-charts
        id="line-chart"
        :options="chartOptions"
        :series="series"
        :key="title"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import VueApexCharts from "vue-apexcharts";
import store from "@/store";
export default {
  components: {
    VueApexCharts,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  props: {
    series: {},
    title: {},
    time: {},
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: 400,
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          colors: ["#000000"],
          width: 2,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        responsive: [
          {
            breakpoint: 3000,
            options: {
              chart: {
                width: "100%",
                height: 350,
              },
            },
          },
          {
            breakpoint: 575,
            options: {
              chart: {
                width: "100%",
                height: 350,
              },
            },
          },
        ],
        xaxis: {
          categories: this.time,
          labels: {
            style: {
              fontSize: "11px",
            },
            // formatter: function (value) {
            //   if (value) {
            //     // Wrap the label text
            //     return value.split("/").join("/\n");
            //   }
            //   return value;
            // },
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.card .card-header .card-title {
  overflow: hidden;
  white-space: nowrap;
}
.chart-result {
  font-size: 18px;
}
</style>
