import axiosIns from '@/libs/axios'
import {getUserData} from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSignStatuses(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/sign_status/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchSignStatus(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/sign_status', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    updateSignStatus(ctx, data) {
      return new Promise((resolve, reject) => {
          axiosIns.post('/sign_status', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSignStatus(ctx, queryParams)
    {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/sign_status', {params:queryParams})
        .then(response=>resolve(response))
        .catch(error=>reject(error))
    })
    }
  },
}
