<template>
    <div class="other-lease-detail">
      <div class="other-info">
        <b-row class="info-wrap">
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">推廣價</b>
              <span>$770 X 12月 = 9240.00</span>
            </div>
            <div class="info-item">
              <b class="info-item-label">其他1</b>
              <span class=""></span>
            </div>
            <div class="info-item">
              <b class="info-item-label">備註</b>
              <span class=""></span>
            </div>
          </b-col>
  
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">智能卡</b>
              <span>$0 X 1張 = 0</span>
            </div>
            <div class="info-item">
              <b class="info-item-label">其他2</b>
              <span></span>
            </div>
          </b-col>
  
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">咭號1</b>
              <span></span>
            </div>
            <div class="info-item">
              <b class="info-item-label">其他3</b>
              <span></span>
            </div>
          </b-col>
  
          <b-col cols="12" xl="4">
            <div class="info-item">
              <b class="info-item-label">咭號2</b>
              <span></span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </template>
      
      <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import axiosIns from "@/libs/axios.js";
  import { ref, onUnmounted, onMounted } from "@vue/composition-api";
  
  export default {
    components: {
      BImg,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
    },
    methods: {},
    data() {
      return {};
    },
    props:{
        otherLeaseData:{}
    },
    setup() {
    },
  };
  </script>
      
    <style lang="scss" scoped>
  </style>
      
      <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
      
      