<template>
  <b-modal
    id="modal-edit-email"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
  >
    <section id="email">
      <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
        <h5 class="colorWhite">撰寫郵件</h5>
        <a @click="hide" class="close-button">
          <feather-icon class="colorWhite" icon="XIcon" size="18" />
        </a>
      </div>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-md-2"
            @submit.prevent="handleSubmit(submitMail)"
            @reset.prevent="resetForm"
          >
            <!-- template -->
            <b-form-group label-cols-sm="1" label="模板" label-for="template">
              <a class="btn-trigger-modal" v-b-modal.modal-1>
                {{
                  formInfo.template.selected != null
                    ? formInfo.template.options[formInfo.template.selected]
                        .title
                    : ""
                }}
                <feather-icon size="24" icon="ChevronDownIcon" />
              </a>
              <b-modal class="" id="modal-1" size="lg" hide-header hide-footer>
                <div class="modal-top">選擇郵件模板</div>
                <div class="modal-container">
                  <div class="modal-search">
                    <feather-icon size="16" icon="SearchIcon" />
                    <b-form-input
                      id="search-template"
                      trim
                      type="search"
                      placeholder="搜尋關鍵字"
                      v-model="formInfo.template.search"
                    />
                  </div>
                  <div
                    v-if="filterTemplateOptions.length > 0"
                    class="modal-options-wrapper"
                  >
                    <div
                      class="modal-options"
                      v-for="(option, index) in filterTemplateOptions"
                      :key="'templateOption' + index"
                      :class="formInfo.template.select == index ? 'select' : ''"
                    >
                      <div
                        href="#"
                        @click="
                          parseInt(collapse) >= 0
                            ? (collapse = '')
                            : (collapse = index)
                        "
                        v-b-toggle="'collapse-' + index"
                        class="drop-btn"
                      >
                        <h3 class="title">
                          {{ option.title }}
                          <div
                            v-if="collapse === index"
                            style="
                              float: right;
                              font-size: 25px;
                              margin-right: 3px;
                            "
                          >
                            -
                          </div>
                          <feather-icon v-else size="14" icon="PlusIcon" />
                        </h3>
                      </div>
                      <b-collapse :id="'collapse-' + index" class="">
                        <div
                          class="drop-item"
                          @click="formInfo.template.select = index"
                        >
                          <h4 class="name">{{ option.name }}</h4>
                          <div
                            class="options-content"
                            v-html="option.content"
                          ></div>
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                  <div v-else class="empty-box">沒有找到搜尋結果</div>
                </div>
                <div class="modal-bottom">
                  <b-button
                    class="btn-select"
                    variant="primary"
                    type="submit"
                    @click="confirmTemplate"
                  >
                    <feather-icon size="14" icon="CheckCircleIcon" /> 選擇
                  </b-button>
                  <b-button
                    class="btn-cancal"
                    variant="primary"
                    @click="$bvModal.hide('modal-1')"
                  >
                    <feather-icon size="14" icon="XCircleIcon" /> 取消
                  </b-button>
                </div>
              </b-modal>
            </b-form-group>

            <!-- to -->
            <validation-provider
              #default="validationContext"
              name="收件者"
              rules="required"
            >
              <b-popover
                target="popover-target-1"
                triggers="hover"
                placement="top"
              >
                <template #title>輸入電子郵件後請按 Enter</template>
              </b-popover>
              <b-form-group
                label-cols-sm="1"
                label-for="email-to"
                label="收件者"
                class="required"
                id="popover-target-1"
              >
                <b-input-group style="width: 95%">
                  <v-select
                    class="hide-dropdown multi-select"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    taggable
                    push-tags
                    v-model="formData.to"
                    placeholder=""
                    label="name_en"
                    :options="customerOptions"
                    :reduce="(option) => option.name_en"
                    style="width: 100%"
                  />
                  <b-input-group-append
                    v-b-modal.modal-2
                    style="
                      position: absolute;
                      right: 3px;
                      top: 3px;
                      padding: 5px 10px;
                      background: #94c8bb;
                      color: white;
                      cursor: pointer;
                      z-index: 10;
                      border-radius: 5px;
                    "
                  >
                    <p style="margin: 0">選擇收件人</p>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

                <b-modal id="modal-2" size="lg" hide-header hide-footer>
                  <div class="modal-top">選擇收件人</div>
                  <div class="modal-container">
                    <div class="modal-search">
                      <feather-icon size="16" icon="SearchIcon" />
                      <b-form-input
                        id="search-client"
                        trim
                        type="search"
                        placeholder="搜尋收件人代碼/姓名/電子郵件"
                        v-model="formInfo.to.search"
                      />
                    </div>
                    <div
                      v-if="filterToOptions.length > 0"
                      class="modal-table-wrapper"
                    >
                      <div class="thead">
                        <div class="tr">
                          <div
                            class="th"
                            v-for="(th, index) in formInfo.to.fields"
                            :key="'thKey' + index"
                          >
                            {{ th }}
                          </div>
                        </div>
                      </div>
                      <div class="tbody">
                        <div
                          class="tr"
                          v-for="(to, index) in filterToOptions"
                          :key="'toOption' + index"
                          @click="selectClient(to.email)"
                          :class="
                            formData.to.includes(to.email) ? 'select' : ''
                          "
                        >
                          <div
                            class="td"
                            v-for="(item, index) in toOptionsKeyArray"
                            :key="'toOptionItem' + index"
                          >
                            {{ to[item] }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="empty-box">沒有找到搜尋結果</div>
                  </div>
                  <div class="modal-bottom">
                    <b-button
                      class="btn-select"
                      type="submit"
                      variant="primary"
                      @click="confirmTo"
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" />
                      搜尋
                    </b-button>
                    <b-button
                      class="btn-cancal"
                      variant="primary"
                      @click="$bvModal.hide('modal-2')"
                    >
                      <feather-icon size="14" icon="XCircleIcon" /> 取消
                    </b-button>
                  </div>
                </b-modal>
                <div class="other-input-list">
                  <a href="#" @click="handleCCClick()">副本</a>
                  <a href="#" style="margin-left: 5px" @click="handleBCCClick()"
                    >密件副本</a
                  >
                </div>
              </b-form-group>
            </validation-provider>

            <!-- cc -->
            <validation-provider
              v-if="isCcOpen"
              #default="validationContext"
              name="副本"
              rules="email"
            >
              <b-popover
                target="popover-target-2"
                triggers="hover"
                placement="top"
              >
                <template #title>輸入電子郵件後請按 Enter</template>
              </b-popover>
              <b-form-group
                id="popover-target-2"
                label-cols-sm="1"
                label="副本"
                label-for="cc-email"
                class="required"
              >
                <v-select
                  class="multi-select"
                  v-if="userOptions != null"
                  id="cc-email"
                  v-model="formData.cc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="userOptions"
                  :reduce="(option) => option.email"
                  multiple
                  taggable
                  push-tags
                  placeholder="增加副本"
                  :state="getValidationState(validationContext)"
                />
                <v-select
                  v-else
                  id="cc-email"
                  v-model="formData.cc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  taggable
                  push-tags
                  placeholder="增加副本"
                  :state="getValidationState(validationContext)"
                />
                <!-- <b-form-input v-else id="cc-email" trim placeholder="" v-model="formData.cc[0]"
                    :state="getValidationState(validationContext)" /> -->
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Bcc -->
            <validation-provider
              v-if="isBccOpen"
              #default="validationContext"
              name="Bcc"
              rules="email"
            >
              <b-popover
                target="popover-target-3"
                triggers="hover"
                placement="top"
              >
                <template #title>輸入電子郵件後請按 Enter</template>
              </b-popover>
              <b-form-group
                id="popover-target-3"
                label-cols-sm="1"
                label="密件副本"
                label-for="bcc-email"
                class="required"
              >
                <v-select
                  class="multi-select"
                  v-if="userOptions != null"
                  id="bcc-email"
                  v-model="formData.bcc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="userOptions"
                  :reduce="(option) => option.email"
                  multiple
                  taggable
                  push-tags
                  placeholder="增加密件副本"
                  :state="getValidationState(validationContext)"
                />
                <v-select
                  v-else
                  id="bcc-email"
                  v-model="formData.bcc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  taggable
                  push-tags
                  placeholder="增加密件副本"
                  :state="getValidationState(validationContext)"
                />
                <!-- <b-form-input v-else id="bcc-email" trim placeholder="" v-model="formData.bcc"
                    :state="getValidationState(validationContext)" /> -->
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subject -->
            <validation-provider
              #default="validationContext"
              name="標題"
              rules="required"
            >
              <b-form-group
                label-cols-sm="1"
                label="標題"
                label-for="subject"
                class="required"
              >
                <b-form-input
                  id="subject"
                  trim
                  placeholder=""
                  v-model="formData.subject"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Message -->
            <b-form-group label-cols-sm="1" label="內容" label-for="message">
              <quill-editor
                class="email-messge-editor"
                :style="{ height: '200px', borderRadius: '4px' }"
                :options="editorOption"
                v-model="formData.content"
              />
              <!-- <p class="error-msg" v-show="error.message">required</p> -->
            </b-form-group>
            <!-- Message Toolbar-->
            <div id="toolbar" slot="toolbar">
              <!-- Add a bold button -->
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-underline">Underline</button>
              <span class="ql-formats">
                <button type="button" class="ql-align" value="">
                  <svg viewBox="0 0 18 18">
                    <line class="ql-stroke" x1="3" x2="15" y1="9" y2="9"></line>
                    <line
                      class="ql-stroke"
                      x1="3"
                      x2="13"
                      y1="14"
                      y2="14"
                    ></line>
                    <line class="ql-stroke" x1="3" x2="9" y1="4" y2="4"></line>
                  </svg>
                </button>
                <button type="button" class="ql-align" value="center">
                  <svg viewBox="0 0 18 18">
                    <line class="ql-stroke" x1="15" x2="3" y1="9" y2="9"></line>
                    <line
                      class="ql-stroke"
                      x1="14"
                      x2="4"
                      y1="14"
                      y2="14"
                    ></line>
                    <line class="ql-stroke" x1="12" x2="6" y1="4" y2="4"></line>
                  </svg>
                </button>
                <button type="button" class="ql-align" value="right">
                  <svg viewBox="0 0 18 18">
                    <line class="ql-stroke" x1="15" x2="3" y1="9" y2="9"></line>
                    <line
                      class="ql-stroke"
                      x1="15"
                      x2="5"
                      y1="14"
                      y2="14"
                    ></line>
                    <line class="ql-stroke" x1="15" x2="9" y1="4" y2="4"></line>
                  </svg>
                </button>
                <button type="button" class="ql-align" value="justify">
                  <svg viewBox="0 0 18 18">
                    <line class="ql-stroke" x1="15" x2="3" y1="9" y2="9"></line>
                    <line
                      class="ql-stroke"
                      x1="15"
                      x2="3"
                      y1="14"
                      y2="14"
                    ></line>
                    <line class="ql-stroke" x1="15" x2="3" y1="4" y2="4"></line>
                  </svg>
                </button>
              </span>
            </div>
            <!-- submit button list-->
            <div class="mt-2 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-button
                  class="btn-send full mobile-w100"
                  variant="primary"
                  type="submit"
                >
                  <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                  提交
                </b-button>

                <!-- file-->
                <div class="file-wrapper d-flex">
                  <a
                    class="btn-trigger-input-file"
                    href="javascript:void(0)"
                    @click="$refs.pickFile.$el.childNodes[0].click()"
                  >
                    <feather-icon size="20" icon="PaperclipIcon" />
                  </a>
                  <div
                    v-if="!(documentData && documentData.original_name)"
                    class="file-list"
                  >
                    {{ fileNameList }}
                  </div>
                  <div v-else class="file-list">
                    <a
                      href="javascript:void(0)"
                      @click="handleDownload(documentData)"
                      >{{ documentData.original_name }}</a
                    >
                  </div>
                  <!-- <img v-if="blah" id="blah" :src="blah.src" alt="" /> -->
                </div>
              </div>
              <a
                @click="handleClearFile()"
                href="javascript:void(0)"
                class="btn-delete-file"
              >
                <feather-icon size="20" icon="Trash2Icon" />
              </a>
            </div>
            <b-form-file
              id=""
              style="display: none"
              multiple
              ref="pickFile"
              @change="testFile"
              class="mb-2"
            >
            </b-form-file>
          </b-form>
        </validation-observer>
      </b-overlay>
    </section>
  </b-modal>
</template>
  
<script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted, computed } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import {
  BModal,
  BForm,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCollapse,
  BFormInvalidFeedback,
  VBToggle,
  BPopover,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BForm,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormFile,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCollapse,
    BFormInvalidFeedback,
    VBToggle,
    BPopover,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      documentData: {},
      loading: false,
      isCcOpen: false,
      isBccOpen: false,
      isScheduleOpen: false,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: "#toolbar",
        },
      },
      collapse: "",
      blah: {
        src: "",
      },
    };
  },
  props: {
    emailData: {},
    // document: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-email");
    },
    handleCCClick() {
      this.isCcOpen = !this.isCcOpen;
      if (this.isCcOpen == false) {
        this.formData.cc = [];
      }
    },
    handleBCCClick() {
      this.isBccOpen = !this.isBccOpen;
      if (this.isBccOpen == false) {
        this.formData.bcc = [];
      }
    },
    handleDownload(item) {
      this.loading = true;
      //this.$store.dispatch(this.DOCUMENT_APP_STORE_MODULE_NAME+'/fetchDocument', {id})
      axiosIns
        .get("/document", { params: { id: item.id }, responseType: "blob" })
        .then((response) => {
          this.loading = false;
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", item.original_name); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(async (error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(await error.response.statusText),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    handleClearFile() {
      this.formData.attachment = [];
      this.documentData = {};
    },
    confirmTemplate: function () {
      if (this.formInfo.template.select != null) {
        const index = this.formInfo.template.select;
        this.formInfo.template.selected = index;
        this.formData.subject = this.formInfo.template.options[index].subject;
        this.formData.content = this.formInfo.template.options[index].content;
        this.$bvModal.hide("modal-1");
      } else {
        this.$bvToast.toast(`Please select a Template`, {
          title: "You Don't select any Template",
          autoHideDelay: 2000,
        });
      }
    },
    selectClient: function (email) {
      const index = this.formData.to.indexOf(email);
      if (index > -1) {
        this.formData.to.splice(index, 1);
      } else {
        this.formData.to.push(email);
      }
    },
    confirmTo: function () {
      this.$bvModal.hide("modal-2");
    },
    testFile: function (event) {
      const file = event.target.files;
      file.forEach((item) => {
        if (item.size < 1024 * 1024 * 2) {
          this.formData.attachment.push(item);
        } else {
          this.$bvToast.toast(`${item.name} too big (> 2MB)`, {
            title: `File Size To Big`,
            autoHideDelay: 2000,
          });
        }
      });
    },
    submitMail: function (method) {
      console.log("sending form data", this.formData);

      let newFormData = new FormData();

      if (this.documentData && this.documentData.id) {
        newFormData.append("document_id", this.documentData.id);
      } else {
        this.formData.attachment.forEach((item) => {
          newFormData.append("attachment[]", item);
        });
      }
      this.formData.to.forEach((item) => {
        newFormData.append("to[]", item);
      });
      this.formData.cc.forEach((item) => {
        newFormData.append("cc[]", item);
      });
      this.formData.bcc.forEach((item) => {
        newFormData.append("bcc[]", item);
      });
      // newFormData.append("message", this.formData.message);
      newFormData.append("content", this.formData.content);
      newFormData.append("subject", this.formData.subject);
      // newFormData.append("to", this.formData.to);
      // newFormData.append("cc", this.formData.cc);
      // newFormData.append("bcc", this.formData.bcc);
      newFormData.append("schedule", this.formData.schedule);
      console.log(newFormData);
      this.loading = true;
      axiosIns
        .post("/email/send", newFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
          this.formData.to = [];
          this.formData.cc = [];
          this.formData.bcc = [];
          this.formData.content = "";
          this.formData.subject = "";
          this.formData.schedule = {
            date: "",
            isEveryWeek: false,
            isEveryDay: false,
            every: {
              week: "",
              days: "",
            },
          };
          this.hide()
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
  watch: {},
  computed: {
    combinOptions() {
      return this.formInfo.template.options.map((item) => {
        return {
          ...item,
          content: item.content.concat(this.formInfo.template.info)
        };
      });
    },
    filterTemplateOptions() {
      return this.combinOptions.filter((item) => {
        return item.title
          .toLowerCase()
          .includes(this.formInfo.template.search.toLowerCase());
      });
    },

    filterToOptions() {
      return this.formInfo.to.options.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(this.formInfo.to.search.toLowerCase()) >= 0 ||
          item.email.toLowerCase().indexOf(this.formInfo.to.search.toLowerCase()) >= 0
        );
      });
    },

    toOptionsKeyArray() {
      return ["id", "name", "email", "id_number"];
    },
    fileNameList() {
      if (this.formData.attachment.length > 0) {
        return this.formData.attachment.map((item) => item.name).join(", ");
      }
      return "";
    },
  },
  mounted() {
    // this.documentData = this.document;
  },
  setup(props) {
    const data = {
      template: {
        search: "",
        selected: null,
        select: null,
        options: [],
        info: ``,
      },
      to: {
        search: "",
        selected: [],
        select: [],
        options: [],
        fields: ["客戶編號", "客戶名稱", "客戶電郵", "身分證號碼"],
      },
    };

    const customerOptions = ref([]);
    const userOptions = ref([]);
    const fetchOptions = () => {
      axiosIns
        .get("/email/template/list")
        .then((response) => {
          const { email_templates } = response.data;
          data.template.options = email_templates;
        })
        .catch((error) => {
          console.log(error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching email templates list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });

      store
        .dispatch("app/fetchOptions", {
          customer: true,
          user: true,
        })
        .then((response) => {
          customerOptions.value = response.data.customers;
          userOptions.value = response.data.users;
          data.to.options = response.data.customers;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    const formInfo = ref(data);

    let blankFormData = {
      to: [],
      cc: [],
      bcc: [],
      subject: "",
      content: "",
      attachment: [],
      schedule: {
        date: "",
        isEveryWeek: false,
        isEveryDay: false,
        every: {
          week: "",
          days: "",
        },
      },
    };
    const formData = ref(JSON.parse(JSON.stringify(blankFormData)));

    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    watch(
      () => props.emailData,
      (newVal) => {
        formData.value.to = [newVal.email];
        formData.value.content = newVal.message;
      }
    );

    let weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    weekDays = ref(weekDays);

    onMounted(() => {
      fetchOptions();
    });

    return {
      formInfo,
      formData,
      weekDays,
      refFormObserver,
      getValidationState,
      resetForm,
      customerOptions,
      userOptions,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.multi-select {
  svg {
    filter: brightness(0) invert(1) !important;
  }
}

.btn-trigger-modal {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 6px;
  padding: 0rem 1rem;
  height: 38px;
  font-size: 14px;
  svg {
    position: absolute;
    top: 6px;
    right: 20px;
  }
}

#modal-1,
#modal-2 {
  .modal-dialog .modal-title {
    font-size: 20px;
  }
  .modal-content {
    border-radius: 25px;
  }

  .modal-body {
    padding: 0 !important;
    .modal-top {
      text-align: center;
      padding: 0.75rem;
      background: #296eb4;
      border-radius: 25px 25px 0 0;
      color: #fff;
    }
    .modal-content .modal-body {
      padding: 0px;
    }
    .modal-wrapper {
      padding: 1rem 1.5rem;
    }
    .modal-search {
      padding: 1rem 1.5rem;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.5rem;
      }
      input {
        padding-left: 2.8rem;
      }
    }
    .drop-btn {
      padding: 1.1rem 1.5rem;
      .title {
        margin-bottom: 0px;
        // padding: 0 1.5rem;
        padding: 0;
        svg {
          float: right;
          margin-top: 5px;
        }
      }
    }
    .modal-options {
      &:last-child {
        .drop-btn {
          &:before {
            content: none;
          }
        }
      }
      &.select {
        background-color: #d8d6de;
        .drop-btn {
          &:before {
            // background: radial-gradient( #e5e5e5, rgba(229, 229, 229, 0.3));
          }
        }
      }
      .drop-btn {
        display: block;
        &:before {
          bottom: 0px;
        }
      }
      .drop-item {
        padding: 0.5rem 1.5rem 2rem;
        .name {
          font-weight: 700;
          color: #013e79;
        }
      }
      .options-content {
        padding-left: 1rem;
      }
    }
    .modal-bottom {
      position: relative;
      display: flex;
      justify-content: end;
      padding: 1rem 0;
      &:before {
        top: 0px;
      }
      button {
        padding: 1rem 1.5rem;
        margin-right: 1rem;
        svg {
          margin-right: 0.75rem;
        }
      }
    }
  }
  .foot-info {
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      padding: 2rem 0;
    }
    .contact {
      // width: calc(100% - 10px);
      img {
        width: 110px;
        margin-bottom: 0.5rem;
      }
    }
    .contact-list {
      .item {
        width: 33.33%;
      }
    }
  }

  .modal-table-wrapper {
    overflow: auto;
    .tr {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      &::before {
        bottom: 0px;
      }

      &.select {
        .th,
        .td {
          background-color: #f9f9f9;
        }
      }
      .th {
        font-weight: 700;
      }
    }
    .tbody {
      .tr:last-child {
        &:before {
          content: none;
        }
      }
    }
    .th,
    .td {
      padding: 1rem 1.5rem;
      // white-space: nowrap;
      // flex: 1;
      width: 22%;
    }
  }

  .modal-bottom,
  .modal-table-wrapper .tr,
  .modal-options .drop-btn,
  .table tr {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0px;
      background: radial-gradient(#e0e0e0, rgba(224, 225, 226, 0.12));
    }
  }
  .empty-box {
    padding: 1.5rem;
    text-align: center;
    font-size: 20px;
  }
}

.hide-dropdown {
  ul {
    display: none !important;
  }
}

.email-messge-editor {
  .ql-snow .ql-editor {
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      padding: 2rem 0;
    }

    p {
      padding-bottom: 0.5rem;
    }
  }
}

modal-edit-email .col-form-label {
  min-width: 80px;
}
</style>
  


<style lang="scss" scoped>
.card-header {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}

label {
  font-size: 18px !important;
}

.btn-trigger-input-file {
  margin: 0 20px;
}

// quill
.ql-toolbar.ql-snow {
  border: 0px;
}

.btn-send {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  border-radius: 6px 0px 0px 6px;

  &.full {
    border-radius: 4px;
  }
}

.btn-drop-down-schedule {
  border-radius: 0 6px 6px 0px;
  padding-right: 1rem;
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.btn-schedule-open {
  position: absolute;
  top: -65px;
  left: -36px;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  border-radius: 6px;
  background: #fff;
  border: 0.5px solid #e0e0e0;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
}

.file-wrapper {
  position: relative;
  // width: calc(100% - 10.25rem);
  color: #013e79;

  .file-list {
    // width: 77%;
    vertical-align: middle;
  }

  img {
    display: inline;
    max-width: 150px;
  }

  .error-msg {
    padding-left: 10px;
  }
}

// .btn-delete-file {
//   position: absolute;
//   right: 20px;
//   top: 0px;
// }

.other-input-list {
  position: absolute;
  top: 8px;
  right: -20px;
}

@media screen and (max-width: 768px) {
  .file-wrapper {
    width: 100%;
    margin-top: 1rem;

    .btn-trigger-input-file {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 575px) {
  .file-wrapper .file-list {
    width: 65%;
  }
}
</style>

