<template>
  <div>
    <warehouse-list-add-new
      :is-add-new-warehouse-sidebar-active.sync="isAddNewWarehouseSidebarActive"
      :id="id"
      :data="warehouseData"
      :warehouse-options="warehouseOptions"
      @refetch-data="refetchData"
    />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="categoryFilter"
              :options="warehouseOptions"
              label="name"
              style="width: 300px"
              :reduce="(val) => val.id"
              class="d-inline-block common-rounded mx-50"
              placeholder="選擇倉庫類別"
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span class="text-nowrap mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block common-rounded"
                placeholder="搜尋"
              />
            </div>
          </div>
          <a v-if="ability.can('create', 'warehouses')" class="mr-2" href="javascript:void(0)" @click="handleAdd()">
            快速
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-table
        ref="refWarehouseListTable"
        class="position-relative"
        :items="fetchWarehouses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <template #cell(warehouse_category_id)="data">
          {{ data.item.category.name }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="ability.can('update', 'warehouses')"
              :to="{ name: 'warehouses-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('update', 'warehouses')" @click="editWarehouse(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">快速修改</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('delete', 'warehouses')" @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalWarehouses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import warehouseStoreModule from "../warehouseStoreModule";
import useWarehousesList from "./useWarehousesList";
// import WarehouseListFilter from './WarehouseListFilter.vue';
import WarehouseListAddNew from "./WarehouseListAddNew.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    vSelect,
    // WarehouseListFilter,
    WarehouseListAddNew,
  },
  data() {
    return {
      id: 0,
      warehouseData: {},
    };
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的行動是最終的，您將無法取回倉庫",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("warehouse/deleteWarehouse", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    editWarehouse(item) {
      this.id = item.id;
      this.warehouseData = { ...item };
      this.isAddNewWarehouseSidebarActive = true;
    },
    handleAdd() {
      this.id = 0;
      this.warehouseData = {
        id: 0,
        warehouse_category_id: null,
        name: "",
        location: "",
        tel: "",
        email: "",
        content: "",
        is_active: 1,
        sort_order: 0,
      };
      this.isAddNewWarehouseSidebarActive = true;
    },
  },
  setup() {
    const APP_WAREHOUSE_STORE_MODULE_NAME = "warehouse";
    const isAddNewWarehouseSidebarActive = ref(false);

    if (!store.hasModule(APP_WAREHOUSE_STORE_MODULE_NAME))
      store.registerModule(
        APP_WAREHOUSE_STORE_MODULE_NAME,
        warehouseStoreModule
      );

    onMounted(() => {
      fetchOptions();
    });

    const warehouseOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", { warehouse_category: true })
        .then((response) => {
          warehouseOptions.value = response.data.warehouse_categories;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    const {
      fetchWarehouses,
      tableColumns,
      perPage,
      currentPage,
      totalWarehouses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWarehouseListTable,
      ability,
      refetchData,
      statusFilter,
      categoryFilter,
    } = useWarehousesList();

    return {
      isAddNewWarehouseSidebarActive,
      fetchWarehouses,
      tableColumns,
      perPage,
      currentPage,
      totalWarehouses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWarehouseListTable,
      ability,
      refetchData,
      statusFilter,
      categoryFilter,
      warehouseOptions,
      fetchOptions,
    };
  },
};
</script>