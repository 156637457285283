import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useDiscountsList() {

    //User toast
    const toast = useToast()

    const refDiscountListTable = ref(null)

    const tableColumns = [
        {key: 'id', label:'編號', sortable: true, tdClass:(value, key, item) => (item.is_active == 1)?null:'text-light'},
        {key: 'code', label:'優惠券號碼', sortable: true},
        {key: 'title', label:'優惠券名稱'},
        {key: 'amount', label:'金額',sortable: true},
        {key: 'percentage', label:'百分比',sortable: true},
        {key: 'date_from', label:'從',sortable: true},
        {key: 'date_to', label:'到',sortable: true},
        {key: 'uses_per_coupon', label:'每張優惠券的使用次數',sortable: true},
        {key: 'uses_per_customer', label:'每個客戶的使次數',sortable: true},
        {key: 'is_active', label: '狀態'},
        {key: 'actions', label:'操作'},
    ]

    const perPage = ref(10)
    const totalDiscounts = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    // Discount
    const statusDiscount = ref(-1)

    const dataMeta = computed(() => {
        const localItemsCount = refDiscountListTable.value ? refDiscountListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalDiscounts.value,
        }
    })

    const refetchData = () => {
        refDiscountListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusDiscount], () => {
        refetchData()
    })

    const fetchDiscounts = (ctx, callback) => {
        store.dispatch('discount/fetchDiscounts',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            is_active: statusDiscount.value, // As defined -1 means get all discount not matter it is active or not
        })
        .then(response =>{
            let {coupons, total} = response.data
            let date = new Date()
            coupons = coupons.map(item => {
                if(date >= new Date(item.date_from) && date <= new Date(item.date_to) && item.is_active)
                {
                    item._rowVariant = 'success'
                }
                return item
            })
            callback(coupons)
            totalDiscounts.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching discount list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchDiscounts,
        tableColumns,
        perPage,
        currentPage,
        totalDiscounts,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDiscountListTable,
        ability,
        refetchData,
        statusDiscount,
    }

}