<template>
  <b-sidebar
    id="add-new-category-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">倉庫類別</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-col cols="12" v-if="categoryData.image_path">
        <hr />
        <img style="width: 100%" :src="categoryData.image_path" />
      </b-col>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <span
              style="
                margin-bottom: 0.2857rem;
                color: #5e5873;
                font-size: 0.857rem;
              "
              >主頁圖片 <span class="error-text">**</span></span
            >
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              ref="upload"
              :action="uploadUrl"
              :headers="headerObj"
              name="image_file"
              :file-list="fileList"
              multiple
              :limit="1"
              :data="categoryData"
              :on-change="onChange"
              :on-remove="toggleUpload"
              :before-remove="beforeRemove"
              :auto-upload="false"
              :class="{ hideUpload: !showUpload }"
            >
              <img
                v-if="categoryData.image_path && false"
                :src="categoryData.image_path"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <label v-if="!categoryData.file && !categoryData.id" class="error-text">
              圖片欄是必填</label
            >

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label="名稱">
                <b-form-input
                  type="text"
                  placeholder="名稱"
                  v-model="categoryData.name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="簡單介紹"
              rules="required"
            >
              <b-form-group label="簡單介紹">
                <b-form-input
                  type="text"
                  placeholder="於手機端顯示"
                  v-model="categoryData.short_description"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Sort order -->
            <validation-provider
              #default="validationContext"
              name="排序"
              rules="required"
            >
              <b-form-group label="排序">
                <b-form-input
                  type="number"
                  placeholder="排序"
                  v-model="categoryData.sort_order"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Background Color"
              rules="required"
            >
              <b-form-group label="Background Color" label-for="background_color">
                <b-input-group>
                <b-form-input
                  id="background_color"
                  v-model="categoryData.background_color"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Background Color"
                />
                <b-input-group-append is-text>
                <el-color-picker v-model="categoryData.background_color" size="mini"></el-color-picker>
                </b-input-group-append>
                </b-input-group>
                
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="狀態"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="categoryData.is_active" /> -->
              <b-form-checkbox
                :checked="categoryData.is_active"
                name="is_active"
                switch
                inline
                v-model="categoryData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ categoryData.is_active ? "啟用" : "禁止" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import categoryStoreModule from "../categoryStoreModule";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { statusOptions } from "@/libs/helper";

export default {
  data() {
    return {
      statusOptions,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          //formData.append('file', this.categoryData.file)
          // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          formData.append("id", this.categoryData.id);
          if(this.categoryData.file)
          {
            formData.append("file", this.categoryData.file);
          }
          formData.append("name", this.categoryData.name);
          formData.append("short_description", this.categoryData.short_description);
          formData.append("sort_order", this.categoryData.sort_order);
          formData.append("background_color", this.categoryData.background_color);
          formData.append("is_active", this.categoryData.is_active ? 1 : 0);
          store
            .dispatch("category/addCategory", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-add-new-category-sidebar-active", false);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },

  model: {
    prop: "isAddNewCategorySidebarActive",
    event: "update:is-add-new-category-sidebar-active",
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const APP_FAQ_STORE_MODULE_NAME = "category";

    if (!store.hasModule(APP_FAQ_STORE_MODULE_NAME))
      store.registerModule(APP_FAQ_STORE_MODULE_NAME, categoryStoreModule);
    // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const blankData = ref({
      id: 0,
      is_active: 1,
      name: "",
      sort_order: "",
      background_color: "",
    });

    const categoryData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetcategoryData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetcategoryData);

    watch(
      () => [props.id, props.isAddNewCategorySidebarActive],
      () => {
        if (props.isAddNewCategorySidebarActive) {
          categoryData.value = JSON.parse(JSON.stringify(props.data));
        }
      }
    );

    const showUpload = ref(true);
    const fileList = ref([]);
    const upload = ref(null);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/banner");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const onChange = (file, fileLists) => {
      //fileList.value = fileLists;
      showUpload.value = !showUpload.value;
      categoryData.value.file = file.raw;
    };

    const beforeRemove = (file) => {
      categoryData.value.file = null;
    };

    const uploadSuccess = (res, file, fileList) => {
      emit("refetch-data");
      emit("update:is-add-new-banner-sidebar-active", false);
      showMessage("成功！", "成功插入新圖片.", "CheckIcon", "success");
    };

    const uploadFail = (err, file, fileList) => {
      //showMessage('Fail', err, 'HeartIcon', 'danger')
      console.log("上傳失敗");
    };

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      categoryData,
      showUpload,
      fileList,
      upload,
      uploadUrl,
      headerObj,
      onChange,
      beforeRemove,
      uploadSuccess,
      uploadFail

    };
  },
};
</script>

<style>
.b-sidebar.b-sidebar-right {
  width: 50% !important;
}

.ql-editor {
  min-height: 350px;
}

@media screen and (max-width: 780px) {
  .b-sidebar.b-sidebar-right {
    width: 100% !important;
  }
}

.input-group-text{
  padding:0 1rem;
}
.demo_button{
  width:100%;
  min-height:35px;
}

</style>