import { ref, onMounted , watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import ability from '@/libs/acl/ability'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from '@/auth/utils'

export default function usePaymentsList(){

    const toast = useToast()

    const refPaymentListTable = ref(null)
    const refPaymentCategoryListTable = ref(null)

    const tableColumns = [
        { key: 'id', label: '編號', sortable: true },
        { key: 'receipt_number', label: '收據編號', sortable: true },
        { key: 'invoice.invoice_number', label: '發票編號' },
        { key: 'customer.name', label: '客戶名稱'},
        { key: 'storage', label: '租用單位' },
        { key: 'payment_date', label: '付款日期', sortable: true },
        { key: 'image', label: '文件' },
        { key: 'payment_type_id', label: '付款類型', sortable: true },
        { key: 'amount', label: '金額', sortable: true },
        { key: 'points', label: '積分', sortable: true },
        { key: 'description', label: '描述', sortable: true },
        { key: 'audit', label: '核實付款'},
        { key: 'audit_remark', label: '核實備註', sortable: true },
        { key: 'receipt', label: '列印收據', sortable: true },
    ]

    const perPage = ref(10)
    const totalPayments = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    

    const dataMeta = computed(() => {
        const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPayments.value,
        }
    })

    const showMessage = (title,text,icon, variant) =>{
        toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon,
              variant
            },
        })
    }

    const refetchData = () =>{
        refPaymentListTable.value.refresh()
    }

    const getPaymentImage = (image) =>  process.env.VUE_APP_IMAGE_URL+image

    const filter_payment_type_id = ref(null)
    const filter_customer_id = ref(null)

    watch([currentPage, perPage, searchQuery, filter_payment_type_id, filter_customer_id], () => {
        refetchData()
    })


    const fetchPayments = (ctx, callback) => {

        store.dispatch('payment/fetchPayments', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            customer_id : filter_customer_id.value,
            payment_type_id : filter_payment_type_id.value,
        })
        .then(response => {
            const { payments, total} = response.data
            callback(payments)
            totalPayments.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching payments list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
            })
        })
    }

    const paymentTypeOptions = ref([]);
    const customerOptions = ref([]);

    const fetchOptions = () => {
        store
          .dispatch("app/fetchOptions", { 
            payment_type: true,
            customer: true,
        })
          .then((response) => {
            paymentTypeOptions.value = response.data.payment_types.filter(ele => ele.id > 1);
            customerOptions.value = response.data.customers
          })
          .catch((error) => {
            console.log({ error });
          });
      };
  

      onMounted(() => {
        fetchOptions()
      });


    return {
        fetchPayments,
        tableColumns,
        perPage,
        currentPage,
        totalPayments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refPaymentListTable,
        refPaymentCategoryListTable,
        getPaymentImage,
        paymentTypeOptions,
        customerOptions,
        ability,
        filter_customer_id,
        filter_payment_type_id,
        showMessage,
    }

}