import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {getCorrectDateTime} from '@/libs/helper'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


Vue.prototype.getCorrectDateTime = getCorrectDateTime

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

import FileManager from 'laravel-file-manager'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCircleMinus, faMessage, faEnvelope, faFileCirclePlus, faAudioDescription, faCashRegister, faCircleXmark, faCloudArrowUp, faReceipt, faStar, faCalendarXmark, faCalendarCheck, faBuilding, faStopwatch, faRectangleList, faTable, faCirclePlus, faFileExport, faCalendar, faUserGroup, faWarehouse, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add( faCircleMinus, faMessage, faEnvelope, faFileCirclePlus, faAudioDescription, faCashRegister, faCircleXmark, faCloudArrowUp, faReceipt, faStar, faCalendarXmark, faCalendarCheck, faBuilding ,faStopwatch, faRectangleList, faTable, faCirclePlus, faFileExport, faCalendar, faUserGroup, faWarehouse, faFileInvoiceDollar )

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)



// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(ElementUI, {locale})
Vue.use(ElementUI)
Vue.use(FileManager, {store});
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
