<template>
  <div>
    <modal-rent-form
      v-if="mini_storage"
      :storageData="mini_storage"
      @refetch-data="fetchRentReports"
    ></modal-rent-form>

    <b-tabs v-model="tabIndex" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">特定倉庫報表</span>
        </template>
        <b-card no-body class="mb-0 no-custome-top-menu">
          <div class="m-2">
            <div class="row align-items-center justify-content-between">
              <h5>請選擇倉庫及年份</h5>
              <div>
                <download-excel
                  class="btn btn-primary mb-1"
                  :header="`${
                    warehouseOptions.length &&
                    getWarehouseText(filter_warehouse_id)
                  }-${filter_year}`"
                  :name="`報表-${
                    warehouseOptions.length &&
                    getWarehouseText(filter_warehouse_id)
                  }-${filter_year}.xls`"
                  :worksheet="filter_year"
                  :fields="reportFields"
                  :data="excel_report_list"
                >
                  導出Excel
                </download-excel>
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="d-flex align-items-center m-2">
                <b>倉庫</b>
                <v-select
                  class="ml-50"
                  style="width: 250px"
                  v-model="filter_warehouse_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="warehouseOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                />
              </div>
              <div class="d-flex align-items-center m-2">
                <b>年份</b>
                <v-select
                  class="ml-50"
                  style="width: 250px"
                  v-model="filter_year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="yearOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                />
              </div>
              <div class="d-flex align-items-center m-2">
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #fefd01"
                    class="block-color mr-25"
                  ></b
                  ><span>新租</span>
                </div>
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #fbc000"
                    class="block-color mr-25"
                  ></b
                  ><span>完租</span>
                </div>
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #f82500"
                    class="block-color mr-25"
                  ></b
                  ><span>欠租</span>
                </div>
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #32b0f0"
                    class="block-color mr-25"
                  ></b
                  ><span>退租</span>
                </div>
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #92d050"
                    class="block-color mr-25"
                  ></b
                  ><span>自用</span>
                </div>
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #ffff95"
                    class="block-color mr-25"
                  ></b
                  ><span>可租用</span>
                </div>
                <div class="mr-2 d-flex align-items-center">
                  <b
                    style="background-color: #42c8a8"
                    class="block-color mr-25"
                  ></b
                  ><span>已出租</span>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <el-table
                v-if="is_init"
                :data="reportList"
                height="500"
                border
                style="width: 100%"
                :summary-method="getSummaries"
                show-summary
                :cell-style="cellStyle"
              >
                <el-table-column
                  fixed
                  prop="storage_number"
                  label="倉號"
                  width="50"
                >
                  <template slot-scope="scope">
                    <div
                      class="text-center cursor-pointer"
                      @click="fetchMiniStorage(scope.row.mini_storage_id)"
                    >
                      {{ scope.row.storage_number }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="filter_year">
                  <el-table-column prop="customer" label="客戶名稱" width="120">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ getCustomers(scope.row) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="average_price_per_area"
                    label="平均每尺租值"
                    width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="average_discount_price_per_area"
                    label="送月份後平均租值"
                    width="140"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="monthly_price"
                    label="定價/月"
                    width="85"
                  >
                  </el-table-column>
                  <el-table-column prop="price" label="實收/月" width="85">
                  </el-table-column>
                  <el-table-column prop="deposite" label="倉按金" width="85">
                  </el-table-column>
                  <el-table-column
                    prop="card_deposite"
                    label="咭按金"
                    width="85"
                  >
                  </el-table-column>
                  <el-table-column prop="width" label="闊" width="85">
                  </el-table-column>
                  <el-table-column prop="depth" label="深" width="85">
                  </el-table-column>
                  <el-table-column prop="height" label="高" width="85">
                  </el-table-column>
                  <el-table-column prop="customer" label="面積" width="120">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ getArea(scope.row) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="jan" label="一月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.jan) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="feb" label="二月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.feb) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="mar" label="三月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.mar) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="apr" label="四月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.apr) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="may" label="五月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.may) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="jun" label="六月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.jun) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="jul" label="七月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.jul) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="aug" label="八月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.aug) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="sep" label="九月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.sep) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="oct" label="十月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.oct) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="nov" label="十一月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.nov) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dec" label="十二月" width="85">
                    <template slot-scope="scope">
                      <div class="text-center">
                        {{ showReportData(scope.row.dec) }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>

              <b-tabs class="mt-2" v-model="tabIndex2" pills>
                <!-- Tab: Information -->
                <b-tab active>
                  <template #title>
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">租用報表</span>
                  </template>

                  <el-table
                    v-if="sumList"
                    :data="sumList"
                    class="mt-3 sum-table"
                    border
                    style="width: 100%"
                  >
                    <el-table-column fixed prop="" width="120">
                      <template slot-scope="scope">
                        <div class="text-left">
                          {{ sumLabel[scope.$index] }}
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column fixed prop="" width="50">
                      <template slot-scope="scope">
                        <div>{{ sumTotalColumn[scope.$index] }}</div>
                      </template>
                    </el-table-column>

                    <el-table-column width="120"> </el-table-column>
                    <el-table-column width="140"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="85"> </el-table-column>
                    <el-table-column width="120"> </el-table-column>
                    <el-table-column prop="1" label="一月" width="85">
                    </el-table-column>
                    <el-table-column prop="2" label="二月" width="85">
                    </el-table-column>
                    <el-table-column prop="3" label="三月" width="85">
                    </el-table-column>

                    <el-table-column prop="4" label="四月" width="85">
                    </el-table-column>
                    <el-table-column prop="5" label="五月" width="85">
                    </el-table-column>
                    <el-table-column prop="6" label="六月" width="85">
                    </el-table-column>
                    <el-table-column prop="7" label="七月" width="85">
                    </el-table-column>
                    <el-table-column prop="8" label="八月" width="85">
                    </el-table-column>
                    <el-table-column prop="9" label="九月" width="85">
                    </el-table-column>
                    <el-table-column prop="10" label="十月" width="85">
                    </el-table-column>
                    <el-table-column prop="11" label="十一月" width="85">
                    </el-table-column>
                    <el-table-column prop="12" label="十二月" width="85">
                    </el-table-column>
                  </el-table>
                </b-tab>

                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">圓餅圖</span>
                  </template>
                  <br />
                  <b-row
                    class="pie-chart-wrapper"
                    v-if="pieChartSeries && pieChartSeries.length"
                  >
                    <b-col
                      class="pie-chart-border"
                      v-for="(item, index) of pieChartSeries"
                      :key="`piechart ${index}`"
                    >
                      <div class="my-2">
                        <h4>{{ index + 1 }}月</h4>
                        <apexchart
                          type="pie"
                          width="360"
                          :options="pieChart.options"
                          :series="item"
                        ></apexchart>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </b-card>
      </b-tab>
      <area-warehouse-report :warehouseCategoryOptions="warehouseCategoryOptions" />
    </b-tabs>
  </div>
</template>
<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import axiosIns from "@/libs/axios.js";
import { ref, onUnmounted, onMounted, watch } from "@vue/composition-api";
import reportStoreModule from "../reportStoreModule";
import ModalRentForm from "@/views/rent/modal/modal-rent-form.vue";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import AreaWarehouseReport from './AreaWarehouseReport.vue'
Vue.component("downloadExcel", JsonExcel);

export default {
  components: {
    BTab,
    BTabs,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalRentForm,
    AreaWarehouseReport
  },
  methods: {
    fetchMiniStorage(id) {
      axiosIns
        .get("warehouse/mini_storage", { params: { id } })
        .then((response) => {
          this.mini_storage = null;
          this.mini_storage = response.data.mini_storage;
          setTimeout(() => {
            console.log("this.mini_storage", this.mini_storage);
            this.$bvModal.show("modal-rent-form");
          }, 500);
        })
        .catch((error) => {
          console.log({ error });
        });
    },
  },
  data() {
    return {
      tabIndex: 0,
      tabIndex2: 0,
      mini_storage: null,
    };
  },
  setup() {
    const filter_warehouse_id = ref(1);
    const filter_year = ref(null);
    const excel_report_list = ref(null);
    const reportFields = ref({
      倉號: "storage_number",
      客戶名稱: "customer",
      平均每尺租值: "average_price_per_area",
      送月份後平均租值: "average_discount_price_per_area",
      "定價/月": "monthly_price",
      "實收/月": "price",
      倉按金: "deposite",
      咭按金: "card_deposite",
      闊: "width",
      深: "depth",
      高: "height",
      面積: "area",
      一月: "jan",
      二月: "feb",
      三月: "mar",
      四月: "apr",
      五月: "may",
      六月: "jun",
      七月: "jul",
      八月: "aug",
      九月: "sep",
      十月: "oct",
      十一月: "nov",
      十二月: "dec",
    });

    const report_STORE_MODULE_NAME = "report";

    if (!store.hasModule(report_STORE_MODULE_NAME))
      store.registerModule(report_STORE_MODULE_NAME, reportStoreModule);

    onUnmounted(() => {
      if (store.hasModule(report_STORE_MODULE_NAME))
        store.unregisterModule(report_STORE_MODULE_NAME);
    });

    const convertedObject = (arr) => {
      var obj = arr.reduce((result, obj) => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        result[key] = value;
        return result;
      }, {});
      return obj;
    };

    const area_list = ref([]);
    // [
    //   "中西區",
    //   "灣仔",
    //   "東區",
    //   "南區",
    //   "深水埗",
    //   "油尖旺",
    //   "九龍城",
    //   "黃大仙",
    //   "觀塘",
    //   "沙田",
    //   "大埔",
    //   "北區",
    //   "元朗",
    //   "屯門",
    //   "西貢",
    //   "離島",
    //   "荃灣",
    //   "葵青",
    // ];

    const series = ref([
      // {
      //   name: "工作間",
      //   data: [
      //     44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22,
      //     43,
      //   ],
      // },
      // {
      //   name: "單車間",
      //   data: [
      //     13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27,
      //   ],
      // },
      // {
      //   name: "辦公室",
      //   data: [
      //     11, 17, 15, 15, 21, 14, 11, 17, 15, 15, 21, 14, 11, 17, 15, 15, 21,
      //     14,
      //   ],
      // },
    ]);

    const chartOptions = ref({
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          borderRadius: 2,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: area_list,
      },
      // yaxis: {
      //   labels: {
      //     formatter: function (value) {
      //       return value + "%";
      //     },
      //   },
      // },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    });

    const area_list2 = ref([]);
    const series2 = ref([]);
    const chartOptions2 = ref({
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          borderRadius: 2,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: area_list2,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    });

    const pieChart = ref({
      series: [],
      options: {
        chart: {
          width: 360,
          type: "pie",
        },
        colors: ["#42c8a8", "#92d050", "#fefe95"],
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
        },
        stroke: { show: false },
        labels: ["已租出", "自用", "未租出"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });

    const pieChartSeries = ref([]);

    const internalUseCount = ref(null);
    const latePayment = ref(null);
    const rentsCount = ref(null);
    const sumList = ref(null);
    const sumLabel = ref(null);
    const sumTotalColumn = ref(null);
    const reportList = ref(null);

    const showReportData = (data) => {
      if (data) {
        if (data.internal_rent) {
          return data.rent_type;
        }
        return data.amount;
      } else {
        return "";
      }
    };
    const target_month = ref(1);

    const is_init = ref(false);
    const fetchRentReports = () => {
      store
        .dispatch("report/fetchRentReports", {
          warehouse_id: filter_warehouse_id.value,
          year: filter_year.value,
        })
        .then((response) => {
          const {
            report,
            year,
            monthlyInternalUseCount,
            monthlyLatePayment,
            monthlyRentsCount,
          } = response.data;

          let t_month;
          reportList.value = report;

          internalUseCount.value = monthlyInternalUseCount;
          latePayment.value = monthlyLatePayment;
          rentsCount.value = monthlyRentsCount;
          const mountArray = Array.from(
            { length: 12 },
            (_, index) => index + 1
          );

          pieChartSeries.value = mountArray.map((ele) => [
            monthlyRentsCount[ele],
            monthlyInternalUseCount[ele],
            report.length -
              monthlyInternalUseCount[ele] -
              monthlyRentsCount[ele],
          ]);

          let monthlyTotalCount = mountArray.map((ele) => ({
            [ele]: report.length,
          }));
          monthlyTotalCount = convertedObject(monthlyTotalCount);

          let monthlyUnrentsCount = mountArray.map((ele) => ({
            [ele]:
              report.length -
              (monthlyInternalUseCount[ele]
                ? monthlyInternalUseCount[ele]
                : 0) -
              (monthlyRentsCount[ele] ? monthlyRentsCount[ele] : 0),
          }));
          monthlyUnrentsCount = convertedObject(monthlyUnrentsCount);

          let monthlyRentRateCount = mountArray.map((ele) => ({
            [ele]:
              Math.round(
                ((monthlyRentsCount[ele] ? monthlyRentsCount[ele] : 0) /
                  (report.length -
                    (monthlyInternalUseCount[ele]
                      ? monthlyInternalUseCount[ele]
                      : 0))) *
                  100 *
                  100
              ) /
                100 +
              "%",
          }));

          monthlyRentRateCount = convertedObject(monthlyRentRateCount);

          sumList.value = [];
          sumList.value = [
            monthlyTotalCount,
            monthlyInternalUseCount,
            monthlyRentsCount,
            monthlyUnrentsCount,
            monthlyRentRateCount,
            monthlyLatePayment,
          ];

          sumLabel.value = [
            "總數量",
            "自用",
            "已租出倉數",
            "未租出倉數",
            "租出%",
            "欠費",
          ];
          // 自用
          const internalUseTotal = report.filter(
            (ele) => ele.internal_rent
          ).length;
          // 已租出倉數
          const rentTotal = report.filter(
            (ele) => ele.on_sell == 0 && !ele.internal_rent
          ).length;
          // 未租出倉數
          const unRentTotal = report.length - internalUseTotal - rentTotal;
          // // 租出%
          // const rentRateTotal = report.filter(ele => ele.on_sell == 1 && !ele.internal_rent).length;
          // // 欠費
          // const latePaymentTotal = report.filter(ele => ele.on_sell == 1 && !ele.internal_rent).length;

          sumTotalColumn.value = [
            report.length,
            internalUseTotal,
            rentTotal,
            unRentTotal,
            "",
            "",
          ];

          // prepare excel data
          excel_report_list.value = [...report].map((ele) => {
            return { ...ele };
          });

          excel_report_list.value.forEach((ele) => {
            ele["customer"] = getCustomers(ele);
            ele["area"] = getArea(ele);
          });

          // add sum row to excel data list
          excel_report_list.value.push(
            getSummariesForArray(excel_report_list.value)
          );

          // add style for excel
          excelCellStyle();

          excel_report_list.value.push(
            {
              storage_number: "",
              customer: "",
              average_price_per_area: "",
              average_discount_price_per_area: "",
              monthly_price: "",
              price: "",
              deposite: "",
              card_deposite: "",
              width: "",
              depth: "",
              height: "",
              area: "",
              jan: "",
              feb: "",
              mar: "",
              apr: "",
              may: "",
              jun: "",
              jul: "",
              aug: "",
              sep: "",
              oct: "",
              nov: "",
              dec: "",
            },
            {
              storage_number: "",
              customer: "",
              average_price_per_area: "",
              average_discount_price_per_area: "",
              monthly_price: "",
              price: "",
              deposite: "",
              card_deposite: "",
              width: "",
              depth: "",
              height: "",
              area: "",
              jan: "一月",
              feb: "二月",
              mar: "三月",
              apr: "四月",
              may: "五月",
              jun: "六月",
              jul: "七月",
              aug: "八月",
              sep: "九月",
              oct: "十月",
              nov: "十一月",
              dec: "十二月",
            }
          );
          const monthNames = [
            "jan",
            "feb",
            "mar",
            "apr",
            "may",
            "jun",
            "jul",
            "aug",
            "sep",
            "oct",
            "nov",
            "dec",
          ];

          var month_sum_list = sumList.value.map((ele) => {
            const transformedData = {};
            for (const key in ele) {
              const monthIndex = parseInt(key) - 1;
              const monthName = monthNames[monthIndex];
              transformedData[monthName] = ele[key];
            }
            return transformedData;
          });

          for (var i = 0; i < 6; i++) {
            excel_report_list.value.push({
              storage_number: sumLabel.value[i],
              customer: sumTotalColumn.value[i],
              average_price_per_area: "",
              average_discount_price_per_area: "",
              monthly_price: "",
              price: "",
              deposite: "",
              card_deposite: "",
              width: "",
              depth: "",
              height: "",
              area: "",
              ...month_sum_list[i],
            });
          }
          console.log("sumList", sumList.value);

          is_init.value = true;
        })
        .catch((error) => {
          console.log({ error });
        });
    };
    // for excel sum
    const getSummariesForArray = (data) => {
      const sums = [];
      const columns = [
        "storage_number",
        "customer",
        "average_price_per_area",
        "average_discount_price_per_area",
        "monthly_price",
        "price",
        "deposite",
        "card_deposite",
        "width",
        "depth",
        "height",
        "area",
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合計";
          return;
        }

        if (index > 0 && index <= 3) {
          sums[index] = "";
          return;
        }

        let values;

        values = data.map((item) => {
          if (item[column]) {
            if (index > 3 && index < 8) {
              return isNaN(Number(item[column])) ? 0 : Number(item[column]);
            } else {
              return isNaN(Number(item[column].amount))
                ? 0
                : Math.round(Number(item[column].amount));
            }
          } else {
            return 0;
          }
        });

        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!Number.isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0)}`;
        } else {
          sums[index] = "N/A";
        }
      });

      for (var i = 8; i <= 11; i++) {
        sums[i] = "";
      }

      const sumArrayObjects = {};
      columns.forEach((key, i) => {
        sumArrayObjects[key] = sums[i];
      });

      return sumArrayObjects;
    };

    // for excel
    const excelCellStyle = () => {
      const columns = Object.keys(reportFields.value);
      const field = {
        倉號: "storage_number",
        客戶名稱: "customer",
        平均每尺租值: "average_price_per_area",
        送月份後平均租值: "average_discount_price_per_area",
        "定價/月": "monthly_price",
        "實收/月": "price",
        倉按金: "deposite",
        咭按金: "card_deposite",
        闊: "width",
        深: "depth",
        高: "height",
        面積: "area",
        一月: "jan",
        二月: "feb",
        三月: "mar",
        四月: "apr",
        五月: "may",
        六月: "jun",
        七月: "jul",
        八月: "aug",
        九月: "sep",
        十月: "oct",
        十一月: "nov",
        十二月: "dec",
      };
      const forStyle = (bgColor) => {
        return `text-align: center; background-color: ${bgColor}; border: 1px solid #EBEEF5; border-collapse: collapse`;
      };

      columns.forEach((item) => {
        reportFields.value[item] = {
          callback: (row) => {
            if (row["storage_number"] == "總數量") {
              return `<table><tr><td style="${forStyle("#ffffff")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            } else if (row["storage_number"] == "自用") {
              return `<table><tr><td style="${forStyle("#92d050")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            } else if (row["storage_number"] == "已租出倉數") {
              return `<table><tr><td style="${forStyle("#fefd01")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            } else if (row["storage_number"] == "未租出倉數") {
              return `<table><tr><td style="${forStyle("#ffffff")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            } else if (row["storage_number"] == "租出%") {
              return `<table><tr><td style="${forStyle("#deeaf6")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            } else if (row["storage_number"] == "欠費") {
              return `<table><tr><td style="${forStyle("#f82500")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            } else if (row["storage_number"] == "") {
              return `<table><tr><td style="${forStyle("#ffffff")}"><b>${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</b></td></tr></table>`;
            }

            if (field[item] == "storage_number") {
              if (row[field[item]] == "合計") {
                return `<table><tr><td style="${forStyle("#d4e2fd")}">${
                  row[field[item]]
                }</td></tr></table>`;
              } else {
                if (row["internal_rent"]) {
                  return `<table><tr><td style="${forStyle("#92d050")}">${
                    row[field[item]]
                  }</td></tr></table>`;
                } else if (row["on_sell"]) {
                  return `<table><tr><td style="${forStyle("#ffff95")}">${
                    row[field[item]]
                  }</td></tr></table>`;
                } else if (!row["on_sell"]) {
                  return `<table><tr><td style="${forStyle("#42c8a8")}">${
                    row[field[item]]
                  }</td></tr></table>`;
                }
              }
            }
            if (row[field[item]] && row[field[item]].is_late) {
              return `<table><tr><td style="${forStyle("#f82500")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            }

            if (row[field[item]] && row[field[item]].terminate_bit) {
              return `<table><tr><td style="${forStyle("#32b0f0")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            }

            if (row[field[item]] && row[field[item]].end_bit) {
              return `<table><tr><td style="${forStyle("#fbc000")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            }

            if (row[field[item]] && row[field[item]].internal_rent) {
              return `<table><tr><td style="${forStyle("#92d050")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            }

            if (row[field[item]] && row[field[item]].start_bit) {
              return `<table><tr><td style="${forStyle("#fefd01")}">${
                typeof row[field[item]] == "object"
                  ? showReportData(row[field[item]])
                  : row[field[item]]
              }</td></tr></table>`;
            }

            return `<table><tr><td style="${forStyle("#ffffff")}">${
              typeof row[field[item]] == "object"
                ? showReportData(row[field[item]])
                : row[field[item]]
            }</td></tr></table>`;
          },
        };
      });
    };

    watch([filter_year, filter_warehouse_id], () => {
      if (filter_year.value && filter_warehouse_id.value) {
        fetchRentReports();
      }
    });

    const getCustomers = (row) => {
      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];
      const currMonth = months[new Date().getMonth()];
      return row[currMonth] ? row[currMonth].customer : "";
    };

    const getArea = (row) => {
      return (row.depth * row.width).toFixed(2);
    };

    const cellStyle = ({ row, column }) => {
      if (column.property) {
        if (column.property == "storage_number") {
          if (row["internal_rent"]) {
            return "background-color: #92d050";
          } else if (row["on_sell"]) {
            return "background-color: #ffff95";
          } else if (!row["on_sell"]) {
            return "background-color: #42c8a8";
          }
        }
        var data = row[column.property];
        if (data) {
          if (data.is_late) {
            return "background-color:  #f82500";
          }
          if (data.terminate_bit) {
            return "background-color: #32b0f0";
          }
          if (data.end_bit) {
            return "background-color: #fbc000";
          }
          if (data.internal_rent) {
            return "background-color: #92d050";
          }
          if (data.start_bit) {
            return "background-color: #fefd01";
          }
        }
      }
      return "background-color: white";
    };

    const yearOptions = ref([]);
    const warehouseOptions = ref([]);
    const warehouseCategoryOptions = ref([]);

    const fetchOptions = () => {
      var now_year = Number(new Date().getFullYear());
      filter_year.value = now_year.toString();

      yearOptions.value = [];
      for (var i = now_year - 4; i <= now_year; i++) {
        yearOptions.value.push({ id: i.toString(), name: i.toString() });
      }

      store
        .dispatch("app/fetchOptions", {
          warehouse: true,
          report_warehouse_category:true,
        })
        .then((response) => {
          warehouseOptions.value = response.data.warehouses;
          warehouseCategoryOptions.value = response.data.report_warehouse_categories;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合計";
          return;
        }
        if (index > 0 && index <= 3) {
          sums[index] = "";
          return;
        }

        let values;
        if (index > 3 && index < 8) {
          values = data.map((item) => Number(item[column.property]));
        } else {
          values = data.map((item) => {
            if (item[column.property]) {
              return isNaN(item[column.property].amount)
                ? 0
                : Number(item[column.property].amount);
            } else {
              return 0;
            }
          });
        }

        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!Number.isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0)}`;
        } else {
          sums[index] = "N/A";
        }
      });

      for (var i = 8; i <= 11; i++) {
        sums[i] = "";
      }
      return sums;
    };

    const apexChart = ref(null);
    const getAreaData = () => {
      axiosIns
        .get("/statistic/districts")
        .then((res) => {
          area_list.value = res.data.statistics.area_list;
          series.value = res.data.statistics.series;
          apexChart.value.refresh();
          apexChart.value.updateOptions({ series: res.data.statistics.series });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const apexChart2 = ref(null);
    const getAreaData2 = () => {
      axiosIns
        .get("/statistic/warehouses")
        .then((res) => {
          area_list2.value = res.data.statistics.area_list;
          series2.value = res.data.statistics.series;
          apexChart2.value.refresh();
          apexChart2.value.updateOptions({
            series: res.data.statistics.series,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getWarehouseText = (warehouseId) => {
      const warehouse = warehouseOptions.value.find(ele => ele.id === warehouseId);
      return warehouse ? warehouse.name : '';
    };

    const customerDistChart = ref(null);
    const customerDistSeries = ref([]);
  

    

    onMounted(() => {
      fetchOptions();
      //getAreaData();
      //getAreaData2();
      fetchRentReports();
    });

    return {
      reportList,
      filter_warehouse_id,
      filter_year,
      yearOptions,
      warehouseOptions,
      showReportData,
      cellStyle,
      getSummaries,
      internalUseCount,
      latePayment,
      rentsCount,
      sumList,
      sumLabel,
      sumTotalColumn,
      excel_report_list,
      reportFields,
      fetchRentReports,
      is_init,
      series,
      series2,
      chartOptions,
      chartOptions2,
      getCustomers,
      getArea,
      pieChart,
      target_month,
      pieChartSeries,
      apexChart,
      apexChart2,
      getWarehouseText,
      customerDistChart,
      customerDistSeries,
      warehouseCategoryOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.el-table .el-table__cell {
  text-align: center;
  padding: 5px 0px;
}
.el-table__fixed-footer-wrapper {
  background-color: #d4e2fd;
  .el-table__footer {
    background-color: #d4e2fd;
    tr {
      background-color: #d4e2fd;
      td {
        background-color: #d4e2fd;
      }
    }
  }
  // .cell {
  //   background-color: #d4e2fd;
  // }
}
.sum-table {
  tbody {
    .el-table__row {
      color: #000;
      &:nth-child(2) {
        background-color: #92d050;
      }
      &:nth-child(3) {
        background-color: #fefd01;
      }
      &:nth-child(5) {
        background-color: #deeaf6;
      }
      &:nth-child(6) {
        background-color: #f82500;
      }
    }
  }
}

// .table-wrapper {
//   max-height: 400px; /* Set a max height for the table wrapper */
//   overflow-y: auto; /* Enable vertical scrolling */
// }
// .el-table__header-wrapper {
//   position: sticky;
//   top: 0;
//   background-color: white;
//   z-index: 2;
// }
// .table-wrapper .el-table__header-wrapper th {
//   position: sticky;
//   top: 0;
// }

// .table-wrapper .el-table__body-wrapper {
//   overflow-x: hidden;
// }

.pie-chart-wrapper,
.pie-chart-wrapper > .pie-chart-border {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
}
.pie-chart-wrapper {
  margin: 10px;
  border-width: 2px 0 0 2px;
}
.pie-chart-wrapper > .pie-chart-border {
  border-width: 0 2px 2px 0;
}

.pie-chart-wrapper .apexcharts-tooltip {
  color: #000;
  text-shadow: s 1px 1px 2px #fff;
  border: 0px;
  border-radius: 0px;
}
.apexcharts-canvas {
  margin: auto;
}
</style>

