export default [
  // (不在項目欄)
  {
    path: "/customers/multi/create",
    name: "customers-multi-create",
    component: () => import("@/views/customer/new/customer-multi/CustomerMultiCreate.vue"),
    meta:{
        action:"create",
        resource:"customers"
    }
},
  // (不在項目欄)
  {
    path: "/booking-stage/list",
    name: "booking-stages",
    component: () =>
      import("@/views/booking-stage/booking-stages-list/BookingStagesList.vue"),
    meta: {
      resource: "booking-stages",
      action: "read",
      text: "租貸階段",
    },
  },
  // (不在項目欄)
  {
    path: "/booking/list",
    name: "bookings-list",
    component: () => import("@/views/booking/booking-list/BookingsList.vue"),
    meta: {
      resource: "booking",
      action: "read",
      text: "租貸記錄",
    },
  },
  // (不在項目欄)
  {
    path: "/contract/list",
    name: "contract-list",
    component: () => import("@/views/contract/contract-list/ContractsList.vue"),
    meta: {
      action: "read",
      resource: "contract",
      text: "合約記錄",
    },
  },
  // (不在項目欄)
  {
    path: "/contract/create/:booking_id",
    name: "contract-create",
    component: () => import("@/views/contract/contract-edit/contractEdit.vue"),
    meta: {
      resource: "contract",
      action: "read",
      text: "合約記錄",
    },
  },
  // (不在項目欄)
  {
    path: "/settings/aboutsettings/edit",
    name: "aboutsettings-edit",
    component: () =>
      import("@/views/setting/aboutsetting/settings-edit/SettingsEdit.vue"),
    meta: {
      resource: "settings",
      action: "read",
    },
  },
  // (不在項目欄)
  {
    path: "/settings/mainsettings/edit",
    name: "mainsettings-edit",
    component: () =>
      import("@/views/setting/mainsetting/settings-edit/SettingsEdit.vue"),
    meta: {
      resource: "settings",
      action: "read",
    },
  },
  // (不在項目欄)
  {
    path: "/customers/list",
    name: "customers",
    component: () =>
      import("@/views/customer/customers-list/CustomersList.vue"),
    meta: {
      action: "read",
      resource: "customers",
      text: "顧客記錄",
    },
  },
  // (不在項目欄)
  {
    path: "/customers/edit/:id",
    name: "customers-edit",
    component: () =>
      import("@/views/customer/customers-edit/CustomersEdit.vue"),
    meta: {
      action: "update",
      resource: "customers",
      text: "顧客記錄",
    },
  },
  // (不在項目欄)
  {
    path: "/customers/create",
    name: "customers-create",
    component: () =>
      import("@/views/customer/customers-edit/CustomersEdit.vue"),
    meta: {
      action: "create",
      resource: "customers",
      text: "客戶記錄",
    },
  },
  // 價目表管理(不在項目欄)
  {
    path: "/price-list",
    name: "price-list",
    component: () => import("@/views/price/price-list/priceList.vue"),
    meta: {
      text: "價目表管理",
    },
  },

  {
    path: "/referral-coupon/list",
    name: "referral-coupon-list",
    component: () =>
      import("@/views/referral/referrals-list/referralsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      // resource: "referral-coupons",
      text: "優惠",
    },
  },
  {
    path: "/coupons/list",
    name: "coupons-list",
    component: () =>
      import("@/views/discount/discounts-list/DiscountsList.vue"),
    meta: {
      action: "read",
      resource: "coupons",
      text: "優惠券記錄",
    },
  },
  {
    path: "/appointments/list",
    name: "appointments-list",
    component: () =>
      import("@/views/appointment/appointments-list/AppointmentsList.vue"),
    meta: {
      action: "read",
      resource: "appointments",
      text: "預約記錄",
    },
  },
  {
    path: "/warehouses/list",
    name: "warehouses-list",
    component: () =>
      import("@/views/warehouse/warehouse/warehouses-list/WarehousesList.vue"),
    meta: {
      action: "read",
      resource: "warehouses",
      text: "倉庫記錄",
    },
  },
  {
    path: "/warehouses/edit/:id",
    name: "warehouses-edit",
    component: () =>
      import("@/views/warehouse/warehouse/warehouses-edit/WarehousesEdit.vue"),
    meta: {
      action: "update",
      resource: "warehouses",
      text: "倉庫編輯",
    },
  },
  {
    path: "/report/list",
    name: "reports-list",
    component: () => import("@/views/report/report-list/ReportsList.vue"),
    meta: {
      resource: "reports",
      action: "read",
      text: "報表",
    },
  },
  {
    path: "/settings/settings/edit",
    name: "settings-edit",
    component: () =>
      import("@/views/setting/setting/settings-edit/SettingsEdit.vue"),
    meta: {
      resource: "setting-infos",
      action: "read",
      text: "系統資訊",
    },
  },

  {
    path: "/discounts/list",
    name: "discounts-list",
    component: () =>
      import("@/views/pages/discount/discounts-list/DiscountsList.vue"),
    meta: {
      action: "read",
      resource: "setting-main-discounts",
      text: "合作商戶優惠",
    },
  },
  {
    path: "/users/list",
    name: "users-list",
    component: () => import("@/views/user/users-list/UsersList.vue"),
    meta: {
      action: "read",
      resource: "setting-users",
      text: "用戶記錄",
    },
  },
  {
    path: "/users/view/:id",
    name: "users-view",
    component: () => import("@/views/user/users-view/UsersView.vue"),
    meta: {
      action: "read",
      resource: "setting-users",
      text: "用戶記錄",
    },
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/user/users-edit/UsersEdit.vue"),
    meta: {
      action: "update",
      resource: "setting-users",
      text: "用戶記錄",
    },
  },
  {
    path: "/users/create",
    name: "users-create",
    component: () => import("@/views/user/users-edit/UsersEdit.vue"),
    meta: {
      action: "create",
      resource: "setting-users",
      text: "用戶記錄",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/main/banner/list",
    name: "main-banners-list",
    component: () =>
      import("@/views/pages/banner/banners-list/BannersList.vue"),
    meta: {
      action: "read",
      resource: "setting-main-banners",
      text: "主頁圖片",
    },
  },
  {
    path: "/news/banner/list",
    name: "news-banners-list",
    component: () =>
      import("@/views/pages/banner/banners-list/BannersList.vue"),
    meta: {
      action: "read",
      resource: "setting-main-banners",
      text: "新聞圖片",
    },
  },
  {
    path: "/about/banner/list",
    name: "about-banners-list",
    component: () =>
      import("@/views/pages/banner/banners-list/BannersList.vue"),
    meta: {
      action: "read",
      resource: "setting-main-banners",
      text: "關於我們圖片",
    },
  },
  {
    path: "/warehouse_categories/list",
    name: "warehouse-categories-list",
    component: () =>
      import("@/views/warehouse/category/categories-list/CategoriesList.vue"),
    meta: {
      action: "read",
      resource: "warehouse-categories",
    },
  },
  {
    path: "/video/list",
    name: "videos-list",
    component: () => import("@/views/pages/video/videos-list/VideosList.vue"),
    meta: {
      action: "read",
      resource: "setting-main-videos",
      text: "影片",
    },
  },
  {
    path: "/page/list",
    name: "pages-list",
    component: () => import("@/views/pages/page/pages-list/PagesList.vue"),
    meta: {
      action: "read",
      resource: "pages",
      text: "頁面",
    },
  },

  // 2023/10/18
  // 租務管理
  {
    path: "/rents",
    name: "rents",
    component: () => import("@/views/rent/WarehousesFloorplan.vue"),
    meta: {
      action: "read",
      resource: "rents",
      text: "租務管理",
    },
  },
  // new contract
  {
    path: "/contract/new/list",
    name: "contract-new-list",
    component: () =>
      import("@/views/contract/new/contract-list/ContractsList.vue"),
    meta: {
      action: "read",
      resource: "contracts",
      text: "合約記錄",
    },
  },
  {
    path: "/contract/new/template/list",
    name: "contract-template-list",
    component: () =>
      import("@/views/contract/new/contract-list/ContractTemplatesList.vue"),
    meta: {
      action: "read",
      resource: "contracts",
      text: "合約模版",
    },
  },
  // new customer
  {
    path: "/customer/new/list",
    name: "customer-new-list",
    component: () =>
      import("@/views/customer/new/customer-list/CustomersList.vue"),
    meta: {
      action: "read",
      resource: "customers",
      text: "客戶記錄",
    },
  },
  // new 收據記錄
  {
    path: "/payments/list",
    name: "payments",
    component: () => import("@/views/payment/payments-list/PaymentsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      text: "收據記錄",
    },
  },
  // new 收款管理
  {
    path: "/payment/schedule/list",
    name: "payment-schedule-list",
    component: () =>
      import("@/views/payment/payment-schedule-list/PaymentSchedulesList.vue"),
    meta: {
      action: "read",
      resource: "payment-schedules",
      text: "收款管理",
    },
  },
  // 發票
  {
    path: "/invoice-list",
    name: "invoice-list",
    component: () => import("@/views/invoice/invoice-list/invoiceList.vue"),
    meta: {
      action: "read",
      resource: "invoices",
      text: "發票",
    },
  },
  // 待支付發票列表
  {
    path: "/invoice-pending-list",
    name: "invoice-pending-list",
    component: () => import("@/views/invoice/invoice-list/invoiceList.vue"),
    meta: {
      action: "read",
      resource: "invoices",
      text: "待支付發票",
    },
  },
  // 事件日歷
  {
    path: "/task-list",
    name: "task-list",
    component: () => import("@/views/task/tasks-list/tasksList.vue"),
    meta: {
      action: "read",
      resource: "tasks",
      text: "事件日歷",
    },
  },
  {
    path: "/email-list",
    name: "emails-list",
    component: () => import("@/views/email/emails-list/EmailsList.vue"),
    meta: {
      action: "read",
      resource: "emails",
      text: "電郵管理系統",
    },
  },
  {
    path: "/email-template-list",
    name: "email-template-list",
    component: () => import("@/views/email/emails-list/EmailsTemplateList.vue"),
    meta: {
      action: "read",
      resource: "email-templates",
      text: "電郵模版系統",
    },
  },
  // setting customer-type
  {
    path: "/customer-type-list",
    name: "customer-type-list",
    component: () =>
      import(
        "@/views/setting/customer-type/customer-type-list/CustomerTypesList.vue"
      ),
    meta: {
      action: "read",
      resource: "setting-customer-types",
      text: "客戶類型",
    },
  },
  // setting rent-type(不在項目欄)
  {
    path: "/rent-type-list",
    name: "rent-type-list",
    component: () =>
      import("@/views/setting/rent-type/rent-type-list/RentTypesList.vue"),
    meta: {
      text: "租用類型",
    },
  },
  {
    path: "/sign-status-list",
    name: "sign-status-list",
    component: () =>
      import("@/views/setting/sign-status/sign-status-list/SignStatusList.vue"),
    meta: {
      action: "read",
      resource: "setting-sign-statuses",
      text: "簽約狀態",
    },
  },
  {
    path: "/payment-type-list",
    name: "payment-type-list",
    component: () =>
      import("@/views/setting/receive-method/payment-type-list/PaymentTypeList.vue"),
    meta: {
      action: "read",
      resource: "setting-payment-types",
      text: "付款類型",
    },
  },
  {
    path: "/referral-discount-option/list",
    name: "referral-discount-option-list",
    component: () =>
      import("@/views/setting/referral/referrals-list/referralsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      // resource: "referral_discount_option",
      text: "優惠設定",
    },
  },

  // fixed page
  {
    path: "/",
    name: "home",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      text: "儀表板",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/pages/not-authorized",
    name: "not-authorized",
    // ! Update import path
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      action: "read",
      resource: "Auth",
    },
  },

  {
    path: "/roles/list",
    name: "roles-list",
    component: () => import("@/views/role/roles-list/RolesList.vue"),
    meta: {
      action: "read",
      resource: "setting-roles",
    },
  },
  {
    path: "/roles/edit/:id",
    name: "roles-edit",
    component: () => import("@/views/role/roles-edit/RolesEdit.vue"),
    meta: {
      action: "update",
      resource: "setting-roles",
    },
  },

  {
    path: "*",
    redirect: "error-404",
  },
];
