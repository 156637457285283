import { render, staticRenderFns } from "./tasksList.vue?vue&type=template&id=4030a123&scoped=true"
import script from "./tasksList.vue?vue&type=script&lang=js"
export * from "./tasksList.vue?vue&type=script&lang=js"
import style1 from "./tasksList.vue?vue&type=style&index=1&id=4030a123&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4030a123",
  null
  
)

export default component.exports