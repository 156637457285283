<template>
  <b-modal
    id="modal-SMS"
    cancel-variant="outline-secondary"
    centered
    size="sm"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
  >
    <!-- @hide="resetModal" -->
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">短訊</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="sms-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="請輸入電話號碼（不需輸入地區號碼，例：不需輸入+852）"
                label-for="phone"
                class="required"
              >
                <validation-provider
                  #default="validationContext"
                  name="電話"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="newSMSData.phone"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="訊息" label-for="message" class="required">
                <validation-provider
                  #default="validationContext"
                  name="訊息"
                  rules="required"
                >
                  <b-form-textarea
                    rows="3"
                    id="message"
                    v-model="newSMSData.message"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    SMSData: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-SMS");
    },
    // resetModal() {
    //   this.newSMSData = {};
    // },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          store
            .dispatch("contract/message", this.newSMSData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newSMSData = ref({ id: 0 });

    const resetSMSData = () => {
      newSMSData.value = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetSMSData);

    watch(
      () => props.SMSData,
      (newVal) => {
        newSMSData.value = newVal;
      }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newSMSData,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sms-form {
  .form-group {
    width: 100%;
  }
}
</style>
  