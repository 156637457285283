<template>
  <div>
    <modal-edit-contract-template
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      :templateData="templateData"
      @refetch-data="refetchData"
    />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                class="d-inline-block common-rounded"
                placeholder="搜尋模版名稱"
              />
            </div>
          </div>
          <!-- <div class="d-flex align-items-center">
            <span class="mr-1">狀態</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="d-inline-block common-rounded"
            />
          </div> -->
        </div>
        <div class="d-flex align-items-center">
          <b-link class="mr-1"
            v-b-popover.hover.top="'返回合約頁面'"
            :to="{
              name: 'contract-new-list',
            }">
            <font-awesome-icon icon="fa-solid fa-rectangle-list" />
          </b-link >
          <a
          v-if="(ability.can('create', 'contract-templates'))"
            class=""
            href="javascript:void(0)"
            v-b-popover.hover.top="'新增合約模版'"
            @click="$bvModal.show('modal-edit-contract-template')"
          >
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <b-card no-body class=" mb-0">
      <b-table
        ref="refContractTemplateListTable"
        class="position-relative"
        :items="fetchContractTemplates"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <template #cell(created_at)="data">
          {{ getCorrectDateTime(data.item.created_at, true) }}
        </template>

        <template #cell(action)="data">
          <a v-if="(ability.can('update', 'contract-templates'))"
            href="javascript:void(0)"
            @click="handleEdit(data.item)"
          >
            <feather-icon class="color1" icon="Edit2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a v-if="(ability.can('delete', 'contract-templates'))" href="javascript:void(0)" @click="confirmDelete(data.item.id)">
            <feather-icon class="color1" icon="Trash2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a href="javascript:void(0)" @click="downloadTemplate(data.item.id)">
            <feather-icon class="color1" icon="DownloadCloudIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import contractStoreModule from "../../contractStoreModule";
import useContractTemplatesList from "./useContractTemplatesList";
import ModalEditContractTemplate from "../modal/ModalEditContractTemplate.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalEditContractTemplate,
  },
  directives: {
    "b-popover": VBPopover,
  },
  methods: {
    handleEdit(item) {
      this.templateData = item;
      this.isEdit = true;
      this.$bvModal.show("modal-edit-contract-template");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.templateData = {id:0, is_active:1}
    },
    downloadTemplate(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "下載",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("contract-template/fetchContractTemplate", { id })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'template.docx'); // or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmDelete(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("contract-template/deleteContractTemplate", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      templateData: {},
      isEdit: false,
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "contract-template";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const {
        fetchContractTemplates,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refContractTemplateListTable,
        showMessage,
        templateList,
        ability
    } = useContractTemplatesList();

    return {
        fetchContractTemplates,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refContractTemplateListTable,
        showMessage,
        templateList,
        ability
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

