<template>
  <b-modal
    id="modal-terminate-form"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">停租</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>

    <b-overlay
      v-if="newTerminateData"
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="payment-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">合約編號</b>
                <span>{{ newTerminateData.contract_id }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">客戶姓名</b>
                <b-form-input
                  id=""
                  v-model="newTerminateData.name"
                  placeholder="客戶姓名"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">終結日期</b>
                <validation-provider
                  #default="validationContext"
                  :name="`終結日期`"
                  rules="required"
                >
                  <flat-pickr
                    v-model="newTerminateData.termination_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">備註</b>
                <b-form-input
                  id=""
                  v-model="newTerminateData.remark"
                  placeholder="備註"
                />
              </div>
            </b-col>

            <b-col cols="12">
              <div class="">
                <div class="my-2 d-flex justify-content-center">
                  <h4 class="colorRed">退金資料</h4>
                </div>
                <b-row
                  v-for="(
                    termination_item, index
                  ) in newTerminateData.termination_items"
                  :key="`termination_item_${index}`"
                  class="m-0"
                >
                  <b-col
                    cols="12"
                    class="d-flex mb-1 bgColor3 radius-round border-1"
                  >
                    <b-row class="flex-grow-1 p-2">
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12" xl="4">
                            <div class="info-item">
                              <b class="info-item-label">項目名稱</b>

                              <validation-provider
                                #default="validationContext"
                                :vid="`termination_item_name${index + 1}`"
                                :name="`項目名稱`"
                                rules="required"
                              >
                                <b-form-input
                                  id="item_name"
                                  v-model="termination_item.item_name"
                                  placeholder="項目名稱"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </div>
                          </b-col>
                          <b-col cols="12" xl="9">
                            <div class="info-item">
                              <b class="info-item-label">項目數量</b>

                              <validation-provider
                                #default="validationContext"
                                :vid="`termination_item_quantity${index + 1}`"
                                :name="`項目數量`"
                                rules="required"
                              >
                                <b-form-input
                                  type="number"
                                  :min="1"
                                  v-model="termination_item.quantity"
                                  placeholder="項目數量"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                              <span class="mx-2">X</span>
                              <b class="info-item-label">項目單價</b>

                              <validation-provider
                                #default="validationContext"
                                :vid="`termination_item_price${index + 1}`"
                                :name="`項目單價`"
                                rules="required"
                              >
                                <b-form-input
                                  type="number"
                                  v-model="termination_item.price"
                                  placeholder="項目單價"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                              <span class="mx-2">=</span>
                              <b class="info-item-label">總額</b>
                              <b-form-input
                                type="number"
                                disabled
                                :value="
                                  calItemAmount(
                                    termination_item.quantity,
                                    termination_item.price
                                  )
                                "
                                placeholder="項目金額"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div
                      class="d-flex flex-column justify-content-between py-50 px-25"
                    >
                      <feather-icon
                        size="16"
                        icon="PlusIcon"
                        class="cursor-pointer text-success"
                        @click="addItem"
                      />
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer text-danger"
                        @click="removeItem(index)"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <hr />
          <h5 class="mt-2">租用週期</h5>
          <b-table
            v-if="rent_schedule_list"
            ref="refRentScheduleListTable"
            class="position-relative border"
            :items="rent_schedule_list"
            responsive
            :fields="tableColumnsRentSchedule"
            primary-key="id"
            :sort-by.sync="sortByRentSchedule"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescRentSchedule"
            :tbody-tr-class="rowRentClass"
          >
            <!-- @row-clicked="onRentRowClicked" -->
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item.id"
                v-model="newTerminateData.deleteRentScheduleRecordIds"
              ></b-form-checkbox>
            </template>

            <template #cell(start_date)="data">
              <validation-provider
                #default="validationContext"
                :vid="`rent_schedule_start_date${data.index + 1}`"
                :name="`開始日期`"
                rules="required"
              >
                <flat-pickr
                  v-model="data.item.start_date"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(end_date)="data">
              <validation-provider
                #default="validationContext"
                :vid="`rent_schedule_end_date${data.index + 1}`"
                :name="`完結日期`"
                rules="required"
              >
                <flat-pickr
                  v-model="data.item.end_date"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(amount)="data">
              <validation-provider
                #default="validationContext"
                :vid="`rent_schedule_amount${data.index + 1}`"
                :name="`金額`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.amount"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(start_bit)="data">
              <b-form-checkbox
                :checked="data.item.start_bit"
                name="start_bit"
                switch
                inline
                v-model="data.item.start_bit"
                value="1"
                style="margin-top: 5px"
              >
                {{ data.item.start_bit ? "是" : "否" }}
              </b-form-checkbox>
            </template>
            <template #cell(end_bit)="data">
              <b-form-checkbox
                :checked="data.item.end_bit"
                name="end_bit"
                switch
                inline
                v-model="data.item.end_bit"
                value="1"
                style="margin-top: 5px"
              >
                {{ data.item.end_bit ? "是" : "否" }}
              </b-form-checkbox>
            </template>
            <template #cell(terminate_bit)="data">
              <b-form-checkbox
                :checked="data.item.terminate_bit"
                name="terminate_bit"
                switch
                inline
                v-model="data.item.terminate_bit"
                value="1"
                style="margin-top: 5px"
              >
                {{ data.item.terminate_bit ? "是" : "否" }}
              </b-form-checkbox>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaRentSchedule.from }} to
                  {{ dataMetaRentSchedule.to }} of
                  {{ dataMetaRentSchedule.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageRentSchedule"
                  :total-rows="totalRowRentSchedule"
                  :per-page="perPageRentSchedule"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>

          <hr />
          <h5 class="mt-2">付款日期</h5>
          <b-table
            v-if="payment_schedules_list"
            ref="refPaymentScheduleListTable"
            class="position-relative border"
            :items="payment_schedules_list"
            responsive
            :fields="tableColumnsPaymentSchedule"
            primary-key="id"
            :sort-by.sync="sortByPaymentSchedule"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescPaymentSchedule"
            :tbody-tr-class="rowPaymentClass"
          >
            <!-- @row-clicked="onPaymentRowClicked" -->
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item.id"
                v-model="newTerminateData.deletePaymentScheduleIds"
              ></b-form-checkbox>
            </template>

            <template #cell(pay_date)="data">
              <validation-provider
                #default="validationContext"
                :vid="`payment_schedule_pay_date${data.index + 1}`"
                :name="`付款日期`"
                rules="required"
              >
                <flat-pickr
                  v-model="data.item.pay_date"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(payment_cycle)="data">
              <validation-provider
                #default="validationContext"
                :vid="`payment_schedule_payment_cycle${data.index + 1}`"
                :name="`付款週期`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.payment_cycle"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(standard_price)="data">
              <validation-provider
                #default="validationContext"
                :vid="`payment_schedule_standard_price${data.index + 1}`"
                :name="`標準租金`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.standard_price"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(price)="data">
              <validation-provider
                #default="validationContext"
                :vid="`payment_schedule_price${data.index + 1}`"
                :name="`租金`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.price"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>
            <template #cell(deposite)="data">
              <validation-provider
                #default="validationContext"
                :vid="`payment_schedule_deposite${data.index + 1}`"
                :name="`按金`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.deposite"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>
            <template #cell(card_deposite)="data">
              <validation-provider
                #default="validationContext"
                :vid="`payment_schedule_card_deposite${data.index + 1}`"
                :name="`智能卡按金`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.card_deposite"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>

            <template #cell(amount)="data">
              <validation-provider
                #default="validationContext"
                :vid="`rent_schedule_amount${data.index + 1}`"
                :name="`總值`"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="data.item.amount"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaPaymentSchedule.from }} to
                  {{ dataMetaPaymentSchedule.to }} of
                  {{ dataMetaPaymentSchedule.of }} entries</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePaymentSchedule"
                  :total-rows="totalRowPaymentSchedule"
                  :per-page="perPagePaymentSchedule"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 mt-2 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import {
  BImg,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BForm,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormInvalidFeedback,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios.js";
import {
  ref,
  computed,
  watch,
  onUnmounted,
  onMounted,
} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInvalidFeedback,
    BOverlay,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    terminateData: {},
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-terminate-form");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          axiosIns
            .post("/contract/terminate", this.newTerminateData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.hide();
              this.$bvModal.hide("modal-rent-form");
              this.$emit("refetch-Data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addItem() {
      this.newTerminateData.termination_items.push(
        JSON.parse(
          JSON.stringify({
            item_name: "",
            quantity: "",
            price: "",
            remark: "",
          })
        )
      );
    },
    removeItem(index) {
      // check number of record
      let record =
        this.newTerminateData &&
        this.newTerminateData.termination_items &&
        this.newTerminateData.termination_items.length;
      if (record <= 1) {
        this.makeToast("danger", "Warning", "請至少留有一個表單。");
      } else {
        this.newTerminateData.termination_items.splice(index, 1);
      }
    },
    rowRentClass(item, type) {
      if (!item) return;
      const id = item.id;
      const ids = this.newTerminateData.deleteRentScheduleRecordIds;
      return ids.indexOf(id) > -1 ? "deleted" : "";
    },
    rowPaymentClass(item, type) {
      if (!item) return;
      const id = item.id;
      const ids = this.newTerminateData.deletePaymentScheduleIds;
      return ids.indexOf(id) > -1 ? "deleted" : "";
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const newTerminateData = ref(null);

    const resetTerminateData = () => {
      newTerminateData.value = {};
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetTerminateData);

    const rent_schedule_list = ref([]);
    const refRentScheduleListTable = ref(null);
    const tableColumnsRentSchedule = [
      { key: "checkbox", label: "", stickyColumn: true },
      { key: "start_date", label: "開始日期", sortable: true },
      { key: "end_date", label: "結束日期" },
      { key: "amount", label: "總值", sortable: true },
      { key: "start_bit", label: "新租", sortable: true },
      { key: "end_bit", label: "完租", sortable: true },
      { key: "terminate_bit", label: "退租", sortable: true },
    ];

    const perPageRentSchedule = ref(10);
    const totalRowRentSchedule = ref(0);
    const currentPageRentSchedule = ref(1);
    const perPageOptionsRentSchedule = [10, 25, 50, 100];
    const sortByRentSchedule = ref("id");
    const isSortDirDescRentSchedule = ref(false);

    const dataMetaRentSchedule = computed(() => {
      const localItemsCount = refRentScheduleListTable.value
        ? refRentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPageRentSchedule.value * (currentPageRentSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPageRentSchedule.value * (currentPageRentSchedule.value - 1) +
          localItemsCount,
        of: totalRowRentSchedule.value,
      };
    });

    const payment_schedules_list = ref([]);
    const refPaymentScheduleListTable = ref(null);
    const tableColumnsPaymentSchedule = [
      { key: "checkbox", label: "", stickyColumn: true },
      { key: "pay_date", label: "付款日期", sortable: true },
      { key: "payment_cycle", label: "付款週期", sortable: true },
      { key: "standard_price", label: "標準租金", sortable: true },
      { key: "price", label: "租金", sortable: true },
      { key: "amount", label: "總值", sortable: true },
    ];

    const perPagePaymentSchedule = ref(10);
    const totalRowPaymentSchedule = ref(0);
    const currentPagePaymentSchedule = ref(1);
    const perPageOptionsPaymentSchedule = [10, 25, 50, 100];
    const sortByPaymentSchedule = ref("id");
    const isSortDirDescPaymentSchedule = ref(false);

    const dataMetaPaymentSchedule = computed(() => {
      const localItemsCount = refPaymentScheduleListTable.value
        ? refPaymentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPagePaymentSchedule.value *
            (currentPagePaymentSchedule.value - 1) +
          (localItemsCount ? 1 : 0),
        to:
          perPagePaymentSchedule.value *
            (currentPagePaymentSchedule.value - 1) +
          localItemsCount,
        of: totalRowPaymentSchedule.value,
      };
    });

    const refetchTbData = (total, meta, perPage, current, target, from) => {
      total.value = from.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      target.value = from.filter((ele, index) => index >= min && index <= max);
    };

    watch(
      () => props.terminateData,
      (newVal) => {
        newTerminateData.value = newVal;

        refetchTbData(
          totalRowRentSchedule,
          dataMetaRentSchedule,
          perPageRentSchedule,
          currentPageRentSchedule,
          rent_schedule_list,
          newVal.rent_schedules
        );

        refetchTbData(
          totalRowPaymentSchedule,
          dataMetaPaymentSchedule,
          perPagePaymentSchedule,
          currentPagePaymentSchedule,
          payment_schedules_list,
          newVal.payment_schedules
        );
      }
      // { immediate: true }
    );

    watch(
      () => currentPageRentSchedule.value,
      (newVal) => {
        refetchTbData(
          totalRowRentSchedule,
          dataMetaRentSchedule,
          perPageRentSchedule,
          currentPageRentSchedule,
          rent_schedule_list,
          props.terminateData.rent_schedules
        );
      }
    );
    watch(
      () => currentPagePaymentSchedule.value,
      (newVal) => {
        refetchTbData(
          totalRowPaymentSchedule,
          dataMetaPaymentSchedule,
          perPagePaymentSchedule,
          currentPagePaymentSchedule,
          payment_schedules_list,
          props.terminateData.payment_schedules
        );
      }
    );

    const calItemAmount = (quantity, price) => {
      return (quantity * price).toFixed(1);
    };

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newTerminateData,

      rent_schedule_list,
      refRentScheduleListTable,
      tableColumnsRentSchedule,
      perPageRentSchedule,
      totalRowRentSchedule,
      currentPageRentSchedule,
      perPageOptionsRentSchedule,
      sortByRentSchedule,
      isSortDirDescRentSchedule,
      dataMetaRentSchedule,

      payment_schedules_list,
      refPaymentScheduleListTable,
      tableColumnsPaymentSchedule,
      perPagePaymentSchedule,
      totalRowPaymentSchedule,
      currentPagePaymentSchedule,
      perPageOptionsPaymentSchedule,
      sortByPaymentSchedule,
      isSortDirDescPaymentSchedule,
      dataMetaPaymentSchedule,
      calItemAmount,
    };
  },
};
</script>
  
<style lang="scss" scoped>
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  
  