<template>
  <b-sidebar
    id="add-new-banner-sidebar"
    :visible="isAddNewBannerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-banner-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ button_text }} 新圖片</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-col cols="12" v-if="bannerData.image_path">
        <hr />
        <img style="width: 100%" :src="bannerData.image_path" />
      </b-col>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-form-group
              label="類別"
              label-cols-md="3"
              class="mt-3"
              v-show="false"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="bannerData.banner_category_id"
                :options="bannerOptions"
                label="name"
                class="w-100"
                :reduce="(val) => val.id"
                :clearable="false"
                disabled
              ></v-select>
            </b-form-group>

            <span
              style="
                margin-bottom: 0.2857rem;
                color: #5e5873;
                font-size: 0.857rem;
              "
              >主頁圖片 <span class="error-text">**</span></span
            >
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              ref="upload"
              :action="uploadUrl"
              :headers="headerObj"
              name="image_file"
              :file-list="fileList"
              multiple
              :limit="1"
              :data="bannerData"
              :on-change="onChange"
              :on-remove="toggleUpload"
              :before-remove="beforeRemove"
              :auto-upload="false"
              :class="{ hideUpload: !showUpload }"
            >
              <img
                v-if="bannerData.image_path && false"
                :src="bannerData.image_path"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <label v-if="!bannerData.file && !bannerData.id" class="error-text">
              圖片欄是必填</label>
            <!-- MENU TITLE 
          <validation-provider
            #default="validationContext"
            name="Banner Title"
            rules="required"
          >
            <b-form-group label="Banner Title" label-for="banner_title">
              <b-form-input
                id="banner_title"
                v-model="bannerData.banner_title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Banner Title"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          -->
            <b-form-group label="排序" label-cols-md="3" class="mt-3">
              <b-form-input
                type="text"
                placeholder="排序"
                v-model="bannerData.sort_order"
              />
            </b-form-group>

            <b-form-group
              label="狀態"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="bannerData.is_active" /> -->
              <b-form-checkbox
                :checked="bannerData.is_active"
                name="is_active"
                switch
                inline
                v-model="bannerData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ bannerData.is_active ? "啟用" : "禁止" }}
              </b-form-checkbox>
            </b-form-group>

            <!-- <b-form-group label="Title" label-cols-md="3">
              <b-form-input
                type="text"
                placeholder="Title"
                v-model="bannerData.title"
              />
            </b-form-group>

            <b-form-group label="Subtitle" label-cols-md="3">
              <b-form-input
                type="text"
                placeholder="Subtitle"
                v-model="bannerData.subtitle"
              />
            </b-form-group> -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { getUserData } from "@/auth/utils";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import useBannersList from "./useBannersList";

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    hide() {
      this.$emit("update:is-add-new-banner-sidebar-active", false);
    },
    getImgPath(path) {
      return process.env.VUE_APP_IMAGE_URL + path;
    },
    onSubmit() {
      if (!this.bannerData.file) {
        return;
      }
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("file", this.bannerData.file);
          formData.append("id", this.bannerData.id);
          /*      if(this.bannerData.title)
            formData.append("title", this.bannerData.title)
          if(this.bannerData.subtitle)
            formData.append("subtitle", this.bannerData.subtitle); */
          formData.append("is_active", this.bannerData.is_active);
          formData.append("sort_order", this.bannerData.sort_order);
          formData.append(
            "banner_category_id",
            this.bannerData.banner_category_id
          );
          this.loading = true;
          store
            .dispatch("bar-banner/updateBanner", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.showUpload = true;
              this.fileList = [];
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
              /*
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              */
            });
        }
      });
    },
  },
  model: {
    prop: "isAddNewBannerSidebarActive",
    event: "update:is-add-new-banner-sidebar-active",
  },
  props: {
    isAddNewBannerSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    bannerOptions: {
      type: Array,
      required: true,
    },
    bannerCategoryFilter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const upload = ref(null);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/banner");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const showUpload = ref(true);
    const fileList = ref([]);
    const button_text = ref("增加");

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    const blankBannerData = ref({
      id: 0,
      name: "",
      is_active: 1,
      sort_order: 0,
      banner_category_id: props.bannerCategoryFilter,
    });

    const bannerData = ref(JSON.parse(JSON.stringify(blankBannerData.value)));

    const resetbannerData = () => {
      bannerData.value = JSON.parse(JSON.stringify(blankBannerData.value));
      fileList.value = [];
      button_text.value = "增加";
    };

    watch(
      () => [
        props.id,
        props.isAddNewBannerSidebarActive,
        props.bannerCategoryFilter,
      ],
      () => {
        if (props.id > 0 && props.isAddNewBannerSidebarActive) {
          bannerData.value = { ...props.data };
        } else {
          bannerData.value.banner_category_id = props.bannerCategoryFilter;
        }
      }
    );

    const onChange = (file, fileLists) => {
      //fileList.value = fileLists;
      showUpload.value = !showUpload.value;
      bannerData.value.file = file.raw;
    };

    const beforeRemove = (file) => {
      bannerData.value.file = null;
    };

    const uploadSuccess = (res, file, fileList) => {
      emit("refetch-data");
      emit("update:is-add-new-banner-sidebar-active", false);
      showMessage(
        "成功！",
        "成功插入新圖片.",
        "CheckIcon",
        "success"
      );
    };

    const uploadFail = (err, file, fileList) => {
      //showMessage('Fail', err, 'HeartIcon', 'danger')
      console.log("上傳失敗");
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetbannerData);

    const { showMessage } = useBannersList();

    return {
      refInputEl,
      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
      refPreviewEl,
      upload,
      uploadUrl,
      uploadSuccess,
      uploadFail,
      onChange,
      headerObj,
      fileList,
      button_text,
      loading,
      showUpload,
      beforeRemove,
      bannerData,
    };
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 178px;
  line-height: 100%;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hideUpload > div {
  display: none;
}
</style>