<template>
  <div>
    <modal-file-manager
      :trigger="trigger"
      :client_code="client_code"
      :path="path"
    />

    <modal-pdf :pdf-url="pdfUrl" />

    <modal-edit-audit :audit-data="auditData" @refetch-data="refetchData" />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div class="d-flex flex-wrap align-items-center colorWhite">
        <div class="d-flex align-items-center mr-2">
          <span>顯示</span>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block common-rounded mx-50"
          />
          <span>條目</span>
        </div>
        <!-- <div class="d-flex align-items-center mr-2">
          <span class="mr-1">搜尋</span>
          <div class="search-primary">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              class="d-inline-block common-rounded"
              placeholder="搜尋收款編號/公司名稱"
            />
          </div>
        </div> -->
        <div class="d-flex align-items-center mr-2">
          <span class="mr-1">客戶</span>
          <v-select
            v-model="filter_customer_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="customerOptions"
            label="name"
            :reduce="(option) => option.id"
            :clearable="true"
            placeholder="選擇客戶"
            class="d-inline-block common-rounded"
          />
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-1">付款類型</span>
          <v-select
            v-model="filter_payment_type_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="paymentTypeOptions"
            label="payment_name"
            :reduce="(option) => option.id"
            :clearable="true"
            placeholder="選擇付款類型"
            class="d-inline-block common-rounded"
          />
        </div>
      </div>
    </div>

    <b-card no-body class="mb-0">
      <b-overlay
        variant="white"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <b-table
          ref="refPaymentListTable"
          class="position-relative"
          :items="fetchPayments"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(image)="data">
            <a
              href="javascript:void(0)"
              @click="
                handleUpload(data.item.customer_id, data.item.invoice.invoice_file_path)
              "
            >
              <feather-icon class="color1" icon="UploadCloudIcon" />
            </a>
          </template>

          <template #cell(storage)="data">
            {{
              data.item.mini_storage &&
              data.item.mini_storage.warehouse &&
              data.item.mini_storage.warehouse.name + ' ' +data.item.mini_storage.storage_number
            }}
          </template>

          <template #cell(payment_type_id)="data">
            <div>
              {{
                data.item.payment_type_id &&
                paymentTypeOptions &&
                paymentTypeOptions.length > 0 &&
                paymentTypeOptions.find(
                  (ele) => ele.id == data.item.payment_type_id
                ).payment_name
              }}
            </div>
          </template>

          <template #cell(audit)="data">
            <span v-if="data.item.is_audit">已核實</span>

            <a
              v-else-if="ability.can('update', 'payments') || true"
              href="javascript:void(0)"
              v-b-popover.hover.top="'核實付款'"
              @click="auditPayment(data.item.id)"
            >
              <font-awesome-icon
                class="color1"
                icon="fa-solid fa-audio-description"
              />
            </a>
          </template>
          <template #cell(description)="data">
            <span v-if="data.item.description != 'undefined'">{{
              data.item.description
            }}</span>
          </template>

          <template #cell(receipt)="data">
            <a
              v-if="data.item.is_audit"
              href="javascript:void(0)"
              v-b-popover.hover.top="'列印收據'"
              @click="downloadReceipt(data.item.id)"
            >
              <font-awesome-icon class="color1" icon="fa-solid fa-receipt" />
            </a>
            <span v-else>- -</span>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BOverlay,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import paymentStoreModule from "../paymentStoreModule";
import usePaymentsList from "./usePaymentsList";
import { getCorrectDateTime } from "@/libs/helper";
import ModalEditAudit from "@/views/invoice/modal/modal-edit-audit.vue";
import ModalFileManager from "@/layouts/components/file/ModalFileManager.vue";

import { getUserData } from "@/auth/utils";
import { getImgPath } from "@/libs/helper";
import ModalPdf from "./ModalPdf.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    vSelect,
    ModalPdf,
    ModalEditAudit,
    ModalFileManager,
    // PaymentListAddNew,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  methods: {
    downloadReceipt(id) {
      this.loading = true;
      this.$store
        .dispatch("payment/downloadReceipt", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    auditPayment(id) {
      (this.auditData = {
        id,
        receipt_date: getCorrectDateTime(new Date(), true),
        is_audit: 1,
      }),
        this.$bvModal.show("modal-edit-audit");
    },
    handleUpload(customer_id, file_path) {
      //console.log(item)
      this.client_code = customer_id;
      this.trigger = !this.trigger;
      this.path = `${file_path}`;
      this.$bvModal.show("modal-file-manager");
    },
  },
  data() {
    return {
      auditData: {},
      pdfUrl: "",
      loading: false,
      trigger: false,
      client_code: 0,
      path: "",
    };
  },
  setup() {
    const PAYMENT_STORE_MODULE_NAME = "payment";
    const isAddNewPaymentSidebarActive = ref(false);

    if (!store.hasModule(PAYMENT_STORE_MODULE_NAME))
      store.registerModule(PAYMENT_STORE_MODULE_NAME, paymentStoreModule);

    onUnmounted(() => {
      if (store.hasModule(PAYMENT_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_STORE_MODULE_NAME);
    });

    const {
      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      getPaymentImage,
      paymentTypeOptions,
      customerOptions,
      ability,
      filter_payment_type_id,
      filter_customer_id,
      showMessage,
    } = usePaymentsList();

    return {
      //SideBar
      isAddNewPaymentSidebarActive,
      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      getPaymentImage,
      paymentTypeOptions,
      customerOptions,
      ability,
      filter_payment_type_id,
      filter_customer_id,
      showMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>


