<template>
  <b-modal
    id="modal-edit-customer"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">{{ isEdit ? "修改" : "新增" }}客戶</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="contract-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            <b-col cols="12" xl="4">
                <b-form-group
                  label="登記名稱（中文）"
                  label-for="name"
                  class="required"
                  label-cols-md="3"
                >
                <validation-provider
                  #default="validationContext"
                  name="登記名稱（中文）"
                  rules="required"
                >
                  <b-form-input
                    v-model="newCustomerData.name"
                    class="d-inline-block"
                    placeholder="搜尋登記名稱（中文）"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
               <b-form-group
                  label="登記名稱（英文）"
                  label-for="name_en"
                  class="required"
                  label-cols-md="3"
                >
                <validation-provider
                  #default="validationContext"
                  name="登記名稱（英文）"
                  rules="required"
                >
                  <b-form-input
                    v-model="newCustomerData.name_en"
                    class="d-inline-block"
                    placeholder="搜尋登記名稱（英文）"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="登記地址"
                  label-for="address"
                  class="required"
                  label-cols-md="3"
                >
                <validation-provider
                  #default="validationContext"
                  name="登記地址"
                  rules="required"
                >
                  <b-form-input
                    v-model="newCustomerData.address"
                    class="d-inline-block"
                    placeholder="搜尋登記地址"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="區域"
                  label-for="address"
                  label-cols-md="3"
                >
                  <!-- class="required" -->
                <!-- <validation-provider
                  #default="validationContext"
                  name="登記地址"
                  rules="required"
                > -->
                <v-select
                    v-model="newCustomerData.district_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="districtOptions"
                    label="name_cn"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />

                  <!-- <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider> -->
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="客戶類別"
                  label-for="customer_type_id"
                  class="required"
                  label-cols-md="3"
                >
                <validation-provider
                  #default="validationContext"
                  name="客戶類別"
                  rules="required"
                >
                  <v-select
                    v-model="newCustomerData.customer_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerTypeOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="身份證號碼 / 商業登記號碼"
                  label-for="id_number"
                  class="required"
                  label-cols-md="3"
                >
                <validation-provider
                  #default="validationContext"
                  name="身份證號碼 / 商業登記號碼"
                  rules="required"
                >

                  <b-form-input
                    id="id_number"
                    v-model="newCustomerData.id_number"
                    :state="getValidationState(validationContext)"
                    placeholder="輸入身份證號碼 / 商業登記號碼"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="電郵"
                  label-for="email"
                  class="required"
                  label-cols-md="3"
                >
                <validation-provider
                  #default="validationContext"
                  :name="`電郵`"
                  rules="required|email"
                >
                  <b-form-input
                    id=""
                    class=""
                    v-model="newCustomerData.email"
                    :state="getValidationState(validationContext)"
                  >
                  </b-form-input>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="電話"
                  label-for="mobile"
                  label-cols-md="3"
                >
                <b-form-input
                  class="info-item-value"
                  v-model="newCustomerData.mobile"
                  @input="updatePassword"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="4"> 
              <b-form-group
                  label="推薦人"
                  label-for="referral_id"
                  label-cols-md="3"
                >
                  <v-select
                    v-model="newCustomerData.referral_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="true"
                    class="w-100"
                  />
                </b-form-group>
            </b-col>
            <b-col cols="12" xl="4">
              <b-form-group
                  label="備註"
                  label-for="remark"
                  label-cols-md="3"
                >
                <b-form-input
                  class="info-item-value"
                  v-model="newCustomerData.remark"
                ></b-form-input>
              </b-form-group>
            </b-col>

            
            <b-col cols="12" xl="4">
              <b-form-group
                  label="啟用?"
                  label-for="is_active"
                  label-cols-md="3"
                >
                <b-form-checkbox
                  :checked="newCustomerData.is_active"
                  name="is_active"
                  switch
                  inline
                  v-model="newCustomerData.is_active"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ newCustomerData.is_active ? "啟用" : "禁用" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>


            <b-col cols="12">
              <div class="">
                <div class="my-2 d-flex justify-content-center">
                  <h4 class="colorRed">
                    聯絡人資料 
                    <a
                    class="ml-2"
                    href="javascript:void(0)"
                    style="filter: brightness(0.5);"
                    @click="addItem"
                  >
                    <font-awesome-icon
                      class="colorWhite"
                      icon="fa-solid fa-circle-plus"
                    />
                  </a>
                  </h4>
                </div>
                <b-row
                  v-for="(contact, index) in newCustomerData.contacts"
                  :key="`contact_${index}`"
                  class="m-0"
                >
                  <b-col
                    cols="12"
                    class="d-flex mb-1 bgColor3 radius-round border-1"
                  >
                    <b-row class="flex-grow-1 p-2">
                      <!-- name id -->
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12" md="6" lg="3">
                            <validation-provider
                              #default="validationContext"
                              :vid="`v-contact-name${index}`"
                              :name="`名稱`"
                              rules="required"
                            >
                              <b-form-group
                                :label="`名稱`"
                                :label-for="`contact-name${index}`"
                                label-cols-md="2"
                                class="required"
                              >
                                <b-form-input
                                  id=""
                                  class=""
                                  v-model="contact.name"
                                  :state="getValidationState(validationContext)"
                                >
                                </b-form-input>

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" md="6" lg="3">
                            <validation-provider
                              #default="validationContext"
                              :vid="`v-contact-position${index}`"
                              :name="`職位`"
                              rules="required"
                            >
                              <b-form-group
                                :label="`職位`"
                                :label-for="`contact-position${index}`"
                                label-cols-md="2"
                                class="required"
                              >
                                <b-form-input
                                  id=""
                                  class=""
                                  v-model="contact.position"
                                  :state="getValidationState(validationContext)"
                                >
                                </b-form-input>

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12" md="6" lg="3">
                            <validation-provider
                              #default="validationContext"
                              :vid="`v-contact-mobile${index}`"
                              :name="`電話`"
                              rules="required"
                            >
                              <b-form-group
                                :label="`電話`"
                                :label-for="`contact-mobile${index}`"
                                label-cols-md="2"
                                class="required"
                              >
                                <b-form-input
                                  id=""
                                  class=""
                                  v-model="contact.mobile"
                                  :state="getValidationState(validationContext)"
                                >
                                </b-form-input>

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div
                      class="d-flex flex-column justify-content-between py-50 px-25"
                    >
                      <feather-icon
                        size="16"
                        icon="PlusIcon"
                        class="cursor-pointer text-success"
                        @click="addItem"
                      />
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer text-danger"
                        @click="removeItem(index, contact.id)"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>


            <b-col cols="12" v-if="!isEdit">
              <b-row class="info-wrap">
                <b-col cols="12" md="4">
                  <div class="info-item">
                    <b class="info-item-label">密碼 <span class="colorRed">*</span></b>
                    <validation-provider
                      #default="validationContext"
                      name="密碼"
                      vid="vid_sub_password"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="password"
                          v-model="newCustomerData.password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          placeholder="輸入密碼"
                          :state="getValidationState(validationContext)"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col cols="12" md="4">
                  <div class="info-item">
                    <b class="info-item-label">確認密碼 <span class="colorRed">*</span></b>
                    <validation-provider
                      #default="validationContext"
                      name="確認密碼"
                      rules="required|confirmed:vid_sub_password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="confirm_password"
                          v-model="c_password"
                          :type="confirmPasswordFieldType"
                          class="form-control-merge"
                          placeholder="輸入確認密碼"
                          :state="getValidationState(validationContext)"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
                            @click="toggleConfirmPasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      loading: false,
      c_password:null,
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    };
  },
  props: {
    customerData: {},
    customerOptions:{},
    customerTypeOptions: {},
    districtOptions: {},
    isEdit: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-customer");
    },
    updatePassword() {
      if (this.newCustomerData.id === 0) {
        this.newCustomerData.password = this.newCustomerData.mobile;
        this.c_password = this.newCustomerData.mobile;
      }
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("customer/updateCustomer", this.newCustomerData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
              this.hide()
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },

    addItem() {
      this.newCustomerData.contacts = this.newCustomerData.contacts && this.newCustomerData.contacts.length > 0 ? this.newCustomerData.contacts : []
      this.newCustomerData.contacts.push(
        JSON.parse(
          JSON.stringify({
            id: 0,
          })
        )
      );
    },
    removeItem(index, id) {
      // check number of record
      let record =
        this.newCustomerData &&
        this.newCustomerData.contacts &&
        this.newCustomerData.contacts.length;
      if (record <= 1) {
        this.makeToast("danger", "Warning", "請至少留有一個表單。");
      } else {
        this.newCustomerData.contacts.splice(index, 1);
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newCustomerData = ref({
      id: 0,
      contacts: [{ id: 0 }],
      is_active: 1
    });

    const resetCustomerData = () => {
      newCustomerData.value = { id: 0, contacts: [{ id: 0 }], is_active: 1 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetCustomerData);

    watch(
      () => props.customerData,
      (newVal) => {
        newCustomerData.value = newVal;
      }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newCustomerData,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
  