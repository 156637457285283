<template>
  <div>
    <b-modal
      :id="`modal-image-viewer${id}`"
      modal-class="modal-image-viewer"
      ok-title="OK"
      cancel-variant="outline-secondary"
      scrollable
      title="圖片"
      title-class="font-weight-bolder"
      body-class="common_modal "
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <font-awesome-icon
        @click="hide"
        class="close-button"
        style="font-size: 25px"
        icon="fa-solid fa-circle-xmark"
      />

      <div v-if="imageList && imageList.length > 0">
        <b-carousel
          :value="selected_file"
          id="carousel-2"
          class="mt-2 image-viewer-carousel"
          :interval="0"
          indicators
          no-animation
          controls
          background="#ababab"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            v-for="(file, index) of imageList"
            :key="`view${index}`"
          >
            <template #img>
              <embed
                v-if="file.type == 'application/pdf'"
                id="pdfcontainer"
                class="pdf"
                :src="file.src"
                type="application/pdf"
              />
              <img
                v-else
                class="d-block image"
                :src="file.src"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>
  </div>
</template>
  
  <script>
import { BModal, BCarousel, BCarouselSlide, VBModal } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {};
  },
  methods: {
    hide() {
      this.$bvModal.hide(`modal-image-viewer${this.id}`);
    },

    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    imageList: {
      type: Array,
      require: true,
    },
    selected_file: {
      type: Number,
      require: true,
    },
  },
  computed: {
    // imageuUrl() {
    //   console.log('this.imageList',this.imageList)
    //   if(this.id.indexOf(`-preview`) > -1){
    //     return this.imageList;
    //   }else{
    //     return process.env.VUE_APP_IMAGE_URL + this.imageList;
    //   }
    // },
  },
};
</script>
  
<style lang="scss">
.modal-image-viewer {
  .modal-dialog-centered.modal-dialog-scrollable {
    max-width: 1200px;
    .modal-content {
      width: 100%;
      .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
  }
}

.close-button {
  position: absolute;
  top: -10px;
  right: 8px;
}

.image-viewer-carousel {
  background: transparent !important;
  .carousel-control-prev {
    left: -12%;
  }
  .carousel-control-next {
    right: -12%;
  }
  .carousel-item {
    background: transparent !important;
    max-width: 1000px;
    width: 100vw;
    height: auto;
    aspect-ratio: 16/9;
    margin: 0 auto 50px;
    .image {
      height: 100%;
      display: block;
      margin: 0 auto;
    }
    .pdf {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }
  .carousel-indicators {
    bottom: -30px;
  }
}
</style>
  
  