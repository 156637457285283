import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useCustomersList() {

    const toast = useToast()

    const refCustomerListTable = ref(null)

    const tableColumns = [
        { key: 'id', label: '客戶編號', sortable: true },
        { key: 'name', label: '登記名稱（中文）', sortable: true },
        { key: 'name_en', label: '登記名稱（英文）', sortable: true },
        { key: 'customer_type_id', label: '客戶類別', sortable: true },
        { key: 'id_number', label: '身份證號碼 / 商業登記號碼', sortable: true },
        { key: 'mobile', label: '電話', sortable: true },
        { key: 'contacts', label: '聯絡人', sortable: true },
        { key: 'contacts_mobile', label: '聯絡人電話'},
        { key: 'position', label: '職位' },
        { key: 'email', label: '聯絡電郵', sortable: true },
        { key: 'address', label: '登記地址', sortable: true },
        { key: 'referrer.name', label: '推薦人' },
        { key: 'remark', label: '備註', sortable: true },
        { key: 'district.name_cn', label: '區域' },
        { key:'loyalty_level_id', label:'會員等級',  sortable:true},
        { key:'points', label:'積分',  sortable:true},
        { key:'is_active', label:'狀態',  sortable:true},
        { key: 'action' },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refCustomerListTable.value.refresh()
    }


    const filterCustomerType = ref(null)
    const filterLoyaltyLevel = ref(null)
    watch([currentPage, perPage, searchQuery, filterCustomerType, filterLoyaltyLevel], () => {
        refetchData()
    })


    const customerList = ref(null)

    const fetchCustomers = (ctx, callback) => {

        store.dispatch('customer/fetchCustomers', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            customer_type_id: filterCustomerType.value,
            loyalty_level_id: filterLoyaltyLevel.value,
        })
            .then(response => {
                const { customers, total } = response.data
                callback(customers)
                customerList.value = customers
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching customers list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    const customerTypeOptions = ref([])
    const customerOptions = ref([])
    const loyaltyLevelOptions = ref([])
    const districtOptions = ref([])
    const fetchOptions = () => {
        store
            .dispatch("app/fetchOptions", {
                customer_type: true,
                customer: true,
                loyalty_level: true,
                district: true
            })
            .then((response) => {
                customerTypeOptions.value = response.data.customer_types
                customerOptions.value = response.data.customers
                loyaltyLevelOptions.value = response.data.loyalty_levels
                districtOptions.value = response.data.districts

            })
            .catch((error) => {
                console.log({ error });
            });
    };

    onMounted(() => {
        fetchOptions();
    });


    return {
        fetchCustomers,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refCustomerListTable,
        showMessage,
        customerList,
        customerOptions,
        customerTypeOptions,
        loyaltyLevelOptions,
        districtOptions,
        filterCustomerType,
        filterLoyaltyLevel,
        ability
    }

}