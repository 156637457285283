<template>
  <div>
    <banner-list-filter
      :banner-options="bannerOptions"
      :banner-category-filter.sync="bannerCategoryFilter"
    />
    <banner-list-add-new
      v-if="bannerCategoryFilter"
      :is-add-new-banner-sidebar-active.sync="isAddNewBannerSidebarActive"
      :id="id"
      :data="bannerData"
      :banner-options="bannerOptions"
      :banner-category-filter="bannerCategoryFilter"
      @refetch-data="refetchData"
      @show-message="showMessage"
    />
    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
        </div>
        <a class="" v-if="ability.can('create', 'setting-main-banners')" href="javascript:void(0)" @click="handleAdd()">
          <font-awesome-icon
            class="colorWhite"
            icon="fa-solid fa-circle-plus"
          />
        </a>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-table
        ref="refBannerListTable"
        class="position-relative"
        :items="fetchBanners"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(image_path)="data">
          <b-img
            rounded
            blank-color="#5A8DEE"
            alt="Rounded image"
            :src="data.item.image_path"
            class="d-inline-block mr-1 mb-1"
            style="width: 150px; height: 150px; object-fit: cover"
          />
        </template>
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <template #cell(banner_category_id)="data">
          {{ data.item.category.name }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="ability.can('update', 'setting-main-banners')"  @click="editBanner(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('delete', 'setting-main-banners')"  @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBanners"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import bannerStoreModule from "../bannerStoreModule";
import useBannersList from "./useBannersList";
import BannerListAddNew from "./BannerListAddNew.vue";
import { getUserData } from "@/auth/utils";
import { getImgPath } from "@/libs/helper";
import BannerListFilter from "./BannerListFilter.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BannerListAddNew,
    BannerListFilter,
  },
  methods: {
    resetBannerData() {
      this.bannerData = {
        id: 0,
        is_active: 1,
        sort_order: 0,
        banner_category_id: this.bannerCategoryFilter,
        // store_id: getUserData().store_id,
        // banner_main_title:'',
        // banner_subtitle1: '',
        // banner_subtitle2: '',
      };
    },
    handleAdd() {
      this.id = 0;
      this.resetBannerData();
      this.isAddNewBannerSidebarActive = true;
    },
    confirmDelete(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的操作是最終的，您無法恢復資料。",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log("result.value", result.value);
          store
            .dispatch("bar-banner/deleteBanner", { id: id })
            .then((response) => {
              this.refetchData();
              this.showMessage(
                "Success",
                response.data.message,
                "CheckIcon",
                "success"
              );
            })
            .catch((error) => {
              console.log(error);
              //this.showMessage('Fail', error.response.data.message, 'HeartIcon', 'danger')
            });
        }
      });
    },
    editBanner(item) {
      this.id = item.id;
      this.bannerData = { ...item };
      this.isAddNewBannerSidebarActive = true;
    },
  },
  data() {
    return {
      id: 0,
      bannerData: {},
      getImgPath,
    };
  },
  setup() {
    const BAR_BANNER_STORE_MODULE_NAME = "bar-banner";
    const isAddNewBannerSidebarActive = ref(false);

    if (!store.hasModule(BAR_BANNER_STORE_MODULE_NAME))
      store.registerModule(BAR_BANNER_STORE_MODULE_NAME, bannerStoreModule);

    onUnmounted(() => {
      if (store.hasModule(BAR_BANNER_STORE_MODULE_NAME))
        store.unregisterModule(BAR_BANNER_STORE_MODULE_NAME);
    });

    onMounted(() => {
      fetchOptions();
    });

    const bannerOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", { banner_category: true })
        .then((response) => {
          bannerOptions.value = response.data.banner_categories;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    const {
      fetchBanners,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBannerListTable,
      refetchData,
      getBannerImage,
      categoryOptions,
      showMessage,
      bannerCategoryFilter,
      ability,
    } = useBannersList();

    return {
      //SideBar
      isAddNewBannerSidebarActive,
      fetchBanners,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBannerListTable,
      refetchData,
      getBannerImage,
      categoryOptions,
      showMessage,
      bannerCategoryFilter,
      bannerOptions,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

