<template>
  <b-sidebar
    id="add-new-discount-sidebar"
    :visible="isAddNewDiscountSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-discount-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ button_text }} 新折扣</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-col cols="12" v-if="discountData.image_path">
        <hr />
        <img style="width: 100%" :src="discountData.image_path" />
      </b-col>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <span
              style="
                margin-bottom: 0.2857rem;
                color: #5e5873;
                font-size: 0.857rem;
              "
              >折扣圖片 <span class="error-text">**</span></span
            >
            <el-upload
              class="avatar-uploader"
              list-type="picture-card"
              ref="upload"
              :action="uploadUrl"
              :headers="headerObj"
              name="image_file"
              :file-list="fileList"
              multiple
              :limit="1"
              :data="discountData"
              :on-change="onChange"
              :on-remove="toggleUpload"
              :before-remove="beforeRemove"
              :auto-upload="false"
              :class="{ hideUpload: !showUpload }"
            >
              <img
                v-if="discountData.image_path && false"
                :src="discountData.image_path"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <label v-if="!discountData.file && discountData.id == 0" class="error-text">
              圖片欄位為必填項</label
            >

            <validation-provider
              #default="validationContext"
              name="公司名稱"
              rules="required"
            >
              <b-form-group label="公司名稱" label-for="company_name">
                <b-form-input
                  id="company_name"
                  v-model="discountData.company_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="公司名稱"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="公司簡介"
              label-for="company_short_description"
            >
              <b-form-input
                id="company_short_description"
                v-model="discountData.company_short_description"
                autofocus
                trim
                placeholder="公司簡介"
              />
            </b-form-group>

            <b-form-group
              label="折扣"
              label-for="discount"
            >
              <b-form-input
                id="discount"
                v-model="discountData.discount"
                autofocus
                trim
                placeholder="折扣"
              />
            </b-form-group>

            <b-form-group
              label="折扣描述"
              label-for="discount_description"
            >
              <b-form-input
                id="discount_description"
                v-model="discountData.discount_description"
                autofocus
                trim
                placeholder="折扣描述"
              />
            </b-form-group>
            


            <!--             <b-form-group label="Sort Order" label-cols-md="3" class="mt-3">
              <b-form-input
                type="text"
                placeholder="Sort Order"
                v-model="discountData.sort_order"
              />
            </b-form-group> -->

            <b-form-group
              label="狀態"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="discountData.is_active" /> -->
              <b-form-checkbox
                :checked="discountData.is_active"
                name="is_active"
                switch
                inline
                v-model="discountData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ discountData.is_active ? "啟用" : "禁止" }}
              </b-form-checkbox>
            </b-form-group>

            <!-- <b-form-group label="Title" label-cols-md="3">
              <b-form-input
                type="text"
                placeholder="Title"
                v-model="discountData.title"
              />
            </b-form-group>

            <b-form-group label="Subtitle" label-cols-md="3">
              <b-form-input
                type="text"
                placeholder="Subtitle"
                v-model="discountData.subtitle"
              />
            </b-form-group> -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { getUserData } from "@/auth/utils";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import useDiscountsList from "./useDiscountsList";

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    hide() {
      this.$emit("update:is-add-new-discount-sidebar-active", false);
    },
    getImgPath(path) {
      return process.env.VUE_APP_IMAGE_URL + path;
    },
    onSubmit() {
      if (!this.discountData.file && this.discountData.file == 0) {
        return;
      }
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("file", this.discountData.file);
          formData.append("id", this.discountData.id);
          /*      if(this.discountData.title)
            formData.append("title", this.discountData.title)
          if(this.discountData.subtitle)
            formData.append("subtitle", this.discountData.subtitle); */
          formData.append("company_name", this.discountData.company_name);
          formData.append("company_short_description", this.discountData.company_short_description);
          formData.append("discount", this.discountData.discount);
          formData.append("discount_description", this.discountData.discount_description);
          formData.append("is_active", this.discountData.is_active);
          formData.append("sort_order", this.discountData.sort_order);
          this.loading = true;
          store
            .dispatch("bar-discount/updateDiscount", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.showUpload = true;
              this.fileList = [];
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
              /*
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              */
            });
        }
      });
    },
  },
  model: {
    prop: "isAddNewDiscountSidebarActive",
    event: "update:is-add-new-discount-sidebar-active",
  },
  props: {
    isAddNewDiscountSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const upload = ref(null);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/discount");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const showUpload = ref(true);
    const fileList = ref([]);
    const button_text = ref("Add");

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    const blankDiscountData = ref({
      id: 0,
      name: "",
      is_active: 1,
      sort_order: 0,
    });

    const discountData = ref(
      JSON.parse(JSON.stringify(blankDiscountData.value))
    );

    const resetdiscountData = () => {
      discountData.value = JSON.parse(JSON.stringify(blankDiscountData.value));
      fileList.value = [];
      button_text.value = "新增";
    };

    watch(
      () => [props.id, props.isAddNewDiscountSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewDiscountSidebarActive) {
          discountData.value = { ...props.data };
        }
      }
    );

    const onChange = (file, fileLists) => {
      //fileList.value = fileLists;
      showUpload.value = !showUpload.value;
      discountData.value.file = file.raw;
    };

    const beforeRemove = (file) => {
      discountData.value.file = null;
    };

    const uploadSuccess = (res, file, fileList) => {
      emit("refetch-data");
      emit("update:is-add-new-discount-sidebar-active", false);
      showMessage(
        "成功！",
        "成功插入新折扣",
        "CheckIcon",
        "success"
      );
    };

    const uploadFail = (err, file, fileList) => {
      //showMessage('Fail', err, 'HeartIcon', 'danger')
      console.log("upload Fail");
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetdiscountData);

    const { showMessage } = useDiscountsList();

    return {
      refInputEl,
      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
      refPreviewEl,
      upload,
      uploadUrl,
      uploadSuccess,
      uploadFail,
      onChange,
      headerObj,
      fileList,
      button_text,
      loading,
      showUpload,
      beforeRemove,
      discountData,
    };
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 178px;
  line-height: 100%;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hideUpload > div {
  display: none;
}
</style>