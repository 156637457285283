<template>
  <div>
    <video-list-add-new
      :is-add-new-video-sidebar-active.sync="isAddNewVideoSidebarActive"
      :id="id"
      :data="videoData"
      @refetch-data="refetchData"
      @show-message="showMessage"
    />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center">
        
          <a class="" v-if="ability.can('create', 'setting-main-videos')" href="javascript:void(0)" @click="handleAdd()">
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>

    <b-card no-body class="mb-0">

        <b-table
        ref="refVideoListTable"
        class="position-relative"
        :items="fetchVideos"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy" 
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        >
        <template #cell(url)="data">
          <div style="width: 300px; overflow:hidden;" v-html="data.item.url"></div>
              
        </template>
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
            <feather-icon 
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
            />
        </template>
        
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="ability.can('update', 'setting-main-videos')" @click="editVideo(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="ability.can('delete', 'setting-main-videos')" @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        </b-table>
        <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalVideos"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import videoStoreModule from "../videoStoreModule";
import useVideosList from './useVideosList'
import VideoListAddNew from './VideoListAddNew.vue';
import {getUserData} from '@/auth/utils'
import {getImgPath} from "@/libs/helper"

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VideoListAddNew,
  },
  methods:{     
    resetVideoData(){
      this.videoData = {
        id: 0,
        is_active: 1,
        sort_order: 0,
        // store_id: getUserData().store_id,
        // video_main_title:'',
        // video_subtitle1: '',
        // video_subtitle2: '',
      }
    },
    handleAdd(){
      this.id = 0
      this.resetVideoData()
      this.isAddNewVideoSidebarActive = true
    },
    confirmDelete(id) {
        this.$swal({
        title: 'Are you sure?',
        text: "您的操作是最終的，您無法恢復資料。",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('bar-video/deleteVideo', {id: id})
          .then((response) => {
            this.refetchData()
            this.showMessage('Success', response.data.message, 'CheckIcon', 'success')
          }).catch( error => {
              console.log(error)
              //this.showMessage('Fail', error.response.data.message, 'HeartIcon', 'danger')
          })
        }
      })
    },
    editVideo(item){
        this.id = item.id
        this.videoData = {...item}
        this.isAddNewVideoSidebarActive = true
    }
  },
  data(){
    return {
      id:0,
      videoData: {},
      getImgPath
    }
  },
  setup() {
    const BAR_VIDEO_STORE_MODULE_NAME = "bar-video";
    const isAddNewVideoSidebarActive = ref(false);

    if (!store.hasModule(BAR_VIDEO_STORE_MODULE_NAME))
      store.registerModule(BAR_VIDEO_STORE_MODULE_NAME, videoStoreModule);

    onUnmounted(() => {
      if (store.hasModule(BAR_VIDEO_STORE_MODULE_NAME))
        store.unregisterModule(BAR_VIDEO_STORE_MODULE_NAME);
    });

    const {

        fetchVideos,
        tableColumns,
        perPage,
        currentPage,
        totalVideos,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refVideoListTable,
        refetchData,
        getVideoImage,
        categoryOptions,
        showMessage,
        ability
    } = useVideosList()    

    return {

        //SideBar
        isAddNewVideoSidebarActive,
        fetchVideos,
        tableColumns,
        perPage,
        currentPage,
        totalVideos,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refVideoListTable,
        refetchData,
        getVideoImage,
        categoryOptions,
        showMessage,
        ability
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

