import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchDiscounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/coupon/list',{params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchDiscount(ctx, qureyParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/coupon`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addDiscount(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/coupon', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    fetchLanguages(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/language/list')
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    deleteDiscount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/coupon', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOptions(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get("/option/list", { params: queryParams }).then((response) => resolve(response)).catch((error) => reject(error));
        });
    },
  },
}
