<template>
  <div>
    <!-- Header: Invoice -->
    <div class="d-flex">
      <h4 class="mb-0">Info Setting</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <b-form-group
              label="Show Video"
              label-for="is_main_page_video"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_main_page_video"  v-model="concertData.is_main_page_video" /> -->
              <b-form-checkbox
                :checked="(infoData[findInvoiceIndex('is_main_page_video')].value)+0"
                name="is_main_page_video"
                :key="`is_main_page_video${videoTrigger}`"
                switch
                inline
                v-model="infoData[findInvoiceIndex('is_main_page_video')].value"
                value="1"
                style="margin-top: 5px"
                @change="videoTrigger = !videoTrigger"
              >
                {{ (infoData[findInvoiceIndex('is_main_page_video')].value == 1) ? "Show" : "Hidden" }}
              </b-form-checkbox>
            </b-form-group>
            </b-col>
            <b-col cols="12" md="12" v-if="infoData[findInvoiceIndex('is_main_page_video')].value == 1">
              <validation-provider
                #default="validationContext"
                name="main_page_video"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="影片"
                  label-for="main_page_video"
                  label-cols-md="3"
                  v-b-popover.hover.top="'請在youtube按分享，再按嵌入，把完整<iframe> ... </iframe>字段貼上, 提交前請確保影片於下方能正常顯示。'"
                   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                >
                  <b-form-textarea
                  id="main_page_video"
                  v-model="infoData[findInvoiceIndex('main_page_video')].value"
                  :state="getValidationState(validationContext)"
                  rows="3"
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>   
            <b-col cols="12" md="3">
            </b-col>
            <b-col cols="12" md="9">

              <div v-if="infoData[findInvoiceIndex('main_page_video')].value && infoData[findInvoiceIndex('is_main_page_video')].value == 1" v-html="infoData[findInvoiceIndex('main_page_video')].value"></div>
            </b-col> 

            <b-col cols="12" md="12">

                <b-form-group
                  class="required"
                  label="About Content"
                  label-for="main_page_content"
                  label-cols-md="3"
                >
                  <b-form-textarea
                  id="main_page_content"
                  v-model="infoData[findInvoiceIndex('main_page_content')].value"
                  rows="18"
                  >
                  </b-form-textarea>
                </b-form-group>
            </b-col>
          </b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <!-- Form Actions -->

          <div class="d-flex justify-content-end mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-2"
              type="submit"
            >
              Edit
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BFormTextarea,
  BOverlay,
  VBTooltip,
  VBPopover,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, between, integer } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  data() {
    return {
      required,
      between,
      integer,
      videoTrigger: false,
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    'b-popover': VBPopover,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    VBTooltip,
    BFormTextarea,
    VBPopover,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    onSubmit() {
      var data = this.infoData;
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("setting-setting/updateSetting", data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    settingData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const infoData = ref([]);
    const loading = ref(false);

    const resetInvoiceData = () => {
      infoData.value = props.settingData.filter((item) => item.code == "info");
    };

    resetInvoiceData();

    const findInvoiceIndex = (key) => {
      return infoData.value.findIndex((obj) => obj.key_set == key);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetInvoiceData);

    return {
      infoData,
      findInvoiceIndex,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.required > label::after {
  content: " **";
  color: red;
}

.vs__selected path {
  fill: white;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;
    .dark-layout & {
      background-color: unset;
    }
  }
}

.text-block {
    white-space: pre; // or pre-line
}

iframe{
  width: 100%;
}

</style>

