<template>
  <div id="warehouse" :class="in_modal ? 'in_modal' : ''">
    <div v-if="in_modal" @click="$emit('update:in_modal', false)" class="black-background"></div>
    <canvas
      @click="selectChecked"
      :style="{ aspectRatio: backgroundWidth / backgroundHeight, maxWidth:backgroundWidth+'px' }"
      id="canvas"
    ></canvas>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
  },
  data() {
    return {
      warehouses: [],
      selected_storage_index: null,
      backgroundWidth: 0,
      backgroundHeight: 0,
    };
  },
  model: [
    {
      prop: "in_modal",
      event: "update:in_modal",
    },
    {
      prop: "filter_mini_storage_id",
      event: "update:filter_mini_storage_id",
    }
  ],
  props: {
    warehouseData: {
      type: Object,
      required: true,
    },
    in_modal: {
      type: Boolean,
    },
    selected_storage_number: {
      type: String,
    },
    filter_mini_storage_id:{}
  },
  computed: {
    isSelected() {
      return this.selected_storage_index === 0 || this.selected_storage_index;
    },
  },
  watch: {
    warehouseData: {
      handler: function (newVal, oldVal) {
        this.storages = newVal.mini_storages;
      },
      deep: true,
    },
    storages: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selected_storage_index: {
      handler() {
        this.draw();
      },
      deep: true,
    },
    selected_storage_number: {
      handler(val) {
        this.selected_storage_index = this.storages.findIndex(
          (ele) => ele.storage_number == val
        );
      },
    },
  },
  mounted() {
    this.canvas = document.getElementById("canvas");
    this.context = this.canvas.getContext("2d");
    this.storages = this.warehouseData.mini_storages;
    if (this.selected_storage_number)
      this.selected_storage_index = this.warehouses.findIndex(
        (ele) => ele.storage_number == this.selected_storage_number
      );

    this.draw();
  },
  methods: {
    goToTable(offset){
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    },
    draw() {
      let image = new Image();

      image.src = this.warehouseData.images.find(
        (ele) => ele.image_type_id === 2
      ).image_path;

      let that = this;

      image.onload = function (e) {
        that.backgroundWidth = e.target.naturalWidth;
        that.backgroundHeight = e.target.naturalHeight;

        that.canvas.width = that.backgroundWidth;
        that.canvas.height = that.backgroundHeight;
        that.canvas.style.maxWidth = that.backgroundWidth

        that.canvas.style.background = `url(${
          that.warehouseData.images.find((ele) => ele.image_type_id === 2)
            .image_path
        }) no-repeat`;
        that.canvas.style.backgroundSize = `100% 100%`;
        that.canvas.style.backgroundPostiion = `0px 0px`;

        // 清空畫布
        that.context.clearRect(0, 0, that.canvas.width, that.canvas.height);

        // 繪製迷你倉

        for (let i = that.storages.length - 1; i >= 0; i--) {
          let storage = that.storages[i];
          that.context.strokeStyle = "grey";
          that.context.lineWidth = 2;

          that.context.strokeRect(
            storage.x,
            storage.y,
            storage.rectWidth,
            storage.rectHeight
          ); 

          if (that.selectedWarehouse === i) {
            that.context.fillStyle = "yellow";
          }else if (storage.on_sell) {
            that.context.fillStyle = "green";
          } else {
            that.context.fillStyle = "red";
          }

          that.context.fillRect(
            storage.x,
            storage.y,
            storage.rectWidth,
            storage.rectHeight
          );
          if (storage.storage_number) {
            // 繪製文字
            if (that.selectedWarehouse === i && storage.on_sell) {
              that.context.fillStyle = "black";
            } else {
              that.context.fillStyle = "white";
            }
            that.context.font = "14px Arial";
            const text = storage.storage_number;
            const textWidth = that.context.measureText(text).width;
            const textX =
              Number(storage.x) +
              (Number(storage.rectWidth) - textWidth) / 2;
            const textY =
              Number(storage.y) + Number(storage.rectHeight) / 2 + 7; // 調整文字的垂直位置
            that.context.fillText(text, textX, textY);
          }
        }
      };
    },
    selectChecked(event) {
      const rect = this.canvas.getBoundingClientRect();
      this.goToTable(rect.top+rect.height);

      const wRadio = rect.width / this.canvas.width;
      const hRadio = rect.height / this.canvas.height;

      const x = (event.clientX - rect.left) / wRadio;
      const y = (event.clientY - rect.top) / hRadio;

      // 檢查被點擊的位置是否在迷你倉的範圍內
      for (let i = this.storages.length - 1; i >= 0; i--) {
        const storage = this.storages[i];

        if (
          x >= storage.x &&
          x <= storage.x + storage.rectWidth &&
          y >= storage.y &&
          y <= storage.y + storage.rectHeight
        ) {

          if(this.selected_storage_index == i){
            this.selected_storage_index = null
            this.$emit('update:filter_mini_storage_id',null)
          }else{
            this.selected_storage_index = i;
            this.$emit('update:filter_mini_storage_id',storage.id)
          }

          // if (storage.on_sell) {
          //   this.$emit('update:in_modal', false)
          // }

          break;
        }
      }
    },
  },
};
</script>
<style lang="scss">
#warehouse {
}

#canvas {
  width: 100%;
  margin-right: 10px;
  border: 1px solid #ccc;
}
#warehouse.in_modal {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  .black-background{
    background: #00000055;
    z-index:1;
    width: 100vw;
    height: 100vh;
  }
  #canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:2;
  }
}
</style>