export default [
  {
    title: "儀表板",
    route: "home",
    icon: "CpuIcon",
    resource:'Auth',
    action:'read',
  },
  {
    title: "倉庫",
    route: "",
    icon: "PackageIcon",
    children:[
      {
        title: "倉庫種類", 
        route: 'warehouse-categories-list',
        resource:'warehouse-categories',
        action:'read',
      },
      {
        title: "倉庫", 
        route: 'warehouses-list',
        resource:'warehouses',
        action:'read',
      }
    ]
  },
  {
    title: "租務管理",
    route: "rents",
    icon: "ShoppingCartIcon",
    resource:'rents',
    action:'read',
  },
  // {
  //   title: "價目表管理",
  //   route: "price-list",
  //   icon: "ColumnsIcon",
  // },
  {
    title: "合約管理",
    route: "contract-new-list",
    icon: "FileTextIcon",
    resource:'contracts',
    action:'read',
  },
  {
    title: "客戶管理",
    route: "customer-new-list",
    icon: "UserIcon",
    resource:'customers',
    action:'read',
  },
  // {
  //   title: "優惠",
  //   route: "referral-coupon-list",
  //   icon: "AwardIcon",
  //   // resource:'referral_coupon',
  //   resource:'Auth',
  //   action:'read',
  // },
  {
    title: "發票管理",
    route: "invoice-list",
    icon: "ClipboardIcon",
    resource:'invoices',
    action:'read',
  },
  {
    title: "待支付發票",
    route: "invoice-pending-list",
    icon: "ClipboardIcon",
    resource:'invoices',
    action:'read',
  },
  {
    title: "收據記錄",
    route: "payments",
    icon: "ClipboardIcon",
    resource:'Auth',
    action:'read',
  },
  {
    title: "收款管理",
    route: "payment-schedule-list",
    icon: "FileMinusIcon",
    resource:'payment-schedules',
    action:'read',
  },
  {
    title: "事件日歷",
    route: "task-list",
    icon: "CalendarIcon",
    resource:'tasks',
    action:'read',
  },
  {
    title: "報表系統",
    route: "reports-list",
    icon: "PaperclipIcon",
    resource:'reports',
    action:'read',
  },
  {
    title: "電郵管理",
    route: "emails-list",
    icon: "MailIcon",
    resource:'emails',
    action:'read',
  },

  // {
  //   title: "租貸記錄(OLD)",
  //   route: "bookings-list",
  //   icon: "FileIcon",
  // },
  // {
  //   title: "合約管理(OLD)",
  //   route: "contract-list",
  //   icon: "FileTextIcon",
  // },
  // {
  //   title: "客戶管理(OLD)",
  //   route: "customers",
  //   icon: "UserIcon",
  // },
  // {
  //   title: "收款管理(OLD)",
  //   route: "payments",
  //   icon: "FileMinusIcon",
  // },
  {
    title: "預約(Appointment)",
    route: "appointments-list",
    icon: "CalendarIcon",
    resource:'appointments',
    action:'read',
  },
  {
    title: "優惠券(Discount)",
    route: "coupons-list",
    icon: "GiftIcon",
    resource:'coupons',
    action:'read',
  },
  {
    title: "設置(Setting)",
    route: "",
    icon: "SettingsIcon",
    children: [
      {
        title: "頁面",
        route: "pages-list",
        icon: "GridIcon",
        resource:'pages',
        action:'read',
      },
      {
        title: "主頁內容",
        route: "",
        icon: "HomeIcon",
        children:[
          {
            title: '首頁 Banner', 
            route: 'main-banners-list',
            resource:'setting-main-banners',
            action:'read',
          },
          {
            title: '最新消息 Banner', 
            route: 'news-banners-list',
            resource:'setting-main-banners',
            action:'read',
          },
          {
            title: '影片', 
            route: 'videos-list',
            resource:'setting-main-videos',
            action:'read',
          },
          {
            title: '合作商戶優惠', 
            route: 'discounts-list',
            resource:'setting-main-discounts',
            action:'read',
          },
        ]
      },
      {
        title: "關於我們 Banner",
        route: "about-banners-list",
        icon: "ImageIcon",
        resource:'setting-main-banners',
        action:'read',
      },
      {
        title: "客戶類型",
        route: "customer-type-list",
        icon: "UsersIcon",
        resource:'setting-customer-types',
        action:'read',
      },
      // {
      //   title: "租用類型",
      //   route: "rent-type-list",
      //   icon: "BoxIcon",
      // },
      // {
      //   title: "租貸階段",
      //   route: "booking-stages",
      //   resource: "booking-stages",
      //   action: "read",
      // },f
      {
        title: "簽約狀態",
        route: "sign-status-list",
        icon: "FeatherIcon",
        resource:'setting-sign-statuses',
        action:'read',
      },
      {
        title: "付款類型",
        route: "payment-type-list",
        icon: "CreditCardIcon",
        resource:'setting-payment-types',
        action:'read',
      },
      {
        title: "系統設定",
        route: "settings-edit",
        icon: "InfoIcon",
        resource:'setting-infos',
        action:'read',
      },
      {
        title: "優惠設定",
        route: "referral-discount-option-list",
        icon: "AwardIcon",
        // resource:'referral-discount-option-list',
        resource:'Auth',
        action:'read',
      },
      {
        title: "用戶權限管理",
        route: "roles-list",
        icon: "ListIcon",
        resource:'setting-roles',
        action:'read',
      },
      {
        title: "員工管理(Staff)",
        route: "users-list",
        icon: "UserIcon",
        resource:'setting-users',
        action:'read',
      },
    ],
  },
];
