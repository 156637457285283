import axiosIns from '@/libs/axios'
import {getUserData} from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // addBookingStage(ctx, data) {
    //     return new Promise((resolve, reject) => {
    //         axiosIns.post('/booking_stage', data,
    //         {
    //           headers: {
    //               'Content-Type': 'multipart/form-data'
    //           }
    //         }
    //         )
    //         .then(response => resolve(response))
    //         .catch(error => reject(error))
    //     })
    // },
    fetchBookingStages(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/booking_stage/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    updateBookingStage(ctx, data) {
      return new Promise((resolve, reject) => {
          axiosIns.post('/booking_stage', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBookingStage(ctx, queryParams)
    {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/booking_stage', {params:queryParams})
        .then(response=>resolve(response))
        .catch(error=>reject(error))
    })
    }
  },
}
