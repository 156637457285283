<template>
  
  <div class="invoice-detail">
    <invoice-list :fetchTrigger="trigger" :contract_id="contract_id" :mini_storage_id="mini_storage_id" :customer_id="customer_id" :in_rent="in_rent"></invoice-list>
  </div>
</template>
      
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api";
import invoiceList from "@/views/invoice/invoice-list/invoiceList.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    invoiceList,
  },
  methods: {},
  data() {
    return {};
  },
  props: {
    in_rent:{},
    customer_id:{},
    contract_id:{},
    mini_storage_id:{},
    trigger: {},
  },
  setup() {
    return {
    }
  },
};
</script>
      
<style lang="scss" scoped>
</style>
      
      <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
      
      