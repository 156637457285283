import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPrices(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/price/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchPrice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/price', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePrice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/price", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePrice(ctx, queryParams)
    {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/price', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
  },
}
