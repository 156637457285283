import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBookings(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/booking/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchBooking(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/booking', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    updateBooking(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/booking', data,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadContract(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/contract/export', { params: queryParams , responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/contract', data,)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getContractPeriods(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/contract/periods', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchWarehouse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/warehouse', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
