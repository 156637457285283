<template>
  <b-modal
    id="modal-rent-schedule-edit"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    headerClass="pr-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">租用記錄</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <div class="=" v-if="newRentScheduleData">
      <div class="d-flex align-items-center mb-2">
        <b>租用週期</b>
        <b-button
          @click="triggerForm"
          class="ml-2"
          v-if="!is_create"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
        >
          新增租期
        </b-button>
      </div>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserverB"
          v-if="is_create"
        >
          <b-form @submit.prevent="handleSubmit(onCreateSubmit)">
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="客戶名稱"
                  label-for="customer_id"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`客戶名稱`"
                    rules="required"
                  >
                    <v-select
                      v-model="createRentScheduleData.customer_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customerOptions"
                      label="id"
                      :reduce="(option) => option.id"
                      :clearable="false"
                      class="w-100"
                    >
                      <template #option="data">
                        {{ data.id }} - {{ data.name }}
                      </template>
                      <template #selected-option="data">
                        {{ data.id }} - {{ data.name }}
                      </template>
                    </v-select>

                    <b-form-invalid-feedback
                      :state="getValidationStateB(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="租用類型"
                  label-for="customer_id"
                  label-cols-xl="4"
                  class="required"
                >
                  <v-select
                    v-model="createRentScheduleData.rent_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="
                      rentTypeOptions &&
                      rentTypeOptions.filter((item) => item.internal_bit)
                    "
                    label="name"
                    :reduce="(option) => option.id"
                    placeholder="選擇租用類型"
                    class="mb-50"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="開始日期"
                  label-for="start_date"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`開始日期`"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="createRentScheduleData.start_date"
                      class="form-control"
                      :config="{
                        allowInput: true,
                        altInput: true,
                        altFormat: 'Y-m-d',
                        dateFormat: 'Y-m-d',
                      }"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationStateB(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="完結日期"
                  label-for="start_date"
                  label-cols-xl="4"
                  class="required"
                >
                  <flat-pickr
                    v-model="createRentScheduleData.end_date"
                    placeholder="可以不填"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                </b-form-group>
              </b-col>
              <!--   <b-col cols="4">
                <b-form-group
                  label="租用期"
                  label-for="end_date"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`租用期`"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="createRentScheduleData.rent_period"
                        class="form-control"
                        :state="getValidationStateB(validationContext)"
                      />
                      <b-input-group-append> 個月 </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col cols="4">
                <b-form-group
                  label="金額"
                  label-for="amount"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`金額`"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      v-model="createRentScheduleData.amount"
                      :state="getValidationStateB(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="4">
                <b-form-group
                  label="新租？"
                  label-for="start_bit"
                  label-cols-xl="4"
                  class="required"
                >
                  <b-form-checkbox
                    id="start_bit"
                    :checked="createRentScheduleData.start_bit"
                    name="start_bit"
                    switch
                    inline
                    v-model="createRentScheduleData.start_bit"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{ createRentScheduleData.start_bit ? "是" : "否" }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="完租？"
                  label-for="end_bit"
                  label-cols-xl="4"
                  class="required"
                >
                  <b-form-checkbox
                    id="end_bit"
                    :checked="createRentScheduleData.end_bit"
                    name="end_bit"
                    switch
                    inline
                    v-model="createRentScheduleData.end_bit"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{ createRentScheduleData.end_bit ? "是" : "否" }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="退租？"
                  label-for="end_bit"
                  label-cols-xl="4"
                  class="required"
                >
                  <b-form-checkbox
                    :checked="createRentScheduleData.terminate_bit"
                    name="terminate_bit"
                    switch
                    inline
                    v-model="createRentScheduleData.terminate_bit"
                    value="1"
                    style="margin-top: 5px"
                  >
                    {{ createRentScheduleData.terminate_bit ? "是" : "否" }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col> -->
              <b-col
                cols="12"
                class="d-flex flex-wrap justify-content-center w-100 mt-2 p-2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  type="submit"
                >
                  新增
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="is_create = false"
                >
                  取消
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
            <b-table
              ref="refRentScheduleListTable"
              class="position-relative rent_scheduleList_table"
              :items="newRentScheduleData"
              :fields="tableColumnsRentSchedule"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- primary-key="id" -->
              <template #cell(rent_type_id)="data">
                <v-select
                  v-model="data.item.rent_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="
                    rentTypeOptions &&
                    rentTypeOptions.filter((item) => item.internal_bit)
                  "
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="選擇租用類型"
                  class="mb-50"
                />
              </template>
              <template #cell(customer_id)="data">
                <validation-provider
                  #default="validationContext"
                  :vid="`rent_schedule_customer_id${data.index + 1}`"
                  :name="`客戶名稱`"
                  rules="required"
                >
                  <v-select
                    v-model="data.item.customer_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    label="id"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  >
                    <template #option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                    <template #selected-option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </template>

              <template #cell(start_date)="data">
                <validation-provider
                  #default="validationContext"
                  :vid="`rent_schedule_start_date${data.index + 1}`"
                  :name="`開始日期`"
                  rules="required"
                >
                  <flat-pickr
                    v-model="data.item.start_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </template>

              <template #cell(end_date)="data">
                <flat-pickr
                  v-model="data.item.end_date"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                />
              </template>

              <template #cell(amount)="data">
                <validation-provider
                  #default="validationContext"
                  :vid="`rent_schedule_amount${data.index + 1}`"
                  :name="`金額`"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="data.item.amount"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </template>
              <template #cell(start_bit)="data">
                <b-form-checkbox
                  :checked="data.item.start_bit"
                  name="start_bit"
                  switch
                  inline
                  v-model="data.item.start_bit"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ data.item.start_bit ? "是" : "否" }}
                </b-form-checkbox>
              </template>
              <template #cell(end_bit)="data">
                <b-form-checkbox
                  :checked="data.item.end_bit"
                  name="end_bit"
                  switch
                  inline
                  v-model="data.item.end_bit"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ data.item.end_bit ? "是" : "否" }}
                </b-form-checkbox>
              </template>
              <template #cell(terminate_bit)="data">
                <b-form-checkbox
                  :checked="data.item.terminate_bit"
                  name="terminate_bit"
                  switch
                  inline
                  v-model="data.item.terminate_bit"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ data.item.terminate_bit ? "是" : "否" }}
                </b-form-checkbox>
              </template>

              <template #cell(trash)="data">
                <a
                  href="javascript:void(0)"
                  @click="confirmDelete(data.item.id, data.index)"
                >
                  <feather-icon class="color1" icon="Trash2Icon" />
                </a>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                    {{ dataMeta.of }} entries</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRow"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                class="d-flex flex-wrap justify-content-center w-100 mt-2 p-2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  type="submit"
                >
                  提交
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  取消
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </div>
  </b-modal>
</template>
        
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BImg,
  BFormCheckbox,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios.js";
import {
  ref,
  watch,
  onUnmounted,
  onMounted,
  computed,
} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-rent-schedule-edit");
    },
    triggerForm() {
      this.is_create = true;
      this.createRentScheduleData = {
        id: 0,
        amount: 0,
        customer_id: null,
        end_bit: 0,
        end_date: null,
        mini_storage_id: this.mini_storage_id,
        rent_type_id: null,
        start_bit: 0,
        start_date: null,
        end_date: null,
        terminate_bit: 0,
        rent_period: 0,
      };
    },
    checkDateConflict(newData, existingData) {
      // 设定 end_date
      if (newData.end_date === null) {
        newData.end_date = new Date(); // 设置为今天的日期
      }

      // 转换日期为时间戳以方便比较
      let newStart = new Date(newData.start_date).getTime();
      let newEnd = new Date(newData.end_date).getTime();

      for (let data of existingData) {
        if (data.start_date === null) continue; // 如果存在数据的开始或结束日期为空，则跳过此次循环

        let existingStart = new Date(data.start_date).getTime();
        let existingEnd = data.end_date
          ? new Date(data.end_date).getTime()
          : new Date(new Date()).getTime();

        // 检查日期是否冲突
        if (
          (newStart >= existingStart && newStart <= existingEnd) || // 新开始日期在现有日期范围内
          (newEnd >= existingStart && newEnd <= existingEnd) || // 新结束日期在现有日期范围内
          (newStart <= existingStart && newEnd >= existingEnd)
        ) {
          // 新的日期范围包含现有的日期范围
          return true; // 存在冲突
        }
      }

      // 如果循环完成没有找到冲突，返回false
      return false;
    },
    onCreateSubmit() {
      if (
        !this.checkDateConflict(
          this.createRentScheduleData,
          this.newRentScheduleData
        )
      ) {
        this.newRentScheduleData.push(this.createRentScheduleData);

        this.createRentScheduleData = {
                id: 0,
                amount: 0,
                customer_id: null,
                end_bit: 0,
                end_date: null,
                mini_storage_id: this.mini_storage_id,
                rent_type_id: null,
                start_bit: 0,
                start_date: null,
                end_date: null,
                terminate_bit: 0,
                rent_period: 0,
              };
        this.$swal({
          text: "已暫時增加記錄，請記得按提交！",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      } else {
        console.log("日期有冲突，不能添加");
        this.$swal({
          text: "加入的日期和現有的有衝突",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }

      /* axiosIns
        .get("rent_schedule/calculation", {
          params: this.createRentScheduleData,
        })
        .then((response) => {
          const { rent_schedules } = response.data;
          var old = [...this.newRentScheduleData];

          rent_schedules.forEach((ele) => {
            ele.customer_id = Number(ele.customer_id);
            ele.rent_type_id = Number(ele.rent_type_id);
            ele.amount = Number(ele.amount);
            ele.mini_storage_id = Number(ele.mini_storage_id);
          });

          this.newRentScheduleData = [
            ...this.newRentScheduleData,
            ...rent_schedules,
          ];

          this.createRentScheduleData = {
            amount: 0,
            customer_id: null,
            end_bit: 0,
            end_date: null,
            mini_storage_id: this.mini_storage_id,
            rent_type_id: null,
            start_bit: 0,
            start_date: null,
            terminate_bit: 0,
            rent_period: 0,
          };
          this.is_create = false;
          this.onSubmit();
        })
        .catch((error) => {
          console.log(error);
        }); */
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          var data = { rent_schedules: [...this.newRentScheduleData] };

          if (data.rent_schedules.length > 0) {
            data.rent_schedules.forEach((ele) => {
              ele.start_bit = ele.start_bit ? 1 : 0;
              ele.end_bit = ele.end_bit ? 1 : 0;
              ele.terminate_bit = ele.terminate_bit ? 1 : 0;
            });
          }

          axiosIns
            .post("rent_schedule/", data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmDelete(id, index) {
      if (id > 0) {
        this.$swal({
          title: "你確定嗎?",
          text: "您的操作是最終的，您無法恢復資料。",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            axiosIns
              .delete("rent_schedule/", { params: { id: id } })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.newRentScheduleData.splice(index, 1);
              })
              .catch((error) => {
                console.log(error);

                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      } else {
        this.newRentScheduleData.splice(index, 1);
      }
    },
  },
  data() {
    return {
      is_create: false,
    };
  },
  props: {
    rentScheduleData: {},
    customerOptions: {},
    rentTypeOptions: {},
    mini_storage_id: {},
  },
  setup(props) {
    const refRentScheduleListTable = ref(null);

    const tableColumnsRentSchedule = [
      { key: "customer_id", label: "客戶", sortable: true },
      { key: "rent_type_id", label: "租用類型", sortable: true },
      { key: "start_date", label: "開始日期", sortable: true },
      { key: "end_date", label: "結束日期", sortable: true },
      { key: "amount", label: "總值", sortable: true },
      { key: "start_bit", label: "新租", sortable: true },
      { key: "end_bit", label: "完租", sortable: true },
      { key: "terminate_bit", label: "退租", sortable: true },
      { key: "trash", label: "刪除" },
    ];
    const perPage = ref(10);
    const totalRow = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
      const localItemsCount = refRentScheduleListTable.value
        ? refRentScheduleListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRow.value,
      };
    });

    const refetchTbData = (total, meta, perPage, current, target, from) => {
      total.value = from && from.length;
      const min = Number(meta.value.from - 1);
      const max = Number(perPage.value * current.value - 1);
      target.value =
        from && from.filter((ele, index) => index >= min && index <= max);
    };

    const newRentScheduleData = ref(null);
    watch(
      () => props.rentScheduleData,
      (newVal) => {
        newRentScheduleData.value = [...newVal];

        // refetchTbData(
        //   totalRow,
        //   dataMeta,
        //   perPage,
        //   currentPage,
        //   newRentScheduleData,
        //   props.rentScheduleData
        // );
      }
      // { immediate: true }
    );

    const resetRentData = () => {
      newRentScheduleData.value = [];
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetRentData);

    const createRentScheduleData = ref(null);
    const resetRentDataB = () => {
      createRentScheduleData.value = {};
    };

    const refFormObserverB = formValidation(resetRentDataB).refFormObserver,
      getValidationStateB = formValidation(resetRentDataB).getValidationState,
      resetFormB = formValidation(resetRentDataB).resetForm;

    // watch(
    //   () => currentPage.value,
    //   (newVal) => {
    //     refetchTbData(
    //       totalRow,
    //       dataMeta,
    //       perPage,
    //       currentPage,
    //       newRentScheduleData,
    //       props.rentScheduleData
    //     );
    //   }
    // );
    const loading = ref(false);

    return {
      tableColumnsRentSchedule,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRentScheduleListTable,
      newRentScheduleData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetRentData,
      refFormObserverB,
      getValidationStateB,
      resetFormB,
      createRentScheduleData,
      refetchTbData,
      loading,
    };
  },
};
</script>
        
  <style lang="scss" scoped>
</style>
        
        <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.rent_scheduleList_table {
  .v-select {
    min-width: 200px;
  }
  .input-group-append {
    background: #fff;
    margin-left: 0px;
    width: 45px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d4d4d4;
  }
  input[type="number"] {
    width: 200px;
  }
}
</style>
        
        