<template>
  <b-modal
    id="modal-edit-task-calendar"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    :visible="isEventHandlerSidebarActive"
    centered
    body-class="common_modal bgWhite modal_visible modal-format1"
    hide-header
    hide-footer
    size="xl"
    @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
  >
    <!-- no-close-on-backdrop -->
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <!-- {{ isEdit ? "修改" : "新增" }} -->
      <h5 class="colorWhite">事件</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <task-edit-tab-information @hide="hide" 
      @remove-event="$emit('remove-event')"
      @update-event="$emit('update-event')"
      @refetch-events="$emit('refetch-events')"
     :taskData="taskData"></task-edit-tab-information>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTextarea,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import TaskEditTabInformation from "../task-edit/task-edit-tab-information.vue";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    BCardBody,
    vSelect,
    BCard,
    TaskEditTabInformation,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    taskData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },

  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-task-calendar");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.modal-format1 {
  .form-group {
    margin-bottom: 0px;
  }
  label {
    font-weight: 800;
  }
}
</style>
<style lang="scss" scoped>
</style>
