<template>
  <div>
    <div class="d-flex justify-content-end">
      <!-- s -->
      <h4 class="mb-0 ml-50">
        Balance: <strong>{{ customerData.points }}</strong>
      </h4>
    </div>
    <b-card no-body class="mb-0 mt-2">
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refCustomerPointTable"
            class="position-relative"
            :items="customerData.loyalty_points"
            :fields="tableColumns"
            responsive
          >
            <template #cell(created_at)="data">
              {{ convertToLocalDate(data.item.created_at) }}
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-start align-items-center">
            <h4>添加積分</h4>
          </div>
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <validation-provider
                  #default="validationContext"
                  name="Remark"
                  rules="required"
                >
                  <b-form-group label="描述添加原因" label-for="remark" class="required"  label-cols-md="3">
                    <b-form-input
                      id="remark"
                      v-model="remark"
                      autofocus
                      trim
                      placeholder=" 描述添加原因"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="Remark"
                  rules="required"
                >
                  <b-form-group label="積分" label-for="points" class="required"  label-cols-md="3">
                    <b-form-input
                      id="points"
                      v-model="points"
                      autofocus
                      trim
                      placeholder=" 積分"
                      type="number"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                  <div class="d-flex justify-content-end">
                 <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                新增積分
              </b-button>
              </div>
              </b-form>
            
            </validation-observer>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { convertToLocalDate } from "@/libs/helper";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableColumns: [
        { key: "id" },
        { key: "remark" },
        { key: "points" },
        { key: "created_at" },
      ],
      convertToLocalDate,
    };
  },
  methods:{
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-customer/addPoints", {customer_id: this.customerData.id, points: this.points, remark: this.remark})
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
              this.remark = null;
              this.points = null;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const remark = ref(null);
    const points = ref(null);
    const loading = ref(false);

    const resetData = () => {
      remark.value = null;
      points.value = null;
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      remark,
      points,
      loading,
    };
  },
};
</script>