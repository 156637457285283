<template>
  <div class="lease-detail">
    <modal-edit-sign
      :signData="signData"
      :signStatusOptions="signStatusOptions"
      @refetch-data="newLeaseData.sign_status_id = signData.sign_status_id"
    />
    <div class="lease-info" v-if=newLeaseData>
      <div>
        <h4 class="text-center color1">租約</h4>
        <!-- <div class="lease-image">
          <el-upload
            v-if="isEdit"
            class="avatar-uploader"
            list-type="picture-card"
            ref="upload"
            :action="uploadUrl"
            :headers="headerObj"
            name="image_file"
            :file-list="fileList"
            multiple
            :limit="1"
            :data="newLeaseData.file"
            :on-remove="toggleUpload"
            :on-change="onChange"
            :auto-upload="false"
            :class="{ hideUpload: !showUpload }"
          >
            <img
              v-if="newLeaseData && newLeaseData.image_path"
              :src="newLeaseData.image_path"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div v-else>
            <img
              v-if="newLeaseData && newLeaseData.image_path"
              :src="newLeaseData.image_path"
              class="avatar"
            />
            <div class="d-grid place-items-center empty-img">No image data</div>
          </div>
        </div> -->
      </div>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="lease-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap" v-if="leaseId">
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">合約編號</b>
                <span>{{ newLeaseData && newLeaseData.contract_number }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">合約日期</b>
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="合約日期"
                  rules="required"
                >
                  <flat-pickr
                    v-model="newLeaseData.contract_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <span v-else>{{ newLeaseData.contract_date }}</span>
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">期間</b>
                <div class="d-flex align-items-center" v-if="isEdit">
                  <validation-provider
                    #default="validationContext"
                    name="開始日期"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="newLeaseData.start_date"
                      class="form-control"
                      :config="{
                        allowInput: true,
                        altInput: true,
                        altFormat: 'Y-m-d',
                        dateFormat: 'Y-m-d',
                      }"
                      disabled
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <span class="mx-50">至</span>
                  <validation-provider
                    #default="validationContext"
                    name="結束日期"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="newLeaseData.end_date"
                      class="form-control"
                      :config="{
                        allowInput: true,
                        altInput: true,
                        altFormat: 'Y-m-d',
                        dateFormat: 'Y-m-d',
                      }"
                      disabled
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <span v-else
                  >{{ newLeaseData.start_date }} 至
                  {{ newLeaseData.end_date }}</span
                >
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >預付款 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="預付款"
                  rules="required"
                >
                  <v-select
                    v-model="newLeaseData.prepay_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="prePayTypeOptions"
                    label="type_name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                    disabled
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <span v-else>
                  {{
                    prePayTypeOptions &&
                    prePayTypeOptions.length > 0 &&
                    prePayTypeOptions.find(
                      (ele) => ele.id == newLeaseData.prepay_type_id
                    ).type_name
                  }}
                </span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >租用期間 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="租用期間"
                  rules="required"
                >
                  <v-select
                    v-model="newLeaseData.rent_period"
                    :clearable="false"
                    label="name"
                    :options="rentPeriodOptions"
                    :reduce="(option) => option.id"
                    disabled
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <span v-else> {{ newLeaseData.rent_period }}個月 </span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >總值 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="總值"
                  rules="required"
                >
                  <b-form-input
                    id="period_amount"
                    v-model="newLeaseData.amount"
                    :state="getValidationState(validationContext)"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <span v-else>{{ newLeaseData.amount }}</span>
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >按金 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="按金"
                  rules="required"
                >
                  <b-form-input
                    id="period_deposite"
                    v-model="newLeaseData.deposite"
                    :state="getValidationState(validationContext)"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <span v-else>{{ newLeaseData.deposite }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >租金 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="租金"
                  rules="required"
                >
                  <b-form-input
                    id="period_price"
                    v-model="newLeaseData.price"
                    :state="getValidationState(validationContext)"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <span v-else>{{ newLeaseData.price }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >標準租金 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="租金"
                  rules="required"
                >
                  <b-form-input
                    id="period_standard_price"
                    v-model="contractPediod.standard_price"
                    :state="getValidationState(validationContext)"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <span v-else>{{ newLeaseData.standard_price }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">智能卡</b>

                <el-select
                  v-if="isEdit"
                  v-model="newLeaseData.smart_cards"
                  class="w-100"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  no-data-text="無數據"
                  placeholder='輸入內容 並 按下"Enter"以創建 智能卡'
                >
                </el-select>

                <span v-else>{{ newLeaseData.smart_cards.toString() }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">智能卡按金</b>

                <b-form-input
                  v-if="isEdit"
                  id="period_card_deposite"
                  v-model="newLeaseData.card_deposite"
                  disabled
                />

                <span v-else>{{ newLeaseData.card_deposite }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >銷售顧問 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="銷售顧問"
                  rules="required"
                >
                  <v-select
                    v-model="newLeaseData.sale_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="saleOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <span v-else>{{
                  newLeaseData.sale_id &&
                  saleOptions &&
                  saleOptions.length > 0 &&
                  saleOptions.find((ele) => ele.id == newLeaseData.sale_id).name
                }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >簽約狀態 <span class="colorRed">*</span></b
                >
                <validation-provider
                  v-if="isEdit"
                  #default="validationContext"
                  name="簽約狀態"
                  rules="required"
                >
                  <v-select
                    v-model="newLeaseData.sign_status_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="signStatusOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    class="w-100"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <span class="d-flex align-items-center" v-else>
                  {{ getSignStatus ? getSignStatus.name : '' }}
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="ml-25 cursor-pointer"
                    @click="handleSign(newLeaseData)"
                  />
                </span>
              </div>
            </b-col>
            
            <b-col cols="12" xl="4">
              <div class="info-item" >
                <b class="info-item-label">簽約時間 </b>
                <flat-pickr v-if="isEdit"
                  v-model="newLeaseData.sign_date"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d H:i',
                    dateFormat: 'Y-m-d H:i',
                  }"
                />
              <span v-else>
                  {{ newLeaseData.sign_date }}
              </span>
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">備註</b>
                <b-form-input
                  v-if="isEdit"
                  class="info-item-value"
                  v-model="newLeaseData.remark"
                ></b-form-input>
                <span v-else>{{ newLeaseData.remark }}</span>
              </div>
            </b-col>
            <div
              class="d-flex flex-wrap w-100 justify-content-end"
              v-if="isEdit"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="isEdit = null"
              >
                取消
              </b-button>
            </div>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <mini-storage-detail :storageData="storageDetail" :customer-options="customerOptions" :rent-type-options="rentTypeOptions" @refetch-data="refetchData"></mini-storage-detail>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BForm,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios.js";
import { ref, watch, onUnmounted, onMounted, computed } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import miniStorageDetail from "./mini-storage-detail.vue";
import ModalEditSign from "@/views/contract/new/modal/modal-edit-sign.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    miniStorageDetail,
    ModalEditSign
  },
  directives: {
    Ripple,
  },
  methods: {
    refetchData(){
      this.$emit("refetch-data");
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    getImgPath(path) {
      return process.env.VUE_APP_IMAGE_URL + path;
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("id", this.leaseData.id);
          formData.append("file", this.leaseData.file);
          formData.append("smart_cards", this.leaseData.smart_cards);
          formData.append("contract_date", this.leaseData.contract_date);
          formData.append("remark", this.leaseData.remark);
          formData.append("sale_id", this.leaseData.sale_id);

          this.loading = true;
          store
            .dispatch("contract/updateContract", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.refetchData();
                this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleSign(item) {
      this.signData = {
        id: item.id,
        sign_date: item.sign_date,
        sign_status_id: item.sign_status_id,
      };
      this.$bvModal.show("modal-edit-sign");
    },
    // getValidationState({ dirty, validated, valid = null }) {
    //   return dirty || validated ? valid : null;
    // },
  },
  props: {
    leaseData: {},
    isEdit: {},
    storageData: {},
    prePayTypeOptions: {},
    saleOptions: {},
    storageDetail:{},
    leaseId: null,
    customerOptions:{},
    rentTypeOptions:{},
    signStatusOptions:{}
  },
  data() {
    return {
      required,
      signData:{}
    };
  },
  computed: {
    signStatus: function(){
      return this.signStatusOptions && this.signStatusOptions.length > 0 && this.signStatusOptions.find(ele => ele.id === this.newLeaseData.sign_status_id).name 
    }
  },
  setup(props) {
    const loading = ref(false);
    const fileList = ref([]);
    const showUpload = ref(true);

    const resetLeaseData = () => {
      props.leaseData = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetLeaseData);

    const onChange = (file, fileLists) => {
      showUpload.value = !showUpload.value;
      props.leaseData.file = file.raw;
    };

    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/lease");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const newLeaseData = ref({});

    const getSignStatus = computed(() => {
      return props.signStatusOptions.find(option => option.id === newLeaseData.value.sign_status_id);
    });

    const getPrePayType = computed(() => {
      return props.prePayTypeOptions.find(option => option.id === newLeaseData.value.prepay_type_id);
    });

    const getSale = computed(() => {
      return props.saleOptions.find(option => option.id === newLeaseData.value.sale_id);
    });

    watch(() => props.leaseData, (newVal) => {
      newLeaseData.value = { ...newVal };
    }, { immediate: true, deep: true });

    const rentPeriodOptions = computed(() => {
      if (newLeaseData.value.prepay_type_id && props.prePayTypeOptions.length > 0) {
        const target = getPrePayType.value;
        if (target) {
          let month = target.months;
          return Array.from({ length: Math.floor(24 / month) }, (_, i) => ({
            id: (i + 1) * month,
            name: `${(i + 1) * month}個月`
          }));
        }
      }
      return [];
    });

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      fileList,
      onChange,
      uploadUrl,
      headerObj,
      showUpload,
      rentPeriodOptions,
      newLeaseData,
      getSignStatus,
      getPrePayType,
      getSale,
    };
  },
};
</script>
  
<style lang="scss" scoped>
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.storage-info {
  .info-wrap .info-item b {
    white-space: nowrap;
    width: auto;
    margin-right: 0.5rem;
  }
}
.lease-detail{
  .info-wrap .info-item{
    height: 28px;
  }
  .vs__actions{
    padding: 4px 3px 0 3px
  }
  .form-group{
    // margin-bottom: 0rem;
  }
}
</style>
  
  
