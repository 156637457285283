import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from "@/libs/acl/ability";

export default function useEmailsList() {

    const toast = useToast()

    const refEmailListTable = ref(null)

    const tableColumns = [
        { key: 'created_at', label: '生成日期', sortable: true },
        { key: 'title', label: '模版名稱' },
        { key: 'subject', label: '模版主題' },
        { key: 'content', label: '模版內容', sortable: true },
        { key: 'is_active', label: '狀態', sortable: true },
        { key: 'action', label: '操作' },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refEmailListTable.value ? refEmailListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refEmailListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })


    const templateList = ref(null)

    const fetchEmailsTemplate = (ctx, callback) => {
        store
          .dispatch("email/fetchEmailTemplates", {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
          })
          .then((response) => {
            console.log("email templates response", response);
            const { email_templates, total } = response.data;
            callback(email_templates);
            templateList.value = email_templates
            totalRow.value = total;
          })
          .catch((error) => {
            console.log(error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching email templates list",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      };

    return {
        fetchEmailsTemplate,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refEmailListTable,
        showMessage,
        templateList,
        ability
    }

}