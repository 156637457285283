import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRentReports(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/warehouse/rent-report', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    getCustomerDistrictDistribution(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/statistic/customer-district-distribution', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
  },
}
