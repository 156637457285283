<template>
  <b-modal
    id="modal-edit-price"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @show="resetModal"
    @hide="resetModal"
  >

    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">{{isEdit ? '修改' : '新增'}}價目表</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="price-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap ">
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">地址</b>
                <b-form-input class="info-item-value" v-model="newPriceData.location"></b-form-input>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
            <div class="info-item">
                <b class="info-item-label">位置</b>
                <b-form-input class="info-item-value" v-model="newPriceData.position"></b-form-input>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">闊x深x高(呎)</b>
                <div class="d-flex align-items-center info-item-value">
                <b-form-input class="" v-model="newPriceData.width"></b-form-input>
                <span class="mx-50">X</span>
                <b-form-input class="" v-model="newPriceData.depth"></b-form-input>
                <span class="mx-50">X</span>
                <b-form-input class="" v-model="newPriceData.height"></b-form-input>
                </div>
              </div>
            </b-col>
            <b-col cols="12" xl="4">  
              <div class="info-item">
                <b class="info-item-label">面積（平方呎</b>
                <span class="info-item-value" v-if="newPriceData.width && newPriceData.depth">{{ newPriceData.width * newPriceData.depth }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">  
              <div class="info-item">
                <b class="info-item-label">每月定價</b>
                <b-form-input class="info-item-value" v-model="newPriceData.monthly_price"></b-form-input>
              </div>
            </b-col>
            <b-col cols="12" xl="4">  
              <div class="info-item">
                <b class="info-item-label">6個月合約價格</b>
                <b-form-input class="info-item-value" v-model="newPriceData.bi_annual_price"></b-form-input>
              </div>
            </b-col>
            <b-col cols="12" xl="4">  
              <div class="info-item">
                <b class="info-item-label">12個月合約價格</b>
                <b-form-input class="info-item-value" v-model="newPriceData.annual_price"></b-form-input>
              </div>
            </b-col>
            <b-col cols="12" xl="4">  
              <div class="info-item">
                <b class="info-item-label" >備註</b>
                <b-form-input class="info-item-value" v-model="newPriceData.remark"></b-form-input>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mobile-w100 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="mobile-w100 px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    priceData: {},
    isEdit: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-price");
    },
    resetModal(){
      this.$emit('fouceIsEditFalse')
    },

    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          this.loading = true;
          store
            .dispatch("price/updatePrice", )
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  watch: {

  },
  setup(props){
    const loading = ref(false);
    const newPriceData = ref({})

    const resetPriceData = () => {
      newPriceData.value = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPriceData);

    watch(
      () => props.priceData,
      (newVal) => {
        newPriceData.value = newVal
      }
    )
    return{
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newPriceData,
    }
  }
};
</script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";


</style>
  