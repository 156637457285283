<template>
  <b-sidebar
    id="add-new-warehouse-sidebar"
    :visible="isAddNewWarehouseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-warehouse-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">倉庫</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
          <validation-provider
              #default="validationContext"
              name="類別"
              rules="required"
            >
            <b-form-group label="類別" label-for="warehouse_category_id">
                <v-select
                    id="warehouse_category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="warehouseData.warehouse_category_id"
                    :options="warehouseOptions"
                    label="name"
                    class="w-100"
                    :reduce="(val) => val.id"
                />
            </b-form-group>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </validation-provider>

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="名稱"
              rules="required"
            >
              <b-form-group label="名稱" label-for="name">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="名稱"
                  v-model="warehouseData.name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Location-->
            <b-form-group label="位置" label-for="location">
              <b-form-input
                id="location"
                type="text"
                placeholder="Location"
                v-model="warehouseData.location"
              />
            </b-form-group>

            <!-- Tel-->
            <b-form-group label="電話" label-for="tel">
              <b-form-input
                id="tel"
                type="text"
                placeholder="電話"
                v-model="warehouseData.tel"
              />
            </b-form-group>

            <!-- Email-->
            <b-form-group label="電郵" label-for="email">
              <b-form-input
                id="email"
                type="email"
                placeholder="電郵"
                v-model="warehouseData.email"
              />
            </b-form-group>

            <!-- Description-->
            <b-form-group label="描述" label-for="content">
              <b-form-textarea
                id="content"
                v-model="warehouseData.content"
                rows="5"
              ></b-form-textarea>
            </b-form-group>

            <!-- Sort order -->
            <validation-provider
              #default="validationContext"
              name="排序"
              rules="required"
            >
              <b-form-group label="排序">
                <b-form-input
                  type="number"
                  placeholder="排序"
                  v-model="warehouseData.sort_order"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Status"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="warehouseData.is_active" /> -->
              <b-form-checkbox
                :checked="warehouseData.is_active"
                name="is_active"
                switch
                inline
                v-model="warehouseData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ warehouseData.is_active ? "啟用" : "禁止" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
  
  <script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import warehouseStoreModule from "../warehouseStoreModule";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { statusOptions } from "@/libs/helper";

export default {
  data() {
    return {
      statusOptions,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BDropdown,
    BDropdownItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("warehouse/addWarehouse", this.warehouseData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-add-new-warehouse-sidebar-active", false);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },

  model: {
    prop: "isAddNewWarehouseSidebarActive",
    event: "update:is-add-new-warehouse-sidebar-active",
  },
  props: {
    isAddNewWarehouseSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    warehouseOptions:{
        type: Array,
        required: true
    }
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const APP_FAQ_STORE_MODULE_NAME = "warehouse";

    if (!store.hasModule(APP_FAQ_STORE_MODULE_NAME))
      store.registerModule(APP_FAQ_STORE_MODULE_NAME, warehouseStoreModule);
    // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const blankData = ref({
      id: 0,
      warehouse_category_id: null,
      is_active: 1,
      name: "",
      location: "",
      tel: "",
      email: "",
      content: "",
      sort_order: "",
    });

    const warehouseData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetwarehouseData = () => {
      warehouseData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetwarehouseData);

    watch(
      () => [props.id, props.isAddNewWarehouseSidebarActive],
      () => {
        if (props.isAddNewWarehouseSidebarActive) {
          warehouseData.value = JSON.parse(JSON.stringify(props.data));
        }
      }
    );

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      warehouseData,
    };
  },
};
</script>
  
  <style>
.b-sidebar.b-sidebar-right {
  width: 50% !important;
}

.ql-editor {
  min-height: 350px;
}

@media screen and (max-width: 780px) {
  .b-sidebar.b-sidebar-right {
    width: 100% !important;
  }
}
</style>