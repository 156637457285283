import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/warehouse_category/list',{params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchCategory(ctx, qureyParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/warehouse_category`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addCategory(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/warehouse_category', data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteCategory(ctx, qureyParams){
      return new Promise((resolve, reject) => {
        axiosIns.delete(`/warehouse_category`, {params:qureyParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchCategoryOptions(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/option/list', data
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
        // }
        )
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }

  },
}
