<template>
  <b-sidebar
    id="add-new-customer-sidebar"
    :visible="isAddNewCustomerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-customer-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ button_text }} Customer</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group label="Name" label-for="name" class="required">
                <b-form-input
                  id="name"
                  v-model="customerData.name"
                  autofocus
                  trim
                  placeholder=" Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group label="Email" label-for="email" class="required">
                <b-form-input
                  id="email"
                  v-model="customerData.email"
                  :state="getValidationState(validationContext)"
                  autofocus
                  trim
                  placeholder="Email"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="HKID / BR" label-for="id_number">
              <b-form-input
                id="id_number"
                v-model="customerData.id_number"
                autofocus
                trim
                placeholder="HKID / BR"
              />
            </b-form-group>

            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="customerData.mobile"
                autofocus
                trim
                placeholder="mobile"
              />
            </b-form-group>

            <b-form-group label="Address" label-for="address">
              <b-form-input
                id="address"
                v-model="customerData.address"
                autofocus
                trim
                placeholder="Address"
              />
            </b-form-group>

            <template v-if="customerData.id === 0">
              <validation-provider
                #default="validationContext"
                name="Password"
                vid="vid_password"
                rules="required|min:8"
              >
                <b-form-group
                  label="Password："
                  label-for="password"
                  class="required"
                >
                  <b-form-input
                    id="password"
                    v-model="customerData.password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    placeholder="Password"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="Comfirm Password"
                rules="required|min:8|confirmed:vid_password"
              >
                <b-form-group
                  label="Comfirm Password："
                  label-for="confirm_p"
                  class="required"
                >
                  <b-form-input
                    id="confirm_p"
                    v-model="confirm_p"
                    type="password"
                    :state="getValidationState(validationContext)"
                    placeholder="Comfirm Password"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>

             <b-form-group label="Remark" label-for="remark">
                 <b-form-textarea
                  id="remark"
                  v-model="customerData.remark"
                  rows="3"
                  >
                  </b-form-textarea>
              </b-form-group>

            <b-form-group
              label="Status"
              label-for="is_active"
              label-cols-md="3"
              class="mt-2"
            >
              <!-- <b-form-input id="is_active"  v-model="customerData.is_active" /> -->
              <b-form-checkbox
                :checked="customerData.is_active"
                name="is_active"
                switch
                inline
                v-model="customerData.is_active"
                value="1"
                style="margin-top: 5px"
              >
                {{ customerData.is_active ? "Active" : "InActive" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { getUserData } from "@/auth/utils";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import useCustomersList from "./useCustomersList";

export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    hide() {
      this.$emit("update:is-add-new-customer-sidebar-active", false);
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("customer/updateCustomer", this.customerData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  model: {
    prop: "isAddNewCustomerSidebarActive",
    event: "update:is-add-new-customer-sidebar-active",
  },
  props: {
    isAddNewCustomerSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      confirm_p: "",
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const button_text = ref("Add New");

    const blankCustomerData = ref({
      id: 0,
      name: "",
      id_number: "",
      email: "",
      mobile: "",
      address: "",
      is_active: 1,
      remark: "",
    });

    const customerData = ref(
      JSON.parse(JSON.stringify(blankCustomerData.value))
    );

    const resetcustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(blankCustomerData.value));
      button_text.value = "Add New";
    };

    watch(
      () => [props.id, props.isAddNewCustomerSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewCustomerSidebarActive) {
          customerData.value = { ...props.data };
          button_text.value = "Edit";
        }
      }
    );

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetcustomerData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
      customerData,
    };
  },
};
</script>

<style lang="scss">
</style>