import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from '@/auth/utils'

export default function useBookingStagesList(){

    const toast = useToast()

    const refBookingStageListTable = ref(null)

    const tableColumns = [
        {key:'id', label:'編號'},
        {key:'stage', label:'階段',  sortable:true},
        {key:'description', label:'描述',  sortable:true},
        {key:'background_color', label:'顏色',  sortable:true},
        {key:'font_color', label:'字體顏色',  sortable:true},
        {key:'actions', label:'操作'}
    ]

    const perPage = ref(10)
    const totalBookingStages = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    

    const dataMeta = computed(() => {
        const localItemsCount = refBookingStageListTable.value ? refBookingStageListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBookingStages.value,
        }
    })

    const showMessage = (title,text,icon, variant) =>{
        toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon,
              variant
            },
        })
    }

    const refetchData = () =>{
        refBookingStageListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const fetchBookingStages = (ctx, callback) => {

        store.dispatch('booking_stage/fetchBookingStages', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            //console.log(response.data)
            const { bookings, total} = response.data
            callback(bookings)
            totalBookingStages.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching booking stages list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
            })
        })
    }



    return {
        fetchBookingStages,
        tableColumns,
        perPage,
        currentPage,
        totalBookingStages,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refBookingStageListTable,
        showMessage,
    }

}