import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useAppointmentsList() {
    //User toast
    const toast = useToast()

    const refAppointmentListTable = ref(null)

    const tableColumns = [
        {key: 'id', label:'編號', sortable: true},
        { key: 'warehouse.name', 'label': '倉庫'},
        { key: 'appointment_date', 'label': '預約時間', sortable: true},
        { key: 'contact_name', 'label': '聯絡人姓名', sortable: true},
        { key: 'contact_number', 'label': '聯絡人電話', sortable: true},
        { key: 'contact_email', 'label': '聯絡人電郵', sortable: true},
        { key: 'customer_remark', 'label': '客戶備註'},
        { key: 'admin_remark', 'label': '管理員備註'},
        { key: 'ip'},
        {key: 'actions', 'label': '動作'},
    ]

    const perPage = ref(10)
    const totalAppointments = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    //Filter 
    const warehouseFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refAppointmentListTable.value ? refAppointmentListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalAppointments.value,
        }
    })

    const refetchData = () => {
        refAppointmentListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, warehouseFilter], () => {
        refetchData()
    })

    const fetchAppointments = (ctx, callback) => {
        store.dispatch('appointment/fetchAppointments',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            warehouse_id: warehouseFilter.value, // As defined -1 means get all appointment not matter it is active or not
 
        })
        .then(response =>{
            const {appointments, total} = response.data
            callback(appointments)
            totalAppointments.value = total        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching appointment list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchAppointments,
        tableColumns,
        perPage,
        currentPage,
        totalAppointments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refAppointmentListTable,
        ability,
        refetchData,
        warehouseFilter, 
    }

}