<template>
  <b-card no-body v-show="false">
    <b-card-header class="pb-50">
      <h5>筛选器</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>显示类别</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="bannerCategoryFilter"
            :options="filteredBannerOptions"
            label="name"
            class="w-100"
            :reduce="(val) => val.id"
            @input="(val) => $emit('update:bannerCategoryFilter', val)"
            @search="searchQuery = $event"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label></label>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="reset"
              style="height: 40px"
            >
              重置
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, computed, watch, onMounted } from "@vue/composition-api";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
  },
  props: {
    bannerCategoryFilter: {
      type: [Number, null],
      default: null,
    }, 
    bannerOptions: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const bannerCategoryFilter = ref(props.bannerCategoryFilter);

    const filteredBannerOptions = computed(() => {
      return props.bannerOptions.filter(option => option.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
    });

    const searchQuery = ref('');

    watch(() => props.bannerCategoryFilter, (newVal) => {
      bannerCategoryFilter.value = newVal;
    });

    const checkRoute = (routerName) => {
      if(routerName === 'main-banners-list'){
        emit("update:bannerCategoryFilter", 1);
      } else if(routerName === 'news-banners-list'){
        emit("update:bannerCategoryFilter", 2);
      } else if(routerName === 'about-banners-list'){
        emit("update:bannerCategoryFilter", 3);
      }
    };

    const reset = () => {
      emit("update:bannerCategoryFilter", null);
    };

    onMounted(() => {
      const routerName = window.$nuxt.$route.name;
      checkRoute(routerName);
    });

    watch(() => window.$nuxt.$route.name, (newVal, oldVal) => {
      console.log('route', newVal, oldVal);
      checkRoute(newVal);
    });

    return {
      bannerCategoryFilter,
      filteredBannerOptions,
      searchQuery,
      reset,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
