<template>
  <div>
    <div class="d-flex">
      <h4 class="mb-0">{{ tab_title }}</h4>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-1">
        <b-form-group
          class="required"
          label="產品編號"
          label-for="id"
          label-cols-md="4"
          style="display: none"
        >
          <b-form-input id="id" v-model="warehouseData.id" disabled />
        </b-form-group>

        <b-form-group
          class=""
          label="上傳圖片"
          label-for="upload_image"
          label-cols-md="4"
        >
          <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            ref="upload_image"
            :action="uploadUrl"
            :headers="headerObj"
            name="image_files"
            multiple
            :limit="20"
            :data="warehouseData"
            :on-change="onChange"
            :on-remove="onChange"
            :auto-upload="false"
            :file-list="fileList"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </b-form-group>

        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="handleSubmit"
          >
            提交
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
          >
            取消
          </b-button>
        </div>
      </b-form>
      <hr />
      <b-card v-if="warehouseData.images">
        <b-table
          responsive
          :items="
            warehouseData.images.filter(
              (item) => item.image_type_id == image_type_id
            )
          "
          :fields="tableColumns"
        >
          <template #cell(image)="data">
            <img :src="data.item.image_path" style="max-width: 300px" />
          </template>
          <!-- Column: Actions -->
          <template #cell(name)="data">
            <b-input-group>
              <b-form-input
                type="text"
                placeholder="平面圖名稱"
                v-model="data.item.name"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SaveIcon"
                  class="cursor-pointer text-success"
                  @click="updateName(data.item.id, data.item.name)"
                />
              </b-input-group-append>
            </b-input-group>
          </template>
          <template #cell(actions)="data">
            <b-button
              class="align-middle ml-50 text-danger"
              variant="danger"
              size="sm"
              @click="deleteImage(data.item.id)"
              >刪除</b-button
            >
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>


<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
  BTable,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import { getImgPath } from "@/libs/helper";

export default {
  data() {
    return {
      getImgPath,
    };
  },
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BCardBody,
    BTable,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    warehouseData: {
      type: Object,
      required: true,
    },
    tab_title: {
      type: String,
      required: true,
    },
    image_type_id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateName(id, name)
    {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("warehouse/updateFloorPlanName", {
              id,name
            })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              //this.warehouseData = response.data.warehouseData
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deleteImage(id) {
      console.log("deleteImage", id);
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("warehouse/deleteImage", {
              id: id,
              subitem_id: this.warehouseData.id,
            })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              //this.warehouseData = response.data.warehouseData
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleSubmit() {
      // this.loading = !this.loading
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          if (this.warehouseData.image_files) {
            //console.log('image_files', this.warehouseData.image_files)
            this.warehouseData.image_files.forEach((file) => {
              formData.append("image_files[]", file);
            });
          }
          formData.append("warehouse_id", this.warehouseData.id);
          formData.append("image_type_id", this.image_type_id);
          formData.append("is_active", 1);
          this.loading = true;
          store
            .dispatch("warehouse/addImage", formData)
            .then((response) => {
              this.loading = false;
              this.fileList = [];
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              //this.warehouseData = response.data.warehouseData
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onChange(file, fileLists) {
      this.warehouseData.image_files = [];
      fileLists.forEach((item) => {
        this.warehouseData.image_files.push(item.raw);
      });
      //   console.log("image_files", this.warehouseData.image_files);
    },
  },

  setup(props, { emit }) {
    const uploadUrl = ref(
      process.env.VUE_APP_SERVICE_URL + "/api/user/centre/branches/updateImage"
    );
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const fileList = ref([]);

    const loading = ref(false);
    const tableColumns = ref([
      { key: "image", label: "圖片", sortable: true },
      { key: "actions", label: "操作" },
    ]);

    if (props.image_type_id == 2) {
      tableColumns.value = [
        { key: "image", label: "圖片", sortable: true },
        { key: "name", label: "名稱" },
        { key: "actions", label: "操作" },
      ];
    }

    return {
      loading,
      uploadUrl,
      headerObj,
      tableColumns,
      fileList,
    };
  },
};
</script>
