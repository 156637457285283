import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getUserData} from '@/auth/utils'
import ability from '@/libs/acl/ability'

export default function useVideosList(){

    const toast = useToast()

    const refVideoListTable = ref(null)
    const refVideoCategoryListTable = ref(null)

    const tableColumns = [
        {key:'url', label:'URL'},
        {key:'is_active', label:'狀態',  sortable:true},
         {key:'sort_order', label:'排序',  sortable:true},
        {key:'actions', label:'操作'}
    ]

    const perPage = ref(10)
    const totalVideos = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('sort_order')
    const isSortDirDesc = ref(false)
    const categoryOptions = ref([])
    

    const dataMeta = computed(() => {
        const localItemsCount = refVideoListTable.value ? refVideoListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalVideos.value,
        }
    })

    const showMessage = (title,text,icon, variant) =>{
        toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon,
              variant
            },
        })
    }

    const refetchData = () =>{
        refVideoListTable.value.refresh()
    }

    const getVideoImage = (image) =>  process.env.VUE_APP_IMAGE_URL+image

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const fetchVideos = (ctx, callback) => {

        store.dispatch('bar-video/fetchVideos', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            //console.log(response.data)
            const { videos, total} = response.data
            callback(videos)
            totalVideos.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching videos list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
            })
        })
    }



    return {
        fetchVideos,
        tableColumns,
        perPage,
        currentPage,
        totalVideos,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refVideoListTable,
        refVideoCategoryListTable,
        getVideoImage,
        categoryOptions,
        showMessage,
        ability
    }

}