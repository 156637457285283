// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ability from "@/libs/acl/ability";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from "@/store";
import axiosIns from "@/libs/axios";

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  const refCalendar = ref(null)
  let calendarApi = null

  const resetView = () => {
    calendarApi.changeView('dayGridMonth')
  }

  const blankEvent = {
    id: 0,
    title: '',
    start: '',
    end: '',
    allDay: false,
    textColor: '',
    backgroundColor: '',
    extendedProps: {
      customer_id: '',
      contract_id: '',
      warehouse_id: '',
      address: '',
      request_date: '',
      meeting_title: '',
      meeting_content: '',
      meeting_stage_id: '',
      users: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))


  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {

    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',

        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }
  
  const updateEvent = eventData => {
    const updatedEvent = eventData
    const propsToUpdate = ['id', 'title', 'textColor', 'backgroundColor']
    const extendedPropsToUpdate = [

      "customer_id",
      "contract_id",
      "warehouse_id",
      "address",
      "request_date",
      "meeting_title",
      "meeting_content",
      'meeting_stage_id',
      "users",
    ]
    updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
  }

  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      allDay,
      textColor,
      backgroundColor,
      extendedProps: {
        customer_id,
        contract_id,
        warehouse_id,
        address,
        request_date,
        meeting_title,
        meeting_content,
        meeting_stage_id,
        users,
      },
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      allDay,
      textColor,
      backgroundColor,

      customer_id,
      contract_id,
      warehouse_id,
      address,
      request_date,
      meeting_title,
      meeting_content,
      meeting_stage_id,
      users,
    }
  }

  const removeEvent = () => {
    const eventId = event.value.id
    removeEventInCalendar(eventId)
  }


  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  const fetchEvents = (info, successCallback) => {
    // const fetchEvents = () => {
    // if (!info)
    //   return

    let tasks = [];
    let color;
    axiosIns.get('/meeting/list').then(response => {
      response.data.meetings.forEach(item => {
        switch (item.meeting_stage_id) {
          case 1:
            color = "#296eb4"
            break;
          case 2:
            color = "#b1c867"
            break;
          case 3:
            color = "#ce7171"
            break;
        }
        tasks.push(
          {
            ...item,
            start: item.start,
            end: item.end,
            backgroundColor: color,
            textColor: '#fff',
            allDay: false
          }
        )
      })
      successCallback(tasks)
    }).catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching calendar events',
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    })
  }

  // const isSameDate = (start, end) => {
  //   const eventTime = new Date(time);
  //   return `${eventTime.getHours()} - ${eventTime.getMinutes()}}`
  // }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    views: {
      dayGridMonth: {
        dayMaxEvents: 5,
      },
    },
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    events: fetchEvents,
    displayEventTime: false,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 2,
    eventClick({ event: clickedEvent }) {
     if(ability.can('update', 'tasks')){
        event.value = grabEventDataFromEventApi(clickedEvent)

        isEventHandlerSidebarActive.value = true
     }
    },
    dateClick(info) {
      if(ability.can('create', 'tasks')){
        event.value = JSON.parse(JSON.stringify(Object.assign({}, { start: info.date, allDay: false })))

        isEventHandlerSidebarActive.value = true
      }
    },
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,

    eventContent: (arg) => {
      var event = arg.event

      let record = event._def.extendedProps

      var customHtml = '';


      customHtml +=
        `<div class="p-50" style="background-color:${event.backgroundColor}; color: ${event.textColor}">
        <div class="d-flex flex-wrap justify-content-between mb-25">
              <div class="mr-50">${record.meeting_title} - ${record.warehouse.name}</div>`

      if (record.meeting_stage_id == 1) {
        customHtml += `<div><i class="fa fa-stopwatch"></i> <span>待出席</span></div>`
      } else if (record.meeting_stage_id == 2) {
        customHtml += `<div><i class="fa fa-calendar-check"> </i> <span>已出席</span></div>`
      } else if (record.meeting_stage_id == 3) {
        customHtml += `<div><i class="fa fa-calendar-xmark"> </i> <span>已取消</span></div>`
      }

      customHtml += `
            </div>
            <div class="d-flex mb-25">
                <div class="">
                    <i class="fa fa-building"></i>
                </div>
                <div class="ml-50" style="white-space: wrap;">
                  ${record.address}
                </div>
            </div>
            <div class="d-flex mb-25 overflow-auto">`

      record.users.forEach(ele => {
        customHtml += `  
              <div class="d-flex mr-50 align-items-center">
                <i class="fa fa-user"></i>
                <div class="ml-50">
                  ${ele.name}
                </div>
              </div>`

      })

      customHtml += `</div>
          </div>`
      return { html: customHtml }
      // return "A"
    },
  })

  const getEventTime = (time) => {
    const eventTime = new Date(time);
    const h = eventTime.getHours() >= 10 ? eventTime.getHours() : '0' + eventTime.getHours()
    const m = eventTime.getMinutes() >= 10 ? eventTime.getMinutes() : '0' + eventTime.getMinutes()
    return `${h}:${m}`
  }
  const getEventDateTime = (time) => {
    const eventTime = new Date(time);
    const y = eventTime.getFullYear();
    const M = Number(eventTime.getMonth() + 1) >= 10 ? Number(eventTime.getMonth() + 1) : '0' + Number(eventTime.getMonth() + 1);
    const d = eventTime.getDate();
    const h = eventTime.getHours() >= 10 ? eventTime.getHours() : '0' + eventTime.getHours()
    const m = eventTime.getMinutes() >= 10 ? eventTime.getMinutes() : '0' + eventTime.getMinutes()
    return `${y}-${M}-${d} ${h}:${m}`
  }

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  onMounted(()=>{
    calendarApi = refCalendar.value.getApi()
  })

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    resetView,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}