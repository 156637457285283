<template>
  <component :class="`no-custome-top-menu ${tabIndex == 1 ? 'mt-5' : ''}`" :is="warehouseData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="warehouseData === undefined">
      <h4 class="alert-heading">取得倉庫資料時發生錯誤</h4>
      <div class="alert-body">
        未找到具有此倉庫 ID 的倉庫。 請查看
        <b-link class="alert-link" :to="{ name: 'warehouses-list' }">
          倉庫清單
        </b-link>
      </div>
    </b-alert>

    <b-tabs v-if="warehouseData" v-model="tabIndex" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">倉庫資訊</span>
        </template>
        <warehouse-edit-tab-information
          :warehouse-data="warehouseData"
          :warehouse-options="warehouseOptions"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">迷你倉種類</span>
        </template>
        <mini-storage-edit-tab
          :warehouse_id="warehouseData.id"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab v-for="(image_type, index) in imageTypes" :key="index">
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ image_type.name }}</span>
        </template>
        <warehouse-edit-tab-image
          :warehouse-data="warehouseData"
          :tab_title="image_type.name"
          :image_type_id="image_type.id"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">平面圖</span>
        </template>

          <!-- :warehouse-options="warehouseOptions" -->
        <warehouses-floorplan
          :warehouse-data="warehouseData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">迷李倉排序</span>
        </template>

          <!-- :warehouse-options="warehouseOptions" -->
          <warehouses-edit-sorting
            :warehouse-data="warehouseData"
            @refetch-data="refetchData"
            class="mt-2 pt-75"
          />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import warehouseStoreModule from "../warehouseStoreModule";
import WarehouseEditTabInformation from "./WarehouseEditTabInformation.vue";
import MiniStorageEditTab from "./MiniStorageEditTab.vue";
import WarehouseEditTabImage from "./WarehouseEditTabImage.vue";
import WarehousesFloorplan from "./WarehousesFloorplan.vue";
import WarehousesEditSorting from './WarehousesEditSorting.vue';
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    WarehouseEditTabInformation,
    WarehouseEditTabImage,
    WarehousesFloorplan,
    MiniStorageEditTab,
    WarehousesEditSorting
  },
  data(){
    return{
      tabIndex:0 
    }
  },
  setup() {
    const warehouseData = ref(null);
    const WAREHOUSE_APP_STORE_MODULE_NAME = "warehouse";

    if (!store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAME))
      store.registerModule(
        WAREHOUSE_APP_STORE_MODULE_NAME,
        warehouseStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAME))
        store.unregisterModule(WAREHOUSE_APP_STORE_MODULE_NAME);
    });

    onMounted(() => {
      refetchData();
      fetchOptions();
    });

    const refetchData = () => {
      if (router.currentRoute.name == "warehouses-edit") {
        store
          .dispatch("warehouse/getWarehouse", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            warehouseData.value = response.data.warehouse;
            warehouseData.value.is_active =
              warehouseData.value.is_active == 1 ? true : false;
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              warehouseData.value = undefined;
            } else {
              console.log("Fail to refetchData", error);
            }
          });
      } else {
        warehouseData.value = undefined;
      }
    };
  
       

    const warehouseOptions = ref([]);
    const imageTypes = ref([])

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", { warehouse_category: true, image_type: true })
        .then((response) => {
          warehouseOptions.value = response.data.warehouse_categories;
          imageTypes.value = response.data.image_types;

        })
        .catch((error) => {
          console.log({ error });
        });
    };

    return {
      warehouseData,
      refetchData,
      warehouseOptions,
      imageTypes,
    };
  },
};
</script>
