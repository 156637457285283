import axiosIns from '@/libs/axios'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReferrals(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/referral_coupon/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReferral(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/referral_coupon', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReferral(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/referral_coupon', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReferral(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/referral_coupon', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }




  },
}
