import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/meeting/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/meeting/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/meeting", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteTask(ctx, queryParams)
    {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/meeting', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
  }
};
