<template>
  <b-modal
    id="modal-file-manager"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="xl"
    title="File Manager"
  >
    <file-manager v-bind:settings="settings" :key="componentKey"></file-manager>
  </b-modal>
</template>
  
  <script>
import { BModal, VBModal } from "bootstrap-vue";

export default {
  data() {
    return {
      settings: {
        // axios headers
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
          "client-code": this.client_code,
        },
        //root: process.env.VUE_APP_IMAGE_URL,
        baseUrl: process.env.VUE_APP_LFM_AXIOS_BASE_URL, // overwrite base url Axios
        windowsConfig: 2, // overwrite config
        lang: "en", // set language
      },
      jump_into_the_file: false,
      jump_into_the_project_file: false,
      componentKey: 0,
    };
  },
  components: {
    BModal,
    VBModal,
  },
  props: {
    client_code: {
      type: Number,
      required: true,
    },
    /*           project_id:{
              type: String,
              required: true,
          }, */
    trigger: {
      type: Boolean,
    },
    path: {
      type: String,
      required: false,
    },
  },
  methods: {
    openFolder(path) {
      this.$refs.fileManager.goTo(path);
    },
    initialSetting() {
      this.settings = {
        // axios headers
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
          "client-code": this.client_code,
        },
        //root: process.env.VUE_APP_IMAGE_URL,
        baseUrl: process.env.VUE_APP_LFM_AXIOS_BASE_URL, // overwrite base url Axios
        windowsConfig: 2, // overwrite config
        lang: "en", // set language
      };
    },
    changeGridView() {
      let button = null;
      button = document.querySelector('[title="Grid"]');
      if (button) {
        //console.log('Successully changed the gridview')
        button.click();
      } else {
        // console.log('Fail to change the gridview')
      }
    },
    goIntoFolders(folders, index) {
            if (index < folders.length) {
                this.go_into_file(String(folders[index]), () => {
                    if (index < folders.length - 1) {
                        this.goIntoFolders(folders, index + 1);
                    }
                });
            }
        },
        go_into_file(folderName, callback){
            this.waitForElement(`[title="${folderName}"]`).then((folder) => {
                var clickEvent  = document.createEvent ('MouseEvents');
                clickEvent.initEvent('dblclick', true, true);
                folder.dispatchEvent(clickEvent);
                if(callback) callback();
            });
        },
        waitForElement(selector) {
            return new Promise((resolve, reject) => {
                let el = document.querySelector(selector);
                if (el) {
                    resolve(el);
                }
                new MutationObserver((mutationRecords, observer) => {
                    // 每次 DOM 更新时都会执行这个回调函数
                    Array.from(document.querySelectorAll(selector)).forEach((element) => {
                        resolve(element);
                        // 当找到元素时，停止观察
                        observer.disconnect();
                    });
                })
                .observe(document.documentElement, {childList: true, subtree: true});
            });
        },
  },
  computed: {
    watch_client_code() {
      return this.client_code;
    },
    /*          watch_project_id(){
              return this.project_id
          }, */
    directory() {
      return this.$store.getters["fm/tree/directories"];
    },
  },
  watch: {
    path(){
      //console.log('path changed');
   /*    this.$nextTick(() => {
      if (this.path) {
        const folders = this.path.split("/").filter(Boolean);
        ///console.log('folders', folders);
        this.goIntoFolders(folders, 0);
      } else {
        // 如果没有提供 path，就直接进入 this.client_code 文件夹
        this.go_into_file(this.client_code);
      }
    }); */
    },
    trigger() {
      //console.log('trigger changed')
      this.jump_into_the_file = false;
      this.jump_into_the_project_file = false;

      this.$nextTick(() => {
      if (this.path) {
        const folders = this.path.split("/").filter(Boolean);
        ///console.log('folders', folders);
        this.goIntoFolders(folders, 0);
      } else {
        // 如果没有提供 path，就直接进入 this.client_code 文件夹
        this.go_into_file(this.client_code);
      }
    });
      
    },
    /*   watch_project_id(){
                  this.initialSetting()
          }, */
    watch_client_code() {
      this.initialSetting();
    },

    directory() {
      this.changeGridView();
      //console.log('directory changed')
      let filter_directory = this.directory.filter(
        (item) =>
          item.basename == this.client_code || item.basename == this.project_id
      );
      //console.log(filter_directory.length);

      if (
        filter_directory.length > 0 &&
        this.jump_into_the_file &&
        !this.jump_into_the_project_file &&
        this.directory != null
      ) {
        this.go_into_project_folder();
      }
      if (
        filter_directory.length > 0 &&
        !this.jump_into_the_file &&
        this.directory != null
      ) {
        //console.log('this.client_code', this.client_code)
        let folder = document.querySelector(
          '[title="' + this.client_code + '"]'
        );
        //console.log('folder', folder)
        this.go_into_file();
        // if(folder != null)
        // {
        // // Simulate the double click event
        //     var clickEvent  = document.createEvent ('MouseEvents');
        //     clickEvent.initEvent('dblclick', true, true);
        //     folder.dispatchEvent(clickEvent);
        // }else{
        //     folder = document.getElementsByClassName('unselectable')
        //     console.log('folder', folder)
        //     if(folder.length > 0)
        //     {
        //         folder[0].click()
        //     }

        // }
        // this.jump_into_the_file = true
      }
    },
  },
  mounted() {
    this.changeGridView();
    
  },
};
</script>
  
  <style>
#modal-file-manager #modal-file-manager___BV_modal_content_ {
  min-height: 90vh !important;
}
</style>