import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCustomerCoupons(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/customer/coupons', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchContracts(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/contract/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchBooking(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/booking', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    downloadContract(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/contract/export', { params: queryParams , responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/contract', data,)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.put('/contract', data,)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // getContractPeriods(ctx, queryParams){
    //   return new Promise((resolve, reject) => {
    //       axiosIns.get('/contract/periods', {params:queryParams})
    //       .then(response=>resolve(response))
    //       .catch(error=>reject(error))
    //   })
    // },
     getContractPeriods(ctx, data){
      return new Promise((resolve, reject) => {
          axiosIns.post('/contract/periods', data)
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    deleteContract(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/contract", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchContractTemplates(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/contract_template/list', {params:queryParams})
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    fetchContractTemplate(ctx, queryParams){
      return new Promise((resolve, reject) => {
          axiosIns.get('/contract_template', { params: queryParams , responseType: "blob" })
          .then(response=>resolve(response))
          .catch(error=>reject(error))
      })
    },
    editContractTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/contract_template', data,)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContractTemplate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/contract_template", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    signContract(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/contract/sign', data,)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    message(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/contract/message', data,)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
