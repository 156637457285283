<template>
  <div class="customer-detail">
    <div class="customer-info" v-if="customerData">
      <b-row class="info-wrap">
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">編號</b>
            <span>{{ customerData.id }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">登記名稱（中文）</b>
            <span>{{ customerData.name }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">登記名稱（英文）</b>
            <span>{{ customerData.name_en }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">電話</b>
            <span>{{ customerData.mobile }}</span>
          </div>
        </b-col>
        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">電郵</b>
            <span>{{ customerData.email }}</span>
          </div>
        </b-col>

        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">身份證號碼 / 商業登記號碼</b>
            <span>{{ customerData.id_number }}</span>
          </div>
        </b-col>

        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">傳真</b>
            <span>{{ customerData.fax }}</span>
          </div>
        </b-col>

        <b-col cols="12" md="6">
          <div class="info-item">
            <b class="info-item-label">登記地址</b>
            <span>{{ customerData.address }}</span>
          </div>
        </b-col>

        <b-col cols="12" xl="4">
          <div class="info-item">
            <b class="info-item-label">備註</b>
            <span>{{ customerData.remark }}</span>
          </div>
        </b-col>
        <b-col cols="12">
          <hr/>
        </b-col>
        <b-col cols="12">
          <h4 class="text-center my-2 colorRed">聯絡人資料</h4>
          <div class="contracts-box bgColor3" v-if="customerData.contacts && customerData.contacts.length> 0">
            <b-row class="" v-for="(contact, index) of customerData.contacts" :key="contact.name +
            index">
              <b-col cols="12" xl="4">
                <div class="info-item">
                  <b class="info-item-label">聯絡人名稱({{ index + 1 }})</b>
                  <span>{{ contact.name }}</span>
                </div>
              </b-col>
              <b-col cols="12" xl="4">
                <div class="info-item">
                  <b class="info-item-label">聯絡人電話</b>
                  <span>{{ contact.mobile }}</span>
                </div>
              </b-col>
              <b-col cols="12" xl="4">
                <div class="info-item">
                  <b class="info-item-label">聯絡人職位</b>
                  <span>{{ contact.position }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-else class="text-center"> 沒有聯絡人資料</div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios.js";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
  },
  props: {
    customerData: {},
  },
  methods: {},
  data() {
    return {
      expirationRemind: [1, 2],
    };
  },
  setup() {
    const remindMethodOptions = ref([
      { id: 1, name: "電話" },
      { id: 2, name: "短訊" },
      { id: 3, name: "whatsapp" },
      { id: 4, name: "接受推廣訊息" },
      { id: 5, name: "電郵" },
    ]);
    return {
      remindMethodOptions,
    };
  },
};
</script>
    
  <style lang="scss" scoped>
</style>
    
    <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.contracts-box{
  padding:1rem 1.5rem;
  border:1px soild #95989b;
  border-radius: 0.375rem;
  .info-item{
    padding:0.5rem;
    height:auto;
  }
}
</style>
    
    