import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getWarehouse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/warehouse", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addWarehouse(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/warehouse", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchWarehouses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/warehouse/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteWarehouse(ctx, queryParams)
    {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/warehouse', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    addImage(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/warehouse/image', data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteImage(ctx, queryParams)
    {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/warehouse/image', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateMiniStorage(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/warehouse/mini_storage/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteMiniStorage(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/warehouse/mini_storage/", {params:queryParams})
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // mini_storage_type
    fetchMiniStorageTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/mini_storage_type/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchMiniStorageType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/mini_storage_type", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateMiniStorageType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/mini_storage_type", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteMiniStorageType(ctx, queryParams)
    {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/mini_storage_type', {params:queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateMinistorageSorting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/warehouse/mini_storage/sorting", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateFloorPlanName(ctx, data){
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/warehouse/image", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

  },
};
