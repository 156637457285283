<template>
  <section id="dashboard" class="no-custome-top-menu container" style="max-width: 1500px;">
    <b-row>
      <b-col cols="12" md="4">
      <div class="dash-info-box">
        <div class="d-flex align-items-center mb-1">
          <font-awesome-icon class="mr-2" icon="fa-solid fa-user-group" />
          租戶數量
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <h3>{{ info && info.number_of_customers }}</h3>
          個
        </div>
      </div>
    </b-col>
    <b-col cols="12" md="4">
      <div class="dash-info-box">
        <div class="d-flex align-items-center mb-1">
          <font-awesome-icon class="mr-2" icon="fa-solid fa-warehouse" />
          空間數量
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <h3>{{ info && info.number_of_mini_storages }}</h3>
          間
        </div>
      </div>
    </b-col>
    <b-col cols="12" md="4">
      <div class="dash-info-box">
        <div class="d-flex align-items-center mb-1">
          <font-awesome-icon
            class="mr-2"
            icon="fa-solid fa-file-invoice-dollar"
          />
          發票數量
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <h3>{{ info && info.number_of_invoices }}</h3>
          張
        </div>
      </div>
    </b-col>
    </b-row>
      <b-row>
        <b-col cols="12" md="6" v-if="monthlyRentsIncome">
          <Linechart class="mt-2" title="本月租金收入" :time="time" :series="monthlyRentsIncome"></Linechart>
        </b-col>
        <b-col cols="12" md="6" v-if="monthlyOccupancyRate">
          <Linechart class="mt-2" title="本月租房率" :time="time" :series="monthlyOccupancyRate"></Linechart>
        </b-col>
        
      </b-row>

  </section>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import VueApexCharts from "vue-apexcharts";
import Linechart from "./linechart.vue";
import dashboardStoreModule from "./dashboardStoreModule";


export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    Linechart
  },
  data() {
    return {
    };
  },
  methods: {},
  created() {},
  setup()
  {
    const DASHBOARD_STORE_MODULE_NAME = "dashboard";

    if (!store.hasModule(DASHBOARD_STORE_MODULE_NAME))
      store.registerModule(DASHBOARD_STORE_MODULE_NAME, dashboardStoreModule);

    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_STORE_MODULE_NAME))
        store.unregisterModule(DASHBOARD_STORE_MODULE_NAME);
    });

    const info = ref(null);

    const fetchInfo = () => {
      store.dispatch(`${DASHBOARD_STORE_MODULE_NAME}/fetchInfo`)
      .then((response) => {
        console.log(response)
        info.value = response.data;
        console.log(info.value)
      })
    }

    const monthlyRentsIncome = ref (null)
    const monthlyOccupancyRate = ref (null)
    const time = ref (null)

    const fetchLineChart = () =>{
      store.dispatch(`${DASHBOARD_STORE_MODULE_NAME}/fetchRecentRentReport`)
      .then((response) => {
        monthlyRentsIncome.value = [{name: "本月租金收入", data: response.data.monthlyRentsIncome}]
        monthlyOccupancyRate.value = [{name: "本月租房率", data: response.data.monthlyOccupancyRate}]
        time.value = response.data.time
      })
    }



    onMounted(() => {
      fetchInfo();
      fetchLineChart();
    })

    return {
      info, 
      fetchInfo,
      monthlyRentsIncome,
      monthlyOccupancyRate,
      time
    }
  }


}
</script>

<style lang="scss" scoped></style>
