import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from "@/libs/acl/ability";

export default function useEmailsList() {

    const toast = useToast()

    const refEmailListTable = ref(null)

    const tableColumns = [
        { key: 'created_at', label: '寄送日期' },
        { key: 'to_email', label: '接收者', sortable: true },
        { key: 'show_details', label: '內容' },
        { key: 'cc', label: 'CC', sortable: true },
        { key: 'bcc', label: 'BCC', sortable: true },

        { key: 'subject', label: '標題', sortable: true },
        // { key: 'subject', label: '主題', sortable: true },
        // { key: 'action', label: '操作' },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)


    const dataMeta = computed(() => {
        const localItemsCount = refEmailListTable.value ? refEmailListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refEmailListTable.value.refresh()
    }

    const emailTemplateId = ref(null)
    watch([currentPage, perPage, searchQuery, emailTemplateId], () => {
        refetchData()
    })


    const emailList = ref(null)

    const fetchEmails = (ctx, callback) => {

        store.dispatch('email/fetchEmails', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            template_id: emailTemplateId.value,
        })
            .then(response => {
                const { email_records, total } = response.data
                callback(email_records)
                emailList.value = email_records
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching email list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    const emailTemplateOptions = ref([])
    const fetchOptions = () => {

        store
        .dispatch("email/fetchEmailTemplates")
        .then((response) => {
          const { email_templates } = response.data;
          emailTemplateOptions.value = email_templates
        })
        .catch((error) => {
          console.log(error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching email templates list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });

        // store
        //     .dispatch("app/fetchOptions", {
        //         customer: true,
        //         user: true,
        //     })
        //     .then((response) => {
        //         customerOptions.value = response.data.customers
        //         userOptions.value = response.data.users
        //     })
        //     .catch((error) => {
        //         console.log({ error });
        //     });
    };

    onMounted(() => {
        fetchOptions();
    });


    return {
        fetchEmails,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refEmailListTable,
        showMessage,
        emailList,
        emailTemplateOptions,
        emailTemplateId,
        ability
    }

}