<template>
  <div>
    <modal-edit-invoice
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      @changeTrigger="editTrigger = !editTrigger"
      :trigger="editTrigger"
      in_rent=""
      contract_id=""
      customer_id=""
      mini_storage_id=""
      :invoiceData="invoiceData"
      :contractOptions="contractOptions"
      :customerOptions="customerOptions"
      :miniStorageOptions="miniStorageOptions"
      :saleOptions="saleOptions"
      @refetch-data="refetchData"
    />

    <modal-pdf :pdf-url="pdfUrl" />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 sami-bottom-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="content-list d-flex align-items-center">
          <div class="d-flex align-items-center mr-1">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-1">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="搜尋收款編號/公司名稱"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <v-select
              v-model="customer_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="過瀘客戶"
              :clearable="true"
              class="d-inline-block common-rounded mx-50"
            />
          </div>
          <div class="d-flex align-items-center mr-1">
            <v-select
              v-model="mini_storage_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="miniStorageOptions"
              label="name"
              :filter="miniStorageSearch"
              :reduce="(option) => option.id"
              placeholder="過瀘倉間"
              :clearable="true"
              class="d-inline-block common-rounded mx-50"
            >
              <template #option="data">
                {{ data.name }} - {{ data.storage_number }}
              </template>
              <template #selected-option="data">
                {{ data.name }} - {{ data.storage_number }}
              </template>
            </v-select>
          </div>
          <div class="d-flex filter-date align-items-center mr-1">
            <span class="mr-1 text-nowrap">日期範圍</span>
            <flat-pickr
              v-model="start_date"
              class="form-control common-rounded"
              :config="{
                allowInput: true,
                altInput: true,
                altFormat: 'Y-m-d',
                dateFormat: 'Y-m-d',
              }"
              placeholder="開始日期"
            />
            <span class="mx-1"> 至 </span>
            <flat-pickr
              v-model="end_date"
              class="form-control common-rounded"
              :config="{
                allowInput: true,
                altInput: true,
                altFormat: 'Y-m-d',
                dateFormat: 'Y-m-d',
              }"
              placeholder="完結日期"
            />
          </div>
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-overlay
        variant="white"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <b-table
          ref="refPaymentSchedulesListTable"
          class="position-relative"
          :items="fetchPaymentSchedules"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

        <template #cell(amount)="data">
          {{ (+data.item.amount + +data.item.deposite + +data.item.card_deposite - +data.item.total_discount).toFixed(2) }}
        </template>
        
        <template #cell(mini_storage)="data">
            <span v-if="data.item.mini_storage && data.item.mini_storage.warehouse">{{ data.item.mini_storage.warehouse.name }}[{{ data.item.mini_storage.storage_number }}]</span>
            </template>
          <template #cell(payment_status)="data">
            <b-button
              class="stage-button"
              v-if="data.item.invoice"
              :style="{
                backgroundColor: getPaymentStatus(data.item.invoice).background_color + '!important',
                color: getPaymentStatus(data.item.invoice).font_color + '!important',
              }"
            >
              {{ getPaymentStatus(data.item.invoice).name }}
            </b-button>
            <span v-else class="colorRed">需要先生成發票</span>
          </template>
          <template #cell(customer_name)="data">
            <div v-if="data.item.customer_id">
              {{ getCustomerName(data.item.customer_id) }}
            </div>
          </template>
          <template #cell(sale_id)="data">
            {{ data.item.contract && data.item.contract.sale_id ? getSaleName(data.item.contract.sale_id) : '' }}
          </template>

          <template #cell(show_details)="row">
            <b-button
              v-if="row.item.invoice"
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
            >
              {{ row.detailsShowing ? "隱藏" : "顯示" }}
            </b-button>
            <span v-else class="mx-1"> - - </span>
          </template>

          <template #row-details="row">
            <div class="mb-2">
              <div class="bgColor1" style="width: 100%; height: 1px" />
              <b-row class="info-wrap">
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">發票編號</b>
                    <span>{{
                      row.item.invoice && row.item.invoice.invoice_number
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">生成日期</b>
                    <span>{{
                      row.item.invoice && row.item.invoice.invoice_date
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">項目經理</b>
                    <span>
                      {{ row.item.invoice && row.item.invoice.sale_id ? getSaleName(row.item.invoice.sale_id) : '' }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">合約編號</b>
                    <span>{{
                      row.item.contract && row.item.contract.contract_number
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">客戶名稱</b>
                    <span>
                      {{ row.item.invoice && row.item.invoice.customer_id ? getCustomerName(row.item.invoice.customer_id) : '' }}
                    </span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">客戶編號</b>
                    <span>{{
                      row.item.invoice && row.item.invoice.customer_id
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">應付金額</b>
                    <span>{{
                      row.item.invoice && row.item.invoice.total_amount
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">已付金額</b>
                    <span>{{
                      row.item.invoice && row.item.invoice.total_paid_amount
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">已核數金額</b>
                    <span>{{
                      row.item.invoice &&
                      row.item.invoice.total_audit_paid_amount
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">餘額</b>
                    <span>{{
                      row.item.invoice &&
                      row.item.invoice.total_amount -
                        row.item.invoice.total_paid_amount
                    }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">備註</b>
                    <span>{{
                      row.item.invoice && row.item.invoice.remark
                    }}</span>
                  </div>
                </b-col>
              </b-row>
              <div class="bgColor1" style="width: 100%; height: 1px" />
              <b-row
                class="info-wrap"
                v-for="invoice of row.item.invoice &&
                row.item.invoice.invoice_items"
                :key="`invoice${invoice.id}`"
              >
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">數量</b>
                    <span>{{ invoice.quantity }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">單價</b>
                    <span>{{ invoice.price }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">金額</b>
                    <span>{{ invoice.amount }}</span>
                  </div>
                </b-col>
                <b-col cols="12" md="3">
                  <div class="info-item">
                    <b class="info-item-label">描述</b>
                    <span>{{ invoice.description }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>

          <template #cell(action)="data">
            <div class="text-center">
              <a
                v-if="!data.item.invoice && (ability.can('create', 'payment-schedules'))"
                v-b-popover.hover.top="'生成發票'"
                href="javascript:void(0)"
                @click="generateInvoice(data.item.id)"
              >
              <font-awesome-icon class="color1" icon="fa-solid fa-file-circle-plus" />
              </a>
              <div v-else>
                <a
                  v-b-popover.hover.top="'列印發票'"
                  href="javascript:void(0)"
                  @click="downloadInvoice(data.item.invoice_id)"
                >
                  <font-awesome-icon
                    class="color1"
                    icon="fa-solid fa-file-export"
                  />
                </a>
                <span class="mx-50 color1">|</span>
                <a v-if="(ability.can('update', 'payment-schedules'))"
                  href="javascript:void(0)"
                  v-b-popover.hover.top="'修改發票'"
                  @click="handleInvoiceEdit(data.item.invoice)"
                >
                  <feather-icon class="color1" icon="Edit2Icon" />
                </a>
              </div>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BImg,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import axiosIns from "@/libs/axios.js";
import { ref, onUnmounted, onMounted, watch, computed } from "@vue/composition-api";

import ModalEditInvoice from "@/views/invoice/modal/modal-edit-invoice.vue";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";
import paymentStoreModule from "../paymentStoreModule";
import usePaymentSchedulesList from "./usePaymentSchedulesList";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    vSelect,
    flatPickr,
    ModalEditInvoice,
    ModalPdf,
  },
  directives: {
    "b-popover": VBPopover,
  },
  methods: {
    miniStorageSearch(options, search) {
      return this.filteredMiniStorageOptions(search);
    },
    generateInvoice(id) {
      this.$swal({
        title: "你確定要生成發票嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("payment/generateInvoice", { id })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.refetchData();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.invoiceData = { id: 0, invoice_items: [{ id: 0 }] };
    },
    handleInvoiceEdit(item) {
      this.invoiceData = item;
      this.isEdit = true;
      this.$bvModal.show("modal-edit-invoice");
    },
    downloadInvoice(id) {
      this.loading = true;
      axiosIns
        .get("/invoice/export", { params: { id }, responseType: "blob" })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
  },
  data() {
    return {
      editTrigger: false,
      isEdit: false,
      pdfUrl: "",
      invoiceData:null
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "payment";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, paymentStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });
    const loading = ref(false);

    // watch(
    //   () => payment_status_id,
    //   () =>{

    //   })

    const {
      fetchPaymentSchedules,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refPaymentSchedulesListTable,
      showMessage,
      paymentSchedulesList,
      payment_status_id,

      paymentStatusOptions,
      contractOptions,
      customerOptions,
      saleOptions,
      miniStorageOptions,

      start_date,
      end_date,
      customer_id,
      mini_storage_id,
      ability,
    } = usePaymentSchedulesList();

    const getPaymentStatus = computed(() => {
      return (item) => {
        const statusId = item.deleted_at
          ? 4
          : item.total_amount - item.total_paid_amount > 0
          ? 1
          : item.total_paid_amount - item.total_audit_paid_amount <= 0
          ? 3
          : item.total_amount - item.total_paid_amount <= 0
          ? 2
          : 1;
        return paymentStatusOptions.value.find(ele => ele.id === statusId);
      };
    });

    const getCustomerName = computed(() => {
      return (customerId) => {
        const customer = customerOptions.value.find(ele => ele.id == customerId);
        return customer ? customer.name : '';
      };
    });

    const getSaleName = computed(() => {
      return (saleId) => {
        const sale = saleOptions.value.find(ele => ele.id == saleId);
        return sale ? sale.name : '';
      };
    });

    const filteredMiniStorageOptions = computed(() => {
      return (search) => {
        return miniStorageOptions.value.filter(item => 
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.storage_number.toLowerCase().includes(search.toLowerCase())
        );
      };
    });

    return {
      fetchPaymentSchedules,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refPaymentSchedulesListTable,
      showMessage,
      paymentSchedulesList,
      payment_status_id,

      paymentStatusOptions,
      contractOptions,
      customerOptions,
      saleOptions,
      miniStorageOptions,

      start_date,
      end_date,
      customer_id,
      mini_storage_id,
      ability,

      loading,
      getPaymentStatus,
      getCustomerName,
      getSaleName,
      filteredMiniStorageOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.stage-button {
  cursor: default;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.custome-top-menu {
  .filter-date {
    input {
      background-color: transparent !important;
    }
  }
}
.info-wrap {
  .info-item {
    height: auto;
    padding: 0.25rem 0;
  }
}
</style>

