<template>
  <b-modal
    id="modal-edit-invoice"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @show="$emit('changeTrigger')"
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">{{ isEdit ? "修改" : "新增" }}發票</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="payment-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">發票編號</b>
                <span>{{
                  newInvoiceData.invoice_number
                    ? newInvoiceData.invoice_number
                    : newInvoiceData.id
                }}</span>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >發票日期 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="發票日期"
                  rules="required"
                >
                  <flat-pickr
                    v-model="newInvoiceData.invoice_date"
                    class="form-control"
                    :config="{
                      allowInput: true,
                      altInput: true,
                      altFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                    }"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">項目經理</b>
                <v-select
                  v-model="newInvoiceData.sale_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="saleOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                />
              </div>
            </b-col>
            <!-- <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label"
                  >項目編號 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="項目編號"
                  rules="required"
                >
                  <v-select
                    v-model="newInvoiceData.invoice_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="invoiceTypeOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  >
                    <template #option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                    <template #selected-option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col> -->
            <!-- <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">發票名稱</b>
                <b-form-input
                  id="rent_period"
                  v-model="newInvoiceData.invoice_name"
                  placeholder="發票名稱"
                />
              </div>
            </b-col> -->
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">客戶</b>
                <validation-provider
                  #default="validationContext"
                  name="客戶"
                  rules="required"
                >
                  <v-select
                    v-model="newInvoiceData.customer_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  >
                    <template #option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                    <template #selected-option="data">
                      {{ data.id }} - {{ data.name }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">聯絡人姓名</b>
                <b-form-input
                  id="name"
                  v-model="newInvoiceData.name"
                  placeholder="聯絡人姓名"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">聯絡人地址</b>
                <b-form-input
                  id="address"
                  v-model="newInvoiceData.address"
                  placeholder="聯絡人地址"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">聯絡人傳真</b>
                <b-form-input
                  id="fax"
                  v-model="newInvoiceData.fax"
                  placeholder="聯絡人傳真"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">聯絡人電話</b>
                <b-form-input
                  id="phone"
                  v-model="newInvoiceData.phone"
                  placeholder="聯絡人電話"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">合約編號</b>
                <v-select
                  v-model="newInvoiceData.contract_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="contractOptions"
                  label="contract_number"
                  :reduce="(option) => option.id"
                  :clearable="false"
                  :disabled="in_rent"
                />
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">倉號</b>
                <validation-provider
                  #default="validationContext"
                  name="倉號"
                  rules="required"
                >
                  <v-select
                    v-model="newInvoiceData.mini_storage_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="miniStorageOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    :disabled="in_rent"
                  >
                    <template #option="data">
                      {{ data.name }} - {{ data.storage_number }}
                    </template>
                    <template #selected-option="data">
                      {{ data.name }} - {{ data.storage_number }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">倉庫地址</b>
                <b-form-input
                  id="location"
                  v-model="newInvoiceData.location"
                  placeholder="倉庫地址"
                />
              </div>
            </b-col>

            <b-col cols="12" xl="4">
              <div class="info-item">
                <b class="info-item-label">備註</b>
                <b-form-input v-model="newInvoiceData.remark"></b-form-input>
              </div>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <div class="">
                <div class="my-2 d-flex justify-content-center">
                  <h4 class="colorRed">項目資料</h4>
                </div>
                <b-row
                  v-for="(invoice_items, index) in newInvoiceData.invoice_items"
                  :key="`invoice_items_${index}`"
                  class="m-0"
                >
                  <b-col
                    cols="12"
                    class="d-flex mb-1 bgColor3 radius-round border-1"
                  >
                    <b-row class="flex-grow-1 p-2">
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12" xl="4">
                            <div class="info-item">
                              <b class="info-item-label">項目數量</b>
                              <b-form-input
                                id="amout"
                                v-model="invoice_items.quantity"
                                placeholder="項目數額"
                              />
                            </div>
                          </b-col>
                          <b-col cols="12" xl="4">
                            <div class="info-item">
                              <b class="info-item-label">項目數額</b>
                              <b-form-input
                                id="amout"
                                v-model="invoice_items.price"
                                placeholder="項目數額"
                              />
                            </div>
                          </b-col>
                          <b-col cols="12" xl="4">
                            <div class="info-item">
                              <b class="info-item-label">項目描述</b>
                              <b-form-input
                                id="description"
                                v-model="invoice_items.description"
                                placeholder="項目描述"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div
                      class="d-flex flex-column justify-content-between py-50 px-25"
                    >
                      <feather-icon
                        size="16"
                        icon="PlusIcon"
                        class="cursor-pointer text-success"
                        @click="addItem"
                      />
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer text-danger"
                        @click="removeItem(index)"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex mr-1 justify-content-end">
                <b class="mr-1">總價：</b>
                <span class="">{{ total_amount() }}</span>
              </div>
            </b-col>
            <b-col cols="12" class="pt-1">
              <div class="info-item" style="height: auto">
                <b class="info-item-label">付款條款</b>
                <div class="border borderColorBlack small-rounded p-1 w-100">
                  <p>
                    支付條款是國際貿易現匯結算中，信用證條款中關於償付票款辦法的具體規定。一般包括：
                  </p>
                  <p>
                    (1)議討行或付款行。如與開證行無直接帳戶關係，則向開證行指定的第三家銀行，即償付行索償。
                  </p>
                  <p>
                    (2)開證行在議付行或付款行開有帳戶，由議付行或付款行在代付貨款後即直接借記開證行有關帳戶。
                  </p>
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    in_rent: {},
    contract_id: {},
    customer_id: {},
    mini_storage_id: {},
    invoiceData: {},
    isEdit: {},
    // invoiceTypeOptions: {},
    contractOptions: {},
    customerOptions: {},
    saleOptions: {},
    miniStorageOptions: {},
    trigger: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-invoice");
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");
      this.$emit("changeTrigger");
    },

    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          this.loading = true;
          store
            .dispatch("invoice/updateInvoice", this.newInvoiceData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addItem() {
      this.newInvoiceData.invoice_items.push(
        JSON.parse(
          JSON.stringify({
            id: 0,
          })
        )
      );
    },
    removeItem(index) {
      // check number of record
      let record =
        this.newInvoiceData &&
        this.newInvoiceData.invoice_items &&
        this.newInvoiceData.invoice_items.length;
      if (record <= 1) {
        this.makeToast("danger", "Warning", "請至少留有一個表單。");
      } else {
        this.newInvoiceData.invoice_items.splice(index, 1);
      }
    },
  },
  // watch: {
  // newInvoiceData: {
  //   handler(newVal) {
  //     if (this.miniStorageOptions && this.miniStorageOptions.length > 0 && newVal.mini_storage_id) {
  //       const target = this.miniStorageOptions.filter(
  //         (ele) => ele.id == newVal.mini_storage_id
  //       )[0];
  //       if (target) {
  //         this.newInvoiceData.location = target.location;
  //       }
  //     }
  //   },
  //   deep:true
  // },

  // },
  setup(props) {
    const loading = ref(false);
    const newInvoiceData = ref({ id: 0, invoice_items: [{ id: 0 }]});

    const resetInvoiceData = () => {
      newInvoiceData.value = { id: 0, invoice_items: [{ id: 0 }]};
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetInvoiceData);

    watch(
      () => props.trigger,
      (newVal) => {

        newInvoiceData.value = {
          ...newInvoiceData.value,
          ...{
            contract_id: props.contract_id ? props.contract_id : null,
            customer_id: props.customer_id ? props.customer_id : null,
            mini_storage_id: props.mini_storage_id
              ? props.mini_storage_id
              : null,
          }
        }

        if (props.miniStorageOptions && props.miniStorageOptions.length > 0) {
          const target = props.miniStorageOptions.filter(
            (ele) => ele.id == newInvoiceData.value.mini_storage_id
          )[0];
          if (target) {
            newInvoiceData.value.location = target.location;
          }
        }

        if (props.customerOptions && props.customerOptions.length > 0) {
          const target = props.customerOptions.filter(
            (ele) => ele.id == newInvoiceData.value.customer_id
          )[0];
          if (target) {
            newInvoiceData.value.name = target.name;
            newInvoiceData.value.address = target.address;
            newInvoiceData.value.phone = target.mobile;
            newInvoiceData.value.email = target.email;
          }
        }

        newInvoiceData.value = {
          ...newInvoiceData.value,
          ...props.invoiceData,
        };
      },
      { immediate: true }
    );

    const total_amount = () => {
      let total = 0;
      if (
        newInvoiceData.value.invoice_items &&
        newInvoiceData.value.invoice_items.length > 0
      ) {
        total += newInvoiceData.value.invoice_items.reduce(
          (a, b) =>
            a +
            (Number(b.quantity) * Number(b.price)
              ? Number(b.quantity) * Number(b.price)
              : 0),
          0
        );
        return total;
      }
    };

    watch(
      () => newInvoiceData.value.customer_id,
      (newVal) => {
        if (props.customerOptions && props.customerOptions.length > 0) {
          const target = props.customerOptions.filter(
            (ele) => ele.id == newVal
          )[0];
          if (target) {
            newInvoiceData.value.name = target.name;
            newInvoiceData.value.address = target.address;
            newInvoiceData.value.phone = target.mobile;
            newInvoiceData.value.email = target.email;
            // newInvoiceData.value.fax = target.fax
          }
        }
      }
    );

    watch(
      () => newInvoiceData.value.mini_storage_id,
      (newVal) => {
        if (props.miniStorageOptions && props.miniStorageOptions.length > 0) {
          const target = props.miniStorageOptions.filter(
            (ele) => ele.id == newVal
          )[0];
          if (target) {
            newInvoiceData.value.location = target.location;
          }
        }
      },
      { immediate: true }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newInvoiceData,
      total_amount,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.info-wrap .info-item b {
  min-width: 140px;
}
.info-item-label {
  & + span {
    width: 100%;
  }
}
.v-select {
  width: 100%;
}
</style>
  