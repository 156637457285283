<template>
    <b-row class="m-0">
      <!-- Left Text-->
      <b-col lg="7" class="p-0 d-none d-lg-block bg-login-wrapper">
        <img src="~@/assets/images/pages/login/login-bg2.png" class="w-100" />
      </b-col>

      <!-- Login-->
      <b-col lg="5" class="d-grid place-items-center">
        <div class="login-form">
          <div class="d-grid place-items-center brand-logo mb-3">
            <img src="@/assets/images/logo/hero.png" />
            <h4 class="font-weight-bolder mb-1">忘記了密碼？</h4>
          </div>
          <div class="d-flex justify-content-center">
            <b-col class="">
              <!-- form -->

              <validation-observer ref="simpleRules">
                <b-overlay
                  :show="loading"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                <h3 class="font-weight-bolder">請輸入電郵來重設密碼</h3>
                  <b-form
                    class="auth-form mt-3"
                    @submit.prevent="resetPasswordForm"
                  >
                    <b-form-group class="required">
                      <label>電郵</label>
                      <validation-provider
                        #default="{ errors }"
                        name="電郵："
                        rules="required|email"
                      >
                        <b-input-group>
                          <b-form-input
                            id="forgot-password-email"
                            v-model="resetData.email"
                            :state="errors.length > 0 ? false : null"
                            name="forgot-password-email"
                            placeholder="輸入電郵"
                          />
                          <b-input-group-append is-text>
                            <b-button
                              variant="primary"
                              class="p-50 bg-submit"
                              @click="sendVertificationCode"
                            >
                              發送驗證碼
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        #default="{ errors }"
                        name="密碼"
                        vid="password"
                        rules="required"
                      >
                        <b-form-group class="required mt-1" label="密碼：">
                          <b-form-input
                            id="password"
                            type="password"
                            placeholder="輸入密碼"
                            v-model="resetData.password"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        #default="{ errors }"
                        name="確認密碼"
                        vid="c_password"
                        rules="required"
                      >
                        <b-form-group class="required" label="確認密碼：">
                          <b-form-input
                            id="c_password"
                            type="password"
                            placeholder="輸入確認密碼"
                            v-model="resetData.c_password"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        name="驗證碼"
                        vid="vaildate"
                        rules="required"
                      >
                        <b-form-group class="required" label="驗證碼：">
                          <b-form-input
                            id="vaildate"
                            placeholder="輸入驗證碼"
                            v-model="resetData.vertification_code"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                    <div>
                      <b-button type="submit" variant="primary" block class=" bg-submit">
                        提交
                      </b-button>
                    </div>
                  </b-form>
                </b-overlay>
              </validation-observer>

              <p class="text-center mt-3">
                <b-link :to="{ name: 'auth-login' }" class="color1">
                  <feather-icon icon="ArrowLeftIcon" /> 返回登入頁
                </b-link>
              </p>
            </b-col>
          </div>
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import {
  BRow,
  BCol,
  BLink,
  BOverlay,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      required,
      email,
      resetData: {},
      confirm_password: "",
      loading: false,
    };
  },
  computed: {
  },
  methods: {
    resetPasswordForm() {
      this.loading = true;
      axiosIns
        .post("/resetpassword_with_code", this.resetData)
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$router.replace("/login");
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$swal({
            text: JSON.stringify(err.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    sendVertificationCode() {
      this.loading = true;
      axiosIns
        .post("/sendVertificationCode", { email: this.resetData.email })
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$swal({
            text: JSON.stringify(err.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
#forgot-password-email {
  border-radius: 0.357rem 0 0 0.357rem;
}
.auth-form {
  label {
    font-size: 14px;
  }
}
.input-group-text {
  padding: 0rem 0.5rem;
}
</style>
