<template>
  <div>
    <discount-list-add-new
      :is-add-new-discount-sidebar-active.sync="isAddNewDiscountSidebarActive"
      :data="discountData"
      :id="id"
      :customer-options="customerOptions"
      @refetch-data="refetchData"
    />
    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="text-nowrap mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block common-rounded"
                placeholder="搜尋"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center">
        
          <a v-if="ability.can('create', 'coupons')" class="" href="javascript:void(0)" @click="addDiscount()">
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>


    <b-card no-body class="mb-0">
      
      <b-table
        ref="refDiscountListTable"
        class="position-relative"
        :items="fetchDiscounts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="ability.can('update', 'coupons')" @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>

            <!-- hidden 了, 未必需要這個 -->
            <b-dropdown-item v-if="ability.can('delete', 'coupons') || false" @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDiscounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import discountStoreModule from "../discountStoreModule";
import useDiscountsList from "./useDiscountsList";
import DiscountListAddNew from "./DiscountListAddNew.vue";

export default {
  data() {
    return {
      discountData: {},
      id: 0,
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
    DiscountListAddNew,
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "您的操作是最終的，您將無法檢索此折扣。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("discount/deleteDiscount", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    resetDiscountData() {
      this.id = 0;
      this.discountData = {
        id: 0,
        code: "",
        title: "",
        description: "",
        amount: null,
        percentage: null,
        total_amount: 0,
        date_from: null,
        date_to: null,
        uses_per_customer: null,
        uses_per_coupon: null,
        is_active: 1,
      };
    },
    addDiscount() {
      this.resetDiscountData();
      this.isAddNewDiscountSidebarActive = true;
    },
    handleEdit(item) {
      this.id = item.id;
      this.discountData = JSON.parse(JSON.stringify(item));
      console.log(this.discountData);
      this.isAddNewDiscountSidebarActive = true;
    },
    getImgPath(path) {
      return process.env.VUE_APP_IMAGE_URL + path;
    },
  },
  setup() {
    const APP_DISCOUNT_STORE_MODULE_NAME = "discount";
    const isAddNewDiscountSidebarActive = ref(false);

    if (!store.hasModule(APP_DISCOUNT_STORE_MODULE_NAME))
      store.registerModule(APP_DISCOUNT_STORE_MODULE_NAME, discountStoreModule);

    // Discount
    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 0 },
      { label: "All", value: -1 },
    ];

    //fetch customer data
    const customerOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", {
          customer: true,
        })
        .then((response) => {
          customerOptions.value = response.data.customers;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    onMounted(() => {
        fetchOptions()
      });


    const {
      fetchDiscounts,
      tableColumns,
      perPage,
      currentPage,
      totalDiscounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refDiscountListTable,
      ability,
      statusDiscount,
      categoryDiscount,
    } = useDiscountsList();

    return {
      isAddNewDiscountSidebarActive,
      fetchDiscounts,
      tableColumns,
      perPage,
      currentPage,
      totalDiscounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refDiscountListTable,
      ability,
      statusDiscount,
      categoryDiscount,
      statusOptions,
      customerOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
