<template>
  <div>
    <modal-file-manager 
    :trigger="trigger"
    :client_code="client_code"
    :path="path"
    />
    <modal-edit-customer
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      :customerData="customerData"
      :customerOptions="customerOptions"
      :customerTypeOptions="customerTypeOptions"
      :districtOptions="districtOptions"
      @refetch-data="refetchData"
    />
    <modal-edit-point
      :customerData="customerData"
      @refetch-data="refetchData"
    />
    <!-- top menu -->
    <div
      class="navbar-container custome-top-menu bgColor2 sami-bottom-rounded"
    >
      <div class="blank-block"></div>
      <div
        class="d-flex align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input 
                v-model="searchQuery" class="d-inline-block" placeholder="搜尋客戶" />
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">類別</span>
            <v-select
              v-model="filterCustomerType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerTypeOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="全部"
              class="d-inline-block common-rounded"
            />
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">等級</span>
            <v-select
              v-model="filterLoyaltyLevel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="loyaltyLevelOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="全部"
              class="d-inline-block common-rounded"
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <b-button variant="primary" class="mr-2" @click="exportExcel"><feather-icon icon="FileTextIcon" class="mr-1" size="16" />輸出Excel</b-button>
          <a v-if="(ability.can('create', 'customers'))"
            class="mr-2"
            href="javascript:void(0)"
            @click="$bvModal.show('modal-edit-customer')"
          >
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <b-card no-body class=" mb-0">

      <b-table
        ref="refCustomerListTable"
        class="position-relative"
        :items="fetchCustomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(customer_type_id)="data">
          {{ data.item.customer_type && data.item.customer_type.name }}
        </template>

        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>

        <template #cell(loyalty_level_id)="data">
          {{ data.item.loyalty_level && data.item.loyalty_level.name }}
        </template>

        <template #cell(contacts)="data">
          <ul class="m-0">
            <li
              class="list-style-none text-left"
              v-for="(contacts, index) of data.item.contacts"
              :key="`receipt_number${index}`"
            >
              {{ contacts.name }}
            </li>
          </ul>
        </template>
        <template #cell(contacts_mobile)="data">
          <ul class="m-0">
            <li
              class="list-style-none text-left"
              v-for="(contacts, index) of data.item.contacts"
              :key="`receipt_number${index}`"
            >
              {{ contacts.mobile }}
            </li>
          </ul>
        </template>
        <template #cell(position)="data">
          <ul class="m-0">
            <li
              class="list-style-none text-left"
              v-for="(contacts, index) of data.item.contacts"
              :key="`receipt_number${index}`"
            >
              {{ contacts.position }}
            </li>
          </ul>
        </template>

        <template #cell(created_at)="data">
          {{ getCorrectDateTime(data.item.created_at, true) }}
        </template>

        <template #cell(action)="data">
          <a href="javascript:void(0)" @click="handleUpload(data.item)">
            <feather-icon class="color1" icon="UploadCloudIcon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a v-if="(ability.can('update', 'customers'))" href="javascript:void(0)" @click="handleEdit(data.item)">
            <feather-icon class="color1" icon="Edit2Icon" />
          </a>
          <span class="mx-50 color1">|</span>
          <a v-if="(ability.can('update', 'customers'))" href="javascript:void(0)" @click="handlePoint(data.item)">
            <font-awesome-icon class="color1" icon="fa-solid fa-star" />
          </a>
          <span class="mx-50 color1">|</span>
          <a v-if="(ability.can('delete', 'customers'))" href="javascript:void(0)" @click="confirmDelete(data.item.id)">
            <feather-icon class="color1" icon="Trash2Icon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import customerStoreModule from "../../customerStoreModule";
import useCustomersList from "./useCustomersList";
import ModalEditCustomer from "../modal/ModalEditCustomer.vue";
import ModalEditPoint from "../modal/ModalEditPoint.vue";
import ModalFileManager from "@/layouts/components/file/ModalFileManager.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalEditCustomer,
    ModalEditPoint,
    ModalFileManager
  },
  methods: {
    handleUpload(item){
      //console.log(item)
      this.client_code = item.id
      this.trigger = !this.trigger
      this.path = `${item.id}`
      this.$bvModal.show("modal-file-manager");
    },
    handleEdit(item) {
      this.customerData = item;
      this.isEdit = true;
      this.$bvModal.show("modal-edit-customer");
    },
    handlePoint(item) {
      this.customerData = item;
      this.$bvModal.show("modal-edit-point");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.customerData = { id: 0, contacts: [{ id: 0 }] };
    },
    exportExcel() {
      this.$swal({
        title: "下載客戶文件",
        text: "您將下載客戶文件",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "輸出",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store.dispatch("customer/exportCustomers").then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
            // create "a" HTLM element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "customer"); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
        }
      });
    },
    confirmDelete(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("customer/deleteCustomer", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      customerData: {},
      pdfUrl: "",
      isEdit: false,
      trigger: false,
      client_code: 0,
      path: "",
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "customer";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, customerStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refCustomerListTable,
      showMessage,
      customerList,
      customerOptions,
      customerTypeOptions,
      loyaltyLevelOptions,
      districtOptions,
      filterCustomerType,
      filterLoyaltyLevel,
      ability
    } = useCustomersList();

    return {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refCustomerListTable,
      showMessage,
      customerList,
      customerOptions,
      customerTypeOptions,
      loyaltyLevelOptions,
      districtOptions,
      filterCustomerType,
      filterLoyaltyLevel,
      ability
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

