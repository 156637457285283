var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-detail"},[_c('div',{staticClass:"payment-info"},[(_vm.paymentStatusOptions && _vm.paymentStatusOptions.length > 0)?_c('b-table',{ref:"refPaymentScheduleListTable",staticClass:"position-relative",attrs:{"items":_vm.newPaymentScheduleData,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(mini_storage_number)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.miniStorageNumber)+" ")]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s((+data.item.amount + +data.item.deposite + +data.item.card_deposite - +data.item.total_discount).toFixed(2))+" ")]}},{key:"cell(payment_status)",fn:function(data){return [(data.item.invoice)?_c('b-button',{staticClass:"stage-button",style:({
            backgroundColor:
              _vm.paymentStatusOptions.find(
                function (ele) { return ele.id ===
                  (data.item.deleted_at
                    ? 4
                    : data.item.total_amount - data.item.total_paid_amount > 0
                    ? 1
                    : data.item.total_paid_amount -
                        data.item.total_audit_paid_amount <=
                      0
                    ? 3
                    : data.item.total_amount - data.item.total_paid_amount <=
                      0
                    ? 2
                    : 1); }
              ).background_color + '!important',
            color:
              _vm.paymentStatusOptions.find(
                function (ele) { return ele.id ===
                  (data.item.deleted_at
                    ? 4
                    : data.item.total_amount - data.item.total_paid_amount > 0
                    ? 1
                    : data.item.total_paid_amount -
                        data.item.total_audit_paid_amount <=
                      0
                    ? 3
                    : data.item.total_amount - data.item.total_paid_amount <=
                      0
                    ? 2
                    : 1); }
              ).font_color + '!important',
          })},[_vm._v(_vm._s(_vm.paymentStatusOptions.find( function (ele) { return ele.id === (data.item.deleted_at ? 4 : data.item.total_amount - data.item.total_paid_amount > 0 ? 1 : data.item.total_paid_amount - data.item.total_audit_paid_amount <= 0 ? 3 : data.item.total_amount - data.item.total_paid_amount <= 0 ? 2 : 1); } ).name)+" ")]):_c('span',{staticClass:"colorRed"},[_vm._v("需要先生成發票")])]}},{key:"cell(prepay_type_id)",fn:function(data){return [_c('div',[(_vm.prepayTypeId == 1)?_c('span',[_vm._v(" 月付 ")]):_vm._e(),(_vm.prepayTypeId == 2)?_c('span',[_vm._v(" 預付6個月 ")]):_vm._e(),(_vm.prepayTypeId == 3)?_c('span',[_vm._v(" 預付12個月 ")]):_vm._e()])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(
              !data.item.invoice && _vm.ability.can('create', 'payment-schedules')
            )?_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('生成發票'),expression:"'生成發票'",modifiers:{"hover":true,"top":true}}],attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.generateInvoice(data.item.id)}}},[_c('font-awesome-icon',{staticClass:"color1",attrs:{"icon":"fa-solid fa-file-circle-plus"}})],1):_vm._e()])]}}],null,false,1882792385)}):_vm._e(),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalRow,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }