<template>
  <b-sidebar
    id="add-new-appointment-sidebar"
    :visible="isAddNewAppointmentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-appointment-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">倉庫預約</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="倉庫"
              rules="required"
            >
              <b-form-group label="倉庫" class="required">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="appointmentData.warehouse_id"
                  :options="warehouseOptions"
                  label="name"
                  class="w-100"
                  :reduce="(val) => val.id"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="預約日期"
              rules="required"
            >
              <b-form-group label="預約日期" class="required">
                <flat-pickr
                  v-model="appointmentData.appointment_date"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d H:i',
                    dateFormat: 'Y-m-d H:i',
                  }"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="聯絡人姓名"
              rules="required"
            >
              <b-form-group label="聯絡人姓名" class="required">
                <b-form-input
                  type="text"
                  placeholder="聯絡人姓名"
                  v-model="appointmentData.contact_name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="聯絡人電話"
              rules="required"
            >
              <b-form-group label="聯絡人電話" class="required">
                <b-form-input
                  type="text"
                  placeholder="聯絡人電話"
                  v-model="appointmentData.contact_number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="聯絡人電郵">
              <b-form-input
                type="text"
                placeholder="聯絡人電郵"
                v-model="appointmentData.contact_email"
              />
            </b-form-group>
            <b-form-group label="客戶備註">
              <b-form-textarea
                id="customer_remark"
                v-model="appointmentData.customer_remark"
                rows="3"
              />
            </b-form-group>
            <b-form-group label="管理員備註">
              <b-form-textarea
                id="admin_remark"
                v-model="appointmentData.admin_remark"
                rows="3"
              />
            </b-form-group>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BImg,
  BCardText,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import appointmentStoreModule from "../appointmentStoreModule";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { statusOptions } from "@/libs/helper";
import flatPickr from "vue-flatpickr-component";

export default {
  data() {
    return {
      statusOptions,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BImg,
    BCardText,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BDropdown,
    BDropdownItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
    flatPickr,
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("appointment/addAppointment", this.appointmentData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-add-new-appointment-sidebar-active", false);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },

  model: {
    prop: "isAddNewAppointmentSidebarActive",
    event: "update:is-add-new-appointment-sidebar-active",
  },
  props: {
    isAddNewAppointmentSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    warehouseOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const APP_FAQ_STORE_MODULE_NAME = "appointment";

    if (!store.hasModule(APP_FAQ_STORE_MODULE_NAME))
      store.registerModule(APP_FAQ_STORE_MODULE_NAME, appointmentStoreModule);
    // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const blankData = ref({
      id: 0,
      warehouse_id: null,
      appointment_date: null,
      contact_name: null,
      contact_number: null,
      contact_email: null,
      customer_remark: null,
      admin_remark: null,
    });

    const appointmentData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetappointmentData = () => {
      appointmentData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappointmentData);

    watch(
      () => [props.id, props.isAddNewAppointmentSidebarActive],
      () => {
        if (props.isAddNewAppointmentSidebarActive) {
          appointmentData.value = JSON.parse(JSON.stringify(props.data));
        }
      }
    );

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      appointmentData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.b-sidebar.b-sidebar-right {
  width: 50% !important;
}

.ql-editor {
  min-height: 350px;
}

@media screen and (max-width: 780px) {
  .b-sidebar.b-sidebar-right {
    width: 100% !important;
  }
}
</style>