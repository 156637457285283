import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useReferralsList() {

    const toast = useToast()

    const refReferralListTable = ref(null)

    const tableColumns = [
        { key: 'id', label: '編號', sortable: true },
        { key: 'referral_discount_type', label: '受益人優惠類型'},
        { key: 'referral_coupon_type', label: '優惠券類型' },
        { key: 'free_month', label: '免租月數', sortable: true },
        { key: 'discount_percentage', label: ' 折扣百分比', sortable: true },
        { key: 'renewal_number', label: '第N次优惠', sortable: true },
        { key: 'is_active', label: '是否啟用？', sortable: true },
        { key: 'remark', label: '備註', sortable: true },
        { key: 'action', label: '行動' },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refReferralListTable.value ? refReferralListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refReferralListTable.value.refresh()
    }


    const filterReferralCouponType = ref(null)
    const filterReferralDiscountType = ref(null)
    watch([currentPage, perPage, searchQuery, filterReferralCouponType, filterReferralDiscountType ], () => {
        refetchData()
    })


    const referralList = ref(null)

    const fetchReferrals = (ctx, callback) => {

        store.dispatch('referral/fetchReferrals', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            referral_coupon_type_id: filterReferralCouponType.value,
            referral_discount_type_id: filterReferralDiscountType.value,
        })
            .then(response => {
                const { referral_discount_options, total } = response.data
                callback(referral_discount_options)
                referralList.value = referral_discount_options
                referralList.value.forEach(ele => ele.is_active = ele.is_active ? true : false);

                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching referrals list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    const referralCouponTypeOptions = ref([])
    const referralDiscountTypeOptions = ref([])
    const fetchOptions = () => {
        store
            .dispatch("app/fetchOptions", {
                referral_coupon_type: true,
                referral_discount_type: true,
            })
            .then((response) => {
                referralCouponTypeOptions.value = response.data.referral_coupon_types;
                referralDiscountTypeOptions.value = response.data.referral_discount_types;
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    onMounted(() => {
        fetchOptions();
    });


    return {
        fetchReferrals,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refReferralListTable,
        showMessage,
        referralList,
        referralCouponTypeOptions,
        referralDiscountTypeOptions,
        filterReferralCouponType,
        filterReferralDiscountType,
        ability
    }

}