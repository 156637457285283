import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useReferralsList() {

    const toast = useToast()

    const refReferralListTable = ref(null)

    const tableColumns = [
        { key: 'id', label: '編號', sortable: true },
        { key: 'free_month', label: '免租月數', sortable: true },
        { key: 'discount_percentage', label: ' 折扣百分比', sortable: true },
        { key: 'customer_id', label: '受益人', sortable: true },
        { key: 'contract_id', label: '合約', sortable: true },
        { key: 'referrer_id', label: '推薦人', sortable: true },
        { key: 'invitee_id', label: '受邀人', sortable: true },
        { key: 'referral_coupon_type_id', label: '優惠券類型', sortable: true },
        { key: 'user_id', label: '創建者', sortable: true },
        { key: 'remark', label: '備註', sortable: true },
        { key: 'action', label: '行動' },
    ]

    const perPage = ref(10)
    const totalRow = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)


    const dataMeta = computed(() => {
        const localItemsCount = refReferralListTable.value ? refReferralListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRow.value,
        }
    })

    const showMessage = (title, text, icon, variant) => {
        toast({
            component: ToastificationContent,
            props: {
                title,
                text,
                icon,
                variant
            },
        })
    }

    const refetchData = () => {
        refReferralListTable.value.refresh()
    }


    const filterReferralCouponType = ref(null)
    const filterReferralCustomer = ref(null)
    const filterReferralContract = ref(null)
    const filterReferralReferrer = ref(null)
    const filterReferralInvitee = ref(null)
    watch([currentPage, perPage, searchQuery, 
        filterReferralCouponType, 
        filterReferralCustomer,
        filterReferralContract,
        filterReferralReferrer,
        filterReferralInvitee
    ], () => {
        refetchData()
    })


    const referralList = ref(null)

    const fetchReferrals = (ctx, callback) => {

        store.dispatch('referral/fetchReferrals', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            referral_coupon_type_id: filterReferralCouponType.value,
            customer_id: filterReferralCustomer.value,
            contract_id: filterReferralContract.value,
            referrer_id: filterReferralReferrer.value,
            invitee_id: filterReferralInvitee.value,
        })
            .then(response => {
                const { referral_coupons, total } = response.data
                callback(referral_coupons)
                referralList.value = referral_coupons
                totalRow.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching referrals list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    const contractOptions = ref([])
    const userOptions = ref([])
    const referralCouponTypeOptions = ref([])
    const customerOptions = ref([])
    const fetchOptions = () => {
        store
            .dispatch("app/fetchOptions", {
                contract: true,
                user: true,
                referral_coupon_type: true,
                customer: true,
            })
            .then((response) => {
                contractOptions.value = response.data.contracts
                userOptions.value = response.data.users
                referralCouponTypeOptions.value = response.data.referral_coupon_types
                customerOptions.value = response.data.customers
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    onMounted(() => {
        fetchOptions();
    });


    return {
        fetchReferrals,
        tableColumns,
        perPage,
        currentPage,
        totalRow,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refReferralListTable,
        showMessage,
        referralList,
        contractOptions,
        userOptions,
        referralCouponTypeOptions,
        customerOptions,
        filterReferralCouponType, 
        filterReferralCustomer,
        filterReferralContract,
        filterReferralReferrer,
        filterReferralInvitee,
        ability
    }

}