<template>
  <div>
    <appointment-list-add-new
      :is-add-new-appointment-sidebar-active.sync="
        isAddNewAppointmentSidebarActive
      "
      :data="appointmentData"
      :id="id"
      :warehouse-options="warehouseOptions"
      @refetch-data="refetchData"
    />

    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="warehouseFilter"
              :options="warehouseOptions"
              label="name"
              style="width: 300px"
              :reduce="(val) => val.id"
              class="d-inline-block common-rounded mx-50"
              placeholder="過瀘倉庫"
            />
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span class="text-nowrap mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block common-rounded"
                placeholder="搜尋"
              />
            </div>
          </div>
          <a v-if="ability.can('create', 'appointments')" class="" href="javascript:void(0)" @click="addAppointment()">
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>

    <b-card no-body class="mb-0">
      <b-table
        ref="refAppointmentListTable"
        class="position-relative"
        :items="fetchAppointments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: is_active -->
        <template #cell(is_active)="data">
          <feather-icon
            v-if="data.item.is_active"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="ability.can('update', 'appointments')" @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="ability.can('delete', 'appointments')" @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAppointments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";

import appointmentStoreModule from "../appointmentStoreModule";
import useAppointmentsList from "./useAppointmentsList";
import AppointmentListAddNew from "./AppointmentListAddNew";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    AppointmentListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: 0,
      appointmentData: {
        warehouse_id: null,
        appointment_date: null,
        contact_name: null,
        contact_number: null,
        contact_email: null,
        customer_remark: null,
        admin_remark: null,
      },
    };
  },
  async created() {
    try {
      const response = await axios.get(
        "https://accumulusworld.com/v1/common/query"
      );
      console.log("v1/common/query", response.data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  },
  methods: {
    reset() {
      this.warehouseFilter = null;
    },
    confirmDelete(id) {
      this.$swal({
        title: "你確定嗎?",
        text: "Your action is final and you will not be able to retrieve the appointment.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("appointment/deleteAppointment", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleEdit(item) {
      this.id = item.id;
      this.appointmentData = { ...item };
      this.isAddNewAppointmentSidebarActive = true;
    },
    addAppointment() {
      this.id = 0;
      this.appointmentData = {
        id: 0,
        warehouse_id: null,
        appointment_date: null,
        contact_name: null,
        contact_number: null,
        contact_email: null,
        customer_remark: null,
        admin_remark: null,
      };
      this.isAddNewAppointmentSidebarActive = true;
    },
  },
  setup() {
    const APP_APPOINTMENT_STORE_MODULE_NAME = "appointment";
    const isAddNewAppointmentSidebarActive = ref(false);

    if (!store.hasModule(APP_APPOINTMENT_STORE_MODULE_NAME))
      store.registerModule(
        APP_APPOINTMENT_STORE_MODULE_NAME,
        appointmentStoreModule
      );

    onMounted(() => {
      fetchOptions();
    });

    const warehouseOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", { warehouse: true })
        .then((response) => {
          warehouseOptions.value = response.data.warehouses;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    const {
      fetchAppointments,
      tableColumns,
      perPage,
      currentPage,
      totalAppointments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refAppointmentListTable,
      ability,
      warehouseFilter,
    } = useAppointmentsList();

    return {
      isAddNewAppointmentSidebarActive,
      fetchAppointments,
      tableColumns,
      perPage,
      currentPage,
      totalAppointments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refAppointmentListTable,
      ability,
      warehouseFilter,
      warehouseOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
