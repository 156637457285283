<template>
  <b-modal
    id="modal-rent-form"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    headerClass="pr-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
    @show="handelLeaseImgReset"
    @hide="handelLeaseImgReset"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card class="no-shadow no-border bgColorWhite mb-0">
        <div class="card-header-menu">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between"
          >
            <div class="d-flex flex-wrap align-items-center">
              <div class="menu-item-wrapper">
                <div class="menu-item bgColor2 d-inline-block colorWhite mr-3">
                  {{ storageData.warehouse && storageData.warehouse.name }}
                </div>
              </div>
              <div cols="12" class="menu-item-wrapper" v-if="leaseData">
                <div
                  class="menu-tap-group d-flex flex-wrap align-items-center mr-3"
                >
                  <a
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 0 ? 'active' : null}`"
                    @click="formCollapse = 0"
                    >租約資料</a
                  >
                  <a
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 1 ? 'active' : null}`"
                    @click="formCollapse = 1"
                    >客戶資料</a
                  >
                  <!-- <a
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 2 ? 'active' : null}`"
                    @click="formCollapse = 2"
                    >其他資料</a
                  > -->
                  <a
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 3 ? 'active' : null}`"
                    @click="formCollapse = 3"
                    >發票記錄</a
                  >
                  <a
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 4 ? 'active' : null}`"
                    @click="formCollapse = 4"
                    >租用週期</a
                  >
                  <a
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 5 ? 'active' : null}`"
                    @click="formCollapse = 5"
                    >付款日期</a
                  >
                  <a
                    v-if="is_terminate"
                    href="javascript:void(0)"
                    :class="`menu-item ${formCollapse == 6 ? 'active' : null}`"
                    @click="formCollapse = 6"
                    >退租資料</a
                  >
                </div>
              </div>
              <div cols="12" class="menu-item-wrapper" v-if="leaseData">
                <div
                  class="menu-control-group d-flex flex-wrap align-items-center"
                >
                  <!-- <a
                    v-if="!is_terminate"
                    @click="handleEdit"
                    href="javascript:void(0)"
                    class="menu-item"
                    >修改</a
                  > -->
                  <!-- <a
                    v-if="!is_terminate"
                    @click="triggerPaymentModal"
                    href="javascript:void(0)"
                    class="menu-item"
                    >繳款</a
                  > -->
                  <a
                    @click="downloadContract"
                    href="javascript:void(0)"
                    class="menu-item"
                    >列印備用書</a
                  >
                  <a
                    v-if="!is_terminate && (ability.can('update', 'rents'))"
                    href="javascript:void(0)"
                    @click="handleTermination"
                    class="menu-item"
                    >停租</a
                  >
                  <a
                    v-if="is_terminate"
                    @click="exportTerminate"
                    href="javascript:void(0)"
                    class="menu-item"
                    >列印退租協議</a
                  >
                  <a
                    v-if="!is_terminate && (ability.can('update', 'rents'))"
                    @click="continuousRent"
                    href="javascript:void(0)"
                    class="menu-item"
                    >續租</a
                  >
                </div>
              </div>

              <div cols="12" class="menu-item-wrapper" v-if="!leaseData">
                <div
                  class="menu-control-group d-flex flex-wrap align-items-center"
                >
                  <a
                    v-if="!leaseData && storageData.on_sell && (ability.can('create', 'rents'))"
                    @click="newContract"
                    href="javascript:void(0)"
                    class="menu-item"
                    >新增租約</a
                  >
                  <a
                    v-if="!leaseData"
                    @click="handleEditRent"
                    href="javascript:void(0)"
                    class="menu-item"
                    >租用記錄</a
                  >
                </div>
              </div>
              <div cols="12" class="menu-item-wrapper">
                <div
                  class="menu-control-group d-flex flex-wrap align-items-center"
                >
                  <a v-if="!(!leaseData && storageData.on_sell) && (ability.can('create', 'rents'))"
                    @click="$bvModal.show('modal-edit-invoice')"
                    href="javascript:void(0)"
                    class="menu-item"
                    >新增發票</a
                  >
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0)"
              @click="hide"
              class="close-btn menu-item"
            >
              <feather-icon icon="XIcon" size="16" />
            </a>
          </div>
        </div>

        <div class="m-2 mb-0">
          <div
            class="d-flex align-items-center justify-content-between"
            v-if="leaseData || storageData.contracts.length > 0"
          >
            <div class="d-flex align-items-center mb-2">
              <span class="mr-1">歷史</span>
              <v-select
                v-model="lease_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="leaseHistoryOptions"
                :clearable="true"
                label="contract_number"
                :reduce="(option) => option.id"
                placeholder="請選擇租約"
                class="history-select d-inline-block common-rounded color1"
              >
                <template #option="data">
                  {{ data.contract_number }}
                  {{
                    data.terminate_date
                      ? `已在${data.terminate_date}停租`
                      : `簽約日期：${data.contract_date}`
                  }}
                </template>
                <template #selected-option="data">
                  {{ data.contract_number }}
                  {{
                    data.terminate_date
                      ? `已在${data.terminate_date}停租`
                      : `簽約日期：${data.contract_date}`
                  }}
                </template>
              </v-select>
            </div>
            <!-- <div
              class="d-flex align-items-center mb-2"
              v-if="formCollapse == 1"
            >
              <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1">
                下載資料
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
              >
                清除
              </b-button>
            </div> -->
          </div>

          <div v-show="formCollapse == 100">
            <div class="d-grid place-items-center">
              <mini-storage-detail
                :no_lease="true"
                :storageData="storageDetail"
                :customer-options="customerOptions"
                :rent-type-options="rentTypeOptions"
                @refetch-data="refetchData"
              ></mini-storage-detail>
            </div>
          </div>

          <lease-detail
            ref="leaseRef"
            v-show="formCollapse == 0"
            :leaseId="lease_id"
            :isEdit="isEdit"
            :lease-data="leaseData"
            :signStatusOptions="signStatusOptions"
            :storage-data="storageData"
            :prePayTypeOptions="prePayTypeOptions"
            :saleOptions="saleOptions"
            :storage-detail="storageDetail"
            :customer-options="customerOptions"
            :rent-type-options="rentTypeOptions"
            @refetch-data="refetchData"
          ></lease-detail>

          <customer-detail
            v-show="formCollapse == 1"
            :customer-data="customerData"
          ></customer-detail>

          <!-- <other-detail
            v-show="formCollapse == 2"
            :other-lease-data="otherLeaseData"
          ></other-detail> -->
          <invoice-detail
            :in_rent="true"
            :trigger="trigger"
            :contract_id="lease_id || storageData.contract_id"
            :customer_id="storageData.customer_id"
            :mini_storage_id="storageData.id"
            v-show="formCollapse == 3"
          ></invoice-detail>

          <rent-schedule-detail
            v-show="formCollapse == 4"
            :prepay-type-id="prepayTypeId"
            :mini-storage-number="miniStorageNumber"
            :rent-schedule-data="rentScheduleData"
          ></rent-schedule-detail>

          <modal-rent-schedule-edit
            :customer-options="customerOptions"
            :rent-type-options="rentTypeOptions"
            :rent-schedule-data="editRentScheduleData"
            :mini_storage_id="storageData.id"
          ></modal-rent-schedule-edit>

          <payment-schedule-detail 
            v-show="formCollapse == 5"
            :prepay-type-id="prepayTypeId"
            :mini-storage-number="miniStorageNumber"
            :payment-schedule-data="paymentScheduleData"
            @refetch-data="refetchData"
            @refetch-mini-data="$emit('refetch-mini-data', storageData.id)"
          ></payment-schedule-detail>

          <terminate-detail
            v-if="leaseData"
            v-show="formCollapse == 6"
            :terminate-data="leaseData.termination"
          ></terminate-detail>

          <modal-terminate-form :terminateData="terminateData">
          </modal-terminate-form>

          <modal-edit-contract
            :is_continuous="is_continuous"
            :contractData="contractData"
            @fouceIsEditFalse="cleanContractData"
            @refetch-data="refetchData"
          ></modal-edit-contract>

          <modal-pdf :pdf-url="pdfUrl" :id="id" />
        </div>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import store from "@/store";
import vSelect from "vue-select";
import leaseDetail from "../component/lease-detail.vue";
import customerDetail from "../component/customer-detail.vue";
import otherDetail from "../component/other-detail.vue";
import invoiceDetail from "../component/invoice-detail.vue";
import rentScheduleDetail from "../component/rent-schedule-detail.vue";
import modalRentScheduleEdit from "./modal-rent-schedule-edit.vue";
import paymentScheduleDetail from "../component/payment-schedule-detail.vue";
import terminateDetail from "../component/terminate-detail.vue";
import modalTerminateForm from "./modal-terminate-form.vue";
import ModalEditContract from "@/views/contract/new/modal/ModalEditContract.vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";
import miniStorageDetail from "../component/mini-storage-detail.vue";
import { getCorrectDateTime } from "@/libs/helper";
import ability from '@/libs/acl/ability'

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
    leaseDetail,
    customerDetail,
    otherDetail,
    invoiceDetail,
    rentScheduleDetail,
    paymentScheduleDetail,
    terminateDetail,
    modalTerminateForm,
    ModalPdf,
    ModalEditContract,
    miniStorageDetail,
    modalRentScheduleEdit,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ability,
      id: "rent-form",
      loading: false,
      formCollapse: 0,
      leaseData: null,
      lease_id: null,
      isEdit: false,
      leaseHistoryOptions: [],
      paymentData: [],
      customerData: {},
      rentScheduleData: {},
      paymentScheduleData: {},
      prepayTypeId: null,
      miniStorageNumber: null,
      terminateData: null,
      is_terminate: false,
      pdfUrl: "",
      isContractEdit: true,
      contractData: null,
      is_continuous: false,
      storageDetail: {},
      editRentScheduleData: {},
    };
  },
  props: {
    storageData: {},
    trigger: {},
  },
  methods: {
    refetchData() {
      this.$emit("refetch-data");
    },
    hide() {
      this.$bvModal.hide("modal-rent-form");
    },
    handleEditRent() {
      this.editRentScheduleData = this.storageData.rent_schedules;
      this.$bvModal.show("modal-rent-schedule-edit");
    },
    // triggerPaymentModal() {
    //   this.paymentData = {}
    //   this.$bvModal.show("modal-edit-payment");
    // },
    handelLeaseImgReset() {
      const childComponent = this.$refs.leaseRef;
      if (childComponent) {
        childComponent.showUpload = true;
      }
    },
    handleEdit() {
      this.formCollapse = 0;
      this.isEdit = !this.isEdit;
    },
    continuousRent() {
      this.resetContractData();
      this.is_continuous = true;
      this.$bvModal.show("modal-edit-contract");
    },
    newContract(){
      this.resetContractData();
      this.is_continuous = false;
      this.$bvModal.show("modal-edit-contract");
    },
    resetContractData() {
      if (this.leaseData) {
        this.contractData = {
          ...this.leaseData,
          id: 0,
          deposite: 0,
          card_deposite: 0,
          contract_date: getCorrectDateTime(new Date()),
          sign_date: null,
          start_date: null,
          is_renew_contract: 1,
          original_deposite: this.leaseData.deposite,
          original_card_deposite: this.leaseData.card_deposite,
        };
      } else {
        this.contractData = {
          id: 0,
          smart_cards: [],
          card_deposite: 0,
          mini_storage_id: this.storageData.id,
        };
      }
      this.is_continuous = false;
    },
    cleanContractData() {
      this.resetContractData();
      this.hide();
    },
    downloadContract() {
      this.loading = true;
      const id = this.lease_id;
      axiosIns
        .get("/contract/export", { params: { id }, responseType: "blob" })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf" + this.id);
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    exportTerminate() {
      console.log(this.leaseData)
      this.loading = true;
      const id = this.leaseData.termination && this.leaseData.termination.id;
      axiosIns
        .get("/contract/terminate/export", {
          params: { id },
          responseType: "blob",
        })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf" + this.id);
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    handleTermination() {
      let termination_items = [];

      if (this.leaseData.card_deposite) {
        termination_items.unshift({
          item_name: "智能卡按金",
          quantity: "1",
          price: Number(this.leaseData.card_deposite),
          remark: "",
        });
      }

      if (this.leaseData.deposite) {
        termination_items.unshift({
          item_name: "按金",
          quantity: "1",
          price: Number(this.leaseData.deposite),
          remark: "",
        });
      }

      if (!this.leaseData.deposite && !this.leaseData.deposite) {
        termination_items = [
          { item_name: "", quantity: "", price: "", remark: "" },
        ];
      }

      this.terminateData = {
        contract_id: this.lease_id,
        name: this.customerData?.name,
        termination_date: new Date().toISOString().slice(0, 10),
        termination_items,
        rent_schedules: this.rentScheduleData,
        payment_schedules: this.paymentScheduleData,
        deleteRentScheduleRecordIds: [],
        deletePaymentScheduleIds: [],
      };
      this.$bvModal.show("modal-terminate-form");
    },
    fetchMiniData() {
      if (this.storageData) {
        this.storageDetail = {
          id: this.storageData.id,
          rectWidth: this.storageData.rectWidth,
          rectHeight: this.storageData.rectHeight,
          x: this.storageData.x,
          y: this.storageData.y,
          is_rotate: this.storageData.is_rotate,
          warehouse_id: this.storageData.warehouse_id,
          name: this.storageData.warehouse.name,
          location: this.storageData.warehouse.location,
          //storage_number: this.miniStorageNumber,
          storage_number: this.storageData.storage_number,
          mini_storage_type_id: this.storageData.mini_storage_type_id,
          width: this.storageData.width,
          depth: this.storageData.depth,
          height: this.storageData.height,
          monthly_price: this.storageData.monthly_price,
          bi_annual_price: this.storageData.bi_annual_price,
          annual_price: this.storageData.annual_price,
          on_sell: this.storageData.on_sell,
          customer_id: this.storageData.customer_id,
          rent_type_id: this.storageData.rent_type_id,
          contract_id: this.storageData.contract_id,
        };
      }
    },
    fetchRentData() {
      this.leaseHistoryOptions = this.storageData.contracts
        ? this.storageData.contracts
        : [];
      this.is_terminate = this.storageData.contract_id ? false : true;
      /*   this.lease_id = this.storageData.contract_id
        ? this.storageData.contract_id
        : this.storageData.contracts[0].id; */
      this.lease_id = this.storageData.contract_id;
      this.leaseData =
        this.storageData.contracts &&
        this.storageData.contracts.find((ele) => ele.id == this.lease_id);
      this.customerData = this.leaseData && this.leaseData.customer;
      this.otherLeaseData = {};
      this.paymentData = {};

      this.rentScheduleData = this.leaseData && this.leaseData.rent_schedules;
      this.paymentScheduleData =
        this.leaseData && this.leaseData.payment_schedules;

      this.prepayTypeId = this.leaseData && this.leaseData.prepay_type_id;
      this.miniStorageNumber =
        this.storageData && this.storageData.storage_number;

      //   .get("payment",{
      //     params:{
      //       mini_storage_id:this.storageData.id
      //     }
      //   })
      //   .then((response) => {
      //     this.paymentData =  response.data.payment
      //   })
      //   .catch(() => {
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Error fetching contract list",
      //         icon: "AlertTriangleIcon",
      //         variant: "danger",
      //       },
      //     });
      //   });
    },
    // handlePaymentScheduleInvoice(){
      
    // }
  },
  watch: {
    storageData: {
      handler: function (newVal, oldVal) {
        this.leaseHistoryOptions = null;
        this.is_terminate = null;
        this.lease_id = null;
        this.leaseData = null;
        this.customerData = null;
        this.rentScheduleData = null;
        this.paymentScheduleData = null;
        this.otherLeaseData = null;
        this.paymentData = null;
        this.prepayTypeId = null;
        this.miniStorageNumber = null;
        this.storageDetail = null;
          console.log('watch storageData')
        this.fetchMiniData();
        if (newVal.contracts && newVal.contracts.length > 0) {
          console.log('fetchRentData')
          this.fetchRentData();
        } else {
          this.formCollapse = 100;
        }
      },
      deep: true,
      immediate: true,
    },
    lease_id: {
      handler: function (newVal, oldVal) {
        // this.formCollapse = 0;
        if (newVal) {
          if (this.storageData) {
            this.leaseData = this.storageData.contracts.find(
              (ele) => ele.id == this.lease_id
            );
            console.log('leaseData',this.leaseData)
            this.is_terminate = this.leaseData.terminate_date ? true : false;
            this.customerData = this.leaseData.customer;
            this.otherLeaseData = {};
            this.paymentData = {};

            this.rentScheduleData = this.leaseData.rent_schedules;
            this.paymentScheduleData = this.leaseData.payment_schedules;

            this.prepayTypeId = this.leaseData && this.leaseData.prepay_type_id;
            this.miniStorageNumber =
              this.storageData && this.storageData.storage_number;
          }
        } else {
          this.leaseData = null;
          this.is_terminate = false;
          this.otherLeaseData = {};
          this.paymentData = {};
          this.rentScheduleData = null;
          this.paymentScheduleData = null;
          this.prepayTypeId = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  setup() {
    const customerOptions = ref([]);
    const saleOptions = ref([]);
    const prePayTypeOptions = ref([]);
    const rentTypeOptions = ref([]);

    const signStatusOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptions", {
          customer: true,
          sign_status: true,
          user: true,
          pre_pay_type: true,
          rent_type: true,
        })
        .then((response) => {
          customerOptions.value = response.data.customers;
          signStatusOptions.value = response.data.sign_statuses;
          saleOptions.value = response.data.users;
          prePayTypeOptions.value = response.data.pre_pay_types;
          rentTypeOptions.value = response.data.rent_types;
        })
        .catch((error) => {
          console.log({ error });
        });
    };

    onMounted(() => {
      fetchOptions();
    });

    return {
      customerOptions,
      saleOptions,
      prePayTypeOptions,
      rentTypeOptions,
      signStatusOptions,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#modal-rent-form {
  .modal-body {
    min-height: 600px;
  }
}

body {
  .history-select {
    width: 500px !important;
    .vs__selected-options .vs__selected {
      color: #1d4987 !important;
    }
  }
}
@media screen and (max-width: 1350px) {
  .lease-image,
  .empty-img {
    position: static;
    margin: 1rem auto 0;
  }
}
</style>
  