var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"p-0 d-none d-lg-block bg-login-wrapper",attrs:{"lg":"7"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/pages/login/login-bg2.png")}})]),_c('b-col',{staticClass:"d-grid place-items-center",attrs:{"lg":"5"}},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"d-grid place-items-center brand-logo mb-3"},[_c('img',{attrs:{"src":require("@/assets/images/logo/hero.png")}}),_c('h4',{staticClass:"font-weight-bolder mb-1"},[_vm._v("忘記了密碼？")])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-col',{},[_c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('h3',{staticClass:"font-weight-bolder"},[_vm._v("請輸入電郵來重設密碼")]),_c('b-form',{staticClass:"auth-form mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.resetPasswordForm.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"required"},[_c('label',[_vm._v("電郵")]),_c('validation-provider',{attrs:{"name":"電郵：","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"forgot-password-email","state":errors.length > 0 ? false : null,"name":"forgot-password-email","placeholder":"輸入電郵"},model:{value:(_vm.resetData.email),callback:function ($$v) {_vm.$set(_vm.resetData, "email", $$v)},expression:"resetData.email"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-button',{staticClass:"p-50 bg-submit",attrs:{"variant":"primary"},on:{"click":_vm.sendVertificationCode}},[_vm._v(" 發送驗證碼 ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"密碼","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required mt-1",attrs:{"label":"密碼："}},[_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"輸入密碼","state":errors.length > 0 ? false : null},model:{value:(_vm.resetData.password),callback:function ($$v) {_vm.$set(_vm.resetData, "password", $$v)},expression:"resetData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"確認密碼","vid":"c_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"確認密碼："}},[_c('b-form-input',{attrs:{"id":"c_password","type":"password","placeholder":"輸入確認密碼","state":errors.length > 0 ? false : null},model:{value:(_vm.resetData.c_password),callback:function ($$v) {_vm.$set(_vm.resetData, "c_password", $$v)},expression:"resetData.c_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"驗證碼","vid":"vaildate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"驗證碼："}},[_c('b-form-input',{attrs:{"id":"vaildate","placeholder":"輸入驗證碼","state":errors.length > 0 ? false : null},model:{value:(_vm.resetData.vertification_code),callback:function ($$v) {_vm.$set(_vm.resetData, "vertification_code", $$v)},expression:"resetData.vertification_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',[_c('b-button',{staticClass:" bg-submit",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" 提交 ")])],1)],1)],1)],1),_c('p',{staticClass:"text-center mt-3"},[_c('b-link',{staticClass:"color1",attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" 返回登入頁 ")],1)],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }