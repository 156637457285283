<template>
  <b-modal
    id="modal-edit-point"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">添加客戶積分</h5>
      <label class="colorWhite">
        現時積分: <strong>{{ customerData.points }}</strong></label
      >
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="contract-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="info-wrap">
            <b-col cols="12" class="mb-1">
              <b-table
                ref="refCustomerPointTable"
                class="position-relative border"
                :items="customerData.loyalty_points"
                :fields="tableColumns"
                responsive
                show-empty
                empty-text="No matching records found"
              >
                <template #cell(created_at)="data">
                  {{ getCorrectDateTime(data.item.created_at) }}
                </template>
              </b-table>
            </b-col>
            <b-col cols="6">
              <div class="info-item">
                <b class="info-item-label"
                  >添加原因<span class="colorRed">*</span></b
                >
                <validation-provider 
                  #default="validationContext"
                  name="添加原因"
                  rules="required"
                >
                  <b-form-input
                    id="remark"
                    v-model="remark"
                    :state="getValidationState(validationContext)"
                    autofocus
                    trim
                    placeholder="添加原因"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="info-item">
                <b class="info-item-label"
                  >積分<span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="積分"
                  rules="required"
                >
                  <b-form-input
                    id="points"
                    v-model="points"
                    :state="getValidationState(validationContext)"
                    autofocus
                    trim
                    placeholder=" 積分"
                    type="number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>

            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                新增積分
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
    
    <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BTable,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BTable,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      tableColumns: [
        { key: "id", label: "編號" },
        { key: "remark", label: "添加原因" },
        { key: "points", label: "積分" },
        { key: "created_at", label: "生成時間" },
      ],
    };
  },
  props: {
    customerData: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-point");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("customer/addPoints", {
              customer_id: this.customerData.id,
              points: this.points,
              remark: this.remark,
            })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              }); 
              this.customerData.loyalty_points = [ ...this.customerData.loyalty_points, response.data.loyalty_points]
              this.loading = false;
              this.remark = null;
              this.points = null;
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  watch: {},
  setup(props) {
    const remark = ref(null);
    const points = ref(null);
    const loading = ref(false);

    const resetData = () => {
      remark.value = null;
      points.value = null;
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      remark,
      points,
      loading,
    };
  },
};
</script>
    
    <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
    