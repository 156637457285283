<template>
  <div>
    <modal-pdf :pdf-url="pdfUrl" />
    <modal-file-manager
      :trigger="trigger"
      :client_code="client_code"
      :path="path"
    />
    <modal-edit-contract
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      :contractData="contractData"
      @refetch-data="refetchData"
    />
    <modal-edit-sign
      :signData="signData"
      :signStatusOptions="signStatusOptions"
      @refetch-data="refetchData"
    />

    <modal-edit-email
      :emailData="emailData"
      @refetch-data="refetchData"
    />

    <modal-SMS :SMSData="SMSData" @refetch-data="refetchData" />

    <modal-wechat :wechatData="wechatData" @refetch-data="refetchData" />


    <!-- top menu -->
    <div class="navbar-container custome-top-menu bgColor2 common-rounded">
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap align-items-center">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block common-rounded"
                placeholder="搜尋名稱"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <v-select
              v-model="warehouseFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="warehouseOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="過瀘倉庫"
              :clearable="true"
              class="d-inline-block common-rounded mx-50"
            />
          </div>
          <div class="d-flex align-items-center">
            <v-select
              v-model="customerFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="過瀘客戶"
              :clearable="true"
              class="d-inline-block common-rounded mx-50"
            />
          </div>    
          <div class="d-flex align-items-center">
            <v-select
              v-model="userFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="saleOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="過瀘銷售顧問"
              :clearable="true"
              class="d-inline-block common-rounded mx-50"
            />
          </div>
          <div class="d-flex align-items-center">
            <v-select
              v-model="contractStatusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="contractStatusOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="過瀘合約狀態"
              :clearable="true"
              class="d-inline-block common-rounded mx-50"
            />
          </div>

          <!-- <div class="d-flex align-items-center">
            <span class="mr-1">狀態</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="d-inline-block common-rounded"
            />
          </div> -->
        </div>
        <div v-if="(ability.can('read', 'contract-templates'))" class="d-flex align-items-center">
          <b-link
            class="mr-1"
            v-b-popover.hover.top="'跳轉合約模版頁面'"
            :to="{
              name: 'contract-template-list',
            }"
          >
            <font-awesome-icon icon="fa-solid fa-table" />
          </b-link>
          <a v-if="(ability.can('create', 'contracts'))"
            class=""
            href="javascript:void(0)"
            v-b-popover.hover.top="'新增合約'"
            @click="$bvModal.show('modal-edit-contract')"
          >
            <font-awesome-icon
              class="colorWhite"
              icon="fa-solid fa-circle-plus"
            />
          </a>
        </div>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-table
          ref="refContractListTable"
          class="position-relative"
          :items="fetchContracts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(mini_storage)="data">
            <div v-if="data.item.mini_storage">
              <div>
                {{
                  data.item.mini_storage.warehouse &&
                  data.item.mini_storage.warehouse.name
                }}
                {{
                  data.item.mini_storage &&
                  data.item.mini_storage.storage_number
                }}號
              </div>
            </div>
          </template>

          <template #cell(amount)="data">
            <div>
              <span v-if="data.item.prepay_type_id == 1">
                每月付款 - 租{{ data.item.rent_period }}個月
              </span>
              <span v-if="data.item.prepay_type_id == 2">
                預付6個月 - 租{{ data.item.rent_period }}個月
              </span>
              <span v-if="data.item.prepay_type_id == 3">
                預付12個月 - 租{{ data.item.rent_period }}個月
              </span>
              <div>
                <b>總數：{{ data.item.amount }}</b>
              </div>
            </div>
          </template>

          <template #cell(sale_id)="data">
            {{ getSaleName(data.item.sale_id) }}
          </template>

          <template #cell(customer_id)="data">
            {{ getCustomerName(data.item.customer_id) }}
          </template>

          <template #cell(sign_status)="data">
            {{ data.item.sign_status && data.item.sign_status.name }}
            <feather-icon
              icon="EditIcon"
              size="16"
              class="ml-25 cursor-pointer"
              @click="handleSign(data.item)"
            />
          </template>

          <template #cell(smart_cards)="data">
            {{ data.item.smart_cards.toString() }}
          </template>

          <template #cell(created_at)="data">
            {{ getCorrectDateTime(data.item.created_at, true) }}
          </template>

          <template #cell(media)="data">
            <div class="d-flex align-items-end justify-content-center mr-1">
              <!-- <a class="mr-1" :href="`mailto:${data.item.customer.email}`" target="_blank"> -->

              <div
                class="cursor-pointer mr-1"
                @click="handleEmailModal(data.item.customer.email, data.item.message)"
              >
                <font-awesome-icon
                  class="color2"
                  icon="fa-solid fa-envelope"
                  style="height: 18px"
                />
              </div>
              <!-- </a> -->
              <a class="mr-1" :href="data.item.whatsapp_link" target="_blank">
                <img
                  src="@/assets/images/icons/whatsapp-logo.png"
                  style="width: 24px"
                  class="whatsapp-icon"
                />
              </a>
              <div v-if="(ability.can('update', 'contracts'))"
                class="mr-1 cursor-pointer"
                @click="
                  handleMsnModal(
                    data.item.id,
                    data.item.phone,
                    data.item.message
                  )
                "
              >
                <font-awesome-icon
                  class="color1"
                  icon="fa-solid fa-message"
                  style="height: 18px"
                />
              </div>

              <div
                class="cursor-pointer"
                @click="
                  handleWechartModal(
                    data.item.id,
                    data.item.message
                  )
                "
              >
                <img src="@/assets/images/logo/wechat.png"
                  style="height: 20px"
                />
              </div>
            </div>
          </template>

          <template #cell(action)="data">
            <a
              href="javascript:void(0)"
              @click="
                handleUpload(
                  data.item.customer_id,
                  data.item.contract_file_path
                )
              "
            >
              <feather-icon class="color1" icon="UploadCloudIcon" />
            </a>
            <span class="mx-50 color1">|</span>
            <a
              href="javascript:void(0)"
              @click="downloadContract(data.item.id)"
            >
              <font-awesome-icon
                class="color1"
                icon="fa-solid fa-file-export"
              />
            </a>
            <span class="mx-50 color1">|</span>
            <a v-if="(ability.can('update', 'contracts'))" href="javascript:void(0)" @click="handleEdit(data.item)">
              <feather-icon class="color1" icon="Edit2Icon" />
            </a>
            <!-- <span class="mx-50 color1">|</span>
          <a href="javascript:void(0)" @click="confirmDelete(data.item.id)">
            <feather-icon class="color1" icon="Trash2Icon" />
          </a> -->
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";

import contractStoreModule from "../../contractStoreModule";
import useContractsList from "./useContractsList";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";
import ModalEditContract from "../modal/ModalEditContract.vue";
import ModalEditSign from "../modal/modal-edit-sign.vue";
import ModalSMS from "../modal/modal-SMS.vue";
import ModalWechat from "../modal/modal-wechat.vue";
import ModalFileManager from "@/layouts/components/file/ModalFileManager.vue";
import ModalEditEmail from "@/views/email/modal/ModalEditEmail.vue";

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalPdf,
    ModalEditContract,
    ModalEditSign,
    ModalSMS,
    ModalWechat,
    ModalFileManager,
    ModalEditEmail,
  },
  directives: {
    "b-popover": VBPopover,
  },
  computed: {
  },
  methods: {
    handleEmailModal(email, message) {
      this.emailData = { email, message };
      this.$bvModal.show("modal-edit-email");
    },
    handleMsnModal(id, phone, message) {
      this.SMSData = { contract_id: id, phone, message };
      this.$bvModal.show("modal-SMS");
    },
    handleWechartModal(id, message) {
      this.wechatData = { message };
      this.$bvModal.show("modal-wechat");
    },
    handleUpload(customer_id, file_path) {
      this.client_code = customer_id;
      this.trigger = !this.trigger;
      this.path = `${file_path}`;
      this.$bvModal.show("modal-file-manager");
    },
    handleEdit(item) {
      this.contractData = item;
      this.isEdit = true;
      this.$bvModal.show("modal-edit-contract");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.contractData = { id: 0, smart_cards: [], card_deposite: 0 };
    },
    handleSign(item) {
      console.log('item',item)
      this.signData = {
        id: item.id,
        sign_date: item.sign_date,
        sign_status_id: item.sign_status_id,
      };
      this.$bvModal.show("modal-edit-sign");
    },
    downloadContract(id) {
      this.loading = true;
      this.$store
        .dispatch("contract/downloadContract", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    confirmDelete(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("contract/deleteContract", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    getSaleName(saleId) {
      const sale = this.saleOptions.find(sale => sale.id === saleId);
      return sale ? sale.name : '';
    },
    getCustomerName(customerId) {
      const customer = this.customerOptions.find(customer => customer.id === customerId);
      return customer ? customer.name : '';
    },
  },
  data() {
    return {
      contractData: { id: 0, smart_cards: [], card_deposite: 0 },
      pdfUrl: "",
      isEdit: false,
      trigger: false,
      client_code: 0,
      path: "",
      signData: {},
      SMSData: {},
      emailData: {},
      wechatData: {},
    };
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = "contract";

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const loading = ref(false);

    const {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refContractListTable,
      showMessage,
      contractList,
      saleOptions,
      signStatusOptions,
      customerFilter,
      userFilter,
      warehouseFilter,
      contractStatusFilter,
      customerOptions,
      contractStatusOptions,
      warehouseOptions,
      ability
    } = useContractsList();

    return {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalRow,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refContractListTable,
      showMessage,
      contractList,
      saleOptions,
      signStatusOptions,
      loading,
      customerFilter,
      userFilter,
      warehouseFilter,
      contractStatusFilter,
      customerOptions,
      contractStatusOptions,
      warehouseOptions,
      ability
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>



