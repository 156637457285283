<template>
  <b-modal
    id="modal-edit-referral"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    title=""
    :ok-disabled="false"
    no-close-on-backdrop
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">{{ isEdit ? "修改" : "新增" }}優惠</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="discount-option-form"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row class="info-wrap">
            <b-col cols="12" xl="6">
              <div class="info-item">
                <b class="info-item-label"
                  >受益人優惠類型 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="受益人優惠類型"
                  rules="required"
                  class="w-100"
                >
                  <v-select
                    v-model="newReferralData.referral_discount_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="referralDiscountTypeOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="true"
                    class="w-100"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <div class="info-item">
                <b class="info-item-label"
                  >優惠券類型<span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="優惠券類型"
                  rules="required"
                  class="w-100"
                >
                  <v-select
                    v-model="newReferralData.referral_coupon_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="referralCouponTypeOptions"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="true"
                    class="w-100"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>

            <b-col
              cols="12"
              xl="6"
              v-if="newReferralData.referral_coupon_type_id == 1"
            >
              <div class="info-item">
                <b class="info-item-label"
                  >免租月數<span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="免租月數"
                  rules="required"
                  class="w-100"
                >
                  <b-input-group class="w-100" size="md" append="個月">
                    <b-form-input
                      v-model="newReferralData.free_month"
                      class="d-inline-block"
                      type="number"
                      placeholder="免租月數"
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col
              cols="12"
              xl="6"
              v-if="newReferralData.referral_coupon_type_id == 2"
            >
              <div class="info-item">
                <b class="info-item-label"
                  >折扣百分比 <span class="colorRed">*</span></b
                >
                <validation-provider
                  #default="validationContext"
                  name="折扣百分比"
                  rules="required"
                  class="w-100"
                >
                  <b-input-group class="input2" size="md" append="%">
                    <b-form-input
                      v-model="newReferralData.discount_percentage"
                      class="d-inline-block"
                      type="number"
                      placeholder="90 = 九折"
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <div class="info-item">
                <b class="info-item-label">第N次优惠</b>
                <validation-provider
                  #default="validationContext"
                  name="第N次优惠"
                  rules="required"
                  class="w-100"
                >
                  <b-input-group class="w-100" size="md" append="次">
                    <b-form-input
                      v-model="newReferralData.renewal_number"
                      class="d-inline-block"
                      type="number"
                      placeholder="第N次优惠"
                      :state="getValidationState(validationContext)"
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <div class="info-item">
                <b class="info-item-label">備註</b>
                <b-form-input
                  class="info-item-value"
                  v-model="newReferralData.remark"
                ></b-form-input>
              </div>
            </b-col>
            <b-col cols="12" xl="6">
              <div class="info-item">
                <b class="info-item-label" style="width:120px;">是否啟用？</b>
                <b-form-checkbox
                  v-model="newReferralData.is_active"
                  class="custome-control-success"
                  name="check-button"
                  switch
                  inline
                />
                {{ newReferralData.is_active ? "啟用" : "禁用" }}
              </div>
            </b-col>

            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
  BInputGroup,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    BInputGroup,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    };
  },
  props: {
    referralData: {},
    referralCouponTypeOptions: {},
    referralDiscountTypeOptions: {},
    isEdit: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-referral");
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");
    },
    onSubmit() {
      this.newReferralData.is_active = this.newReferralData.is_active === true ? 1 : 0
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("referral/updateReferral", this.newReferralData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
              this.hide();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newReferralData = ref({
      id: 0,
      is_active:true
    });

    const resetReferralData = () => {
      newReferralData.value = { id: 0,is_active:true};
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetReferralData);

    watch(
      () => props.referralData,
      (newVal) => {
        newReferralData.value = newVal;
      }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newReferralData,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.discount-option-form {
  .info-item .info-item-label {
    width: 170px;
  }
}
</style>
  