<template>
  <b-modal
    id="modal-edit-customer-type"
    cancel-variant="outline-secondary"
    centered
    size="sm"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">{{ isEdit ? "修改" : "新增" }}客戶類型</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="contract-form" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="">
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="客戶類型"
                rules="required"
              >
                <b-form-group
                  label="客戶類型"
                  label-for="customer_type"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="customer_type"
                    v-model="newCustomerTypeData.name"
                    class="d-inline-block"
                    placeholder="客戶類型"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> 
            <b-col cols="12">
              <b-form-group
                label="排序"
                label-for="sort_order"
                label-cols-md="3"
              >
                  <b-form-input
                    id="sort_order"
                    v-model="newCustomerTypeData.sort_order"
                    class="d-inline-block"
                    placeholder="排序"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="狀態"
                label-for="is_active"
                label-cols-md="3"
              >
                <b-form-checkbox
                  :checked="newCustomerTypeData.is_active"
                  name="is_active"
                  switch
                  inline
                  v-model="newCustomerTypeData.is_active"
                  value="1"
                  style="margin-top: 5px"
                >
                  {{ newCustomerTypeData.is_active ? "啟用" : "禁用" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="d-flex flex-wrap justify-content-center w-100 p-2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 px-3"
                type="submit"
              >
                提交
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="px-3"
                @click="hide"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
  
  <script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import flatPickr from "vue-flatpickr-component";

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BOverlay,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    customerTypeData: {},
    isEdit: {},
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-customer-type");
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");
    },
    onSubmit() {
      this.newCustomerTypeData.is_active = this.newCustomerTypeData.is_active ? 1 : 0

      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("customer-type/updateCustomerType", this.newCustomerTypeData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.loading = false;
              this.hide();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
    IsHKID(str) {
      var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      // basic check length
      if (str.length < 8) {
        this.idError = "身份證號碼" + str + "不正確";
        return false;
      }

      // handling bracket
      if (
        str.charAt(str.length - 3) == "(" &&
        str.charAt(str.length - 1) == ")"
      )
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

      // convert to upper case
      str = str.toUpperCase();

      // regular expression to check pattern and split
      var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;

      var matchArray = str.match(hkidPat);

      // not match, return false
      if (matchArray == null) {
        this.idError = "身份證號碼" + str + "不正確";
        return;
      }

      // the character part, numeric part and check digit part
      var charPart = matchArray[1];
      var numPart = matchArray[2];
      var checkDigit = matchArray[3];

      // calculate the checksum for character part
      var checkSum = 0;
      if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      }

      // calculate the checksum for numeric part
      for (var i = 0, j = 7; i < numPart.length; i++, j--)
        checkSum += j * numPart.charAt(i);

      // verify the check digit
      var remaining = checkSum % 11;
      var verify = remaining == 0 ? 0 : 11 - remaining;

      if (verify == checkDigit || (verify == 10 && checkDigit == "A")) {
        this.idError = "";
      } else {
        this.idError = "身份證號碼" + str + "不正確";
      }
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newCustomerTypeData = ref( { id: 0, sort_order:0, is_active:1 });

    const resetCustomerTypeData = () => {
      newCustomerTypeData.value =  { id: 0, sort_order:0, is_active:1 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetCustomerTypeData);

    watch(
      () => props.customerTypeData,
      (newVal) => {
        newCustomerTypeData.value = newVal;
      }
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newCustomerTypeData,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
  