<template>
  <b-row class="m-0">
    <!-- Left Text-->
    <b-col lg="7" class="p-0 d-none d-lg-block bg-login-wrapper">
      <img src="~@/assets/images/pages/login/login-bg2.png" class="w-100" />
    </b-col>

    <!-- Login-->
    <b-col lg="5" class="d-grid place-items-center">
      <div class="login-form">
        <div class="d-grid place-items-center brand-logo mb-3">
          <img src="@/assets/images/logo/hero.png" />
          <h4 class="font-weight-bolder mb-1">歡迎來到大昌迷你倉！</h4>
        </div>
        <div class="d-flex justify-content-center">
          <b-col class="">
            <!-- form -->
            <validation-observer
              class="w-100"
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <h3 class="font-weight-bolder">登入</h3>
                <b-form class="auth-login-form mt-2" @submit.prevent="login">
                  <!-- email -->
                  <b-form-group label-for="email" label="電郵">
                    <validation-provider
                      #default="{ errors }"
                      name="電郵"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userEmail"
                        name="login-email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="電郵"
                        autofocus
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- password -->
                  <b-form-group label-for="password" label="密碼">
                    <validation-provider
                      #default="{ errors }"
                      name="密碼"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="login-password"
                          placeholder="密碼"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div class="d-flex justify-content-end">
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>忘記密碼?</small>
                    </b-link>
                  </div>

                  <!-- submit button -->
                  <b-button
                    class="mt-3 w-100 px-3 bg-submit"
                    type="submit"
                    variant="primary"
                    @click="$bvModal.show('modal-login')"
                  >
                    立即登入
                  </b-button>
                </b-form>
              </b-overlay>
            </validation-observer>
          </b-col>
        </div>
      </div>
    </b-col>
    <!-- /Login-->
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import vSelect from "vue-select";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      //userEmail: 'key@moonview.com.hk',
      //password: '12345678',
      userEmail: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
      loading: false,
      language_id: $themeConfig.app.language_id,
      company: $themeConfig.app.appName,
      // Variable for selecting the server
    };
  },
  mounted() {},
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    setServer(val) {
      localStorage.setItem("server", val);
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              this.loading = false;
              const { userData } = response.data;
              useJwt.setToken(response.data.access_token);
              this.$ability.update(userData.ability)
              localStorage.setItem("userData", JSON.stringify(userData));
              localStorage.setItem("language_id", this.language_id);
              this.$router
                .replace("/")
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Welcome ${userData.name}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `You have successfully logged in as ${userData.name}. Now you can start to explore!`,
                    },
                  });
                })
                .catch((error) => {
                  console.log(error);
                  this.$refs.loginForm.setErrors(error.response.data.error);
                });
            })
            .catch((error) => {
              this.$swal({
                text: "Email and Password not match",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
