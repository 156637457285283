<template>
  <b-sidebar
    id="edit-page-sidebar"
    :visible="isEditPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0"> 編輯頁面</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="姓名"
              rules="required"
            >
              <b-form-group label="姓名" label-for="name" class="required">
                <b-form-input
                  id="name"
                  v-model="pageData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="姓名"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Slug"
              rules="required"
            >
              <b-form-group label="Slug" label-for="slug" class="required">
                <b-form-input
                  id="slug"
                  v-model="pageData.slug"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Slug"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

           
            <validation-provider
              #default="validationContext"
              name="內容"
              rules="required"
            >
              <b-form-group label="內容" label-for="content" class="required">
                <quill-editor
                    class="custome-editor"
                    v-model="pageData.content"
                  />


                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                提交
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant=""
                class="mr-2"
                @click="hide"
              >
              取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BCol,
  BOverlay,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

// Notification
export default {
  components: {
    BOverlay,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods: {
    hide() {
      this.$emit("update:is-edit-page-sidebar-active", false);
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("bar-page/updatePage", this.pageData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  model: {
    prop: "isEditPageSidebarActive",
    event: "update:is-add-edit-sidebar-active",
  },
  props: {
    isEditPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const fileList = ref([]);


    const blankPageData = ref({
      id: 0,
      name: "",
      is_active: 1,
      sort_order: 0,
    });

    const pageData = ref(JSON.parse(JSON.stringify(blankPageData.value)));

    const resetpageData = () => {
      pageData.value = JSON.parse(JSON.stringify(blankPageData.value));
      fileList.value = [];
    };

    watch(
      () => [props.id, props.isEditPageSidebarActive],
      () => {
        if (props.id > 0 && props.isEditPageSidebarActive) {
          pageData.value = { ...props.data };
        }
      }
    );

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData);


    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      pageData,
    };
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 178px;
  line-height: 100%;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hideUpload > div {
  display: none;
}
</style>