import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";
import router from '@/router'

export default function useTasksList() {
  // Use toast
  const toast = useToast();

  const refTasksListTable = ref(null);
  // Table Handlers
  const tableColumns = [
    { key: "id", label: "事件編號", sortable: true },
    { key: "meeting_stage_id", label: "狀態", sortable: true },
    { key: "dateTime", label: "日期" },
    { key: "customer_id", label: "客戶編號", sortable: true },
    { key: "customer_name", label: "客戶名稱" },
    { key: "contract_number", label: "合約編號" },
    { key: "warehouse", label: "倉庫" },
    { key: "address", label: "地址", sortable: true },
    { key: "meeting_title", label: "標題", sortable: true },
    { key: "meeting_content", label: "會議紀錄", sortable: true },
    { key: "users", label: "出席者" },
    { key: "actions", label: "操作" },
  ];
  const perPage = ref(10);
  const totalRow = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refTasksListTable.value
      ? refTasksListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRow.value,
    };
  });

  const refetchData = () => {
    refTasksListTable.value.refresh();
  };



  watch(
    [currentPage, perPage, searchQuery, 
    ],
    () => {
      refetchData();
    }
  );

  const taskList = ref([])
  const fetchTasks = (ctx, callback) => {
    store
      .dispatch("task/fetchTasks", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(1)
        const { meetings, total } = response.data;
        callback(meetings);
        taskList.value = meetings
        totalRow.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tasks list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };



  return {
    fetchTasks,
    tableColumns,
    perPage,
    currentPage,
    totalRow,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTasksListTable,
    ability,
    refetchData,
    taskList
  };
}
