<template>
  <div>
    <modal-file-manager
      :trigger="trigger"
      :client_code="client_code"
      :path="path"
    />

    <modal-pdf :pdf-url="pdfUrl" />
    <modal-invoice-status
      :invoice-data="invoiceData"
      :invoice-status-options="invoiceStatusOptions"
      @refetch-data="refetchData"
    />

    <modal-edit-audit :audit-data="auditData" @refetch-data="refetchData" />

    <modal-edit-invoice
      :isEdit="isEdit"
      @fouceIsEditFalse="fouceIsEditFalse"
      @changeTrigger="editTrigger = !editTrigger"
      :trigger="editTrigger"
      :in_rent="in_rent"
      :contract_id="contract_id"
      :customer_id="customer_id"
      :mini_storage_id="mini_storage_id"
      :invoiceData="invoiceData"
      :contractOptions="contractOptions"
      :customerOptions="customerOptions"
      :miniStorageOptions="miniStorageOptions"
      :saleOptions="saleOptions"
      @refetch-data="refetchData"
    />

    <!-- :invoiceTypeOptions="invoiceTypeOptions" -->

    <modal-edit-payment
      :paymentData="paymentData"
      :paymentTypeOptions="paymentTypeOptions"
      @refetch-data="refetchData"
    >
    </modal-edit-payment>

    <modal-delete :invoice_id="select_invoice_id" @refetch-data="refetchData">
    </modal-delete>

    <!-- top menu -->
    <div
      v-if="!in_rent"
      class="navbar-container custome-top-menu bgColor2 common-rounded"
    >
      <div class="blank-block"></div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between colorWhite"
      >
        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <span>顯示</span>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block common-rounded mx-50"
            />
            <span>條目</span>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">搜尋</span>
            <div class="search-primary">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block common-rounded"
                placeholder="搜尋發票編號/公司名稱"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mr-2">
            <span class="mr-1">倉庫</span>
            <v-select
              v-model="warehouse_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="warehouseOptions"
              label="name"
              :reduce="(option) => option.id"
              placeholder="選擇倉庫"
              class="d-inline-block common-rounded"
              @input="(val) => clearMiniStorage()"
            />
          </div>
          <div class="d-flex align-items-center mr-2" v-if="warehouse_id">
            <span class="mr-1">迷李倉</span>
            <v-select
              v-model="miniStorageFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filteredMiniStorageOptions"
              label="storage_number"
              :reduce="(option) => option.id"
              placeholder="選擇倉庫"
              class="d-inline-block common-rounded"
            />
          </div>
          <div class="d-flex align-items-center">
            <span class="mr-1">狀態</span>
            <v-select
              v-model="invoice_status_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoiceStatusOptions2"
              label="name"
              :reduce="(option) => option.id"
              placeholder="選擇狀態"
              class="d-inline-block common-rounded"
            />
          </div>
        </div>
        <a
          v-if="(ability.can('create', 'invoices'))"
          class=""
          href="javascript:void(0)"
          v-b-popover.hover.top="'新增發票'"
          @click="$bvModal.show('modal-edit-invoice')"
        >
          <font-awesome-icon
            class="colorWhite"
            icon="fa-solid fa-circle-plus"
          />
        </a>
      </div>
    </div>
    <b-card no-body class="mb-0">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-table
          ref="refInvoiceListTable"
          class="position-relative"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- <template #cell(invoice_status_id)="data">
            <b-button
              class="stage-button"
              @click="handleEditStatus(data.item)"
              :style="{
                backgroundColor:
                  invoiceStatusOptions &&
                  invoiceStatusOptions.find(
                    (ele) => ele.id === data.item.invoice_status_id
                  ).background_color + '!important',
                color:
                  invoiceStatusOptions &&
                  invoiceStatusOptions.find(
                    (ele) => ele.id === data.item.invoice_status_id
                  ).font_color + '!important',
              }"
              >{{
                invoiceStatusOptions &&
                invoiceStatusOptions.find(
                  (ele) => ele.id === data.item.invoice_status_id
                ).name
              }}
            </b-button>
          </template> -->

          <template #cell(mini_storage)="data">
            <span
              v-if="data.item.mini_storage && data.item.mini_storage.warehouse"
              >{{ data.item.mini_storage.warehouse.name }}[{{
                data.item.mini_storage.storage_number
              }}]</span
            >
          </template>
          <template #cell(invoice_status_id)="data">
            <b-button
              class="stage-button cursor-default"
              :style="{
                backgroundColor: getInvoiceStatus(data.item).background_color + '!important',
                color: getInvoiceStatus(data.item).font_color + '!important',
              }"
            >
              {{ getInvoiceStatus(data.item).name }}
            </b-button>
          </template>

          <template #cell(invoice_number)="data">
            {{ data.item.invoice_number }}
          </template>
          <!-- <template #cell(invoice_type)="data">
          {{ data.item.invoice_type && data.item.invoice_type.name }}
        </template> -->
          <template #cell(invoice_type_number)="data">
            {{
              data.item.invoice_type &&
              data.item.invoice_type.invoice_type_number
            }}
          </template>

          <template #cell(show_details)="row">
            <b-button
              v-if="row.item.invoice_items && row.item.invoice_items.length > 0"
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
            >
              {{ row.detailsShowing ? "隱藏" : "顯示" }}
            </b-button>
            <span v-else> - - </span>
          </template>

          <template #row-details="row">
            <b-table
              class="position-relative"
              :items="row.item.invoice_items"
              responsive
              :fields="tableItemColumns"
              primary-key="id"
            >
            </b-table>
            <div class="bgColor1" style="width: 100%; height: 1px" />
            <h5 class="my-1">付款記錄</h5>
            <b-table
              class="position-relative"
              :items="row.item.payments"
              responsive
              :fields="tablePaymentColumns"
              primary-key="id"
            >
              <template #cell(image)="data">
                <a
                  href="javascript:void(0)"
                  @click="
                    handleUpload(
                      row.item.customer_id,
                      row.item.invoice_file_path
                    )
                  "
                >
                  <feather-icon class="color1" icon="UploadCloudIcon" />
                </a>
              </template>

              <template #cell(payment_type_id)="data">
                <div>
                  {{ getPaymentTypeName(data.item.payment_type_id) }}
                </div>
              </template>

              <template #cell(audit)="data">
                <span v-if="data.item.is_audit">已核實</span>

                <a
                  v-else-if="(ability.can('update', 'invoices'))"
                  href="javascript:void(0)"
                  v-b-popover.hover.top="'核實付款'"
                  @click="auditPayment(data.item.id)"
                >
                  <font-awesome-icon
                    class="color1"
                    icon="fa-solid fa-audio-description"
                  />
                </a>
              </template>
              <template #cell(description)="data">
                <span v-if="data.item.description != 'undefined'">{{ data.item.description }}</span>
              </template>

              <template #cell(receipt)="data">
                <a
                  v-if="data.item.is_audit"
                  href="javascript:void(0)"
                  v-b-popover.hover.top="'列印收據'"
                  @click="downloadReceipt(data.item.id)"
                >
                  <font-awesome-icon
                    class="color1"
                    icon="fa-solid fa-receipt"
                  />
                </a>
                <span v-else>- -</span>
              </template>
            </b-table>
          </template>

          <template #cell(sale_id)="data">
            {{ getSaleName(data.item.sale_id) }}
          </template>

          <template #cell(contract_number)="data">
            {{ getContractNumber(data.item.contract_id) }}
          </template>

          <template #cell(customer_name)="data">
            {{ getCustomerName(data.item.customer_id) }}
          </template>

          <template #cell(balance)="data">
            {{
              data.item.total_amount - data.item.total_paid_amount > 0
                ? data.item.total_amount - data.item.total_paid_amount
                : 0
            }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex" v-if="data.item.deleted_at">
              <a
                href="javascript:void(0)"
                v-b-popover.hover.top="'復原刪除的發票'"
                @click="handleRestore(data.item.id)"
              >
                <feather-icon class="color1" icon="CornerDownLeftIcon" />
              </a>
            </div>
            <div class="d-flex" v-else>
              <a
                href="javascript:void(0)"
                v-b-popover.hover.top="'文件庫'"
                @click="
                  handleUpload(
                    data.item.customer_id,
                    data.item.invoice_file_path
                  )
                "
              >
                <feather-icon class="color1" icon="UploadCloudIcon" />
              </a>
              <span class="mx-50 color1">|</span>
              <a 
                v-if="(ability.can('update', 'invoices'))"
                href="javascript:void(0)"
                v-b-popover.hover.top="'付款'"
                @click="payment(data.item)"
              >
                <font-awesome-icon
                  class="color1"
                  icon="fa-solid fa-cash-register"
                />
              </a>
              <span class="mx-50 color1">|</span>
              <a
                href="javascript:void(0)"
                v-b-popover.hover.top="'列印發票'"
                @click="downloadInvoice(data.item.id)"
              >
                <font-awesome-icon
                  class="color1"
                  icon="fa-solid fa-file-export"
                />
              </a>
              <span class="mx-50 color1">|</span>
              <a v-if="(ability.can('update', 'invoices'))"
                href="javascript:void(0)"
                v-b-popover.hover.top="'修改發票'"
                @click="handleEdit(data.item)"
              >
                <feather-icon class="color1" icon="Edit2Icon" />
              </a>
              <span class="mx-50 color1">|</span>
              <a v-if="(ability.can('delete', 'invoices'))"
                href="javascript:void(0)"
                v-b-popover.hover.top="'刪除發票'"
                @click="removeItem(data.item.id)"
              >
                <feather-icon class="color1" icon="Trash2Icon" />
              </a>
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watch,  onMounted, computed } from "@vue/composition-api";
import invoiceStoreModule from "../invoiceStoreModule";
import useInvoiceList from "./useInvoiceList";
import ModalPdf from "@/layouts/components/file/ModalPdf.vue";
import ModalInvoiceStatus from "../modal/modal-invoice-status.vue";
import ModalEditInvoice from "../modal/modal-edit-invoice.vue";
import ModalEditPayment from "../modal/modal-edit-payment.vue";
import ModalEditAudit from "../modal/modal-edit-audit.vue";
import ModalDelete from "../modal/modal-delete.vue";
import ModalFileManager from "@/layouts/components/file/ModalFileManager.vue";
import { getCorrectDateTime } from "@/libs/helper";
import router from "@/router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    vSelect,
    ModalPdf,
    ModalInvoiceStatus,
    ModalEditInvoice,
    ModalEditPayment,
    ModalEditAudit,
    ModalFileManager,
    ModalDelete,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      invoiceData: { id: 0, invoice_items: [{ id: 0 }] },
      pdfUrl: "",
      isEdit: false,
      paymentData: null,
      auditData: {},
      trigger: false,
      client_code: 0,
      path: "",
      select_invoice_id: null,
      editTrigger: false,
    };
  },
  props: {
    in_rent: {},
    contract_id: {},
    customer_id: {},
    mini_storage_id: {},
    fetchTrigger: {},
  },
  watch:{
        $route(to, from){
        this.fetchStatus()
        }
    }, 
  methods: {
    clearMiniStorage() {
      this.miniStorageFilter = null;
    },
    auditPayment(id) {
      (this.auditData = {
        id,
        receipt_date: getCorrectDateTime(new Date(), true),
        is_audit: 1,
      }),
        this.$bvModal.show("modal-edit-audit");
    },
    handleUpload(customer_id, file_path) {
      //console.log(item)
      this.client_code = customer_id;
      this.trigger = !this.trigger;
      this.path = `${file_path}`;
      this.$bvModal.show("modal-file-manager");
    },
    handleEdit(item) {
      this.invoiceData = item;
      this.isEdit = true;
      this.$bvModal.show("modal-edit-invoice");
    },
    fouceIsEditFalse() {
      this.isEdit = false;
      this.invoiceData = { id: 0, invoice_items: [{ id: 0 }] };
    },
    downloadInvoice(id) {
      this.loading = true;
      this.$store
        .dispatch("invoice/downloadInvoice", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    downloadReceipt(id) {
      this.loading = true;
      this.$store
        .dispatch("invoice/downloadReceipt", { id })
        .then((response) => {
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    handleEditStatus(item) {
      this.invoiceData = item;
      this.$bvModal.show("modal-invoice-status");
    },
    payment(invoice) {
      const target = this.customerOptions.find(
        (ele) => ele.id == invoice.customer_id
      )
      if(target){
        const points = this.customerOptions.find(
          (ele) => ele.id == invoice.customer_id
        ).points;
        this.paymentData = { invoice_id: invoice.id, points, ...invoice, id: 0 };
      }else{
        this.paymentData = { invoice_id: invoice.id, points: 0, ...invoice, id: 0 };
      }
      this.$bvModal.show("modal-edit-payment");
    },
    removeItem(id) {
      this.select_invoice_id = id;
      this.$bvModal.show("modal-delete");
    },
    handleRestore(id) {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("invoice/restoreInvoice", { id })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props) {
    const APP_PRICE_STORE_MODULE_NAME = "invoice";


    if (!store.hasModule(APP_PRICE_STORE_MODULE_NAME))
      store.registerModule(APP_PRICE_STORE_MODULE_NAME, invoiceStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_PRICE_STORE_MODULE_NAME))
        store.unregisterModule(APP_PRICE_STORE_MODULE_NAME);
    });
    const loading = ref(false);

    onMounted(() => {
      fetchStatus();
    });

    const fetchStatus = () => {
      let name = router.currentRoute.name;
      if (name == "invoice-pending-list") {
        invoice_status_id.value = 1;
      }else{
        invoice_status_id.value = null;
      }
    };

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      ability,
      refetchData,
      invoiceList,
      invoice_status_id,
      tableItemColumns,
      tablePaymentColumns,

      invoiceStatusOptions,
      // invoiceTypeOptions,
      contractOptions,
      customerOptions,
      saleOptions,
      invoiceStatusOptions2,
      miniStorageOptions,
      paymentTypeOptions,
      warehouseOptions,
      warehouse_id,
      miniStorageFilter,
      contractFilter,
    } = useInvoiceList();

    // trigger after payment schedule paid 
    const isFetchTriggerChanged = computed(() => props.fetchTrigger);
    watch(
      isFetchTriggerChanged,
      (newVal)=>{
         refetchData()
    })

    watch(() => props.contract_id, (newContractId) => {
      contractFilter.value = newContractId;
    }, { immediate: true });

    const getInvoiceStatus = computed(() => {
      return (item) => {
        const statusId = item.deleted_at
          ? 4
          : item.total_amount - item.total_paid_amount > 0
          ? 1
          : item.total_paid_amount - item.total_audit_paid_amount <= 0
          ? 3
          : item.total_amount - item.total_paid_amount <= 0
          ? 2
          : 1;
        return invoiceStatusOptions.value.find(ele => ele.id === statusId);
      };
    });

    const getPaymentTypeName = computed(() => {
      return (paymentTypeId) => {
        const paymentType = paymentTypeOptions.value.find(ele => ele.id == paymentTypeId);
        return paymentType ? paymentType.payment_name : '';
      };
    });

    const getSaleName = computed(() => {
      return (saleId) => {
        const sale = saleOptions.value.find(ele => ele.id == saleId);
        return sale ? sale.name : '';
      };
    });

    const getContractNumber = computed(() => {
      return (contractId) => {
        const contract = contractOptions.value.find(ele => ele.id == contractId);
        return contract ? contract.contract_number : '';
      };
    });

    const getCustomerName = computed(() => {
      return (customerId) => {
        const customer = customerOptions.value.find(ele => ele.id == customerId);
        return customer ? customer.name : '';
      };
    });

    const filteredMiniStorageOptions = computed(() => {
      return miniStorageOptions.value.filter(item => item.warehouse_id == warehouse_id.value);
    });

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      ability,
      refetchData,
      invoiceList,
      invoice_status_id,
      tableItemColumns,
      tablePaymentColumns,
      loading,

      invoiceStatusOptions,
      // invoiceTypeOptions,
      contractOptions,
      customerOptions,
      saleOptions,
      invoiceStatusOptions2,
      miniStorageOptions,
      paymentTypeOptions,
      warehouseOptions,
      warehouse_id,
      miniStorageFilter,
      fetchStatus,
      contractFilter,
      getInvoiceStatus,
      getPaymentTypeName,
      getSaleName,
      getContractNumber,
      getCustomerName,
      filteredMiniStorageOptions,
    };
  },
};
</script>
<style lang="scss">
</style>
