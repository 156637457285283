import axiosIns from '@/libs/axios'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addCustomer(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/customer', data,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPoints(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/loyalty_point', data
        )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/customer/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/customer', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/customer/export', { params: queryParams, responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.post('/customer', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/customer', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkImportCustomerData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/customer/validateCustomerImportData", data )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },




  },
}
