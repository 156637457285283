<template>
  <b-modal
    id="modal-edit-task"
    cancel-variant="outline-secondary"
    centered
    size="xl"
    title=""
    :ok-disabled="false"
    hide-header
    hide-footer
    @hide="resetModal"
  >
    <!-- no-close-on-backdrop -->
    <div class="blue-header sami-top-rounded bgColor1 text-center w-100">
      <h5 class="colorWhite">事件</h5>
      <a @click="hide" class="close-button">
        <feather-icon class="colorWhite" icon="XIcon" size="18" />
      </a>
    </div>
    <task-edit-tab-information 
      @hide="hide"
      :is_normal="true"
      @refetch-data = "$emit('refetch-data')" 
      :taskData="taskData"></task-edit-tab-information>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTextarea,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import TaskEditTabInformation from "../task-edit/task-edit-tab-information.vue";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    BCardBody,
    vSelect,
    BCard,
    TaskEditTabInformation,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    taskData: {},
  },
  data() {
    return {
    };
  },

  methods: {
    hide() {
      this.$bvModal.hide("modal-edit-task");
    },
    resetModal() {
      this.$emit("fouceIsEditFalse");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.modal-format1 {
  .form-group {
    margin-bottom: 0px;
  }
  label {
    font-weight: 800;
  }
}
</style>
<style lang="scss" scoped>
</style>
