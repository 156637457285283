<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div class="storage-info">
      <div>
        <div :class="storageData.contract_id ? 'mt-2 d-flex justify-content-center' : ''">
          <h4 class="colorRed">迷你倉資料</h4>
          <!-- <b-form-checkbox class="ml-3" v-if="!no_lease" v-model="isEdit">
            修改
          </b-form-checkbox> -->
        </div>
        <div class="row" v-if="storageData">
          <div class="d-flex py-50 col-6 align-items-center info-item">
            <b class="col-4">名稱</b>
            <span class="col-8">{{ storageData.name }}</span>
          </div>
          <div class="d-flex py-50 col-6 align-items-center info-item">
            <b class="col-4">大廈地址</b>
            <span class="col-8">{{ storageData.location }}</span>
          </div>
          <div class="d-flex py-50 col-6 align-items-center info-item">
            <b class="col-4">號碼</b>
            <span class="col-8">{{ storageData.storage_number }}</span>
          </div>
          <div class="d-flex py-50 col-6 align-items-center info-item">
            <b class="col-4">面積（平方呎）</b>
            <span class="col-8"
              >{{ storageData.width }} X {{ storageData.depth }} =
              {{ (storageData.width * storageData.depth).toFixed(1) }}</span
            >
          </div>
          <div class="d-flex py-50 col-6 align-items-center info-item">
            <b class="col-4">闊x深x高(呎)</b>
            <span class="col-8"
              >{{ storageData.width }} X {{ storageData.depth }} X
              {{ storageData.height }}</span
            >
          </div>
          <div class="d-flex py-50 col-6 align-items-center info-item">
            <b class="col-4">租用狀態</b>
           
            <span class="col-8"
              > {{ storageData.on_sell?'未租用': '已租用' }}</span
            >
          </div>          
        </div>
      </div>
    </div>
    <div v-if="isEdit || no_lease">
      <hr />
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
            <div>
                <b-form-group
                  label="迷你倉種類"
                  label-for="mini_storage_type_id"
                  class=""
                >
                  <!-- label-cols-xl="4" -->
                    <v-select
                      v-model="newStorageData.mini_storage_type_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="miniStorageTypeOptions"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="選擇迷你倉種類"
                      class="mb-50"
                      :disabled="true"
                    >
                    <template #option="{name, monthly_price, width, depth, height }">
                    [{{ name }}] {{ monthly_price }} {{ width }} * {{ depth }} * {{ height }}
                </template>
                <template #selected-option="{name, monthly_price, width, depth, height }">
                    [{{ name }}] {{ monthly_price }} {{ width }} * {{ depth }} * {{ height }}
                </template>
                  </v-select>
                  </v-select>
                </b-form-group>
              </div>
            <b-row>
              
              <b-col cols="3">
                <b-form-group
                  label="倉號"
                  label-for="width"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="倉號"
                    rules="required"
                  >
                    <b-form-input
                      type="text"
                      placeholder="倉號"
                      :state="getValidationState(validationContext)"
                      v-model="newStorageData.storage_number"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="闊度"
                  label-for="width"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="闊度"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="闊度"
                      :state="getValidationState(validationContext)"
                      v-model="newStorageData.width"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="深度"
                  label-for="width"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="深度"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="深度"
                      :state="getValidationState(validationContext)"
                      v-model="newStorageData.depth"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="高度"
                  label-for="width"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="高度"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="高度"
                      :state="getValidationState(validationContext)"
                      v-model="newStorageData.height"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="每月定價"
                  label-for="monthly_price"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="每月定價"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="每月定價"
                      v-model="newStorageData.monthly_price"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="預付6個月:"
                  label-for="bi_annual_price"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="預付6個月"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="預付6個月"
                      v-model="newStorageData.bi_annual_price"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="預付12個月:"
                  label-for="annual_price"
                  label-cols-xl="4"
                  class="required"
                >
                  <validation-provider
                    #default="validationContext"
                    name="預付12個月"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      step="0.01"
                      placeholder="預付12個月"
                      v-model="newStorageData.annual_price"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!storageData.contract_id">
              <b-col cols="12" xl="3">
                <b-form-group
                  label="租用狀態"
                  label-for="on_sell"
                  label-cols-xl="4"
                  class=""
                >
                    <v-select
                      v-model="newStorageData.on_sell"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rentOptions"
                      :clearable="false"
                      label="label"
                      :reduce="(option) => option.id"
                      placeholder="選擇租用狀態"
                      class="mb-50"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="3">
                <b-form-group
                  label="客戶"
                  label-for="customer_id"
                  label-cols-xl="4"
                  :class="`${newStorageData.on_sell == 0? 'required': ''}`"
                >
                <validation-provider
                    #default="validationContext"
                    name="客戶"
                    :rules="`${newStorageData.on_sell == 0? 'required': ''}`"
                  >
                    <v-select
                      v-model="newStorageData.customer_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customerOptions"
                      :filter="customFilter"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="選擇客戶"
                      class="mb-50"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="3">
                <b-form-group
                  label="租用類型"
                  label-for="rent_type_id"
                  label-cols-xl="4"
                  :class="`${newStorageData.on_sell == 0? 'required': ''}`"
                >
                <validation-provider
                    #default="validationContext"
                    name="租用類型"
                    :rules="`${newStorageData.on_sell == 0? 'required': ''}`"
                  >
                    <v-select
                      v-model="newStorageData.rent_type_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="rentTypeOptions && rentTypeOptions.filter(item => item.internal_bit)"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="選擇租用類型"
                      class="mb-50"
                      
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
            </b-row>

            <div>
              <b-button v-if="(ability.can('update', 'rents')) && !newStorageData.contract_id" variant="primary" type="submit" class="w-100 mt-2">
                提交
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import ability from '@/libs/acl/ability'

import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      ability,
      isEdit: true,
      rentOptions: [
        { id: 0, label: "已租用" },
        { id: 1, label: "未租用" },
      ],
    };
  },
  props: {
    storageData: {},
    no_lease: {},
    customerOptions: {},
    rentTypeOptions: {},
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          const warehouse_id = this.newStorageData.warehouse_id;
          delete this.newStorageData.warehouse_id;
          //console.log(this.newStorageData)
          axiosIns
            .put("/warehouse/mini_storage/", {
              warehouse_id: warehouse_id,
              ...this.newStorageData
            })
            .then((response) => {
              console.log("refetch-data");
              this.$emit("refetch-data");
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.fetchMiniStorageTypes();
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    customFilter(item, queryText, itemText) {
      queryText = typeof queryText === 'number' ? queryText.toString() : queryText;
      const hasMatch = item.filter(obj => {

        const name = typeof obj.name === 'number' ? obj.name.toString() : obj.name;
        const name_en = typeof obj.name_en === 'number' ? obj.name_en.toString() : obj.name_en;

        return (
          (name && name.toLowerCase().includes(queryText.toLowerCase())) ||
          (name_en && name_en.toLowerCase().includes(queryText.toLowerCase()))
        )
      });
      return hasMatch;
    },
  },
  watch: {},
  setup(props) {
    const loading = ref(false);
    const newStorageData = ref({ id: 0 });

    const resetStorageData = () => {
      newStorageData.value = { id: 0 };
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetStorageData);

    watch(
      () => props.storageData,
      (newVal) => {
        newStorageData.value = { ...newVal };
      },
      { immediate: true }
    );

    const miniStorageTypeOptions = ref([]);
    const fetchOptions = () => {
      axiosIns
        .get("/mini_storage_type/list", {
          params: { warehouse_id: props.storageData.warehouse_id },
        })
        .then((response) => {
          miniStorageTypeOptions.value = response.data.mini_storage_types;
        });
    };
    onMounted(() => {
      fetchOptions();
    });

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newStorageData,
      miniStorageTypeOptions,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  